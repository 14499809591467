import { push } from 'connected-react-router';
import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';

import { acceptanceProtocolRoutes } from '../../acceptance-protocol/routes';
import {
  selectCurrentId,
  selectIsLeavingAgreementStep,
} from '../../acceptance-protocol/redux/selectors';
import { COMMON_ABORT_AND_CLOSE_SIGN_PAGE } from './constants';

export function commonAbortAndCloseSignPage() {
  return {
    type: COMMON_ABORT_AND_CLOSE_SIGN_PAGE,
  };
}

function* doRedirectSignPage() {
  const protocolId = yield select(selectCurrentId);
  const isLeavingAgreementStep = yield select(selectIsLeavingAgreementStep);

  const route = isLeavingAgreementStep
    ? acceptanceProtocolRoutes.leavingAgreementStep
    : acceptanceProtocolRoutes.signStep;

  yield put(push(route(protocolId)));
}

export function* switchRedirectSignPage() {
  yield takeLatest(COMMON_ABORT_AND_CLOSE_SIGN_PAGE, doRedirectSignPage);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_ABORT_AND_CLOSE_SIGN_PAGE:
        draft.currentSigner = {};
        break;
      default:
        return state;
    }
  });
