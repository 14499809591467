import { call, takeLatest } from 'redux-saga/effects';

import { askForConfirmation } from '../../../common/redux/openConfirmationModal';
import { FILL_STEP_CONFIRM_MODAL } from './constants';

export function fillStepConfirmModal(message, action) {
  return {
    type: FILL_STEP_CONFIRM_MODAL,
    payload: { message, action },
  };
}

function* doConfirmModal({ payload: { message, action } }) {
  const confirmed = yield call(askForConfirmation, message);
  if (confirmed) {
    action();
  }
}

export function* switchConfirmModal() {
  yield takeLatest(FILL_STEP_CONFIRM_MODAL, doConfirmModal);
}
