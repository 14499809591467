/** @jsxImportSource @emotion/react */
import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { Camera, ErrorTypes, FacingModes } from './Camera/Camera';
import { Icon } from './Icon';
import { colors } from '../../common/theme/colors';
import { v4 as uuid } from 'uuid';

const styles = {
  webCam: {
    gridColumn: '1',
    gridRow: '1',
    zIndex: '100',
  },
  buttons: {
    height: '100%',
    justifySelf: 'end',
    alignSelf: 'center',
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '1fr 1fr',
    gridColumn: '1',
    gridRow: '1',
    zIndex: '101',
    padding: '1em',
  },
  bottomButton: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
  },
  changeCameraButton: {
    gridColumn: '1',
    gridRow: '1',
    alignSelf: 'end',
    cursor: 'pointer',
    borderRadius: '50%',
    backgroundColor: colors.white,
  },
  takePhotoButton: {
    gridColumn: '1',
    gridRow: '2',
    alignSelf: 'start',
    cursor: 'pointer',
    backgroundColor: colors.white,
    borderRadius: '50%',
  },
  exitButton: {
    gridColumn: '1',
    gridRow: '2',
    alignSelf: 'end',
    cursor: 'pointer',
    backgroundColor: colors.white,
    borderRadius: '50%',
  },
  stream: {
    width: '640px',
    height: '480px',
  },
};

export const ImageTakePhoto = (props) => {
  const { onClose, loadImage, fillStepHideSpinner, ...passThroughProps } = props;
  const [windowHeight, setWindowHeight] = useState(window.innerHeight * 0.01);
  const camera = useRef(null);

  const errorMessages = useMemo(
    () => ({
      [ErrorTypes.PERMISSION_DENIED]:
        'Permission denied. Please refresh and give camera permission.',
      [ErrorTypes.GENERAL_ERROR]:
        'Any camera device accessible. Please connect your camera or try different browser.',
      [ErrorTypes.NO_CAMERA_AVAILABLE]: "There isn't any video device accessible.",
      [ErrorTypes.CANVAS_NOT_SUPPORTED]: 'Canvas is not supported',
      [ErrorTypes.SWITCH_NOT_POSSIBLE]:
        'It is not possible to switch camera to different one, because there is only one video device accessible.',
      [ErrorTypes.CAMERA_NOT_INITIALIZED]:
        'Camera is not available yet. Please wait a bit and try again.',
      [ErrorTypes.DEBUG]: 'DEBUG: ',
    }),
    [],
  );

  const containerStyle = useCallback(
    () => ({
      display: 'grid',
      height: `calc(${windowHeight}px * 100)`,
      width: '100vw',
      gridTemplateRows: '100%',
      gridTemplateColumns: '100%',
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: colors.black,
    }),
    [windowHeight],
  );

  const handleTakePhoto = useCallback(async () => {
    if (camera.current) {
      const filename = uuid() + '.jpg';
      const imageData = await camera.current.takePhoto(filename);

      loadImage(filename, imageData);
    }
  }, [camera, loadImage]);

  const handleChangeCamera = useCallback(() => {
    if (camera.current) {
      camera.current.switchCamera();
    }
  }, [camera]);

  const handleUploadImage = useCallback(
    (filename, imageData) => {
      loadImage(filename, imageData);
    },
    [loadImage],
  );

  const handleExit = useCallback(() => {
    onClose();
    fillStepHideSpinner();
  }, [onClose, fillStepHideSpinner]);

  const handleErrorMessage = useCallback(
    (errorType, customMessage = null) => {
      console.error('CAMERA ERROR:', errorMessages[errorType], customMessage);
    },
    [errorMessages],
  );

  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight * 0.01);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div css={containerStyle}>
      <Camera
        style={styles.webCam}
        ref={camera}
        facingMode={FacingModes.ENVIRONMENT}
        onUploadImage={handleUploadImage}
        onErrorMessage={handleErrorMessage}
        {...passThroughProps}
      />
      <div css={styles.buttons}>
        <Icon
          icon={'sync'}
          size={'4x'}
          color={colors.black}
          css={styles.changeCameraButton}
          onClick={handleChangeCamera}
          transform={'shrink-6'}
          data-testid={'changeCameraButton'}
        />
        <Icon
          icon={'camera'}
          size={'4x'}
          color={colors.black}
          css={styles.takePhotoButton}
          onClick={handleTakePhoto}
          transform={'shrink-6'}
          data-testid={'takePhotoButton'}
        />

        <Icon
          icon={'reply'}
          size={'4x'}
          color={colors.black}
          css={styles.exitButton}
          onClick={handleExit}
          transform={'shrink-6'}
        />
      </div>
    </div>
  );
};
