/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Component, useCallback } from 'react';
import Fade from 'react-reveal/Fade';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import { i18n } from '../../common/i18n-loader';
import { colors } from '../../common/theme/colors';
import plusButton from '../../images/plus-icon.svg';
import arrowInBig from '../../images/arrow-in-big.svg';
import arrowOutBig from '../../images/arrow-out-big.svg';
import arrowInOut from '../../images/arrow-in-out.svg';
import Button from '../common/Button';
import Modal from '../common/Modal';
import { SearchResultCard } from '../common/SearchResultCard';
import Spinner from '../common/Spinner';
import { NewProtocolWithREMReferenceModal } from './NewProtocolWithREMReferenceModal';

const baseResult = {
  alignItems: 'center',
};

const baseGridResult = {
  ...baseResult,
  cursor: 'pointer',
};

const styles = {
  container: {
    height: `100%`,
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'min-content auto',
    overflow: 'hidden',

    search: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#E1E1E1',
      padding: '10px',
      label: {
        border: 'none',
        padding: '0',
        fontWeight: 'bold',
      },
      input: {
        border: 'none',
        padding: '3px 10px',
        borderRadius: '5px',
        height: '25px',
        fontSize: '14px',
      },
    },

    results: {
      overflow: 'auto',
      display: 'grid',
      gridAutoFlow: 'row',
      gridTemplateRows: 'auto',
      alignItems: 'center',
      padding: '10px',

      card: {
        marginBottom: '7px',
        backgroundColor: '#F1F1F1',
        borderRadius: '3px',
        padding: '5px',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.16)',
      },

      cardInUse: {
        marginBottom: '7px',
        borderRadius: '3px',
        padding: '5px',
        cursor: 'default',
        pointerEvents: 'none',
        userSelect: 'none',
        backgroundColor: '#CACACA',
      },

      containerIsInUse: {
        cursor: 'default',
        pointerEvents: 'none',
        userSelect: 'none',
      },
      result: {
        ...baseGridResult,
      },
      altResult: {
        ...baseGridResult,
        background: `${colors.whiteSmoke}`,
      },
      noResult: {
        ...baseResult,
        background: `${colors.whiteSmoke}`,
        padding: '1.5em',
      },
      iconLarge: {
        textAlign: 'center',
        height: '18px',
        width: '24px',
        paddingLeft: '0.5em',
        paddingRight: '1.5em',
      },
      iconSmall: {
        textAlign: 'center',
        height: '20px',
        width: '24px',
        paddingLeft: '0.5em',
        paddingRight: '1.5em',
      },
      isInUse: {
        whiteSpace: 'nowrap',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: '#656565',
      },
      plus: {},
    },
  },
  modal: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content',

    buttons: {
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
      gridTemplateRows: 'auto',
      gridTemplateAreas: '". leftButton rightButton"',
      gridGap: '0 1em',
      justifyContent: 'right',
      alignItems: 'center',

      '& .leftButton': {
        gridArea: 'leftButton',
      },
      '& .rightButton': {
        gridArea: 'rightButton',
      },
    },
    modeSwitchButton: {
      textDecoration: 'underline',
      fontWeight: 'bold',
      cursor: 'pointer',
      backgroundColor: 'unset',
      justifySelf: 'start',
    },
  },

  modalPrevious: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    gridGap: '1em',

    modalContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr',

      function: {
        display: 'grid',
        gridTemplateRows: 'min-content min-content',
        gridGap: '.5em',

        checkboxesContainer: {
          display: 'grid',
          gridTemplateRows: '1fr 1fr',
          justifyContent: 'left',
          gridGap: '.5em',
        },
        checkboxes: {
          display: 'grid',
          gridAutoFlow: 'column',
          gridAutoColumns: 'min-content',
          gridColumnGap: '4px',
          whiteSpace: 'nowrap',
        },
        buttons: {
          marginTop: '1em',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1em',
          justifyContent: 'left',
        },
      },
    },
  },
};

const RentalObject = (props) => {
  const {
    item,
    onClick,
    formerTenantReferenceNumber,
    tenantReferenceNumber,
    buildingStreet,
    buildingZipcode,
    buildingPlace,
    numberOfRoomsCode,
    floorCode,
    isInUseByUser,
    handOverIcon,
    language,
  } = props;

  const handleClick = useCallback(() => onClick(item), [onClick, item]);

  return (
    <div css={isInUseByUser ? styles.container.results.cardInUse : styles.container.results.card}>
      <Fade duration={500} collapse>
        <div
          css={{
            ...styles.container.results.result,
            ...(isInUseByUser && styles.container.results.containerIsInUse),
          }}
          onClick={!isInUseByUser ? handleClick : undefined}
        >
          <SearchResultCard
            formerTenantReferenceNumber={formerTenantReferenceNumber}
            tenantReferenceNumber={tenantReferenceNumber}
            buildingStreet={buildingStreet}
            buildingZipcode={buildingZipcode}
            buildingPlace={buildingPlace}
            numberOfRoomsCode={numberOfRoomsCode}
            floorCode={floorCode}
            tenantForname={item?.rentalObject?.tenantForename}
            tenantSurname={item?.rentalObject?.tenantSurname}
            previousProtocolId={item?.previousProtocolId}
            previousProtocolTemplate={item?.previousProtocolTemplate}
            previousProtocolCreatedAt={item?.previousProtocolCreatedAt}
            formerTenantForename={item?.rentalObject?.formerTenantForename}
            formerTenantSurname={item?.rentalObject?.formerTenantSurname}
            language={language}
            arrowIcon={!!handOverIcon && handOverIcon}
            addIcon={
              !isInUseByUser && (
                <img css={styles.container.results.plus} src={plusButton} alt="plus" />
              )
            }
          />
        </div>
      </Fade>
    </div>
  );
};

export class SearchRentalObjects extends Component {
  constructor() {
    super();
    this.state = { currentRentalObject: {}, checkedItems: new Map() };
  }

  componentDidMount() {
    this.searchRental.focus();
  }

  checkboxes = [
    {
      name: 'images',
      key: 'imagesCheckbox',
      label: 'CHOOSE-DATA-TO-COPY-MODAL.IMAGES',
    },
    {
      name: 'pdf',
      key: 'pdfCheckbox',
      label: 'CHOOSE-DATA-TO-COPY-MODAL.PDF',
    },
  ];

  handleChange = (event) => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    this.setState((prevState) => ({ checkedItems: prevState.checkedItems.set(item, isChecked) }));
  };

  getHandOverIcon(item) {
    const types = [
      <div css={styles.container.results.iconSmall} />,
      <div css={styles.container.results.iconLarge}>
        <img src={arrowInBig} alt="In" />
      </div>,
      <div css={styles.container.results.iconLarge}>
        <img src={arrowOutBig} alt="Out" />
      </div>,
      <div css={styles.container.results.iconSmall}>
        <img src={arrowInOut} alt="InOut" />
      </div>,
    ];
    const index = (item.formerTenantSurname ? 2 : 0) + (item.tenantSurname ? 1 : 0);
    return types[index];
  }

  getAddMarkerForText(text) {
    const escapeRegExp = (x) => x.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const pattern =
      '(' +
      text
        .split(' ')
        .filter((x) => x)
        .map(escapeRegExp)
        .join('|') +
      ')';

    const regexp = new RegExp(pattern, 'gi');
    let count = -1;

    return (item) => {
      if (!item) return null;

      regexp.lastIndex = 0;

      let results = [];
      let index = 0;
      let match = regexp.exec(item);

      while (match) {
        count++;
        results.push(<span key={count + '-text'}>{item.substring(index, match.index)}</span>);
        results.push(
          <mark key={count + '-mark'}>{item.substr(match.index, match[0].length)}</mark>,
        );
        index = match.index + match[0].length;
        match = regexp.exec(item);
      }

      count++;
      results.push(<span key={count + '-text'}>{item.substring(index, item.length)}</span>);
      return results;
    };
  }

  static propTypes = {
    isOnline: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    search: PropTypes.string,
    rentalObjects: PropTypes.array,
    onSearch: PropTypes.func,
  };

  onHandleOpenCreateProtocolWithREMReferenceModal = (item) => {
    const { onOpenProtocolWithREMReferenceModal } = this.props;
    this.setState({ currentRentalObject: item, checkedItems: new Map() });
    onOpenProtocolWithREMReferenceModal();
  };

  onHandleOpenCreateProtocolFromPreviousProtocolModal = (item) => {
    const { onOpenProtocolFromPreviousProtocolModal } = this.props;
    this.setState({
      currentRentalObject: item,
      checkedItems: new Map([
        ['images', true],
        ['pdf', true],
      ]),
    });
    onOpenProtocolFromPreviousProtocolModal();
  };

  onHandleCreateProtocolWithREMReference = (templateId) => {
    const { onCreateProtocolWithREMReference, language } = this.props;
    onCreateProtocolWithREMReference({
      rentalObjectId: this.state.currentRentalObject.rentalObject.id,
      templateId: templateId,
      language: language,
    });
  };

  onHandleCreateProtocolWithREMReferenceFromAnotherProtocol = (sourceRentalObjectReference) => {
    const { onCreateProtocolWithREMReferenceFromAnotherProtocol } = this.props;

    onCreateProtocolWithREMReferenceFromAnotherProtocol(
      this.state.currentRentalObject.rentalObject.id,
      sourceRentalObjectReference,
    );
  };

  onHandleCreateProtocolWithREMReferencWithPrevious = () => {
    const { onCreateProtocolFromPreviousProtocol } = this.props;
    const downloadImages = !!this.state.checkedItems.get('images')
      ? this.state.checkedItems.get('images')
      : false;
    const downloadPdf = !!this.state.checkedItems.get('pdf')
      ? this.state.checkedItems.get('pdf')
      : false;
    onCreateProtocolFromPreviousProtocol({
      downloadImages: downloadImages,
      downloadPdf: downloadPdf,
      rentalObjectId: this.state.currentRentalObject.rentalObject.id,
      previousProtocolId: this.state.currentRentalObject.previousProtocolId,
    });
  };

  render() {
    const {
      language,
      onSearch,
      isOnline,
      templates,
      isCreateWithREMReferenceModalOpen,
      isCreateProtocolFromPreviousProtocolModalOpen,
      onCloseProtocolWithREMReferenceModal,
      onCloseProtocolFromPreviousProtocolModal,
      isBusyTemplates,
      isBusyRentalObjects,
      isBusyCreateProtocolWithREMReference,
      isCreateProtocolWitREMReferenceFromAnotherProtocolBusy,
      createProtocolFromPreviousProtocolBusy,
      hasInitialAcceptance,
    } = this.props;

    const isCreateProtocolWithRemReferenceBusy =
      isCreateProtocolWitREMReferenceFromAnotherProtocolBusy ||
      isBusyCreateProtocolWithREMReference ||
      isBusyTemplates;

    return (
      <div css={styles.container}>
        <div css={styles.container.search}>
          <label css={styles.container.label}>
            <Trans>SEARCH.RENTAL-OBJECT</Trans>
          </label>
          <input
            ref={(input) => {
              this.searchRental = input;
            }}
            css={styles.container.input}
            onKeyUp={isOnline ? onSearch : () => {}}
          />
        </div>
        <div css={styles.container.results}>
          <Spinner show={isBusyRentalObjects}>{this.showResult()}</Spinner>
        </div>

        <NewProtocolWithREMReferenceModal
          language={language}
          styles={styles}
          isModalOpen={isCreateWithREMReferenceModalOpen}
          onCloseModal={onCloseProtocolWithREMReferenceModal}
          isBusy={isCreateProtocolWithRemReferenceBusy}
          onHandleCreateProtocolFromTemplate={(values) =>
            this.onHandleCreateProtocolWithREMReference(values.value)
          }
          onHandleCreateProtocolFromAnotherProtocol={(values) =>
            this.onHandleCreateProtocolWithREMReferenceFromAnotherProtocol(
              values.sourceRentalObjectReference,
            )
          }
          templates={templates}
          hasInitialAcceptance={hasInitialAcceptance}
        />

        <Modal
          title={i18n._('CHOOSE-DATA-TO-COPY-MODAL.TITLE')}
          isOpen={isCreateProtocolFromPreviousProtocolModalOpen}
          onClose={onCloseProtocolFromPreviousProtocolModal}
          zIndex={1003}
          width="50vw"
        >
          <Spinner show={createProtocolFromPreviousProtocolBusy}>
            <div css={styles.modalPrevious}>
              <span>
                <Trans>CHOOSE-DATA-TO-COPY-MODAL.DESCRIPTION</Trans>
              </span>
              <div css={styles.modalPrevious.modalContainer}>
                <div css={styles.modalPrevious.modalContainer.function}>
                  <div css={styles.modalPrevious.modalContainer.function.checkboxesContainer}>
                    {this.checkboxes.map((item) => (
                      <span
                        key={item.key}
                        css={styles.modalPrevious.modalContainer.function.checkboxes}
                      >
                        <input
                          type="checkbox"
                          name={item.name}
                          value={this.state.checkedItems[item.name]}
                          checked={this.state.checkedItems.get(item.name)}
                          onChange={this.handleChange}
                        />
                        {i18n._(item.label)}
                      </span>
                    ))}
                  </div>
                  <div css={styles.modalPrevious.modalContainer.function.buttons}>
                    <Button secondary onClick={onCloseProtocolFromPreviousProtocolModal}>
                      <Trans>CHOOSE-DATA-TO-COPY-MODAL.ABORT</Trans>
                    </Button>
                    <Button
                      default
                      onClick={this.onHandleCreateProtocolWithREMReferencWithPrevious}
                    >
                      <Trans>CHOOSE-DATA-TO-COPY-MODAL.DOWNLOAD</Trans>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Spinner>
        </Modal>
      </div>
    );
  }

  showResult() {
    const {
      search,
      isBusyRentalObjects,
      rentalObjects,
      isOnline,
      onOpenProtocolWithREMReferenceModal,
      language,
    } = this.props;

    if (!search | isBusyRentalObjects) {
      return false;
    }

    if (!!rentalObjects && !!rentalObjects.length) {
      return this.showRentalObjects(
        search,
        rentalObjects,
        onOpenProtocolWithREMReferenceModal,
        language,
      );
    } else {
      if (!isOnline) {
        return (
          <div css={styles.container.results.noResult}>
            <Trans>SEARCH.OFFLINE-RESULT</Trans>
          </div>
        );
      }

      return (
        <div css={styles.container.results.noResult}>
          <Trans>SEARCH.NO-RESULTS</Trans>
        </div>
      );
    }
  }

  showRentalObjects(search, rentalObjects, onOpenProtocolWithREMReferenceModal, language) {
    const addMarker = this.getAddMarkerForText(search);

    return (
      <TransitionGroup appear enter exit>
        {rentalObjects?.map((item, itemIndex) => (
          <RentalObject
            onOpenProtocolWithREMReferenceModal={onOpenProtocolWithREMReferenceModal}
            key={itemIndex}
            itemIndex={itemIndex}
            item={item}
            language={language}
            handOverIcon={this.getHandOverIcon(item.rentalObject)}
            onClick={
              !!item.previousProtocolId
                ? this.onHandleOpenCreateProtocolFromPreviousProtocolModal
                : this.onHandleOpenCreateProtocolWithREMReferenceModal
            }
            formerTenantReferenceNumber={addMarker(item.rentalObject.formerTenantReferenceNumber)}
            tenantReferenceNumber={addMarker(item.rentalObject.tenantReferenceNumber)}
            buildingStreet={addMarker(item.rentalObject.buildingStreet)}
            buildingZipcode={addMarker(item.rentalObject.buildingZipcode)}
            buildingPlace={addMarker(item.rentalObject.buildingPlace)}
            numberOfRoomsCode={addMarker(item.rentalObject.numberOfRoomsCode)}
            floorCode={addMarker(item.rentalObject.floorCode)}
            tenantForname={addMarker(item.rentalObject.formerTenantForename)}
            tenantSurname={addMarker(item.rentalObject.formerTenantSurname)}
            isInUseByUser={item.rentalObject.isInUseByUser}
          />
        ))}
      </TransitionGroup>
    );
  }
}
