import { getByProperty } from '../../features/common/Functions';
import { join } from 'ramda';

export function extractNames(fields, ...names) {
  const values = [];
  for (const name of names) {
    const field = getByProperty('fieldname', name)(fields);
    if (!!field && !!field.value) {
      values.push(field.value);
    }
  }
  return join(' ', values);
}
