import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { persistor, store } from './common/configStore';
import routeConfig from './common/routeConfig';
import { actions as toastrActions } from 'react-redux-toastr';
import { i18n } from './common/i18n-loader';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import {
  Sentry,
  ReportingObserverIntegration,
  ExtraErrorDataIntegration,
  DedupeIntegration,
  RewriteFramesIntegration,
} from './common/sentry-loader';
import { sentryConfiguration } from './common/env.service';
import version from './version.json';
import { commonPersistSentryError } from './features/common/redux/sentryConf';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

Sentry.init({
  dsn: sentryConfiguration.dsn,
  release: version.version,
  environment: sentryConfiguration.environment,
  debug: false, //process.env.NODE_ENV !== 'production',
  ignoreErrors: [],
  integrations: [
    new ReportingObserverIntegration(),
    new ExtraErrorDataIntegration(),
    new DedupeIntegration(),
    new RewriteFramesIntegration(),
  ],
  beforeSend(event, hint) {
    if (!navigator.onLine) {
      const error = hint.originalException;

      store.dispatch(commonPersistSentryError(error));
      return null; //we return null and don't keep the event, because the error is persisted to the store
    }
    return event;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Root store={store} persistor={persistor} routeConfig={routeConfig} />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.register({
  onUpdate: (reg) => {
    serviceWorkerRegistration.addClientReloadListener();
    store.dispatch(
      toastrActions.add({
        options: {
          timeOut: 0,
          onToastrClick: () => reg.waiting.postMessage({ type: 'SKIP_WAITING' }),
        },
        type: 'warning',
        attention: true,
        message: i18n._('MESSAGES.NEW-VERSION'),
      }),
    );
  },
});

// Add event listener to detect translation
document.addEventListener('DOMSubtreeModified', (event) => {
  const html = document.documentElement;
  if (html.classList.contains('translated-ltr')) {
    // Translation has occurred!
    console.info('Auto Translation detected!');
  }
});

// Modified event listener to detect translation using MutationObserver
const observer = new MutationObserver((mutations) => {
  try {
    mutations.forEach((mutation) => {
      if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
        for (const node of mutation.addedNodes) {
          if (node instanceof HTMLElement && hasTranslatedContent(node)) {
            console.info('Auto Translation detected!');
            return; // No need to check further if translated content is found
          }
        }
      }
      if (
        mutation.type === 'attributes' &&
        (mutation.attributeName === 'lang' || mutation.attributeName === 'translate')
      ) {
        if (hasTranslatedContent(mutation.target)) {
          console.info('Auto Translation detected!');
        }
      }
    });
  } catch (error) {
    console.error('Error during translation detection:', error);
    // Optionally, report this error to error tracking service (e.g., Sentry)
    Sentry.captureException(error);
  }
});

observer.observe(document.body, { childList: true, subtree: true, attributes: true });

// Helper function to recursively check for translated content within an element
function hasTranslatedContent(element) {
  // Check if the element itself has translation-related attributes or classes
  if (
    element.hasAttribute('translate') ||
    (element.lang && !['de', 'it', 'fr', 'en'].includes(element.lang))
  ) {
    return true;
  }

  // Recursively check child elements
  for (const child of element.children) {
    if (hasTranslatedContent(child)) {
      return true;
    }
  }

  return false;
}
