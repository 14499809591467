import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';

import { post } from '../../common/services/api';
import {
  MIGRATION_START_MIGRATION,
  MIGRATION_START_MIGRATION_SUCCESS,
  MIGRATION_START_MIGRATION_ERROR,
} from './constants';

export function migrationStartMigration(id) {
  return {
    type: MIGRATION_START_MIGRATION,
    payload: id,
  };
}

function* doStartMigration({ payload }) {
  try {
    yield call(post, 'migration/start', { migrationId: payload });

    yield put({
      type: MIGRATION_START_MIGRATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: MIGRATION_START_MIGRATION_ERROR,
      payload: error.message,
    });
  }
}

export function* switchStartMigration() {
  yield takeLatest(MIGRATION_START_MIGRATION, doStartMigration);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_START_MIGRATION:
        draft.ui.busy.loading = true;
        break;
      case MIGRATION_START_MIGRATION_SUCCESS:
        draft.ui.busy.loading = false;
        break;
      case MIGRATION_START_MIGRATION_ERROR:
        draft.ui.busy.loading = false;
        break;
      default:
        return state;
    }
  });
