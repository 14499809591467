/** @jsxImportSource @emotion/react */
import { colors } from '../../common/theme/colors';
import { Icon } from '../common/Icon';
import { Fragment } from 'react';

export const StatusIcon = (props) => {
  const { success, size } = props;

  return (
    <Fragment>
      {success ? (
        <Icon icon={'check'} color={colors.green} size={size} />
      ) : (
        <Icon icon={'exclamation'} color={colors.red} size={size} />
      )}
    </Fragment>
  );
};
