/* This is the Root component mainly initializes Redux and React Router. */
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { Route, Switch } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import history from './common/history';
import I18nLoader from './common/i18n-loader';
import { ErrorBoundary, ProviderErrorBoundary } from './features/common/ErrorBoundary';

function renderRouteConfigV3(routes, contextPath, SwitchComponent) {
  // Resolve route config object in React Router v3.
  const children = []; // children component list

  const renderRoute = (item, routeContextPath) => {
    let newContextPath;
    if (/^\//.test(item.path)) {
      newContextPath = item.path;
    } else {
      newContextPath = `${routeContextPath}/${item.path}`;
    }
    newContextPath = newContextPath.replace(/\/+/g, '/');
    if (item.component && item.childRoutes) {
      const childRoutes = renderRouteConfigV3(item.childRoutes, newContextPath, Switch);
      children.push(
        <Route
          key={newContextPath}
          render={(props) => <item.component {...props}>{childRoutes}</item.component>}
          path={newContextPath}
        />,
      );
    } else if (item.component) {
      children.push(
        <Route key={newContextPath} component={item.component} path={newContextPath} exact />,
      );
    } else if (item.childRoutes) {
      item.childRoutes.forEach((r) => renderRoute(r, newContextPath));
    }
  };

  routes.forEach((item) => renderRoute(item, contextPath));

  // Use Switch so that only the first matched route is rendered.
  return <SwitchComponent>{children}</SwitchComponent>;
}

export default class Root extends React.Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    persistor: PropTypes.object.isRequired,
    routeConfig: PropTypes.array.isRequired,
  };
  render() {
    const children = renderRouteConfigV3(this.props.routeConfig, '/', Switch);
    return (
      <ErrorBoundary>
        <Provider store={this.props.store}>
            <ProviderErrorBoundary>
              <PersistGate loading={null} persistor={this.props.persistor}>
                <I18nLoader>
                  <ReduxToastr
                    timeOut={4000}
                    newestOnTop={true}
                    position="top-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick
                  />
                  <ConnectedRouter history={history}>{children}</ConnectedRouter>
                </I18nLoader>
              </PersistGate>
            </ProviderErrorBoundary>
        </Provider>
      </ErrorBoundary>
    );
  }
}
