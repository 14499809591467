import styled from '@emotion/styled';

import { colors } from '../../common/theme/colors';

export default styled.textarea((props) => {
  let textarea = {
    height: '100%',
    width: '100%',
    border: `solid 1px ${colors.lighterGrey}`,
    padding: '16px',
    borderBottomLeftRadius: '8px',
    borderBottomRightRadius: '8px',

    resize: 'none',
  };

  return textarea;
});
