import { takeLatest } from 'redux-saga/effects';
import {
  COMMON_DELETE_PROTOCOL_ERROR,
  COMMON_GET_PROTOCOLS_ERROR,
  COMMON_LOGIN_ERROR,
  COMMON_MARK_AS_OFFLINE_ERROR,
  COMMON_UPDATE_PROTOCOL_ERROR,
  COMMON_ERROR_SIGN,
  COMMON_GET_LEAVING_AGREEMENT_ERROR,
  COMMON_NOTIFY_ERROR,
  COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR,
} from './constants';
import { doToast } from "../../../common/rootToastr";

export function notifyError(message) {
  return {
    type: COMMON_NOTIFY_ERROR,
    payload: message,
  };
}

export function notifyGetTenancyAgreementError(message) {
  return {
    type: COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR,
    payload: message,
  };
}

function* doNotifyError({ payload }) {
  yield* doToast({
    payload: {
      type: 'error',
      attention: true,
      message: payload,
    }
  });
}

function* doNotifyErrorWithCustom({ payload }) {
  yield* doToast({
    payload: {
      type: 'error',
      attention: true,
      message: payload.error,
    }
  });
}

export function* doNotifyGetTenancyAgreementError({ payload }) {
  yield* doToast({
   payload: {
      type: 'error',
      attention: true,
      message: payload,
    }
  });
}

export function* switchNotifyError() {
  yield takeLatest(
    [
      COMMON_NOTIFY_ERROR,
      COMMON_LOGIN_ERROR,
      COMMON_GET_PROTOCOLS_ERROR,
      COMMON_UPDATE_PROTOCOL_ERROR,
      COMMON_DELETE_PROTOCOL_ERROR,
      COMMON_GET_LEAVING_AGREEMENT_ERROR,
      COMMON_ERROR_SIGN,
    ],
    doNotifyError,
  );

  yield takeLatest(COMMON_MARK_AS_OFFLINE_ERROR, doNotifyErrorWithCustom);

  yield  takeLatest(COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR, doNotifyGetTenancyAgreementError);
}
