import React from 'react';

export const Industry = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 50"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <rect x="24" y="38" width="2" height="1" />
    <rect x="24" y="45" width="2" height="1" />
    <path d="M49,34V32h8V20H37V32h8v2H28V28H19v6H17V48h2V43H31v5h2V36H61V48h2V34ZM39,22H55v8H39ZM20,29h7v2H20Zm0,3h7v2H20Zm11,9H19V36H31Z" />
  </svg>
);
