import produce from 'immer';

import { COMMON_OPEN_INFORMATION_MODAL } from './constants';

export function commonOpenInformationModal({ title, message, ok }) {
  return {
    type: COMMON_OPEN_INFORMATION_MODAL,
    payload: { title, message, ok },
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_OPEN_INFORMATION_MODAL:
        const { title, message, ok } = action.payload;
        draft.ui.modal.information = true;
        draft.ui.information.title = title;
        draft.ui.information.message = message;
        draft.ui.information.ok = ok;
        break;
      default:
        return state;
    }
  });
