import produce from 'immer';
import { LeavingAgreementPatcher } from '../../../common/code/leaving-agreement-patcher';
import { ProtocolPatcher } from '../../../common/code/protocol-patcher';
import { getById, getByProperty } from '../Functions';
import { COMMON_DELETE_OPERATION } from './constants';

export function commonDeleteOperation(protocolId, expression, isLeavingAgreement) {
  return {
    type: COMMON_DELETE_OPERATION,
    payload: {
      protocolId,
      expression,
      isLeavingAgreement,
    },
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_DELETE_OPERATION:
        const { protocolId, expression, isLeavingAgreement } = action.payload;

        if (isLeavingAgreement) {
          const leavingAgreement = getByProperty('protocolId', protocolId)(draft.leavingAgreements);
          new LeavingAgreementPatcher().delete(leavingAgreement, expression);
        } else {
          const protocol = getById(protocolId)(draft.protocols);
          new ProtocolPatcher().delete(protocol, expression);
        }

        break;
      default:
        return state;
    }
  });
