import React from 'react';

import { colors } from '../../common/theme/colors';
import { Icon } from './Icon';

export function NetworkIcon({ isOnline }) {
  return isOnline ? (
    <Icon icon={'wifi'} color={colors.success} size="xs" />
  ) : (
    <Icon icon={'wifi'} color={colors.error} size="xs" />
  );
}
