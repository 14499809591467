import { push } from 'connected-react-router';
import produce from 'immer';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { commonGetProtocols } from '../../common/redux/actions';
import { post } from '../../common/services/api';
import {
  HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL,
  HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR,
  HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_SUCCESS,
} from './constants';
import { homeRoutes } from '../routes';

export function homeCreateProtocolFromPreviousProtocol(data) {
  return {
    type: HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL,
    payload: data,
  };
}

function* doHomeCreateProtocolFromPreviousProtocol({ payload }) {
  try {
    yield call(post, 'protocol/frompreviousprotocol', payload);

    yield put({
      type: HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR,
      payload: error.message,
    });
  }
}

function* doGoToHomepage() {
  yield put(push(homeRoutes.home));
  yield put(commonGetProtocols());
}

export function* switchCreateProtocolFromPreviousProtocol() {
  yield takeEvery(
    HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL,
    doHomeCreateProtocolFromPreviousProtocol,
  );
  yield takeLatest(HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_SUCCESS, doGoToHomepage);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL:
        draft.ui.busy.createProtocolFromPreviousProtocol = true;
        break;
      case HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_SUCCESS:
        draft.ui.busy.createProtocolFromPreviousProtocol = false;
        draft.ui.modal.protocolFromPreviousProtocol = false;
        break;
      case HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR:
        draft.ui.busy.createProtocolFromPreviousProtocol = false;
        break;
      default:
        return state;
    }
  });
