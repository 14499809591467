/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

import { colors } from '../../common/theme/colors';
import { getLocalizedName } from '../acceptance-protocol/fill-step/Functions';
import { PlusIcon } from './PlusIcon';

const styles = {
  container: {
    display: 'grid',

    gridTemplateColumns: 'auto min-content',
    gridGap: '5px',
    paddingRight: '1em',

    text: {
      padding: '0.25em',
      paddingLeft: '1em',

      textAlign: 'left',
      backgroundColor: `${colors.dark}`,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      alignSelf: 'center',
    },

    icon: {
      backgroundColor: 'transparent',
      color: `${colors.white}`,
      alignSelf: 'center',
      width: '32px',
      height: '32px',
    },
  },
};

const EditComponentToAddElement = ({ language, template, onAdd }) => {
  const handleAdd = useCallback(() => onAdd(template.id), [onAdd, template.id]);

  return (
    <div css={styles.container}>
      <div css={styles.container.text}>{getLocalizedName(template, language)}</div>
      <button css={styles.container.icon} onClick={handleAdd}>
        <PlusIcon />
      </button>
    </div>
  );
};

export default EditComponentToAddElement;
