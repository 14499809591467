import styled from '@emotion/styled';

import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';

export default styled.button((props) => {
  let button = {
    padding: '20px 10px',
    transition: '0.5s background-position',
    borderRadius: '3px',
    border: `1px solid ${colors.darkGrey}`,
    backgroundSize: '200% auto',
    '&:hover': {
      backgroundPosition: 'right center',
    },
    color: colors.black,
    backgroundColor: colors.whiteSmoke,
    textAlign: 'center',

    textTransform: 'uppercase',

    [variables.breakpoints.md]: { padding: '40px 15px' },
  };

  if (props.secondary) {
    button = {
      ...button,
      textTransform: 'none',
      fontWeight: 'bold',

      [variables.breakpoints.md]: {
        padding: '15px 40px',
      },
    };
  }

  if (props.default) {
    button = {
      ...button,
      textTransform: 'none',
      fontWeight: 'bold',
      backgroundImage: 'none',
      border: `1px solid ${colors.darkGrey}`,
      boxShadow: `0 0 3px ${colors.brand}`,

      [variables.breakpoints.md]: {
        padding: '15px 40px',
      },
    };
  }

  if (props.toogle) {
    button = {
      ...button,
      textTransform: 'none',
      fontWeight: 'bold',
      backgroundImage: 'none',
      border: `1px solid ${colors.darkGrey}`,
      boxShadow: `0 0 3px ${colors.brand}`,
      backgroundColor: colors.brand,
      color: colors.white,
      [variables.breakpoints.md]: {
        padding: '15px 40px',
      },
    };
  }
  return button;
});
