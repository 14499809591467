import { push } from 'connected-react-router';
import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';

import { commonGetProtocols } from '../../../common/redux/actions';
import { homeRoutes } from '../../../home/routes';
import { SEND_STEP_GO_TO_HOME } from './constants';

export function sendStepGoToHome() {
  return {
    type: SEND_STEP_GO_TO_HOME,
  };
}

function* doGoToHome() {
  yield put(push(homeRoutes.home));
  yield put(commonGetProtocols());
}

export function* switchGoToHome() {
  yield takeLatest(SEND_STEP_GO_TO_HOME, doGoToHome);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SEND_STEP_GO_TO_HOME:
        draft.ui.modal.progress = false;
        break;
      default:
        return state;
    }
  });
