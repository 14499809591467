/** @jsxImportSource @emotion/react */
import { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import * as actions from './redux/actions';
import * as commonActions from '../../common/redux/actions';
import { colors } from '../../../common/theme/colors';
import { Form } from './Form';
import { InputBaseData } from './InputBaseData';
import { getLocalizedLabel, getAllId, getFirstFieldName } from './Functions';
import { bindActionCreators } from 'redux';
import { selectSelected } from './redux/selectors';
import {
  selectCurrentId,
  selectProtocolLanguage,
  selectBasedataInput,
  selectRentalData,
  selectIsWithRemReference,
  selectIsTenantsErased,
} from '../redux/selectors';
import {
  selectIsTenancyAgreementLoading,
  selectTenancyAgreementSuccess
} from '../../common/redux/selectors';
import { AddTenantIcon } from '../../common/AddTenantIcon';
import { RemoveTenantIcon } from '../../common/RemoveTenantIcon';
import Spinner from '../../common/Spinner';
import { i18n } from '../../../common/i18n-loader';
import {
  uniqBy,
  differenceWith,
  filter,
  contains,
  flip,
  prop,
  pipe,
  complement,
  clone,
} from 'ramda';
import {
  isMBusField, MBusMessagesFields
} from '../../common/redux/mBusMessageFields';
import { getHandoverType } from '../../../common/code/protocol.helper';
import { HandoverTypes } from '../../../common/types/handoverTypes';

const styles = {
  container: {
    button: {
      cursor: 'pointer',
      background: 'transparent',
      color: colors.black,
    },
  },
  section: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content min-content',
    backgroundColor: `${colors.lighterGrey}`,
    alignItems: 'center',
    fontWeight: 'bold',
    span: {
      padding: '1.5em 1.0em',
    },
  },
  content: {
    padding: '1.5em',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '24px',
  },
  group: {
    display: 'grid',
  },
  empty: {
    width: '60px',
    height: '60px',
  },
  buttons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '1em',
    paddingRight: '1em',
    button: {
      background: 'transparent',
      width: '32px',
      height: '32px',
      color: 'white',
    },
  },
};

export class InputRentalPerson extends InputBaseData {
  constructor(props) {
    super(props);
    this.formTenant2 = createRef();
  }

  getValue(fieldName) {
    const { rentalData } = this.props;
    if (!!fieldName) {
      const value = fieldName.substring(0, 1).toLowerCase() + fieldName.substring(1);
      return rentalData[value];
    }
    return '';
  }

  isOldOrNewRentalPersonType = (type) => type === 'oldRentalPerson' || type === 'newRentalPerson';

  updateValuesFromRentalData(fields, values, useRentalData) {
    if (!this.isOldOrNewRentalPersonType(values.typeId)) return values;
    if (!!fields && !!fields.length) {
      const templateFieldsNotInInputFields = differenceWith(
        (x, y) => x.fieldname === y.fieldname,
        fields,
        values.fields,
      );

      values.fields.push.apply(
        values.fields,
        templateFieldsNotInInputFields.reduce((accumulator, current) => {
          const field = getFirstFieldName(values.fields, current.fieldname);
          if (!!field) {
            accumulator.push({ fieldname: current.fieldname, value: field.Value });
            return accumulator;
          }

          if (useRentalData) {
            const rentalDataValue = this.getValue(current.fieldname);
            if (!!rentalDataValue) {
              accumulator.push({ fieldname: current.fieldname, value: rentalDataValue });
            }
          }

          return accumulator;
        }, []),
      );
    }

    return values;
  }

  onAddRentalPerson = () => {
    const {
      protocolId,
      selected,
      actions: { commonPatchOperation },
    } = this.props;

    setTimeout(() => {
      if (!!this.formTenant2.current) {
        const element = this.formTenant2.current;
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);

    const expression = `basedataInputs/[${selected.element.template.id}_2]`;

    commonPatchOperation(protocolId, expression, {});
  };

  onRemoveRentalPerson = (id) => {
    const {
      protocolId,
      selected,
      actions: {
        commonPatchOperation,
        commonDeleteOperation,
        fillStepConfirmModal
      },
    } = this.props;
    const isPrimaryTenant = Number(id.split('_')[1]);
    fillStepConfirmModal(
      {
        title: !!!isPrimaryTenant ? i18n._('FILL-STEP.CONFIRM-REMOVE-TENANT.TITLE') : i18n._('FILL-STEP.CONFIRM-REMOVE-TENANT-2.TITLE', { position: isPrimaryTenant }),
        message: !!!isPrimaryTenant ? i18n._('FILL-STEP.CONFIRM-REMOVE-TENANT.MESSAGE') : i18n._('FILL-STEP.CONFIRM-REMOVE-TENANT-2.MESSAGE', { position: isPrimaryTenant }),
        ok: i18n._('BUTTONS.DELETE'),
        cancel: i18n._('BUTTONS.CANCEL'),
      },
      () => {
        const isAdditionalTenant = !!selected.element.template && !!selected.element.template.secondaryTenants ?
          selected.element.template.secondaryTenants.fields : null
        if(!!isAdditionalTenant || !!!isPrimaryTenant) {
          commonPatchOperation(protocolId, `basedataInputs/[${id}]/isDeleted`, true);
        }

        if (!!!isPrimaryTenant) {
          const fields = !!selected.element.template ? selected.element.template.fields : [];
          const uniqFields = uniqBy((f) => f.fieldname, fields);
          for (const field of uniqFields) {
            if (!isMBusField(field.fieldname)) {
              const expression = `basedataInputs/[${id}]/fields/[${encodeURIComponent(field.fieldname)}]`;

              commonDeleteOperation(protocolId, expression);
            }
          }
          return;
        }
        commonDeleteOperation(protocolId, `basedataInputs/[${id}]`);
      },
    );
  };

  onErasePrimaryForm = (fields) => {
    const {
      protocolId,
      selected,
      actions: { commonPatchOperation },
    } = this.props;

    commonPatchOperation(protocolId, `basedataInputs/[${selected.element.template.id}]/isErased`, true);

    const uniqFields = uniqBy((f) => f.fieldname, fields);
    for (const field of uniqFields) {
      const expression = `basedataInputs/[${
        selected.element.template.id
      }]/fields/[${encodeURIComponent(field.fieldname)}]`;

      commonPatchOperation(protocolId, expression, { ...field, value: '' });
    }
  };

  onEraseSecondaryForm = (fields) => {
    const {
      protocolId,
      selected,
      actions: { commonPatchOperation },
    } = this.props;

    const uniqFields = uniqBy((f) => f.fieldname, fields);
    for (const field of uniqFields) {
      const expression = `basedataInputs/[${
        selected.element.template.id
      }_2]/fields/[${encodeURIComponent(field.fieldname)}]`;

      commonPatchOperation(protocolId, expression, { ...field, value: '' });
    }
  };

  splitTenantObject = (originalObject, fieldsToSplit, handoverType) => {
    // Filter fields based on the provided list
    const isFieldInList = pipe(prop('fieldname'), flip(contains)(Object.values(fieldsToSplit)));

    // Clone the original data to create two new objects
    const tenantObject = clone(originalObject);
    tenantObject.fields = filter(complement(isFieldInList), tenantObject.fields);

    const lettingObject = clone(originalObject);
    let lettingFields = filter(isFieldInList, lettingObject.fields);

    if (handoverType === HandoverTypes.VACANCY) {
      lettingFields = [];
    } else if (handoverType === HandoverTypes.HANDOUT) {
      const newTenantFields = pipe(
        prop('fieldname'),
        flip(contains)([
          fieldsToSplit.TENANT_SECURITY_DEPOSIT_AMOUNT,
          fieldsToSplit.TENANT_SECURITY_DEPOT_TYPE,
          fieldsToSplit.LIABILITY_INSURANCE_CHECK,
          fieldsToSplit.TENANT_SECURITY_PAID_AMOUNT,
        ]),
      );

      lettingFields = filter(complement(newTenantFields), lettingFields);
    } else if (handoverType === HandoverTypes.ACCEPTANCE) {
      const oldTenantFields = pipe(
        prop('fieldname'),
        flip(contains)([
          fieldsToSplit.TENANT_SECURITY_DEPOSIT_AMOUNT_OLD_TENANT,
          fieldsToSplit.TENANT_SECURITY_DEPOT_TYPE_OLD_TENANT,
          fieldsToSplit.LIABILITY_INSURANCE_CHECK_OLD_TENANT,
          fieldsToSplit.TENANT_SECURITY_PAID_AMOUNT_OLD_TENANT,
        ]),
      );

      lettingFields = filter(complement(oldTenantFields), lettingFields);
    }

    if (lettingFields.length === 0) return [tenantObject];

    lettingObject.fields = lettingFields;
    lettingObject.typeId = 'lettingInfo';

    return [tenantObject, lettingObject];
  };

  static propTypes = {
    protocolId: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    input: PropTypes.array.isRequired,
    rentalData: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    loadingTenancyAgreement: PropTypes.bool.isRequired
  };

  render() {
    const {
      selected,
      input,
      language,
      rentalData,
      loadingTenancyAgreement,
      isTenancyAgreementSuccess,
    } = this.props;

    const handoverType = getHandoverType(rentalData);
    const title = getLocalizedLabel(selected.element.template, language);
    const fields = !!selected.element.template ? selected.element.template.fields : null;
    const secondaryTenantFields = !!selected.element.template && !!selected.element.template.secondaryTenants ?
      selected.element.template.secondaryTenants.fields : null;
    const valuesArray = getAllId(input, selected.element.template.id);
    const splitValueArray = this.splitTenantObject(
      valuesArray[0],
      MBusMessagesFields,
      handoverType,
    );

    if (valuesArray.length > 1) {
      valuesArray.shift();
      valuesArray.map((value) => splitValueArray.push(value));
    }

    const isFieldInList = pipe(
      prop('fieldname'),
      flip(contains)(Object.values(MBusMessagesFields)),
    );
    const tenantFields = filter(complement(isFieldInList), fields);
    const additionalSecondaryTenantFields = tenantFields.map(field => {
      return {
        ...field,
        isReadOnly: false,
      }
    });
    const lettingFields = filter(isFieldInList, fields);
    const updatedValuesArray = !!splitValueArray
      ? splitValueArray.map((values, index) =>
          this.updateValuesFromRentalData(fields, values, index === 0),
        )
      : [];

    const countRentalPerson = !!updatedValuesArray && updatedValuesArray.filter(x => x.id.toLowerCase().includes('rentalperson') && !!!x.isDeleted).length
    const primaryTenantId = updatedValuesArray[0].id;
    return (
      <div css={styles.container}>
        <div css={styles.content}>
          {
            updatedValuesArray.length > 0 &&
            updatedValuesArray.map((value) => {
              const getLettingInfoFormKey = () => {
                return value.fields ? value.fields.reduce((acc, currentValue) => acc + currentValue.value, "") : value.id;
              };

              if (!this.isOldOrNewRentalPersonType(value.typeId)) {
                return (
                  <Spinner
                    show={loadingTenancyAgreement}
                    isBlocked={!isTenancyAgreementSuccess}
                  >
                    <Form
                      dataTestId={'letting-information-form'}
                      key={getLettingInfoFormKey()}
                      id={value.id}
                      fields={lettingFields}
                      values={value}
                      language={language}
                      onModifyField={this.onModifyField}
                      onModifySibling={this.onModifySibling}
                    >
                      {() => (
                        <div css={styles.section}>
                          <span>{i18n._('FILL-STEP.SECURITY-DEPOSIT.TITLE')}</span>
                        </div>
                      )}
                    </Form>
                  </Spinner>
                );
              }

              return null;
            })}
          {
            !!!countRentalPerson && (
              <div >{i18n._('MESSAGES.NO-TENANTS-AVAILABLE')}</div>
            )
          }
          {
            !!updatedValuesArray && !!updatedValuesArray[0] && !!!updatedValuesArray[0].isDeleted
              && 
                <Form
                  id={primaryTenantId}
                  dataTestId={primaryTenantId}
                  fields={tenantFields}
                  values={updatedValuesArray.length > 0 ? updatedValuesArray[0] : []}
                  language={language}
                  onModifyField={this.onModifyField}
                  onModifySibling={this.onModifySibling}
                >
                  {() => {
                    return (
                      <div css={styles.section}>
                        <span>{title}</span>
                        <div css={styles.buttons}>
                          {!!!secondaryTenantFields 
                            && splitValueArray.filter(value => value.typeId && value.typeId.toLowerCase().includes('rentalperson')).length === 1 
                            && (
                            <button
                              data-testid={'option-tenant-data'}
                              onClick={this.onAddRentalPerson}
                            >
                              <AddTenantIcon/>
                            </button>
                          )}
                          <button data-testid={'remove-tenant-button'} onClick={() => this.onRemoveRentalPerson(primaryTenantId)}>
                            <RemoveTenantIcon/>
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Form>
          }
          {
            updatedValuesArray.shift() &&
            !!updatedValuesArray.length > 0 &&
            updatedValuesArray.map((value, index) => {
              if (this.isOldOrNewRentalPersonType(value.typeId) && !!!value.isDeleted) {
                const position = value.id.split('_')[1];
                return (
                  <Form
                    id={value.id}
                    fields={secondaryTenantFields || additionalSecondaryTenantFields}
                    values={value}
                    language={language}
                    onModifyField={this.onModifyField}
                    onModifySibling={this.onModifySibling}
                  >
                    {() => {
                      return (
                        <div css={styles.section} ref={this.formTenant2}>
                          <span>{`${title} ${position}`}</span>
                          <div css={styles.buttons}>
                            <button data-testid={`remove-secondary-tenant-button-${index}`} onClick={() => this.onRemoveRentalPerson(value.id)}>
                              <RemoveTenantIcon />
                            </button>
                          </div>
                        </div>
                      );
                    }}
                  </Form>
                );
              }
              return null;
            })}
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  const protocolId = selectCurrentId(state);
  return {
    selected: selectSelected(state),
    protocolId,
    language: selectProtocolLanguage(state),
    input: selectBasedataInput(state),
    rentalData: selectRentalData(state),
    isWithRemReference: selectIsWithRemReference(state),
    loadingTenancyAgreement: selectIsTenancyAgreementLoading(state),
    isTenantErased: selectIsTenantsErased(protocolId)(state),
    isTenancyAgreementSuccess: selectTenancyAgreementSuccess(protocolId)(state)
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputRentalPerson);
