/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { colors } from '../../common/theme/colors';
import Select from 'react-select';

const styles = {
  defaultSelect: {
    width: '100%',
    minHeight: '38px',
    background:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNSIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDUgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yOTZfMTExKSI+CjxwYXRoIGQ9Ik0wLjg3NzE0OSA0LjEwMzA4QzEuMDA2NTEgMy45NzMgMS4xODUxMyAzLjk2NDI0IDEuMzQwNjggNC4xMDcwNUwyLjQ4MDY1IDUuMjE4OTZMMy42Mzk1IDQuMTI2NzVDMy43OTc0OCAzLjk4NjYzIDMuOTc2MjIgMy45OTg0NSA0LjEwMjQ0IDQuMTMwNzJDNC4yMjk1NiA0LjI2MjcgNC4yMTk0MyA0LjQ4MzgxIDQuMDk4MzcgNC42MDU3MkMzLjk3NzkxIDQuNzI3NjQgMi43MDYxNCA1LjkxOCAyLjcwNjE0IDUuOTE4QzIuNjQxNzYgNS45ODMwNCAyLjU1NzY1IDYuMDE1MjcgMi40NzM4MyA2LjAxNDU1QzIuMzkgNi4wMTM4MyAyLjMwNjQ2IDUuOTgwMTcgMi4yNDI2MSA1LjkxNDAzQzIuMjQyNjEgNS45MTQwMyAwLjk5MjAyNCA0LjcwMjA1IDAuODczMDc4IDQuNTc4MDhDMC43NTQxMzIgNC40NTQxMSAwLjc0Nzc5MiA0LjIzMjg2IDAuODc3MTQ5IDQuMTAzMDhaIiBmaWxsPSIjODI4MjgyIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMjk2XzExMSI+CjxyZWN0IHdpZHRoPSI0Ljk1IiBoZWlnaHQ9IjEwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=) no-repeat 98% 50%',
    backgroundSize: 'contain',
    appearance: 'none',
    WebkitAppearance: 'none',
    display: 'block',
    outlineWidth: '0',
    backgroundColor: colors.white,
    border: `1px solid ${colors.grey80}`,
    borderRadius: '4px',
    cursor: 'default',

    '&:required:invalid': {
      color: colors.darkGrey,
    },
    '&, option': {
      color: colors.black,

      '&[disabled]': {
        color: colors.darkGrey,
      },
    },
  },
};

const Dropdown = (props) => {
  const { options, name, value, emptyValue, setFieldValue, setFieldTouched, disabled, language } =
    props;
  const [selectChangedValue, setSelectChangedValue] = useState(value);
  const [selectedOption, setSelectedOption] = useState(null);

  const hasOneOption = useMemo(() => options && options.length === 1, [options]);

  const onChange = useCallback((inputValue, reason) => {
    if (
      reason.action === 'set-value' ||
      reason.action === 'input-blur' ||
      reason.action === 'menu-close'
    ) {
      return;
    }
    setSelectChangedValue(inputValue);
  }, []);

  const onInputChange = useCallback((inputValue, reason) => {
    if (
      reason.action === 'set-value' ||
      reason.action === 'input-blur' ||
      reason.action === 'menu-close'
    ) {
      return;
    }
    setSelectChangedValue(inputValue);
  }, []);

  const onBlur = useCallback(() => {
    setFieldTouched(name, true);
  }, [setFieldTouched, name]);

  useEffect(() => {
    const selectedOption = !!options && options.find((fo) => fo.value === value);
    !!selectedOption &&
      setSelectedOption({
        value: value,
        label: selectedOption.labels
          ? selectedOption.labels[language]
            ? selectedOption.labels[language]
            : selectedOption.labels['default']
          : selectedOption.label,
      });
  }, [options, value, language]);

  useEffect(() => {
    if (selectChangedValue !== value) {
      setFieldValue(name, !selectChangedValue ? null : selectChangedValue.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectChangedValue]);

  useEffect(() => {
    if (hasOneOption && options[0].value !== value) {
      setFieldValue(name, options[0].value);
    }
  }, [hasOneOption, options, setFieldValue, name, value]);

  return (
    <div className={props.className}>
      <Select
        name={name}
        value={selectedOption}
        onChange={onChange}
        onInputChange={onInputChange}
        onBlur={onBlur}
        isDisabled={disabled}
        styles={styles.defaultSelect}
        placeholder={emptyValue.label}
        options={
          !!options &&
          options.map((t) => ({
            label: t.labels
              ? t.labels[language]
                ? t.labels[language]
                : t.labels['default']
              : t.label,
            value: t.value,
          }))
        }
      />
    </div>
  );
};

export default Dropdown;
