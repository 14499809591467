/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MainStep from './main-step/MainStep';
import FillStep from './fill-step/FillStep';
import SignStep from './sign-step/SignStep';
import WriteSign from '../common/WriteSign';
import LeavingAgreementStep from './leaving-agreement-step/LeavingAgreementStep';
import SendStep from './send-step/SendStep';
import {
  selectCurrentStep,
  selectCurrentId,
  selectIsCurrentProtocolOffline
} from './redux/selectors';
import * as commonActions from '../common/redux/actions';

export class DefaultPage extends Component {
  static propTypes = {
    currentStep: PropTypes.string,
  };

  componentDidMount() {
    const isReloaded = sessionStorage.getItem('isReloaded');
    const { 
      protocolId,
      isProtocolOffline,
      actions: {
        commonGetTenancyAgreement
      } 
    } = this.props;
    if (!!isReloaded && !isProtocolOffline) {
      commonGetTenancyAgreement(protocolId);
    }
    else {
      sessionStorage.setItem('isReloaded', 'true');
    }
  }

  componentWillUnmount() {
    sessionStorage.removeItem('isReloaded');
  }

  render() {
    const { currentStep } = this.props;

    switch (currentStep) {
      case 'fill-step':
        return <FillStep />;
      case 'sign-step':
        return <SignStep />;
      case 'sign':
        return <WriteSign />;
      case 'leaving-agreement-step':
        return <LeavingAgreementStep />;
      case 'leaving-agreement-sign':
        return <WriteSign />;
      case 'send-step':
        return <SendStep />;
      default:
      case 'main-step':
        return <MainStep />;
    }
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    currentStep: selectCurrentStep(state),
    protocolId: selectCurrentId(state),
    isProtocolOffline: selectIsCurrentProtocolOffline(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
