/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { variables } from '../../common/theme/variables';
import Button from '../common/Button';
import Modal from '../common/Modal';

const styles = {
  content: { textAlign: 'center' },
  buttons: {
    display: 'grid',
    gridGap: '0 1em',

    [variables.breakpoints.md]: {
      gridTemplateColumns: '1fr 2fr 1fr',
    },
  },
};

const InfoModal = (props) => {
  const { title, isOpen, onClose, children, ok } = props;

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose} width="50vw">
      <div css={styles.content}>{children}</div>
      <div css={styles.buttons}>
        <div />

        <Button secondary onClick={onClose}>
          {!!ok ? ok : <Trans>BUTTONS.OK</Trans>}
        </Button>
        <div />
      </div>
    </Modal>
  );
};

InfoModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

InfoModal.defaultProps = {
  isOpen: false,
};

export default InfoModal;
