/** @jsxImportSource @emotion/react */
import { routerActions } from 'connected-react-router';
import { map } from 'ramda';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { i18n } from '../../../common/i18n-loader';
import ConfirmModal from '../../common/ConfirmModal';
import * as commonActions from '../../common/redux/actions';
import Spinner from '../../common/Spinner';
import { selectCurrentProtocolDataSection } from '../redux/selectors';
import * as actions from './redux/actions';
import { selectIsAssignmentModalOpen } from './redux/selectors';
import { Trans } from '@lingui/macro';
import { get } from '../../common/services/api';

const styles = {
  search: {
    container: {
      display: 'grid',
      gridTemplateRows: 'min-content auto',
      gridTemplateAreas: '"title" "select"',
      gridRowGap: '24px',
    },
    title: {
      gridArea: 'title',
      fontSize: '15px',
    },
    select: {
      gridArea: 'select',
    },
  },
};
const dropdownStyles = {
  menuPortal: (base) => {
    const { zIndex, ...rest } = base; // remove zIndex from base by destructuring
    return { ...rest, zIndex: 9999 };
  },
  menu: (base) => ({
    ...base,
    // kill the gap
    marginTop: 1,
  }),
};

const AssignUserModal = (props) => {
  const {
    isAssignmentModalOpen,
    dataSectionId,
    actions: { mainStepCloseAssignmentModal, assignUserProtocol, notifyError },
  } = props;
  const [assignedUser, setAssignedUser] = useState();
  const [users, setUsers] = useState({
    data: null,
    isBusy: true,
  });

  useEffect(() => {
    if (isAssignmentModalOpen === true) {
      get('users/assignableusers/' + (dataSectionId || '')).then((res) => {
        setUsers({ data: res, isBusy: false });
      });
    }
    return () => {
      setAssignedUser(null);
    };
  }, [dataSectionId, isAssignmentModalOpen]);

  const updateAssignedUser = useCallback((inputValue, reason) => {
    setAssignedUser(inputValue);
  }, []);

  const updateAssignedUserInput = useCallback((inputValue, reason) => {
    if (
      reason.action === 'set-value' ||
      reason.action === 'input-blur' ||
      reason.action === 'menu-close'
    ) {
      return;
    }
    setAssignedUser(inputValue);
  }, []);

  const assignToNewUser = useCallback(() => {
    if (!assignedUser) {
      notifyError(i18n._('PROTOCOL.ASSIGN-USER.NO-USER-SELECTED'));
      return null;
    }
    assignUserProtocol(assignedUser.value);
  }, [assignedUser, assignUserProtocol, notifyError]);

  const noOptionsMessage = useCallback(({ inputValue }) => {
    return i18n._('PROTOCOL.ASSIGN-USER.NOT-FOUND');
  }, []);

  return (
    <ConfirmModal
      title={i18n._('PROTOCOL.ASSIGN-USER')}
      isOpen={isAssignmentModalOpen}
      onConfirm={assignToNewUser}
      onClose={mainStepCloseAssignmentModal}
    >
      <Spinner show={users.isBusy}>
        <div css={styles.search.container} id="confirmModal">
          <div css={styles.search.title}>
            <Trans>PROTOCOL.ASSIGN-USER.DESCRIPTION</Trans>
          </div>
          <Select
            value={assignedUser}
            onChange={updateAssignedUser}
            onInputChange={updateAssignedUserInput}
            options={
              !!users.data && !!users.data.users
                ? map(
                    (data) => ({
                      label: `${data.fullname} (${data.userId})`,
                      value: data.userId,
                    }),
                    users.data.users,
                  )
                : []
            }
            isClearable={true}
            menuPortalTarget={document.body}
            noOptionsMessage={noOptionsMessage}
            placeholder={i18n._('PROTOCOL.ASSIGN-USER.PLACEHOLDER')}
            styles={dropdownStyles}
            classNamePrefix="react-select-element-"
          />
        </div>
      </Spinner>
    </ConfirmModal>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isAssignmentModalOpen: selectIsAssignmentModalOpen(state),
    dataSectionId: selectCurrentProtocolDataSection(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...routerActions, ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignUserModal);
