/** @jsxImportSource @emotion/react */
import { useState, useEffect, useCallback } from 'react';
import { colors } from '../../common/theme/colors';

const styles = {
  container: {
    touchAction: 'pan-x',
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: '0',
    padding: '0',
    userSelect: 'none',
    WebkitTapHighlightColor: 'rgba(0,0,0,0)',
  },

  toggleContainer: (isChecked) => ({
    width: '120px',
    height: '40px',
    padding: '0',
    borderRadius: '48px',
    backgroundColor: colors.grey80,
    transition: 'all .2s ease',
    position: 'relative',
    ...(isChecked && {
      backgroundColor: colors.brand,
      color: colors.white,
    }),
  }),

  toggleCheck: {
    position: 'absolute',
    lineHeight: '0',
    opacity: '0',
    transition: 'opacity .25s ease',
    top: '20px',
  },
  toggleChecked: (isChecked) => ({
    left: '16px',
    ...(isChecked && {
      opacity: '1',
    }),
  }),
  toggleUnchecked: (isChecked) => ({
    opacity: '1',
    right: '16px',
    ...(isChecked && {
      opacity: '0',
    }),
  }),

  toggleCircle: (isChecked) => ({
    transition: 'all .5s cubic-bezier(.23,1,.32,1) 0ms',
    position: 'absolute',
    top: '1px',
    left: '1px',
    width: '38px',
    height: '38px',
    borderRadius: '50%',
    backgroundColor: '#fafafa',
    boxShadow: '0px 1px 2px rgba(14, 15, 23, 0.08)',
    ...(isChecked && {
      left: '81px',
    }),
  }),

  toggleInput: {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
  },

  toggleDisabled: {
    cursor: 'not-allowed',
    opacity: '0.5',
    transition: 'opacity 0.25s',
  },
};

export const ToggleButton = (props) => {
  const { title, defaultChecked, onChange, disabled } = props;
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    if (defaultChecked) {
      setToggled(defaultChecked);
    }
  }, [defaultChecked]);

  const triggerToggle = useCallback(() => {
    if (disabled) {
      return;
    }

    setToggled((prevToggle) => !prevToggle);

    if (onChange) {
      onChange(!toggled);
    }
  }, [toggled, onChange, disabled]);

  return (
    <div onClick={triggerToggle} css={styles.container}>
      <div css={styles.toggleContainer(toggled)}>
        <div
          css={{
            ...styles.toggleCheck,
            ...(toggled ? styles.toggleChecked(toggled) : styles.toggleUnchecked(toggled)),
          }}
        >
          {title}
        </div>
      </div>
      <div css={styles.toggleCircle(toggled)} />
      <input type="checkbox" aria-label="Toggle Button" css={styles.toggleInput} />
    </div>
  );
};
