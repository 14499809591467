/** @jsxImportSource @emotion/react */
import Fade from 'react-reveal/Fade';
import { Fragment, useContext, memo } from 'react';
import { colors } from '../../common/theme/colors';
import { TabsContext } from './Tabs';
import { TabContext } from './Tab';

const styles = {
  tabButton: {
    alignItems: 'center',

    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    gridRow: '1/2',

    cursor: 'pointer',
    marginTop: '1px',
    background: colors.whiteSmoke,
    color: colors.dark,
    textAlign: 'center',
    transition: 'all 0.5s ease-in-out',
    textTransform: 'uppercase',
    padding: '1em',
    borderTop: `1px solid ${colors.lighterGrey}`,
    borderRight: `1px solid ${colors.lighterGrey}`,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    borderBottom: `2px ${colors.lighterGrey} solid`,
    ':first-of-type': {
      borderLeft: `1px ${colors.lighterGrey} solid`,
    },
    indicator: {
      display: 'grid',
      justifyContent: 'center',
      content: {
        color: colors.brand,
        padding: '0.2em',
        width: '1.5em',
        height: '1.5em',
        border: `1px solid ${colors.brand}`,
        borderRadius: '100%',
      },
    },
  },
  input: {
    display: 'none',
    '&:checked + label': {
      background: colors.brand,
      color: colors.white,
    },
  },
};

const TabTitle = (props) => {
  const { indicator, children } = props;
  const { selectedTab, selectTab } = useContext(TabsContext);
  const { id } = useContext(TabContext);

  function handleOnChange(evt) {
    selectTab(id);
  }

  return (
    <Fragment>
      <input
        type="radio"
        id={id}
        name="tab"
        css={styles.input}
        onChange={handleOnChange}
        checked={selectedTab === id}
      />
      <label css={styles.tabButton} htmlFor={id}>
        <div />
        <div>{children}</div>
        <div css={styles.tabButton.indicator}>
          <Fade when={!!indicator}>
            <div css={styles.tabButton.indicator.content}>{indicator}</div>
          </Fade>
        </div>
      </label>
    </Fragment>
  );
};

TabTitle.propTypes = {};

TabTitle.defaultProps = {};

export default memo(TabTitle);
