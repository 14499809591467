/** @jsxImportSource @emotion/react */
import React from 'react';
import { ErrorBoundaryTemplate } from './ErrorBoundaryTemplate';
import { colors } from '../../common/theme/colors';

const styles = {
  container: {
    zIndex: 1000,
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100vw',
    height: '100vh',
    background: colors.dark,
    paddingTop: '64px',
  },
};
export default class ProtocolNotFound extends React.Component {
  render() {
    return (
      <div css={styles.container}>
        <ErrorBoundaryTemplate
          type="info"
          title="Unfortunately we were not able to find what you are looking for."
          subtitle="The page you are looking for does not exist."
          showReportFeedbackButton={false}
          showGoBackButton={false}
        />
      </div>
    );
  }
}
