import produce from 'immer';
import {
  HOME_GET_TEMPLATES,
  HOME_GET_TEMPLATES_SUCCESS,
  HOME_GET_TEMPLATES_ERROR,
} from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getTemplates } from './../services/templates';

export function homeGetTemplates() {
  return { type: HOME_GET_TEMPLATES };
}

function* doGetTemplates() {
  try {
    const data = yield call(getTemplates);

    yield put({
      type: HOME_GET_TEMPLATES_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: HOME_GET_TEMPLATES_ERROR,
      payload: error.message,
    });
  }
}

export function* switchGetTemplates() {
  yield takeLatest(HOME_GET_TEMPLATES, doGetTemplates);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_GET_TEMPLATES:
        draft.ui.busy.templates = true;
        break;
      case HOME_GET_TEMPLATES_SUCCESS:
        draft.ui.busy.templates = false;
        draft.templates = action.payload.map((t) => ({
          value: t.id,
          label: t.templateName,
          labels: t.labels,
        }));
        break;
      case HOME_GET_TEMPLATES_ERROR:
        draft.ui.busy.templates = false;
        break;
      default:
        return state;
    }
  });
