import produce from 'immer';
import { FILL_STEP_OPEN_IMAGE_SKETCHER_MODAL } from './constants';

export function fillStepOpenImageSketcherModal(image) {
  return {
    type: FILL_STEP_OPEN_IMAGE_SKETCHER_MODAL,
    payload: image,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_OPEN_IMAGE_SKETCHER_MODAL:
        draft.ui.modal.imageSketcher = true;
        draft.ui.busy.spinner = false;
        draft.currentEditImage = action.payload;
        break;
      default:
        return state;
    }
  });
