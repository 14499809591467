/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { colors } from '../../common/theme/colors';
import { StatusIcon } from './StatusIcon';
import { Trans } from '@lingui/macro';

const styles = {
  container: {
    display: 'grid',
    gridGap: '5px',
    backgroundColor: colors.darker,
  },
  title: {
    fontWeight: 'normal',
    color: colors.white,
    padding: '10px',
  },
  clients: {
    display: 'grid',
    gridTemplateColumns: `10fr 1fr`,
    padding: '10px',
    borderBottomStyle: 'ridge',
    borderBottomColor: colors.grey80,
    borderBottomWidth: '1px',
    backgroundColor: colors.white,
    cursor: 'pointer',
  },
  clientName: {
    fontWeight: 'normal',
  },
  selected: {
    backgroundColor: colors.lighterGrey,
  },
  notSelected: {
    backgroundColor: colors.white,
  },
  clientNumber: {
    fontWeight: 'bold',
  },
};

export const MigrationList = (props) => {
  const { clientName, setTemplateMigration, selectedTemplate, migration, isMigrationRunning } =
    props;

  const handleSelect = useCallback(
    (templateName) => {
      setTemplateMigration(templateName);
    },
    [setTemplateMigration],
  );

  return (
    <div css={styles.container}>
      <div css={styles.title}>
        <Trans>TITLE.MIGRATIONS</Trans> {clientName}
      </div>
      <div>
        {!!migration && !!migration.items ? (
          migration.items.map((item, index) => (
            <div
              css={{
                ...styles.clients,
                ...(selectedTemplate === item.name ? styles.selected : styles.notSelected),
              }}
              key={index}
              onClick={() => handleSelect(item.name)}
            >
              <span css={styles.clientName}>
                {item.name}
                <span css={styles.clientNumber}> ({item.count})</span>
              </span>
              {!isMigrationRunning && <StatusIcon success={item.success} size={'lg'} />}
            </div>
          ))
        ) : (
          <div
            css={{
              ...styles.clients,
              ...styles.notSelected,
            }}
          >
            <span css={styles.clientNumber}>
              <Trans>LABELS.MIGRATIONS.NOTHINGTOSHOW</Trans>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
