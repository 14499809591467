import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  COMMON_FETCH_CONFIGURATION,
  COMMON_LOGIN_ERROR,
  COMMON_LOGIN_SUCCESS
} from "./constants";
import {
  LOGIN_WORKFLOW_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE,
  LOGIN_WORKFLOW_SEARCH_RENTAL_OBJECT,
} from './loginWorkflowConstants';
import { homeRoutes } from "../../home/routes";
import { homeOpenProtocolWithoutREMReferenceModal } from "../../home/redux/actions";
import { get } from "../services/api";

export function commonLoginSuccess(workflow, username, email, locales, securityDepotTypes, client, clientConfig) {
  return {
    type: COMMON_LOGIN_SUCCESS,
    payload: {
      workflow,
      username,
      email,
      locales,
      securityDepotTypes,
      client,
      clientConfig,
    }
  };
}

export function commonFetchConfiguration(loginWorkflow, username, email, client) {
  return {
    type: COMMON_FETCH_CONFIGURATION,
    payload: {
      loginWorkflow,
      username,
      email,
      client,
    }
  };
}

function* doCommonLoginSuccess({ payload }) {

  let workflow;
  if (typeof payload === 'object'){
    workflow = payload.workflow;
  } else {
    workflow = payload;
  }

  switch (workflow) {
    case LOGIN_WORKFLOW_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE:
      yield put(homeOpenProtocolWithoutREMReferenceModal());
      break;
    case LOGIN_WORKFLOW_SEARCH_RENTAL_OBJECT:
      yield put(push(homeRoutes.search));
      break;
    default:
      break;
  }
}

function* doCommonFetchConfiguration({ payload }){
  try {
    const locales = yield call(get, 'locale');
    const clientConfig = yield call(get, 'clientconfig');

    let securityDepotTypes = [];
    try {
      securityDepotTypes = yield call(get, 'letting/securitydeposittypes');
    } catch (error) {
      console.error(error.message);
    }

    yield put(commonLoginSuccess(payload.loginWorkflow, payload.username, payload.email, locales, securityDepotTypes, payload.client, clientConfig));
  } catch (error) {
    yield put({
      type: COMMON_LOGIN_ERROR,
      payload: error.message,
    });
  }
}

export function* switchLoginSuccess() {
  yield takeLatest([COMMON_LOGIN_SUCCESS], doCommonLoginSuccess);
  yield takeLatest(COMMON_FETCH_CONFIGURATION, doCommonFetchConfiguration);
}
