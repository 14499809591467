/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { range, without, keys, pluck, isEmpty } from 'ramda';
import { memo, useCallback, useEffect, useState } from 'react';
import { getImages } from '../../../common/code/image.repository';
import { colors } from '../../../common/theme/colors';
import Button from '../../common/Button';
import { ImagePicker } from '../../common/ImagePicker';
import ImageTakePhotoModal from '../../common/ImageTakePhotoModal';
import { SpinnerIcon } from '../../common/SpinnerIcon';
import { ImageViewer } from './ImageViewer';
import { BrokenImageIcon } from '../../common/BrokenImageIcon';
import { MAX_IMAGES_PER_PROTOCOL } from './redux/fillStepSaveImage';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridGap: '12px',
    gridTemplateRows: 'auto min-content',
  },
  tilesWithTotalImages: {
    display: 'flex',
    flexFlow: 'column nowrap',
    rowGap: '4px',
  },
  totalImages: {
    fontSize: '14px',
    alignSelf: 'end',
    color: colors.darkGrey,
    display: 'flex',
    flexFlow: 'row nowrap',
    columnGap: '4px',
  },
  totalImagesAmount: {
    fontWeight: 'bold',
    color: colors.black,
  },
  tiles: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridAutoFlow: 'row',
    borderBottom: `solid 1px ${colors.lighterGrey}`,
  },
  tile: {
    borderTop: `solid 1px ${colors.lighterGrey}`,
    borderRight: `solid 1px ${colors.lighterGrey}`,

    ':nth-of-type(4n + 1)': {
      borderLeft: `solid 1px ${colors.lighterGrey}`,
    },

    newImage: {
      width: '100%',
      height: '100%',
    },
    image: {
      width: '100%',
      height: '100%',
    },
  },

  spinnerIcon: {
    gridRow: '1 / 4',
    gridColumn: '1 / 5',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  errorIcon: {
    display: 'grid',
    color: colors.red,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  imageBroken: {
    height: '32px',
    width: '32px',
  },
};

const InputImages = ({
  images,
  totalProtocolImages,
  isOfflineProtocol,
  onViewImage,
  onDeleteAllImages,
  isTakePhotoModalOpen,
  fillStepOpenTakePhotoModal,
  fillStepCloseTakePhotoModal,
  fillStepOpenImageSketcherModal,
  fillStepShowSpinner,
  fillStepHideSpinner,
  notifyError,
}) => {
  const [externalImages, setExternalImages] = useState([]);
  const [isBusy, setIsBusy] = useState(true);
  const [isError, setIsError] = useState(false);
  const cameraConfiguration = {
    width: 800,
    height: 600,
    debug: false,
  };

  const hasTooManyImages = totalProtocolImages >= MAX_IMAGES_PER_PROTOCOL;

  const handleViewImage = useCallback((image) => onViewImage(image), [onViewImage]);

  const loadImage = useCallback(
    async (filename, imageData) => {
      if (filename && imageData) {
        fillStepCloseTakePhotoModal();
        fillStepOpenImageSketcherModal({
          filename: filename,
          data: imageData.image,
          size: imageData.size,
        });
      }
    },
    [fillStepOpenImageSketcherModal, fillStepCloseTakePhotoModal],
  );

  useEffect(() => {
    let didCancel = false;

    setIsBusy(true);
    setIsError(false);

    async function asyncGetImages() {
      try {
        const remainingImages = without(keys(externalImages), pluck('reference', images));
        if (!isEmpty(remainingImages)) {
          const downloadedImages = await getImages(remainingImages, isOfflineProtocol);
          if (!didCancel) {
            setExternalImages({ ...externalImages, ...downloadedImages });
          }
        }
      } catch (error) {
        console.error(error);
        if (!didCancel) {
          setIsError(true);
        }
      }

      if (!didCancel) {
        setIsBusy(false);
      }
    }

    asyncGetImages();

    return () => {
      didCancel = true;
    };
  }, [images, externalImages, isOfflineProtocol]);

  return (
    <div css={styles.container}>
      <ImageTakePhotoModal
        isOpen={isTakePhotoModalOpen}
        onClose={fillStepCloseTakePhotoModal}
        loadImage={loadImage}
        fillStepHideSpinner={fillStepHideSpinner}
        {...cameraConfiguration}
      />
      <div css={styles.tilesWithTotalImages}>
        <div css={styles.tiles} style={isBusy || isError ? { borderBottom: 'none' } : {}}>
          {isBusy ? (
            <SpinnerIcon style={styles.spinnerIcon} />
          ) : (
            range(0, 12).map((index) => {
              const image = index < images.length ? images[index] : null;

              return (
                <div key={`image-${index}`} css={styles.tile}>
                  {index < images.length ? (
                    <div css={styles.tile.image}>
                      {!isError &&
                      !!image &&
                      !!externalImages &&
                      !!image.reference &&
                      !!externalImages[image.reference] ? (
                        <ImageViewer
                          image={image}
                          imageData={externalImages[image.reference]}
                          onView={handleViewImage}
                        />
                      ) : (
                        <div css={styles.errorIcon}>
                          <BrokenImageIcon style={styles.imageBroken} />
                        </div>
                      )}
                    </div>
                  ) : index === images.length ? (
                    <div css={styles.tile.newImage}>
                      <ImagePicker
                        onUploadImage={loadImage}
                        onCameraOpen={fillStepOpenTakePhotoModal}
                        showSpinner={fillStepShowSpinner}
                        hideSpinner={fillStepHideSpinner}
                        notifyError={notifyError}
                        disabled={hasTooManyImages}
                        {...cameraConfiguration}
                      />
                    </div>
                  ) : null}
                </div>
              );
            })
          )}
        </div>
        <div css={styles.totalImages}>
          <span
            css={{
              ...styles.totalImagesAmount,
              ...(hasTooManyImages && { color: colors.red }),
            }}
          >
            {totalProtocolImages}/{MAX_IMAGES_PER_PROTOCOL}
          </span>
          <Trans>FILL-STEP.PROTOCOL-IMAGES</Trans>
        </div>
      </div>
      <div>
        {!isBusy && (
          <Button
            secondary
            onClick={() => onDeleteAllImages(images)}
            disabled={images.length === 0}
          >
            <Trans>BUTTONS.CLEAR-ALL</Trans>
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(InputImages);
