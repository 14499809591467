import { DefaultPage, SearchPage } from './';

const homeRoute = {
  path: '/',
  name: 'Home',
  childRoutes: [
    {
      path: 'default',
      name: 'Default page',
      component: DefaultPage,
      isIndex: true,
    },
    {
      path: 'search',
      name: 'Search page',
      component: SearchPage,
      isIndex: true,
    },
  ],
};

export default homeRoute;
