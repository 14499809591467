/** @jsxImportSource @emotion/react */
import classNames from 'classnames';

import { colors } from '../../common/theme/colors';

const styles = {
  subModal: {
    transition: '0.5s opacity',

    opacity: '0',
    '&.is-open': {
      opacity: '1',
    },
    '&>div': {
      transition: '0.5s transform',
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: colors.darker,
      color: '#333333',
      borderRadius: '5px',
      maxHeight: '90vh',
      overflowY: 'auto',
      overflowX: 'hidden',

      width: '40vw',
    },
  },
};

const EditComponentSubModal = (props) => {
  const { isOpen, children, position } = props;

  let transform;
  switch (position) {
    case 'center':
    default:
      transform = 'translate(-50%, -50%)';
      break;
    case 'left':
      transform = 'translate(-102%, -50%)';
      break;
    case 'right':
      transform = 'translate(2%, -50%)';
      break;
  }

  return (
    <div css={styles.subModal} className={classNames({ 'is-open': isOpen })}>
      <div style={{ transform: isOpen ? transform : 'translate(-50%, -50%)' }}>{children}</div>
    </div>
  );
};

export default EditComponentSubModal;
