export const COMMON_NOTIFY_ERROR = '[Common] Notify Error';
export const COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR = '[Common] Notify Get Tenancy Agreement Error';

export const COMMON_SET_LANGUAGE = '[Common] Set Language';
export const COMMON_SET_VERSION = '[Common] Set Version';

export const COMMON_IS_ONLINE = '[Common] Is Online';
export const COMMON_IS_OFFLINE = '[Common] Is Offline';

export const COMMON_LOGIN = '[Common] Login';
export const COMMON_LOGIN_SUCCESS = '[Common] Login Success';
export const COMMON_FETCH_CONFIGURATION = '[Common] Fetch User Configurations';
export const COMMON_LOGIN_ERROR = '[Common] Login Error';

export const COMMON_UPDATE_TENANCY_AGREEMENT_DETAILS_MBUS =
  '[Common] Update tenancy agreement details';
export const COMMON_UPDATE_TENANCY_AGREEMENT_SECURITY_DEPOT_MBUS =
  '[Common] Update tenancy agreement security depot';

export const COMMON_LOGOUT = '[Common] Logout';
export const COMMON_LOGOUT_CONFIRMED = '[Common] Logout Confirmed';
export const COMMON_LOGOUT_CANCELED = '[Common] Logout Canceled';

export const COMMON_CHECK_AUTH = '[Common] Check auth';
export const COMMON_IS_LOGGED_IN = '[Common] Is logged in';
export const COMMON_IS_LOGGED_OUT = '[Common] Is logged out';
export const COMMON_TOKENS_REFRESH_SUCCESS = '[Common] Tokens Refresh Success';
export const COMMON_TOKENS_REFRESH_ERROR = '[Common] Tokens Refresh Error';

export const COMMON_OPEN_NOT_IMPLEMENTED_MODAL = '[Common] Open Not Implemented Modal';
export const COMMON_CLOSE_NOT_IMPLEMENTED_MODAL = '[Common] Close Not Implemented Modal';

export const COMMON_OPEN_CONFIRMATION_MODAL = '[Common] Open Confirmation Modal';
export const COMMON_CLOSE_CONFIRMATION_MODAL = '[Common] Close Confirmation Modal';

export const COMMON_CONFIRMATION_APPROVED = '[Common] Confirmation Approved';
export const COMMON_CONFIRMATION_REJECTED = '[Common] Confirmation Rejected';

export const COMMON_OPEN_INFORMATION_MODAL = '[Common] Open Information Modal';
export const COMMON_CLOSE_INFORMATION_MODAL = '[Common] Close Information Modal';

export const COMMON_DELETE_PROTOCOL = '[Common] Delete protocol';
export const COMMON_DELETE_PROTOCOL_SUCCESS = '[Common] Delete protocol Success';
export const COMMON_DELETE_PROTOCOL_ERROR = '[Common] Delete protocol Error';
export const COMMON_DELETE_PROTOCOL_CONFIRM = '[Common] Delete protocol Confirm';
export const COMMON_DELETE_PROTOCOL_DELETE = '[Common] Delete protocol Delete';
export const COMMON_DELETE_PROTOCOL_CANCEL = '[Common] Delete protocol Cancel';

export const COMMON_GET_PROTOCOLS = '[Common] Get Protocols';
export const COMMON_GET_PROTOCOLS_SUCCESS = '[Common] Get Protocols Success';
export const COMMON_GET_PROTOCOLS_ERROR = '[Common] Get Protocols Error';

export const COMMON_GET_LEAVING_AGREEMENT = '[Common] Get Leaving Agreement';
export const COMMON_GET_LEAVING_AGREEMENT_ERROR = '[Common] Get Leaving Agreement Error';
export const COMMON_GET_LEAVING_AGREEMENT_SUCCESS = '[Common] Get Leaving Agreement Success';

export const COMMON_GET_TENANCY_AGREEMENT = '[Common] Get Tenancy Agreement';
export const COMMON_GET_TENANCY_AGREEMENT_ERROR = '[Common] Get Tenancy Agreement Error';
export const COMMON_GET_TENANCY_AGREEMENT_SUCCESS = '[Common] Get Tenancy Agreement Success';

export const COMMON_SET_PROTOCOL_LANGUAGE = '[Common] Set Protocol Language';

export const COMMON_FLUSH_QUEUES = '[Common] Flush queues';
export const COMMON_RETRY_FLUSH_QUEUES = '[Common] Retry Flush queues';
export const COMMON_POP_QUEUE = '[Common] Pop queue';
export const COMMON_POP_QUEUE_ERROR = '[Common] Pop queue Error';
export const COMMON_POPPED_QUEUE = '[Common] Popped queue';
export const COMMON_FLUSHED_QUEUES = '[Common] Flushed queues';
export const COMMON_FLUSH_QUEUES_ERROR = '[Common] Flush queues Error';
export const COMMON_UPDATE_PROTOCOL = '[Common] Update Protocol';
export const COMMON_UPDATE_PROTOCOL_SUCCESS = '[Common] Update Protocol Success';
export const COMMON_UPDATE_PROTOCOL_ERROR = '[Common] Update Protocol Error';
export const COMMON_UPDATE_LEAVING_AGREEMENT = '[Common] Update Leaving Agreement';
export const COMMON_UPDATE_LEAVING_AGREEMENT_SUCCESS = '[Common] Update Leaving Agreement Success';
export const COMMON_UPDATE_LEAVING_AGREEMENT_ERROR = '[Common] Update Leaving Agreement Error';

export const COMMON_INJECT_PROTOCOL = '[Common] Inject Protocol';

export const COMMON_PATCH_OPERATION = '[Common] Patch operation';
export const COMMON_DELETE_OPERATION = '[Common] Delete operation';

export const COMMON_GET_PDF_FROM_PROTOCOL = '[Common] Get Pdf From Protocol';
export const COMMON_GET_PDF_FROM_PROTOCOL_SUCCESS = '[Common] Get Pdf From Protocol Success';
export const COMMON_GET_PDF_FROM_PROTOCOL_ERROR = '[Common] Get Pdf From Protocol Error';

export const COMMON_DOWNLOAD_PDF = '[Common] Download PDF ';
export const COMMON_DOWNLOAD_LEAVING_AGREEMENT_PDF = '[Common] Download Leaving Agreement PDF ';

export const COMMON_ADD_COMPONENT = '[Common] Add Component';
export const COMMON_ADDED_COMPONENT = '[Common] Added Component';

export const COMMON_COPY_COMPONENT = '[Common] Copy Component';
export const COMMON_COPIED_COMPONENT = '[Common] Copied Component';

export const COMMON_DUPLICATE_ALL_COMPONENTS = '[Common] Duplicate All Components';
export const COMMON_DUPLICATE_ALL_COMPONENTS_SUCCESS = '[Common] Duplicate All Components Success';
export const COMMON_DUPLICATE_ALL_COMPONENTS_ERROR = '[Common] Duplicate All Components Error';

export const COMMON_DUPLICATE_COMPONENT = '[Common] Duplicate Component';
export const COMMON_DUPLICATE_COMPONENT_SUCCESS = '[Common] Duplicate Component Success';
export const COMMON_DUPLICATE_COMPONENT_ERROR = '[Common] Duplicate Component Error';

export const COMMON_REMOVE_COMPONENT = '[Common] Remove Component';

export const COMMON_MARK_AS_OFFLINE = '[Common] Mark As Offline';
export const COMMON_MARK_AS_OFFLINE_SUCCESS = '[Common] Mark As Offline Success';
export const COMMON_MARK_AS_OFFLINE_ERROR = '[Common] Mark As Offline Error';

export const COMMON_SYNC_OFFLINE = '[Common] Sync Offline';
export const COMMON_SYNC_OFFLINE_TRIGGERED = '[Common] Sync Offline Triggered';
export const COMMON_SYNC_OFFLINE_START = '[Common] Sync Offline Start';
export const COMMON_SYNC_OFFLINE_SUCCESS = '[Common] Sync Offline Success';
export const COMMON_SYNC_OFFLINE_ERROR = '[Common] Sync Offline Error';

export const COMMON_SET_CURRENT_SIGNER = '[Common] Set current signer';
export const COMMON_ABORT_AND_CLOSE_SIGN_PAGE = '[Common] Aabort and close sign page';
export const COMMON_ERROR_SIGN = '[Common] Error sign';
export const COMMON_CONFIRM_SIGN = '[Common Step] Confirm sign';
export const COMMON_CONFIRMED_SIGN = '[Common] Confirmed sign';
export const COMMON_REJECTED_SIGN = '[Common] Rejected sign';
export const COMMON_REJECT_SIGN = '[Common] Reject sign';
export const COMMON_DELETE_SIGN = '[Common] Delete sign';
export const COMMON_DELETED_SIGN = '[Common] Deleted sign';

export const COMMON_MARK_AS_ERROR = '[Common] Mark As Error';
export const COMMON_UNMARK_ERROR = '[Common] Unmark Error';

export const COMMON_PERSIST_SENTRY_ERROR = '[Common] Persist Sentry Error';
export const COMMON_RESET_SENTRY_ERRORS = '[Common] Reset Sentry Errors';

export const COMMON_ADD_TO_DOWNLOAD = '[Common] Add To Download';
export const COMMON_ADD_TO_DOWNLOAD_SUCCESS = '[Common] Add To Download Success';
export const COMMON_ADD_TO_DOWNLOAD_ERROR = '[Common] Add To Download Error';

export const COMMON_REMOVE_FROM_DOWNLOAD = '[Common] Remove From Download';
export const COMMON_REMOVE_FROM_DOWNLOAD_SUCCESS = '[Common] Remove From Download Success';
export const COMMON_REMOVE_FROM_DOWNLOAD_ERROR = '[Common] Remove From Download Error';

export const COMMON_LOAD_KEYCLOAK_CONFIG = '[Common] Load Keycloak Config';
export const COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS = '[Common] Load Keycloak Config Success';
export const COMMON_LOAD_KEYCLOAK_CONFIG_ERROR = '[Common] Keycloak Config Error';
export const COMMON_KEYCLOAK_SET = '[Common] Set Keycloak';
export const COMMON_KEYCLOAK_UNSET = '[Common] Unset Keycloak';

export const COMMON_KEYCLOAK_DO_LOGIN = '[Common] Keycloak Do Login';

export const COMMON_KEYCLOAK_TOKENS = '[Common] Keycloak Tokens';
export const COMMON_KEYCLOAK_TOKENS_REFRESH = '[Common] Keycloak Tokens Refresh';
export const COMMON_KEYCLOAK_TOKENS_SUCCESS = '[Common] Keycloak Tokens Success';
export const COMMON_KEYCLOAK_TOKENS_ERROR = '[Common] Keycloak Tokens Error';
export const COMMON_REMOVE_KEYCLOAK_TOKENS = '[Common] Remove Keycloak Tokens';
export const COMMON_OPEN_LOGIN_MODAL = '[Common] Open Login Modal';
export const COMMON_LOGIN_TOKEN_SET = '[Common] Login Token Set';
export const COMMON_LOGIN_TOKEN_UNSET = '[Common] Login Token Unset';
export const COMMON_DAP_LOGIN_TOKEN_CHECKER_START = '[Common] DAP Login Token Checker Start';
export const COMMON_DAP_LOGIN_TOKEN_EXPIRED = '[Common] DAP Login Token Expired';
export const COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DEPOT = '[Common] Patch Tenancy Agreement Security Deposit';
export const COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS = '[Common] Patch Tenancy Agreement Security Details';
export const COMMON_CLOSE_LOGIN_MODAL = '[Common] Close Login Modal';
