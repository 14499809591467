/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useEffect } from 'react';

import { colors } from '../../common/theme/colors';
import { usePrevious } from '../../common/code/usePrevious';

const styles = {
  modal: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'none',
    '&.is-open': {
      display: 'block',
    },
  },
  body: {
    background: colors.lightGrey,
    width: '100%',
    height: '100%',
  },
};

const FullScreenModal = (props) => {
  const { isOpen, onOpen, children } = props;

  const previousIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (isOpen !== previousIsOpen) {
      if (isOpen) {
        onOpen();
      }
    }
  }, [isOpen, previousIsOpen, onOpen]);

  return (
    <div
      css={styles.modal}
      style={{ zIndex: isOpen ? 1499 : -1 }}
      className={classNames({ 'is-open': isOpen })}
    >
      <div css={styles.body}>{isOpen && children}</div>
    </div>
  );
};

FullScreenModal.propTypes = {
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  children: PropTypes.node.isRequired,
};

FullScreenModal.defaultProps = {
  isOpen: false,
  onOpen: () => {},
};

export default FullScreenModal;
