const initialState = {
  ui: {
    modal: {
      delete: false,
      assignment: false,

      info: false,
      message: null,
      title: null,
    },
  },
};

export default initialState;
