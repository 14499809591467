import produce from 'immer';
import { eventChannel, END } from 'redux-saga';
import { select, call, take, put, takeLatest, cancelled } from 'redux-saga/effects';
import {
  MIGRATION_HUB_LISTENER,
  MIGRATION_HUB_LISTENER_ERROR,
  MIGRATION_HUB_MESSAGE_PROGRESS_CHANGED,
  MIGRATION_HUB_MESSAGE_IS_COMPLETED,
  MIGRATION_HUB_MESSAGE_IS_FAULTED,
  MIGRATION_HUB_MESSAGE_IS_SUSPENDED,
  MIGRATION_HUB_MESSAGE_IS_RUNNING,
} from './constants';

import { selectHubConnection } from './selectors';

export function migrationHubListener() {
  return {
    type: MIGRATION_HUB_LISTENER,
  };
}

function* doHubListener() {
  try {
    const connection = yield select(selectHubConnection);

    if (!connection) {
      throw new Error('Error: listener failed, connection not valid.');
    }

    const channel = yield call(subscribe, connection);

    try {
      while (true) {
        let action = yield take(channel);
        yield put(action);
      }
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  } catch (error) {
    yield put({
      type: MIGRATION_HUB_LISTENER_ERROR,
      payload: error.message,
    });
  }
}

export function* switchHubListener() {
  yield takeLatest(MIGRATION_HUB_LISTENER, doHubListener);
}

function subscribe(connection) {
  return eventChannel((emitter) => {
    connection.on('MigrationProgressChanged', (type, id, progressPercentage) => {
      emitter({
        type: MIGRATION_HUB_MESSAGE_PROGRESS_CHANGED,
        payload: { type, id, progressPercentage },
      });
    });
    connection.on('MigrationIsCompleted', (type, id, result) => {
      emitter({ type: MIGRATION_HUB_MESSAGE_IS_COMPLETED, payload: { type, id, result } });
    });
    connection.on('MigrationIsFaulted', (type, id, message) => {
      emitter({ type: MIGRATION_HUB_MESSAGE_IS_FAULTED, payload: { type, id, message } });
    });
    connection.on('MigrationIsSuspended', (type, id) => {
      emitter({ type: MIGRATION_HUB_MESSAGE_IS_SUSPENDED, payload: { type, id } });
    });
    connection.on('MigrationIsRunning', (type, id) => {
      emitter({ type: MIGRATION_HUB_MESSAGE_IS_RUNNING, payload: { type, id } });
    });
    connection.exit = () => {
      emitter(END);
    };
    connection.on('disconnect', (e) => {
      // TODO: handle
    });
    return () => {
      connection.stop();
    };
  });
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_HUB_LISTENER_ERROR:
        draft.hubConnection = null;
        break;
      case MIGRATION_HUB_MESSAGE_PROGRESS_CHANGED:
      case MIGRATION_HUB_MESSAGE_IS_COMPLETED:
      case MIGRATION_HUB_MESSAGE_IS_FAULTED:
      case MIGRATION_HUB_MESSAGE_IS_SUSPENDED:
      case MIGRATION_HUB_MESSAGE_IS_RUNNING:
        const { type } = action.payload;
        const reducers = [importReducer, retainReducer, deleteReducer];
        reducers[type](draft, action);
        break;
      default:
        return state;
    }
  });

function importReducer(draft, action) {
  switch (action.type) {
    case MIGRATION_HUB_MESSAGE_PROGRESS_CHANGED:
      const { progressPercentage } = action.payload;
      draft.ui.busy.progressPercentage = progressPercentage;
      break;
    case MIGRATION_HUB_MESSAGE_IS_COMPLETED:
      const { result } = action.payload;
      draft.ui.busy.isRunning = false;
      draft.migration.status = 2;
      draft.migration = result;
      draft.importMigration = result.results;
      draft.selectedTemplate = result.items && result.items[0].name;
      break;
    case MIGRATION_HUB_MESSAGE_IS_FAULTED:
      draft.ui.busy.isRunning = false;
      draft.migration.status = 2;
      break;
    case MIGRATION_HUB_MESSAGE_IS_SUSPENDED:
      draft.ui.busy.isRunning = false;
      break;
    case MIGRATION_HUB_MESSAGE_IS_RUNNING:
      draft.ui.busy.isRunning = true;
      break;
    default:
      break;
  }
}

function retainReducer(draft, action) {
  switch (action.type) {
    case MIGRATION_HUB_MESSAGE_IS_COMPLETED:
      const { result } = action.payload;
      draft.ui.busy.loading = false;
      draft.ui.modal.retain = true;
      draft.retainMigration = result;
      break;
    case MIGRATION_HUB_MESSAGE_IS_FAULTED:
      draft.ui.busy.loading = false;
      draft.ui.modal.retain = true;
      break;
    default:
      break;
  }
}

function deleteReducer(draft, action) {
  switch (action.type) {
    case MIGRATION_HUB_MESSAGE_IS_COMPLETED:
      const { result } = action.payload;
      draft.ui.busy.loading = false;
      draft.ui.modal.delete = true;
      draft.deleteMigration = result;
      break;
    case MIGRATION_HUB_MESSAGE_IS_FAULTED:
      draft.ui.busy.loading = false;
      draft.ui.modal.delete = true;
      break;
    default:
      break;
  }
}
