/** @jsxImportSource @emotion/react */
import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { object } from 'yup';
import { equals, map } from 'ramda';
import { colors } from '../../../common/theme/colors';
import { FormField } from './FormField';
import { getFirstFieldName } from './Functions';
import { validation } from '../../common/Validations';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content max-content',
  },
  title: {
    backgroundColor: `${colors.lighterGrey}`,
  },
  content: (rows) => ({
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'auto',
  }),
};

const getValue = (fieldname, input) => {
  const field = getFirstFieldName(input.fields, fieldname);
  return !!field && !!field.value ? field.value : '';
};

const getSiblings = (fieldname, fields) => {
  const field = getFirstFieldName(fields, fieldname);
  return !!field ? field.siblings : null;
};

const getSiblingValue = (siblingFieldname, siblings) => {
  const field = getFirstFieldName(siblings, siblingFieldname);
  return !!field ? field.value : null;
};

export class Form extends Component {
  constructor(props) {
    super(props);
    this.state = { initialValues: null, validationSchema: null };
  }

  componentDidMount() {
    this.setState({
      initialValues: this.getInitialValues(),
      initialEmptyValues: this.getInitialEmptyValues(),
      validationSchema: this.getValidationSchema(),
    });
  }

  mapFields = map((f) => f.fieldname);
  mapFieldValues = map((f) => ({ name: f.fieldname, value: f.value }));

  componentDidUpdate(prevProps) {
    const prevFields = this.mapFields(prevProps.fields);
    const fields = this.mapFields(this.props.fields);
    const prevValues = this.mapFieldValues(prevProps.values.fields);
    const values = this.mapFieldValues(this.props.values.fields);

    if (!equals(prevFields, fields) || !equals(prevValues, values)) {
      this.setState({ initialValues: this.getInitialValues() });
      this.setState({ initialEmptyValues: this.getInitialEmptyValues() });
    }

    if (!equals(prevFields, fields)) {
      this.setState({ validationSchema: this.getValidationSchema() });
    }
  }

  getInitialValues() {
    const { fields, values } = this.props;

    if (!!fields && !!fields.length && !!values && !!values.fields) {
      return fields.reduce((accumulator, current) => {
        accumulator[escape(current.fieldname)] = getValue(current.fieldname, values);
        if (!!current.siblings && !!current.siblings.length) {
          const inputSiblings = getSiblings(current.fieldname, values.fields);
          current.siblings.forEach((sibling) => {
            accumulator[`${escape(current.fieldname)}\\${escape(sibling.fieldname)}`] =
              getSiblingValue(sibling.fieldname, inputSiblings);
          });
        }
        return accumulator;
      }, {});
    }

    return {};
  }

  getInitialEmptyValues() {
    const { fields } = this.props;

    if (!!fields && !!fields.length) {
      return fields.reduce((accumulator, current) => {
        accumulator[escape(current.fieldname)] = '';
        if (!!current.siblings && !!current.siblings.length) {
          current.siblings.forEach((sibling) => {
            accumulator[`${escape(current.fieldname)}\\${escape(sibling.fieldname)}`] = '';
          });
        }
        return accumulator;
      }, {});
    }

    return {};
  }

  getValidationSchema() {
    const { fields } = this.props;

    return object().shape(
      fields.reduce((accumulator, current) => {
        const validator =
          (!!current.fieldType && validation[current.fieldType.toLowerCase()]) || validation.string;
        if (!!validator) {
          accumulator[current.fieldname] = validator;
        }

        if (!!current.siblings && !!current.siblings.length) {
          current.siblings.forEach((sibling) => {
            const siblingValidator =
              (!!sibling.fieldType && validation[sibling.fieldType.toLowerCase()]) || null;
            if (!!siblingValidator) {
              accumulator[`${escape(current.fieldname)}\\${escape(sibling.fieldname)}`] =
                siblingValidator;
            }
          });
        }

        return accumulator;
      }, {}),
    );
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    fields: PropTypes.array.isRequired,
    values: PropTypes.object.isRequired,
    language: PropTypes.string.isRequired,
    onModifyField: PropTypes.func.isRequired,
    onModifySibling: PropTypes.func.isRequired,
  };

  render() {
    const { id, fields, children, language, onModifyField, onModifySibling, dataTestId } = this.props;
    const { initialEmptyValues, initialValues, validationSchema } = this.state;

    return (
      !!initialEmptyValues &&
      !!initialValues &&
      !!validationSchema && (
        <div css={styles.container}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={true}
          >
            {({ values, errors, touched, setFieldValue, setFieldTouched, resetForm }) => {
              return (
                <Fragment>
                  <div css={styles.title}>{children({ initialEmptyValues, resetForm })}</div>
                  <form data-testid={dataTestId} css={styles.content(fields.length)} autoComplete="off">
                    {fields.map((field, index) => (
                      <FormField
                        id={id}
                        key={field.fieldname + index}
                        index={index}
                        field={field}
                        values={values}
                        errors={errors}
                        touched={touched}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        onModifyField={onModifyField}
                        onModifySibling={onModifySibling}
                        language={language}
                        hasSomeSiblings={fields.some((f) => f.siblings)}
                      />
                    ))}
                  </form>
                </Fragment>
              );
            }}
          </Formik>
        </div>
      )
    );
  }
}
