import { DefaultPage } from './';

const migrationRoute = {
  path: '/migration',
  name: 'Migration',
  childRoutes: [
    {
      path: 'default',
      name: 'Default page',
      component: DefaultPage,
      isIndex: true,
    },
  ],
};

export default migrationRoute;
