/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { map, indexOf, reduce } from 'ramda';
import { Trans } from '@lingui/macro';

import { i18n } from '../../common/i18n-loader';
import { colors } from '../../common/theme/colors';
import EditComponentToAddElement from './EditComponentToAddElement';
import { getLocalizedName } from '../acceptance-protocol/fill-step/Functions';

const styles = {
  container: {
    display: 'grid',
    gridGap: '0.3em',
    gridTemplateRows: 'auto min-content',
  },
  scrollZone: {
    backgroundColor: colors.dark,
    maxHeight: '70vh',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
  },
  header: {
    display: 'grid',
    alignContent: 'center',
    minHeight: '3em',
    borderBottom: `1px ${colors.greyBorderAcceptanceProtocol} solid`,
    paddingLeft: '1em',

    textAlign: 'left',
    color: `${colors.white}`,
  },
  rowHeader: {
    display: 'grid',
    alignContent: 'center',

    backgroundColor: `${colors.darker}`,
    color: `${colors.white}`,

    minHeight: '3em',

    paddingLeft: '1em',
    textAlign: 'left',
  },

  row: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: 'auto min-content',

    borderBottom: `1px ${colors.greyBorderAcceptanceProtocol} solid`,
    minHeight: '3em',

    textAlign: 'left',
    backgroundColor: `${colors.dark}`,
    color: `${colors.white}`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

const EditComponentToAddElements = (props) => {
  const { language, componentsTemplate, indexedElementsTemplate, currentElements, onAdd } = props;

  const currentElementIds = useMemo(() => map((el) => el.id)(currentElements), [currentElements]);

  const filterAddableElements = (elements) =>
    reduce(
      (accumulator, element) => {
        if (indexOf(element)(currentElementIds) === -1) {
          accumulator.push(indexedElementsTemplate[element]);
        }
        return accumulator;
      },
      [],
      elements,
    );

  const addableElements = reduce(
    (accumulator, componentTemplate) => {
      const elementsTemplate = filterAddableElements(
        !!componentTemplate.elements ? componentTemplate.elements : [],
      );

      if (elementsTemplate.length > 0) {
        accumulator.push({ componentTemplate, elementsTemplate });
      }

      return accumulator;
    },
    [],
    componentsTemplate,
  );

  return addableElements.length > 0 ? (
    <div css={styles.container}>
      <div css={styles.header}>{i18n._('FILL-STEP.ADD-ELEMENT.TITLE')}</div>
      <div style={styles.scrollZone}>
        {map(({ elementsTemplate, componentTemplate }) => {
          return (
            <div key={componentTemplate.id}>
              <div css={styles.rowHeader}>{getLocalizedName(componentTemplate, language)}</div>
              <div>
                {map(
                  (elementTemplate) => (
                    <div key={elementTemplate.id} css={styles.row}>
                      <EditComponentToAddElement
                        language={language}
                        template={elementTemplate}
                        onAdd={onAdd}
                      />
                    </div>
                  ),
                  elementsTemplate,
                )}
              </div>
            </div>
          );
        }, addableElements)}
      </div>
    </div>
  ) : (
    <div css={styles.rowHeader}>
      <Trans>MESSAGES.NO-ELEMENT-TO-ADD</Trans>
    </div>
  );
};

export default EditComponentToAddElements;
