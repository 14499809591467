/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';

import { acceptanceProtocolRoutes } from '../acceptance-protocol/routes';
import { TopBarBaseStep } from './TopBarBaseStep';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'uppercase',
    svg: {
      float: 'right',
    },
    '&.language-locked': {
      cursor: 'not-allowed',
    },
  },
};

export const TopBarFillStep = (props) => {
  const { protocolId, currentStep, onNavigateTo, isProtocolLocked, isLast } = props;

  const handleNavigateToFillStep = useCallback(() => {
    if (!isProtocolLocked) {
      onNavigateTo(acceptanceProtocolRoutes.fillStep(protocolId));
    }
  }, [onNavigateTo, protocolId, isProtocolLocked]);

  const isLocked = useMemo(
    () => !!isProtocolLocked && <Icon icon="lock" size="sm" title="toggle" />,
    [isProtocolLocked],
  );

  return (
    <TopBarBaseStep
      step="fill-step"
      isActive={!!currentStep && currentStep === 'fill-step'}
      onSelectStep={handleNavigateToFillStep}
      isLast={isLast}
    >
      <div css={styles.container} className={classNames({ 'language-locked': isProtocolLocked })}>
        <div css={styles.label}>
          <Trans>LABELS.REGISTRATION</Trans>
        </div>
        {isLocked}
      </div>
    </TopBarBaseStep>
  );
};
