// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.
import { reducer as closeCostModal } from './fillStepCloseCostModal';
import { reducer as closeImageSketcherModal } from './fillStepCloseImageSketcherModal';
import { reducer as closeViewImageModal } from './fillStepCloseViewImageModal';
import { reducer as editComponent } from './fillStepEditComponent';
import { reducer as openCostModal } from './fillStepOpenCostModal';
import { reducer as openImageSketcherModal } from './fillStepOpenImageSketcherModal';

import { reducer as openTakePhotoModal } from './fillStepOpenTakePhotoModal';
import { reducer as closeTakePhotoModal } from './fillStepCloseTakePhotoModal';
import { reducer as showSpinner } from './fillStepShowSpinner';
import { reducer as hideSpinner } from './fillStepHideSpinner';
import { reducer as openViewImageModal } from './fillStepOpenViewImageModal';
import { reducer as saveImage } from './fillStepSaveImage';
import { reducer as scrollToComponent } from './fillStepScrollToComponent';
import { reducer as selectElement } from './fillStepSelectElement';
import initialState from './initialState';

const reducers = [
  selectElement,
  openCostModal,
  closeCostModal,
  openImageSketcherModal,
  closeImageSketcherModal,
  openTakePhotoModal,
  closeTakePhotoModal,
  showSpinner,
  hideSpinner,
  saveImage,
  openViewImageModal,
  closeViewImageModal,
  scrollToComponent,
  editComponent,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
