import { put, select, takeLatest } from 'redux-saga/effects';

import { selectCurrentProtocol } from '../../acceptance-protocol/redux/selectors';
import { COMMON_SET_PROTOCOL_LANGUAGE } from './constants';
import { commonPatchOperation } from './patchOperation';

export function commonSetProtocolLanguage(language) {
  return {
    type: COMMON_SET_PROTOCOL_LANGUAGE,
    payload: language,
  };
}

function* doUpdateProtocolLanguage({ payload }) {
  const protocol = yield select(selectCurrentProtocol);
  if (!!protocol) {
    yield put(commonPatchOperation(protocol.id, 'lockLanguage', payload));
  }
}

export function* switchSetProtocolLanguage() {
  yield takeLatest(COMMON_SET_PROTOCOL_LANGUAGE, doUpdateProtocolLanguage);
}
