import produce from 'immer';
import { select, call, put, takeLatest } from 'redux-saga/effects';
import { authLogout } from '../services/auth';
import { i18n } from '../../../common/i18n-loader';
import { purgeAllImages } from '../../../common/code/image.repository';
import { COMMON_LOGOUT, COMMON_LOGOUT_CONFIRMED, COMMON_LOGOUT_CANCELED } from './constants';
import { push } from 'connected-react-router';
import {
  selectHasAnyQueue,
  selectIsKeycloakActive, selectIsOnline, selectIsSsoLogin,
  selectKeycloak,
  selectSessionId
} from "./selectors";
import { askForConfirmation } from './openConfirmationModal';
import { commonKeycloakDoLogin, commonRemoveFromDownload } from "./actions";
import { LOGIN_WORKFLOW_DEFAULT } from "./loginWorkflowConstants";

export function commonLogout() {
  return {
    type: COMMON_LOGOUT,
  };
}

function* doLogout() {
  const sessionId = yield select(selectSessionId);
  const hasAnyQueue = yield select(selectHasAnyQueue);
  const isKeycloakActive = yield select(selectIsKeycloakActive);
  const keycloak = yield select(selectKeycloak);
  const isSsoLogin = yield select(selectIsSsoLogin);
  const isOnline = yield select(selectIsOnline);
  let confirmed;
  if (isKeycloakActive && isSsoLogin){
    confirmed = hasAnyQueue
      ? yield call(askForConfirmation, {
        title: i18n._('CONFIRM-LOGOUT.TITLE'),
        message: i18n._('CONFIRM-LOGOUT.SSO.MESSAGE'),
        ok: i18n._('COMMON.BUTTONS.LOGOUT'),
        cancel: i18n._('COMMON.BUTTONS.LOGIN'),
      })
      : true;
  } else {
    confirmed = hasAnyQueue
      ? yield call(askForConfirmation, {
        title: i18n._('CONFIRM-LOGOUT.TITLE'),
        message: i18n._('CONFIRM-LOGOUT.MESSAGE'),
      })
      : true;
  }

  if (confirmed) {
    yield put({
      type: COMMON_LOGOUT_CONFIRMED,
    });

    yield purgeAllImages();

    yield put(commonRemoveFromDownload({ sessionId }));

    authLogout();

    if (isOnline && isKeycloakActive && keycloak) {
      keycloak.logout();
    }

    yield put(push('/'));
  } else if (isKeycloakActive && isSsoLogin) {
    yield put(commonKeycloakDoLogin());
  } else {
    yield put({
      type: COMMON_LOGOUT_CANCELED,
    });
  }
}

export function* switchLogout() {
  yield takeLatest(COMMON_LOGOUT, doLogout);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_LOGOUT_CONFIRMED:
        draft.authInfo.isLoggedIn = false;
        draft.authInfo.isTokenExpired = false;
        draft.authInfo.isSsoLogin = false;
        draft.authInfo.accessToken = null;
        draft.authInfo.idToken = null;
        draft.authInfo.expiresAt = null;
        draft.authInfo.refreshToken = null;
        draft.username = '';
        draft.locales = null;

        draft.protocols = [];
        draft.queues = {};
        draft.offlineProtocols = [];
        draft.errorSynchronizationProtocols = [];
        draft.sessionId = null;
        draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        break;
      default:
        return state;
    }
  });
