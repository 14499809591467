/** @jsxImportSource @emotion/react */
import { routerActions } from 'connected-react-router';
import { keys } from 'ramda';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Trans } from '@lingui/macro';

import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';
import {
  selectCurrentId,
  selectCurrentProtocol,
  selectCurrentStep,
  selectIsProtocolReadonly,
  selectProgressDetails,
  selectProtocolLanguage,
  selectIsLanguageLocked,
  selectCurrentLeavingAgreementHasManagerSigned,
} from '../acceptance-protocol/redux/selectors';
import { homeRoutes } from '../home/routes';
import { Icon } from './Icon';
import * as commonActions from './redux/actions';
import * as actions from './redux/actions';
import {
  selectLanguage,
  selectLanguages,
  selectIsHome,
  selectIsOnline,
  selectHasLeavingAgreement,
  selectIsLoggedIn,
  selectUsername,
  selectClient,
} from './redux/selectors';
import { TopBarLanguage } from './TopBarLanguage';
import { TopBarSteps } from './TopBarSteps';
import { NetworkIcon } from './NetworkIcon';
import version from '../../version.json';

const before = (color) => ({
  width: 0,
  height: 0,
  borderTop: `1.2em solid ${color}`,
  borderLeft: '0.6em solid transparent',
  borderBottom: `1.2em solid ${color}`,
});

const after = (color) => ({
  width: 0,
  height: 0,
  borderTop: '1.2em solid transparent',
  borderLeft: `0.6em solid ${color}`,
  borderBottom: '1.2em solid transparent',

  '&.is-active': {
    borderLeftColor: colors.brand,
  },
});

const baseItem = (template) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: template,
  border: 'none',
  color: 'white',
  backgroundColor: colors.darker,
  textAlign: 'center',
  height: '2.4em',
});

const styles = {
  header: {
    zIndex: 1,
    width: '100%',
    height: '100%',
  },
  nav: {
    position: 'relative',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'min-content min-content auto min-content min-content',
    height: '100%',

    background: colors.dark,
    padding: `1.2em ${variables.gutter.sm}`,

    [variables.breakpoints.md]: {
      padding: `1.2em ${variables.gutter.md}`,
    },
    home: {
      ...baseItem('auto min-content'),
      justifyContent: 'center',
      backgroundColor: colors.darker,
      position: 'relative',

      padding: `0 0.3em`,
      cursor: 'pointer',

      [variables.breakpoints.md]: {
        padding: `0 0.6em`,
      },
      button: {
        backgroundColor: 'transparent',
      },
      after: after(colors.darker),

      '&.is-active': {
        backgroundColor: colors.brand,
      },
      networkIcon: {
        display: 'none',
        position: 'absolute',
        transform: 'rotate(-45deg)',

        [variables.breakpoints.md]: {
          display: 'block',
        },
      },
      icon: {
        alignSelf: 'center',
      },
      user: {
        marginLeft: '12px',
        alignSelf: 'center',
        display: 'grid',
        gridTemplateColumns: 'min-content',
        gridTemplateRows: 'auto auto',
        gridTemplateAreas: '"client" "username"',
        textAlign: 'left',
        fontFamily: 'Rubik, sans-serif',
        maxWidth: '100px',
        userSelect: 'none',

        client: {
          gridArea: 'client',
          fontSize: '14px',
          color: 'rgba(255,255,255,0.8)',
          lineHeight: '1',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: 'inherit',
        },
        username: {
          gridArea: 'username',
          fontSize: '16px',
          fontWeight: '500',
          lineHeight: '1',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          maxWidth: 'inherit',
        },
      },
    },
    language: {
      ...baseItem('min-content auto'),
      backgroundColor: colors.dark,
      before: before(colors.dark),
      paddingLeft: '.6em',
    },
    version: {
      fontSize: '12px',
      color: 'white',
      position: 'absolute',
      bottom: '4px',
      right: variables.gutter.sm,

      [variables.breakpoints.md]: {
        right: variables.gutter.md,
      },
    },
  },
};

export class TopBar extends Component {
  onNavigateTo = (route) => {
    const {
      actions: { push },
    } = this.props;

    push(route);
  };

  static propTypes = {
    language: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    protocol: PropTypes.object,
    protocolLanguage: PropTypes.string,
    progressDetails: PropTypes.object,
    isProtocolReadonly: PropTypes.bool,
    isLanguageLocked: PropTypes.bool,
    currentStep: PropTypes.string,
  };

  render() {
    const {
      language,
      languages,
      protocol,
      protocolLanguage,
      progressDetails,
      isProtocolReadonly,
      isLanguageLocked,
      currentStep,
      protocolId,
      isOnline,
      isHome,
      hasLeavingAgreement,
      hasManagerSigned,
      isLoggedIn,
      username,
      client,
      actions: { commonOpenNotImplementedModal, commonSetLanguage, commonSetProtocolLanguage },
    } = this.props;

    let stepKeys = keys(progressDetails);

    if (!!progressDetails && hasLeavingAgreement) {
      stepKeys.splice(stepKeys.length - 1, 0, 'leavingAgreementStep');
    }

    return (
      <header css={styles.header}>
        <nav css={styles.nav}>
          <div
            css={styles.nav.home}
            className={classNames({ 'is-active': isHome })}
            onClick={this.onHomeClick}
          >
            <div css={styles.nav.home.networkIcon}>
              <NetworkIcon isOnline={isOnline} />
            </div>
            <Icon icon="home" color="white" css={styles.nav.home.icon} />
            {isLoggedIn && (
              <div css={styles.nav.home.user}>
                <div css={styles.nav.home.user.client}>{client}</div>
                <div css={styles.nav.home.user.username}>{username}</div>
              </div>
            )}
          </div>
          <div css={styles.nav.home.after} className={classNames({ 'is-active': isHome })} />
          <TopBarSteps
            isProtocolLocked={isProtocolReadonly}
            hasManagerSigned={hasManagerSigned}
            protocolId={protocolId}
            currentStep={currentStep}
            protocol={protocol}
            stepKeys={stepKeys}
            onNavigateTo={this.onNavigateTo}
            onOpenNotImplementedModal={commonOpenNotImplementedModal}
          />
          <div css={styles.nav.language}>
            <TopBarLanguage
              language={!!protocolLanguage ? protocolLanguage : language}
              languages={languages}
              onSetLanguage={!!protocolLanguage ? commonSetProtocolLanguage : commonSetLanguage}
              isLanguageLocked={isLanguageLocked}
            />
          </div>
          <div css={styles.nav.version}>
            <Trans>LABELS.VERSION</Trans>&nbsp;{version.version}
          </div>
        </nav>
      </header>
    );
  }

  onHomeClick = (e, v) => {
    const {
      actions: { push },
    } = this.props;

    push(homeRoutes.home);
  };
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    language: selectLanguage(state),
    languages: selectLanguages(state),
    protocol: selectCurrentProtocol(state),
    protocolLanguage: selectProtocolLanguage(state),
    progressDetails: selectProgressDetails(state),
    isProtocolReadonly: selectIsProtocolReadonly(state),
    hasManagerSigned: selectCurrentLeavingAgreementHasManagerSigned(state),
    isLanguageLocked: selectIsLanguageLocked(state),
    currentStep: selectCurrentStep(state),
    protocolId: selectCurrentId(state),
    isOnline: selectIsOnline(state),
    isHome: selectIsHome(state),
    hasLeavingAgreement: selectHasLeavingAgreement(state),
    isLoggedIn: selectIsLoggedIn(state),
    username: selectUsername(state),
    client: selectClient(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...routerActions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
