import { find } from 'ramda';
import { getLocalizedLabel } from '../../fill-step/Functions';

export function getValueFromFields(fields, value) {
  const field = find((field) => field.fieldname === value, fields);
  return !!field && !!field.value ? field.value : '';
}

export function getBaseDataFromInput(inputBaseData) {
  let baseDataForValidation = {
    VormieterEmail: null,
    VormieterHaftungsdatum: null,
    NachmieterEmail: null,
    NachmieterMietbeginn: null,
    VormieterEmail_2: null,
    VormieterHaftungsdatum_2: null,
    NachmieterEmail_2: null,
    NachmieterMietbeginn_2: null,
  };

  inputBaseData.forEach((baseData) => {
    switch (baseData.id) {
      case 'oldRentalPerson':
        baseDataForValidation.VormieterEmail = getValueFromFields(
          baseData.fields,
          'VormieterEmail',
        );
        baseDataForValidation.VormieterHaftungsdatum = getValueFromFields(
          baseData.fields,
          'VormieterHaftungsdatum',
        );
        break;
      case 'newRentalPerson':
        baseDataForValidation.NachmieterEmail = getValueFromFields(
          baseData.fields,
          'NachmieterEmail',
        );
        baseDataForValidation.NachmieterMietbeginn = getValueFromFields(
          baseData.fields,
          'NachmieterMietbeginn',
        );
        break;
      case 'oldRentalPerson_2':
        baseDataForValidation.VormieterEmail_2 = getValueFromFields(
          baseData.fields,
          'VormieterEmail',
        );
        baseDataForValidation.VormieterHaftungsdatum_2 = getValueFromFields(
          baseData.fields,
          'VormieterHaftungsdatum',
        );
        break;
      case 'newRentalPerson_2':
        baseDataForValidation.NachmieterEmail_2 = getValueFromFields(
          baseData.fields,
          'NachmieterEmail',
        );
        baseDataForValidation.NachmieterMietbeginn_2 = getValueFromFields(
          baseData.fields,
          'NachmieterMietbeginn',
        );
        break;
      default:
    }
  });
  return baseDataForValidation;
}

export function getLocalizedLabelForComponentFromTemplate(template, key, lang) {
  return getLocalizedLabel(
    template.find((elm) => elm.id === key),
    lang,
  );
}

export function getLocalizedLabelForFieldFromTemplate(template, key, lang) {
  const field = template
    .map((elm) => elm.fields)
    .flat()
    .find((elm) => elm.fieldname === key);
  return getLocalizedLabel(field, lang);
}

export function validationFieldTranslation(template, key, lang) {
  const componentId = key.includes('Vormieter') ? 'oldRentalPerson' : 'newRentalPerson';
  const additionalPerson = key.includes('_2');
  const fieldName = key.replace('_2', '');

  return `${getLocalizedLabelForComponentFromTemplate(template, componentId, lang)} ${
    additionalPerson ? '2' : ''
  } 
    ${getLocalizedLabelForFieldFromTemplate(template, fieldName, lang)}`;
}
