import produce from 'immer';
import {
  COMMON_LOAD_KEYCLOAK_CONFIG,
  COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS,
  COMMON_LOAD_KEYCLOAK_CONFIG_ERROR,
  COMMON_KEYCLOAK_UNSET,
  COMMON_KEYCLOAK_SET,
  COMMON_KEYCLOAK_DO_LOGIN,
  COMMON_KEYCLOAK_TOKENS,
  COMMON_KEYCLOAK_TOKENS_ERROR,
  COMMON_REMOVE_KEYCLOAK_TOKENS,
  COMMON_KEYCLOAK_TOKENS_REFRESH
} from "./constants";

export function commonKeycloakOnSet(keycloak) {
  return {
    type: COMMON_KEYCLOAK_SET,
    payload: { keycloak },
  };
}

export function commonKeycloakOnTokens(tokens, workflow) {
  return {
    type: COMMON_KEYCLOAK_TOKENS,
    payload: { tokens: tokens, workflow: workflow },
  };
}

export function commonLoadKeycloakConfig() {
  return {
    type: COMMON_LOAD_KEYCLOAK_CONFIG,
  };
}

export function commonKeycloakDoLogin() {
  return {
    type: COMMON_KEYCLOAK_DO_LOGIN,
  };
}

export function commonKeycloakDoTokenRefresh() {
  return {
    type: COMMON_KEYCLOAK_TOKENS_REFRESH,
  };
}

export function removeKeycloakTokens() {
  return {
    type: COMMON_REMOVE_KEYCLOAK_TOKENS,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_LOAD_KEYCLOAK_CONFIG:
        draft.ui.busy.config = true;
        break;
      case COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS:
        draft.ui.busy.config = false;
        draft.authInfo.isSsoLogin = action.payload.keycloakConfig.isKeycloakActive;
        draft.keycloakConfig = action.payload.keycloakConfig;

        if (action.payload.keycloakConfig.client) {
          draft.client = action.payload.keycloakConfig.client;
        }
        break;
      case COMMON_LOAD_KEYCLOAK_CONFIG_ERROR:
        draft.ui.busy.config = false;
        draft.client = '';
        draft.keycloak = null;
        draft.keycloakConfig = null;
        draft.authInfo.isSsoLogin = false;
        break;
      case COMMON_KEYCLOAK_SET:
        draft.keycloak = action.payload.keycloak;
        break;
      case COMMON_KEYCLOAK_UNSET:
        draft.ui.busy.config = false;
        draft.client = '';
        draft.keycloak = null;
        draft.keycloakConfig = null;
        draft.authInfo.isSsoLogin = false;
        break;
      case COMMON_KEYCLOAK_TOKENS:
        break;
      case COMMON_KEYCLOAK_DO_LOGIN:
        break;
      case COMMON_KEYCLOAK_TOKENS_ERROR:
        draft.authInfo.isLoggedIn = false;
        break;
      case COMMON_REMOVE_KEYCLOAK_TOKENS:
        if (draft.keycloak) {
          draft.keycloak.token = null;
          draft.keycloak.idToken = null;
          draft.keycloak.refreshToken = null;
        }
        break;
      default:
        return state;
    }
  });
