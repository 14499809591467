import React from 'react';

export const Residence = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 50"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M60,46h4V32H61V25H19v7H16V46h4v2h3V46H57v2h3ZM59,32H57v7H42V27H59ZM21,27H40V39H23V32H21Zm-3,7h3v7H59V34h3V44H18Z" />
  </svg>
);
