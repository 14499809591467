import produce from 'immer';

import { COMMON_CLOSE_NOT_IMPLEMENTED_MODAL } from './constants';

export function commonCloseNotImplementedModal() {
  return {
    type: COMMON_CLOSE_NOT_IMPLEMENTED_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_CLOSE_NOT_IMPLEMENTED_MODAL:
        draft.ui.modal.notImplemented = false;
        break;
      default:
        return state;
    }
  });
