import produce from 'immer';
import { nanoid } from 'nanoid';

import { COMMON_PATCH_OPERATION, COMMON_DELETE_OPERATION, COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DEPOT, COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS } from './constants';

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_PATCH_OPERATION:
      case COMMON_DELETE_OPERATION:
        const {
          payload: { protocolId, expression, value, isImage, isLeavingAgreement },
        } = action;

        let queue = draft.queues[protocolId];
        if (!queue) {
          queue = { operations: [], retry: 0 };
          draft.queues[protocolId] = queue;
        }

        queue.retry = 0;
        queue.operations.push({
          id: nanoid(),
          type:
            action.type === COMMON_DELETE_OPERATION ? OperationType.Delete : OperationType.Patch,
          expression,
          value,
          isImage,
          isLeavingAgreement,
        });

        break;
      case COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DEPOT:
      case COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS:
        {
          const { payload } = action;

          let queue = draft.queues[payload.protocolId];
          if (!queue) {
            queue = { operations: [], retry: 0 };
            draft.queues[payload.protocolId] = queue;
          }

          queue.retry = 0;
          const index = queue.operations.findIndex(
            (op) => op.isUpdateLettingDetails && op.isOldTenant === payload.isOldTenant
              && op.actionType === action.type
          )

          if (index !== -1) {
            queue.operations[index] = { ...queue.operations[index], ...payload };
          }
          else {
            queue.operations.push({ ...payload, id: nanoid(), isUpdateLettingDetails: true, actionType: action.type });
          }
        }
        break;
      default:
        return state;
    }
  });

export class OperationType {
  static Patch = 1;
  static Delete = -1;
}
