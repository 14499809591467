import { createSelector } from 'reselect';
import { createMatchSelector } from 'connected-react-router';

const selectHome = (state) => state.home;

export const selectSearchRentalObjectRoute = createSelector(
  (state) => state,
  createMatchSelector({ path: '/search' }),
);

export const selectUser = createSelector(selectHome, (state) => state.user);

export const selectIsProtocolWithoutREMReferenceModalOpen = createSelector(
  selectHome,
  (state) => state.ui.modal.protocolWithoutREMReference,
);

export const selectIsProtocolWithREMReferenceModalOpen = createSelector(
  selectHome,
  (state) => state.ui.modal.protocolWithREMReference,
);

export const selectIsProtocolFromPreviousProtocolModalOpen = createSelector(
  selectHome,
  (state) => state.ui.modal.protocolFromPreviousProtocol,
);

export const selectRentalObjects = createSelector(selectHome, (state) => state.rentalObjects);

export const selectSearch = createSelector(selectHome, (state) => state.search);

export const selectUi = createSelector(selectHome, (state) => state.ui);

export const selectBusy = createSelector(selectUi, (ui) => ui.busy);

export const selectIsProtocolWithoutREMReferenceModalBusy = createSelector(
  selectBusy,
  (busy) => busy.createProtocolWithoutREMReference,
);

export const selectIsProtocolWithoutREMReferenceWithPreviousBusy = createSelector(
  selectBusy,
  (busy) => busy.createProtocolFromPreviousProtocol,
);

export const selectIsGetTemplatesBusy = createSelector(selectBusy, (busy) => busy.templates);

export const selectIsGetRentalObjectBusy = createSelector(selectBusy, (busy) => busy.rentalObjects);

export const selectCreateProtocolWithRemReferenceBusy = createSelector(
  selectBusy,
  (busy) => busy.createProtocolWithREMReference,
);

export const selectIsChangeProtocolIdModalOpen = createSelector(
  selectHome,
  (state) => state.ui.modal.changeProtocolId,
);

export const selectTemplates = createSelector(selectHome, (state) => state.templates);

export const selectCreateProtocolWithREMReferenceFromAnotherProtocol = createSelector(
  selectHome,
  (state) => state.ui.busy.createProtocolWithREMReferenceFromAnotherProtocol,
);
