import { takeLatest } from 'redux-saga/effects';
import { MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR } from './constants';
import { doToast } from "../../../../common/rootToastr";

export function notifyError(message) {
  return {
    type: MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR,
    payload: message,
  };
}

function* doNotifyError({ payload }) {
  yield* doToast({
    payload: {
      type: 'error',
      attention: true,
      message: payload,
    }
  });
}

export function* switchNotifyError() {
  yield takeLatest([MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR], doNotifyError);
}
