import { call, put, takeLatest } from 'redux-saga/effects';
import { i18n } from '../../../../common/i18n-loader';
import { COMMON_PATCH_OPERATION } from '../../../common/redux/constants';
import { askForConfirmation } from '../../../common/redux/openConfirmationModal';
import { FILL_STEP_DELETE_COMMENT } from './constants';

export function fillStepDeleteComment(data) {
  return {
    type: FILL_STEP_DELETE_COMMENT,
    payload: data,
  };
}

function* doDeleteComment({ payload }) {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('FILL-STEP.DELETE-COMMENT.TITLE'),
    message: i18n._('FILL-STEP.DELETE-COMMENT.MESSAGE'),
  });
  if (!confirmed) {
    return;
  }

  const expression =
    // prettier-ignore
    `componentsInput/[${payload.selectedComponentId}]/elements/[${payload.selectedElementId}]/comment`;

  yield put({
    type: COMMON_PATCH_OPERATION,
    payload: {
      protocolId: payload.protocolId,
      expression,
      value: '',
    },
  });
}

export function* switchDeleteComment() {
  yield takeLatest(FILL_STEP_DELETE_COMMENT, doDeleteComment);
}
