export const HandoverTypes = {
  VACANCY: 'vacancy',
  HANDOUT: 'handout',
  ACCEPTANCE: 'acceptance',
  HANDOVER: 'handover',
};

export const HandoverTypeFromIndex = (index) => {
  return Object.values(HandoverTypes)[index];
};
