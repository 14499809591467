import { call, put, select, takeLatest } from 'redux-saga/effects';

import { i18n } from '../../../../common/i18n-loader';
import { COMMON_DELETE_OPERATION } from '../../../common/redux/constants';
import { askForConfirmation } from '../../../common/redux/openConfirmationModal';
import { deleteImages } from '../../../../common/code/image.repository';
import { selectCurrentId, selectIsCurrentProtocolOffline } from '../../redux/selectors';
import { FILL_STEP_DELETE_IMAGE } from './constants';
import { fillStepCloseViewImageModal } from './fillStepCloseViewImageModal';
import { selectSelectedIds } from './selectors';

export function fillStepDeleteImage(image) {
  return {
    type: FILL_STEP_DELETE_IMAGE,
    payload: image,
  };
}

function* doDeleteImage({ payload }) {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('FILL-STEP.DELETE-IMAGE.TITLE'),
    message: i18n._('FILL-STEP.DELETE-IMAGE.MESSAGE'),
  });

  if (!confirmed) {
    return;
  }

  const isCurrentProtocolOffline = yield select(selectIsCurrentProtocolOffline);
  const protocolId = yield select(selectCurrentId);
  const selectedIds = yield select(selectSelectedIds);

  const expression =
    // prettier-ignore
    `componentsInput/[${selectedIds.componentId}]/elements/[${selectedIds.elementId}]/images/[${payload.id}]`;

  yield put({
    type: COMMON_DELETE_OPERATION,
    payload: {
      protocolId,
      expression,
    },
  });

  yield deleteImages([payload.reference], isCurrentProtocolOffline);
  yield put(fillStepCloseViewImageModal());
}

export function* switchDeleteImage() {
  yield takeLatest(FILL_STEP_DELETE_IMAGE, doDeleteImage);
}
