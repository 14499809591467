/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react';

import { i18n } from '../../common/i18n-loader';
import Modal from '../common/Modal';
import Spinner from '../common/Spinner';
import NewProtocolWithREMReferenceFromTemplate from './NewProtocolWithREMReferenceFromTemplate';
import NewProtocolWithREMReferenceFromAnotherProtocol from './NewProtocolWithREMReferenceFromAnotherProtocol';

const FROM_TEMPLATE = 'fromTemplate';
const FROM_ANOTHER_PROTOCOL = 'fromAnotherProtocol';

export function NewProtocolWithREMReferenceModal(props) {
  const {
    language,
    styles,
    isModalOpen,
    onCloseModal,
    isBusy,
    templates,
    onHandleCreateProtocolFromTemplate,
    onHandleCreateProtocolFromAnotherProtocol,
    hasInitialAcceptance,
  } = props;

  const [currentMode, setCurrentMode] = useState(FROM_TEMPLATE);

  const switchToNewProtocolFromTemplate = useCallback(() => {
    setCurrentMode(FROM_TEMPLATE);
  }, [setCurrentMode]);

  const switchToNewProtocolFromAnotherProtocol = useCallback(() => {
    setCurrentMode(FROM_ANOTHER_PROTOCOL);
  }, [setCurrentMode]);

  if (!isModalOpen) {
    return null;
  }

  if (currentMode === FROM_TEMPLATE) {
    return (
      <Modal
        title={i18n._('SELECT-TEMPLATE-MODAL.TITLE')}
        language={language}
        onClose={onCloseModal}
        isOpen={true}
        zIndex={1003}
      >
        <Spinner show={isBusy}>
          <div css={styles.modal}>
            <NewProtocolWithREMReferenceFromTemplate
              language={language}
              onSwitchProtocolCreationMode={switchToNewProtocolFromAnotherProtocol}
              onCancel={onCloseModal}
              onSubmit={onHandleCreateProtocolFromTemplate}
              styles={styles}
              templates={templates}
              hasInitialAcceptance={hasInitialAcceptance}
            />
          </div>
        </Spinner>
      </Modal>
    );
  }

  if (currentMode === FROM_ANOTHER_PROTOCOL) {
    return (
      <Modal
        title={i18n._('SELECT-ANOTHER-PROTOCOL-MODAL.TITLE')}
        onClose={onCloseModal}
        isOpen={true}
        zIndex={1003}
      >
        <Spinner show={isBusy}>
          <NewProtocolWithREMReferenceFromAnotherProtocol
            onSwitchProtocolCreationMode={switchToNewProtocolFromTemplate}
            onCancel={onCloseModal}
            onSubmit={onHandleCreateProtocolFromAnotherProtocol}
            styles={styles}
            hasInitialAcceptance={hasInitialAcceptance}
          />
        </Spinner>
      </Modal>
    );
  }
}
