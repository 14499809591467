import { map } from 'ramda';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { deleteImages } from '../../../../common/code/image.repository';
import { i18n } from '../../../../common/i18n-loader';
import { COMMON_PATCH_OPERATION } from '../../../common/redux/constants';
import { askForConfirmation } from '../../../common/redux/openConfirmationModal';
import { selectCurrentId, selectIsCurrentProtocolOffline } from '../../redux/selectors';
import { FILL_STEP_DELETE_ALL_IMAGES } from './constants';
import { selectSelectedIds } from './selectors';

export function fillStepDeleteAllImages(images) {
  return {
    type: FILL_STEP_DELETE_ALL_IMAGES,
    payload: images,
  };
}

function* doDeleteAllImages({ payload }) {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('FILL-STEP.DELETE-ALL-IMAGES.TITLE'),
    message: i18n._('FILL-STEP.DELETE-ALL-IMAGES.MESSAGE'),
  });
  if (!confirmed) {
    return;
  }

  const isCurrentProtocolOffline = yield select(selectIsCurrentProtocolOffline);
  const protocolId = yield select(selectCurrentId);
  const selectedIds = yield select(selectSelectedIds);

  const expression =
    // prettier-ignore
    `componentsInput/[${selectedIds.componentId}]/elements/[${selectedIds.elementId}]/images`;

  yield put({
    type: COMMON_PATCH_OPERATION,
    payload: {
      protocolId,
      expression,
      value: null,
    },
  });

  yield deleteImages(
    map((img) => img.reference, payload),
    isCurrentProtocolOffline,
  );
}

export function* switchDeleteAllImages() {
  yield takeLatest(FILL_STEP_DELETE_ALL_IMAGES, doDeleteAllImages);
}
