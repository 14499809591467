import produce from 'immer';
import { select, call, delay, put, takeLatest } from 'redux-saga/effects';
import { saveImage } from '../../../common/code/image.repository';
import { downloadFile, get } from '../services/api';
import {
  COMMON_MARK_AS_OFFLINE,
  COMMON_MARK_AS_OFFLINE_ERROR,
  COMMON_MARK_AS_OFFLINE_SUCCESS,
  COMMON_DELETE_PROTOCOL_CONFIRM,
} from './constants';
import { unzipImages } from '../../../common/code/zip.helper';
import { commonGetPdfsFromProtocol, commonGetLeavingAgreement, commonGetTenancyAgreement } from './actions';
import { selectHasLeavingAgreement, selectSessionId } from './selectors';

import { commonAddToDownload } from './actions';
import { i18n } from '../../../common/i18n-loader';

export function commonMarkAsOffline(protocolId) {
  return { type: COMMON_MARK_AS_OFFLINE, payload: protocolId };
}

export function* doMarkAsOffline({ payload }) {
  try {
    const sessionId = yield select(selectSessionId);

    const result = yield call(get, `protocol/check-download/${payload}`);

    if (result.cannotDownload) {
      yield put({
        type: COMMON_DELETE_PROTOCOL_CONFIRM,
        payload: {
          protocolId: payload,
          error: i18n._(result.error),
        },
      });

      return;
    }

    yield put(commonAddToDownload({ sessionId, protocolId: payload }));

    const images = yield call(downloadFile, `protocol/images/${payload}`);

    const unzippedImages = yield unzipImages(images);

    for (const unzippedImage of unzippedImages || []) {
      yield saveImage(unzippedImage);
    }

    yield put(commonGetTenancyAgreement(payload));

    const hasLeavingAgreement = yield select(selectHasLeavingAgreement);

    if (hasLeavingAgreement) {
      yield put(commonGetLeavingAgreement(payload));
    }

    yield delay(1000);

    yield put({
      type: COMMON_MARK_AS_OFFLINE_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: COMMON_MARK_AS_OFFLINE_ERROR,
      payload: { error: error.message, id: payload },
    });
  }
}

function* doGetPdfFromProtocol() {
  yield put(commonGetPdfsFromProtocol());
}

export function* switchMarkAsOffline() {
  yield takeLatest(COMMON_MARK_AS_OFFLINE, doMarkAsOffline);
  yield takeLatest(COMMON_MARK_AS_OFFLINE_SUCCESS, doGetPdfFromProtocol);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_MARK_AS_OFFLINE:
        draft.ui.busy.markAsOffline[action.payload] = true;
        break;
      case COMMON_MARK_AS_OFFLINE_ERROR:
        draft.ui.busy.markAsOffline[action.payload.id] = false;
        break;
      case COMMON_MARK_AS_OFFLINE_SUCCESS:
        draft.ui.busy.markAsOffline[action.payload] = false;
        draft.offlineProtocols.length = 0;
        draft.offlineProtocols.push(action.payload);
        break;
      default:
        return state;
    }
  });
