/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { useCallback, useRef, useState } from 'react';
import { map, find } from 'ramda';

import Button from '../common/Button';
import { colors } from '../../common/theme/colors';
import { WriteIcon } from './WriteIcon';
import { PlusIcon } from './PlusIcon';
import EditComponentElement from './EditComponentElement';
import { variables } from '../../common/theme/variables';

const styles = {
  container: {
    display: 'grid',
    gridGap: '0.3em',
    gridTemplateRows: 'auto min-content',
  },
  scrollZone: {
    backgroundColor: colors.dark,
    maxHeight: '70vh',
    overflow: 'auto',
    scrollBehavior: 'smooth',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
  },
  header: {
    display: 'grid',
    alignItems: 'center',
    gridGap: '5px',
    gridTemplateColumns: 'auto min-content min-content',

    minHeight: '3em',
    paddingRight: '1em',

    textAlign: 'left',
    color: colors.white,
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    input: {
      padding: '0.25em',
      paddingLeft: '47px',
      backgroundColor: 'transparent',
      color: colors.white,
      width: '100%',
      height: '100%',
    },
  },
  row: {
    display: 'grid',
    alignContent: 'center',
    gridTemplateColumns: 'auto min-content',

    minHeight: '3em',

    textAlign: 'left',
    backgroundColor: `${colors.dark}`,
    color: `${colors.white}`,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  buttons: {
    display: 'grid',
    padding: '5px',
    justifyContent: 'space-around',
    gridGap: '1em',

    [variables.breakpoints.md]: {
      gridAutoFlow: 'column',
    },

    button: {
      padding: '20px 10px',

      [variables.breakpoints.xl]: {
        padding: '40px 15px',
      },
    },
  },
  icon: {
    backgroundColor: 'transparent',
    color: `${colors.white}`,
    width: '32px',
    height: '32px',
  },
};

const EditComponentCurrentElements = (props) => {
  const {
    language,
    componentHeader,
    indexedElementInputs,
    elements,
    onNameChange,
    onOpenAddModal,
    onRemove,
    onNameElementChange,
    onCancel,
    onSave,
  } = props;

  const [saveIsDisabled, setSaveIsDisabled] = useState(false);

  const inputRef = useRef(null);

  const handleEdit = useCallback(() => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleOnBlur = useCallback((evt) => onNameChange(evt.currentTarget.value), [onNameChange]);

  const handleOnChange = (name) => {
    setSaveIsDisabled(!!!name.target.value);
  };

  return (
    <div css={styles.container}>
      <div css={styles.header}>
        <input
          css={styles.header.input}
          defaultValue={componentHeader}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          ref={inputRef}
        />
        <button css={styles.icon} onClick={handleEdit}>
          <WriteIcon />
        </button>
        <button css={styles.icon} onClick={onOpenAddModal}>
          <PlusIcon />
        </button>
      </div>
      <div style={styles.scrollZone}>
        {map((elementTemplate) => {
          const elementInput =
            !!indexedElementInputs &&
            find((element) => element.id === elementTemplate.id, indexedElementInputs);

          return (
            <div key={elementTemplate.id} css={styles.row}>
              <EditComponentElement
                language={language}
                template={elementTemplate}
                input={elementInput}
                onNameElementChange={onNameElementChange}
                onChange={handleOnChange}
                onRemove={onRemove}
              />
            </div>
          );
        }, elements)}
      </div>
      <div css={styles.buttons}>
        <Button onClick={onCancel}>
          <Trans>BUTTONS.CANCEL</Trans>
        </Button>
        <Button onClick={onSave} disabled={saveIsDisabled}>
          <Trans>BUTTONS.SAVE</Trans>
        </Button>
      </div>
    </div>
  );
};

export default EditComponentCurrentElements;
