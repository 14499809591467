import { call, put, select, takeEvery } from 'redux-saga/effects';
import { COMMON_REMOVE_COMPONENT } from './constants';
import { reject } from 'ramda';

import {
  selectCurrentId,
  selectComponentsOrderInput,
} from '../../acceptance-protocol/redux/selectors';
import { commonPatchOperation } from './patchOperation';

import { i18n } from '../../../common/i18n-loader';
import { askForConfirmation } from '../../common/redux/openConfirmationModal';

export function commonRemoveComponent(componentId) {
  return { type: COMMON_REMOVE_COMPONENT, payload: componentId };
}

function* doRemoveComponent({ payload }) {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('FILL-STEP.REMOVE-COMPONENT.TITLE'),
    message: i18n._('FILL-STEP.REMOVE-COMPONENT.MESSAGE'),
  });
  if (!confirmed) {
    return;
  }

  const protocolId = yield select(selectCurrentId);
  if (!!protocolId) {
    yield put(
      commonPatchOperation(protocolId, `componentsInput/[${payload}]`, {
        elements: [],
        elementsOrder: [],
        localName: null,
        removed: true,
      }),
    );

    const componentsOrderInput = yield select(selectComponentsOrderInput);

    if (!!componentsOrderInput) {
      const updatedComponentsOrderInput = reject((ci) => ci === payload)(componentsOrderInput);

      yield put(
        commonPatchOperation(protocolId, 'componentsOrderInput', updatedComponentsOrderInput),
      );
    }
  }
}

export function* switchRemoveComponent() {
  yield takeEvery(COMMON_REMOVE_COMPONENT, doRemoveComponent);
}
