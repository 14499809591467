import produce from 'immer';

import { HOME_OPEN_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL } from './constants';
export function homeOpenProtocolFromPreviousProtocolModal() {
  return { type: HOME_OPEN_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_OPEN_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL:
        draft.ui.modal.protocolFromPreviousProtocol = true;
        break;
      default:
        return state;
    }
  });
