import { createSelector } from 'reselect';

const selectSend = (state) => state.sendStep;

export const selectIsProgressModalOpen = createSelector(
  selectSend,
  (state) => state.ui.modal.progress,
);

export const selectProgress = createSelector(selectSend, (state) => state.progress);
