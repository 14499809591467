export const HOME_OPEN_PROTOCOL_WITHOUT_REM_REFERENCE_MODAL =
  '[Home] Open Protocol Without Rem Reference Modal';
export const HOME_CLOSE_PROTOCOL_WITHOUT_REM_REFERENCE_MODAL =
  '[Home] Close Protocol Without Rem Reference Modal';

export const HOME_OPEN_PROTOCOL_WITH_REM_REFERENCE_MODAL =
  '[Home] Open Protocol With Rem Reference Modal';
export const HOME_CLOSE_PROTOCOL_WITH_REM_REFERENCE_MODAL =
  '[Home] Close Protocol With Rem Reference Modal';

export const HOME_SEARCH_RENTAL_OBJECTS = '[Home] Search Rental Objects';
export const HOME_SEARCH_RENTAL_OBJECTS_SUCCESS = '[Home] Search Rental Objects Success';
export const HOME_SEARCH_RENTAL_OBJECTS_ERROR = '[Home] Search Rental Objects Error';
export const HOME_CLEAR_RENTAL_OBJECTS = '[Home] Clear Rental Objects';

export const HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE =
  '[Home] Create Protocol Without Rem Reference';
export const HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_SUCCESS =
  '[Home] Create Protocol Without Rem Reference Success';
export const HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR =
  '[Home] Create Protocol Without Rem Reference Error';

export const HOME_OPEN_CHANGE_PROTOCOL_ID_MODAL = '[Home] Open change protocol id modal';
export const HOME_CLOSE_CHANGE_PROTOCOL_ID_MODAL = '[Home] Close change protocol id modal';
export const HOME_GET_TEMPLATES = '[Home] Get Templates';
export const HOME_GET_TEMPLATES_SUCCESS = '[Home] Get Templates Success';
export const HOME_GET_TEMPLATES_ERROR = '[Home] Get Templates Error';

export const HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE = '[Home] Create Protocol With REM Reference';
export const HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_SUCCESS =
  '[Home] Create Protocol With REM Reference Success';
export const HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR =
  '[Home] Create Protocol With REM Reference Error';

export const HOME_OPEN_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL =
  '[Home] Open Protocol From Previous Protocol Modal';
export const HOME_CLOSE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL =
  '[Home] Close Protocol From Previous Protocol Modal';

export const HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL =
  '[Home] Create Protocol From Previous Protocol';
export const HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR =
  '[Home] Create Protocol From Previous Protocol Error';
export const HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_SUCCESS =
  '[Home] Create Protocol From Previous Protocol Success';

export const HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL =
  '[Home] Create Protocol with REM Reference from another Protocol';
export const HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_SUCCESS =
  '[Home] Create Protocol with REM Reference from another Protocol Success';
export const HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR =
  '[Home] Create Protocol with REM Reference from another Protocol Error';
