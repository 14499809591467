import produce from 'immer';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { get } from '../services/api';
import { MBusMessagesFields } from './mBusMessageFields';
import {
  COMMON_GET_TENANCY_AGREEMENT,
  COMMON_GET_TENANCY_AGREEMENT_ERROR,
  COMMON_GET_TENANCY_AGREEMENT_SUCCESS,
  COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR,
} from './constants';
import { selectIsTenantsErased } from '../../acceptance-protocol/redux/selectors';
import { selectTenancyAgreementEligibility, selectIsTokenExpired } from './selectors';
import { i18n } from '../../../common/i18n-loader';

const rentalPersons = {
  OLD_RENTAL_PERSON: 'oldRentalPerson',
  NEW_RENTAL_PERSON: 'newRentalPerson',
};

export function commonGetTenancyAgreement(protocolId) {
  return {
    type: COMMON_GET_TENANCY_AGREEMENT,
    payload: protocolId,
  };
}

export function* doGetTenancyAgreement({ payload }) {
  try {
    const isTenantsErased = yield select(selectIsTenantsErased(payload));

    const isAllTenantsErased = Object.values(isTenantsErased).reduce(
      (result, currentValue) => result || !currentValue,
      false,
    );

    const isTenancyAgreementEligible = yield select(selectTenancyAgreementEligibility(payload));

    if (isAllTenantsErased && isTenancyAgreementEligible) {
      const tenancyAgreement = yield call(
        get,
        `letting/gettenancyagremeentsecuritydeposit/${payload}`,
      );

      yield put({
        type: COMMON_GET_TENANCY_AGREEMENT_SUCCESS,
        payload: { protocolId: payload, ...tenancyAgreement },
      });
    }
  } catch (error) {
    const isTokenExpired = yield select(selectIsTokenExpired);

    yield put({
      type: COMMON_GET_TENANCY_AGREEMENT_ERROR,
      payload: { protocolId: payload },
    });

    if (!isTokenExpired) {
      yield put({
        type: COMMON_NOTIFY_TENANCY_AGREEMENT_ERROR,
        payload: i18n._('MESSAGES.TENANCY-AGREEMENT-ERROR'),
      });
    }
  }
}

export function* switchGetTenancyAgreement() {
  yield takeLatest(COMMON_GET_TENANCY_AGREEMENT, doGetTenancyAgreement);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_GET_TENANCY_AGREEMENT:
        draft.ui.busy.isTenancyAgreementLoading = true;
        break;
      case COMMON_GET_TENANCY_AGREEMENT_SUCCESS: {
        const { newTenantTenancyAgreement, oldTenantTenancyAgreement } = action.payload;

        const selectedProtocol = draft.protocols.find(
          (protocol) => protocol.id === action.payload.protocolId,
        );
        selectedProtocol.isTenancyAgreementSuccess = true;

        selectedProtocol.input.basedataInputs.forEach((basedataInput) => {
          if (
            basedataInput.typeId === rentalPersons.OLD_RENTAL_PERSON &&
            oldTenantTenancyAgreement
          ) {
            basedataInput.fields.forEach((field) => {
              if (field.fieldname === MBusMessagesFields.TENANT_SECURITY_DEPOT_TYPE_OLD_TENANT) {
                field.value = oldTenantTenancyAgreement.securityDeposit.typeCode;
              } else if (
                field.fieldname === MBusMessagesFields.TENANT_SECURITY_DEPOSIT_AMOUNT_OLD_TENANT
              ) {
                field.value = oldTenantTenancyAgreement.securityDeposit.depositAmount;
              } else if (
                field.fieldname === MBusMessagesFields.TENANT_SECURITY_PAID_AMOUNT_OLD_TENANT
              ) {
                field.value = oldTenantTenancyAgreement.securityDeposit.paidAmount;
              } else if (
                field.fieldname === MBusMessagesFields.LIABILITY_INSURANCE_CHECK_OLD_TENANT
              ) {
                field.value = oldTenantTenancyAgreement.liabilityInsuranceChecked;
              }
            });
          } else if (
            basedataInput.typeId === rentalPersons.NEW_RENTAL_PERSON &&
            newTenantTenancyAgreement
          ) {
            basedataInput.fields.forEach((field) => {
              if (field.fieldname === MBusMessagesFields.TENANT_SECURITY_DEPOT_TYPE) {
                field.value = newTenantTenancyAgreement.securityDeposit.typeCode;
              } else if (field.fieldname === MBusMessagesFields.TENANT_SECURITY_DEPOSIT_AMOUNT) {
                field.value = newTenantTenancyAgreement.securityDeposit.depositAmount;
              } else if (field.fieldname === MBusMessagesFields.TENANT_SECURITY_PAID_AMOUNT) {
                field.value = newTenantTenancyAgreement.securityDeposit.paidAmount;
              } else if (field.fieldname === MBusMessagesFields.LIABILITY_INSURANCE_CHECK) {
                field.value = newTenantTenancyAgreement.liabilityInsuranceChecked;
              }
            });
          }
        });

        draft.ui.busy.isTenancyAgreementLoading = false;
        break;
      }
      case COMMON_GET_TENANCY_AGREEMENT_ERROR: {
        draft.ui.busy.isTenancyAgreementLoading = false;
        const selectedProtocol = draft.protocols.find(
          (protocol) => protocol.id === action.payload.protocolId,
        );
        selectedProtocol.isTenancyAgreementSuccess = false;
        break;
      }
      default:
        return state;
    }
  });
