import { call, put, takeLatest } from 'redux-saga/effects';
import { deleteByIdAnonymous } from "../services/api";
import {
  COMMON_REMOVE_FROM_DOWNLOAD,
  COMMON_REMOVE_FROM_DOWNLOAD_SUCCESS,
  COMMON_REMOVE_FROM_DOWNLOAD_ERROR,
} from './constants';

export function commonRemoveFromDownload(payload) {
  return { type: COMMON_REMOVE_FROM_DOWNLOAD, payload };
}

function* doRemoveFromDownload({ payload }) {
  try {
    const { sessionId, protocolId } = payload;

    yield call(deleteByIdAnonymous, 'download', `${sessionId}/${!!protocolId ? protocolId : ''}`);

    yield put({
      type: COMMON_REMOVE_FROM_DOWNLOAD_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: COMMON_REMOVE_FROM_DOWNLOAD_ERROR,
      payload: { error: error.message, id: payload },
    });
  }
}

export function* switchRemoveFromDownload() {
  yield takeLatest(COMMON_REMOVE_FROM_DOWNLOAD, doRemoveFromDownload);
}
