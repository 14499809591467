/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { useCallback } from 'react';

import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';

import Button from '../common/Button';
import { StatusIcon } from './StatusIcon';
import { Icon } from '../common/Icon';
import { MigrationResults } from './MigrationResults';
import ProgressMigration from './ProgressMigration';

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: 'min-content max-content',
    gridAutoFlow: 'row',
  },

  buttonsContainer: {
    display: 'grid',
    gridTemplateColumns: '5fr 5fr',
    gridGap: '1em',
    alignItems: 'center',
  },

  buttons: {
    display: 'grid',
    gridGap: '1em',

    gridAutoFlow: 'row',

    [variables.breakpoints.md]: {
      gridAutoFlow: 'column',
    },
  },

  iconContainer: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '3fr 3fr',
    alignItems: 'center',
  },

  filtersContainer: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateColumns: '1fr 4fr 1fr',
    gridAutoFlow: 'row',
    alignItems: 'rigth',
  },

  filtersSwitch: {
    borderRadius: '34px',
  },

  filtersLabel: {
    display: 'grid',
    gridGap: '0em',
    gridTemplateColumns: '4fr 1fr',
    gridAutoFlow: 'row',
    alignItems: 'center',
    color: 'white',
    position: 'relative',
    top: '0.6em',
    left: '0.5em',
    fontSize: '14px',
    cursor: 'pointer',
  },

  ShowOnlyErrorsLog: {
    backgroundColor: colors.red,
  },
  ShowAllErrorsLog: {
    backgroundColor: colors.green,
  },

  iconStatus: {
    textAlign: 'end',
  },
};

export const LogViewer = (props) => {
  const {
    importMigration,
    migrationId,
    migrationStartMigration,
    migrationStopMigration,
    migrationRetainMigration,
    migrationDeleteMigration,
    migrationToogleShowSuccessLogs,
    migrationStatus,
    isMigrationRunning,
    isMigrationSuccess,
    toogleShowSuccessLogs,
    selectedTemplate,
    isLoading,
  } = props;

  const handleStartImport = useCallback(() => {
    migrationStartMigration(migrationId);
  }, [migrationId, migrationStartMigration]);

  const handleStopImport = useCallback(() => {
    migrationStopMigration(migrationId);
  }, [migrationId, migrationStopMigration]);

  const handleRetain = useCallback(() => {
    migrationRetainMigration(migrationId);
  }, [migrationId, migrationRetainMigration]);

  const handleDelete = useCallback(() => {
    migrationDeleteMigration(migrationId);
  }, [migrationId, migrationDeleteMigration]);

  const handleLogs = useCallback(() => {
    migrationToogleShowSuccessLogs();
  }, [migrationToogleShowSuccessLogs]);

  function StatusMigration() {
    if (!isMigrationRunning) {
      return (
        <div css={styles.iconStatus}>
          <span>
            <Trans>LABELS.MIGRATIONS.STATUS</Trans>:
          </span>
          <span>
            <StatusIcon success={isMigrationSuccess} size={'2x'} />
          </span>
        </div>
      );
    }
    return null;
  }

  function FilterMigration() {
    if (!isMigrationRunning) {
      return (
        <div css={styles.filtersContainer}>
          <div>&nbsp;</div>
          <div
            css={{
              ...styles.filtersSwitch,
              ...(toogleShowSuccessLogs ? styles.ShowAllErrorsLog : styles.ShowOnlyErrorsLog),
            }}
          >
            <div css={styles.filtersLabel} onClick={handleLogs}>
              <div>
                <Trans>LABELS.MIGRATIONS.SHOWERRORLOGS</Trans>:
              </div>
              <div>
                {toogleShowSuccessLogs ? (
                  <Icon icon="square" size="lg" />
                ) : (
                  <Icon icon="check-square" size="lg" />
                )}
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div css={styles.container}>
      <div css={styles.buttonsContainer}>
        <div css={styles.buttons}>
          {isMigrationRunning ? (
            <Button default onClick={handleStopImport}>
              <Trans>BUTTONS.MIGRATION-STOP</Trans>
            </Button>
          ) : (
            <Button
              default
              onClick={handleStartImport}
              disabled={!importMigration || migrationStatus >= 2}
            >
              <Trans>BUTTONS.MIGRATION-START</Trans>
            </Button>
          )}

          <Button secondary onClick={handleRetain} disabled={migrationStatus !== 2}>
            <Trans>BUTTONS.MIGRATION-RETAIN</Trans>
          </Button>
          <Button secondary onClick={handleDelete} disabled={migrationStatus !== 2}>
            <Trans>BUTTONS.MIGRATION-DELETE</Trans>
          </Button>
        </div>

        <div css={styles.iconContainer}>
          <FilterMigration />
          <StatusMigration />
        </div>
      </div>
      <ProgressMigration />

      {!isLoading && !isMigrationRunning && (
        <MigrationResults results={importMigration} selectedTemplate={selectedTemplate} />
      )}
    </div>
  );
};
