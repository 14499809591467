/** @jsxImportSource @emotion/react */
import classNames from 'classnames';
import { map, range } from 'ramda';
import { memo } from 'react';
import { colors } from '../../../common/theme/colors';
import { getById } from '../../common/Functions';
import { getLocalizedCostName, getLocalizedName } from './Functions';
import InputAttribute from './InputAttribute';

const styles = {
  tiles: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridAutoFlow: 'row',
    borderBottom: `solid 1px ${colors.lighterGrey}`,
  },
  tile: (bgColor) => ({
    borderTop: `solid 1px ${colors.lighterGrey}`,
    borderRight: `solid 1px ${colors.lighterGrey}`,
    backgroundColor: bgColor,
    transition: '0.5s background-color',

    ':nth-of-type(4n + 1)': {
      borderLeft: `solid 1px ${colors.lighterGrey}`,
    },
    '&.is-selected': {
      backgroundColor: colors.reportBlue,
    },

    '& > button': {
      color: colors.black,
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
    },
  }),
};

const InputAttributes = (props) => {
  const {
    attributes,
    attributesSelected,
    indexedAttributesTemplate,
    language,
    onSelectAttribute,
    getAttributeBgColor,
    templateCosts,
  } = props;

  return (
    <div css={styles.tiles}>
      {map((index) => {
        const attribute = index < attributes.length ? attributes[index] : null;
        const attributeTemplate = !!attribute && indexedAttributesTemplate[attribute];
        const bgColor = attributeTemplate && getAttributeBgColor(attributeTemplate.id);
        const attributeInput =
          !!attribute && !!attributesSelected && getById(attributeTemplate.id)(attributesSelected);
        const attributeInputCost = Boolean(attributeInput) && attributeInput.cost;

        return (
          <div
            data-testid={`tile-${index}`}
            key={`tile-${index}`}
            css={styles.tile(bgColor)}
            className={classNames({
              'is-selected': !!attributeInput,
            })}
          >
            {!!attributeTemplate && (
              <InputAttribute
                id={attributeTemplate.id}
                label={getLocalizedName(attributeTemplate, language)}
                cost={attributeInputCost}
                text={getLocalizedCostName(attributeInputCost, templateCosts, language)}
                onSelectAttribute={onSelectAttribute}
              />
            )}
          </div>
        );
      }, range(0, 16))}
    </div>
  );
};

export default memo(InputAttributes);
