import { routerMiddleware } from 'connected-react-router';
import { setAutoFreeze } from 'immer';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import history from './history';
import buildRootReducer from './rootReducer';
import rootSaga from './rootSaga';

import { createSentryMiddleware, Sentry } from './sentry-loader';

setAutoFreeze(false);

const rootReducer = buildRootReducer(history);

const saga = createSagaMiddleware({
  onError: (error) => Sentry.captureException(error),
});

const router = routerMiddleware(history);

const sentry = createSentryMiddleware(Sentry, {});

// NOTE: Do not change middleares delaration pattern since rekit plugins may register middlewares to it.
const middlewares = [thunk, saga, router, sentry];

/* istanbul ignore if  */
if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger');

  const logger = createLogger({ collapsed: true });
  middlewares.push(logger);
}

let composeEnhancers;
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 });
} else {
  composeEnhancers = compose;
}

export function configureStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  /* istanbul ignore if  */
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default; // eslint-disable-line
      store.replaceReducer(nextRootReducer);
    });
  }

  saga.run(rootSaga);

  return store;
}

export const store = configureStore();

export const persistor = persistStore(store);
