import { DateTime } from 'luxon';
import { put, select, takeLatest } from 'redux-saga/effects';

import { commonDeleteOperation, commonPatchOperation } from '../../../common/redux/actions';
import {
  selectCurrentId,
  selectHasSignedManager,
  selectHasSignedNewTenant,
  selectHasSignedOldTenant,
  selectProtocolSignedAt,
  selectSignStep,
} from '../../redux/selectors';
import {
  COMMON_ABORT_AND_CLOSE_SIGN_PAGE,
  COMMON_CONFIRMED_SIGN,
  COMMON_REJECTED_SIGN,
  COMMON_DELETED_SIGN,
} from '../../../common/redux/constants';

function* doUpdateSignStep() {
  const protocolId = yield select(selectCurrentId);
  const signedAt = yield select(selectProtocolSignedAt);

  const hasSignedOldTenant = yield select(selectHasSignedOldTenant);
  const hasSignedNewTenant = yield select(selectHasSignedNewTenant);
  const hasSignedManager = yield select(selectHasSignedManager);
  const signStep = yield select(selectSignStep);

  if (!signedAt && hasSignedOldTenant | hasSignedNewTenant | hasSignedManager) {
    yield put(commonPatchOperation(protocolId, 'signatures/signedAt', DateTime.local().toISO()));
  }
  if (!!signedAt && !hasSignedOldTenant && !hasSignedNewTenant && !hasSignedManager) {
    yield put(commonDeleteOperation(protocolId, 'signatures/signedAt'));
  }

  if (!signStep && (hasSignedOldTenant || hasSignedNewTenant || hasSignedManager)) {
    yield put(
      commonPatchOperation(protocolId, 'progressDetails', {
        signStep: true,
      }),
    );
  }
  if (signStep && !hasSignedOldTenant && !hasSignedNewTenant && !hasSignedManager) {
    yield put(
      commonPatchOperation(protocolId, 'progressDetails', {
        signStep: false,
      }),
    );
  }
}

export function* switchUpdateSignStep() {
  yield takeLatest(
    [
      COMMON_REJECTED_SIGN,
      COMMON_CONFIRMED_SIGN,
      COMMON_ABORT_AND_CLOSE_SIGN_PAGE,
      COMMON_DELETED_SIGN,
    ],
    doUpdateSignStep,
  );
}
