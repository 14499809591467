export const variables = {
  breakpoints: {
    md: '@media (min-width: 640px)',
    lg: '@media (min-width: 768px)',
    xl: '@media (min-width: 1281px)',
  },
  gutter: {
    sm: '8px',
    md: '16px',
    lg: '24px',
  },
  topbar: '80px',
  content: '1fr',
  footer: '50px',
};
