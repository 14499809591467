import React from 'react';

export const ReorderIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <polygon points="21 26 0 26 0 21 1 21 1 25 20 25 20 3 1 3 1 7 0 7 0 2 21 2 21 26" />
    <rect x="12.5" y="6" width="5" height="1" />
    <rect x="12.5" y="9" width="5" height="1" />
    <rect x="12.5" y="12" width="5" height="1" />
    <rect x="12.5" y="15" width="5" height="1" />
    <rect x="12.5" y="18" width="5" height="1" />
    <rect x="12.5" y="21" width="5" height="1" />
    <path d="M6.31,14a.43.43,0,0,0,.19,0,.47.47,0,0,0,.35-.15l4-4a.48.48,0,0,0,0-.7l-4-4A.47.47,0,0,0,6.31,5,.5.5,0,0,0,6,5.5V9H0V19H10.5V18H1V10H6v3.5A.5.5,0,0,0,6.31,14ZM7,6.71,9.79,9.5,7,12.29Z" />
  </svg>
);
