export const MIGRATION_GET_MIGRATION = '[Migration] Get Migration';
export const MIGRATION_GET_MIGRATION_SUCCESS = '[Migration] Get Migration Success';
export const MIGRATION_GET_MIGRATION_ERROR = '[Migration] Get Migration Error';

export const MIGRATION_START_MIGRATION = '[Migration] Start Migration';
export const MIGRATION_START_MIGRATION_SUCCESS = '[Migration] Start Migration Success';
export const MIGRATION_START_MIGRATION_ERROR = '[Migration] Start Migration Error';

export const MIGRATION_STOP_MIGRATION = '[Migration] Stop Migration';
export const MIGRATION_STOP_MIGRATION_SUCCESS = '[Migration] Stop Migration Success';
export const MIGRATION_STOP_MIGRATION_ERROR = '[Migration] Stop Migration Error';

export const MIGRATION_RETAIN_MIGRATION = '[Migration] Retain Migration';
export const MIGRATION_RETAIN_MIGRATION_ERROR = '[Migration] Retain Migration Error';

export const MIGRATION_DELETE_MIGRATION = '[Migration] Delete Migration';
export const MIGRATION_DELETE_MIGRATION_ERROR = '[Migration] Delete Migration Error';

export const MIGRATION_SET_TEMPLATE_MIGRATION = '[Migration] Set Template Migration';
export const MIGRATION_TOOGLE_SHOW_SUCCESS_LOGS = '[Migration] Toogle Show Success Logs';

export const MIGRATION_CLOSE_RETAIN_MODAL = '[Migration] Close Retain Modal';
export const MIGRATION_CLOSE_DELETE_MODAL = '[Migration] Close Delete Modal';

export const MIGRATION_HUB_CONNECT = '[Migration] Hub Connect';
export const MIGRATION_HUB_CONNECT_SUCCESS = '[Migration] Hub Connect Success';
export const MIGRATION_HUB_CONNECT_ERROR = '[Migration] Hub Connect Error';

export const MIGRATION_HUB_DISCONNECT = '[Migration] Hub Disconnect';
export const MIGRATION_HUB_DISCONNECT_SUCCESS = '[Migration] Hub Disconnect Success';
export const MIGRATION_HUB_DISCONNECT_ERROR = '[Migration] Hub Disconnect Error';

export const MIGRATION_HUB_LISTENER = '[Migration] Hub Messages Listener';
export const MIGRATION_HUB_LISTENER_ERROR = '[Migration] Hub Messages Listener Error';

export const MIGRATION_HUB_MESSAGE_PROGRESS_CHANGED = '[Migration] Hub Message Progress Changed';
export const MIGRATION_HUB_MESSAGE_IS_COMPLETED = '[Migration] Hub Message Is Completed';
export const MIGRATION_HUB_MESSAGE_IS_FAULTED = '[Migration] Hub Message Is Faulted';
export const MIGRATION_HUB_MESSAGE_IS_SUSPENDED = '[Migration] Hub Message Is Suspended';
export const MIGRATION_HUB_MESSAGE_IS_RUNNING = '[Migration] Hub Message Is Running';
