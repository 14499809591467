/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import classNames from 'classnames';

import { colors } from '../../common/theme/colors';

const baseContent = {
  display: 'grid',
  gridTemplateColumns: 'auto',
  alignItems: 'center',
  backgroundColor: colors.brand,
  overflow: 'hidden',
  '&.has-pointer': {
    cursor: 'pointer',
  },
};

const baseBefore = {
  width: 0,
  height: 0,
  borderTopWidth: '1.2em',
  borderTopStyle: 'solid',
  borderLeftWidth: '0.6em',
  borderLeftStyle: 'solid',
  borderLeftColor: 'transparent',
  borderBottomWidth: '1.2em',
  borderBottomStyle: 'solid',
};

const baseAfter = {
  width: 0,
  height: 0,
  borderTopWidth: '1.2em',
  borderTopStyle: 'solid',
  borderTopColor: 'transparent',
  borderLeftWidth: '0.6em',
  borderLeftStyle: 'solid',
  borderBottomWidth: '1.2em',
  borderBottomStyle: 'solid',
  borderBottomColor: 'transparent',
};

const styles = {
  item: {
    listStyleType: 'none',
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'min-content auto min-content',
    border: 'none',
    color: 'white',
    backgroundColor: colors.dark,
    textAlign: 'center',
    height: '2.4em',
  },
  activeContent: {
    ...baseContent,
    backgroundColor: colors.brand,
  },
  activeBefore: {
    ...baseBefore,
    borderTopColor: colors.brand,
    borderBottomColor: colors.brand,
  },
  activeAfter: {
    ...baseAfter,
    borderLeftColor: colors.brand,
  },
  inactiveContent: {
    ...baseContent,
    backgroundColor: colors.darker,
  },
  inactiveBefore: {
    ...baseBefore,
    borderTopColor: colors.darker,
    borderBottomColor: colors.darker,
  },
  inactiveAfter: {
    ...baseAfter,
    borderLeftColor: colors.darker,
  },
  unselectedContent: {
    ...baseContent,
    backgroundColor: colors.topbarUnselected,
  },
  unselectedBefore: {
    ...baseBefore,
    borderTopColor: colors.topbarUnselected,
    borderBottomColor: colors.topbarUnselected,
  },
  unselectedAfter: {
    ...baseAfter,
    borderLeftColor: colors.topbarUnselected,
  },
};

export const TopBarBaseStep = (props) => {
  const { step, isActive, hasManagerSigned, onSelectStep, isLast, children } = props;

  const handleClick = useCallback(() => {
    if (!!onSelectStep) {
      onSelectStep();
    }
  }, [onSelectStep]);

  const currentStyles = useMemo(
    () =>
      !!isActive
        ? {
            content: styles.activeContent,
            before: styles.activeBefore,
            after: styles.activeAfter,
          }
        : hasManagerSigned === false
        ? {
            content: styles.unselectedContent,
            before: styles.unselectedBefore,
            after: styles.unselectedAfter,
          }
        : {
            content: styles.inactiveContent,
            before: styles.inactiveBefore,
            after: styles.inactiveAfter,
          },
    [isActive, hasManagerSigned],
  );

  return (
    <li key={step} css={styles.item} onClick={handleClick}>
      <span css={currentStyles.before} />
      <span css={currentStyles.content} className={classNames({ 'has-pointer': !!onSelectStep })}>
        {children}
      </span>
      {!isLast && <span css={currentStyles.after} />}
    </li>
  );
};
