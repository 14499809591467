import { put, select, delay, takeLatest } from "redux-saga/effects";
import { selectExpiresAt, selectIsLoggedIn } from "./selectors";
import { commonDapLoginTokenExpired } from "./login";
import {
  COMMON_DAP_LOGIN_TOKEN_CHECKER_START,
  COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS,
  COMMON_LOGIN_ERROR,
  COMMON_LOGIN_SUCCESS,
  COMMON_TOKENS_REFRESH_ERROR,
  COMMON_TOKENS_REFRESH_SUCCESS
} from "./constants";

const CHECK_AUTH_TOKEN_INTERVAL = 1000;
const AUTH_TOKEN_EXPIRATION_GRACE_PERIOD = 60 * 1000;

function* checkAuthTokenPeriodicallySagas() {
  while (true) {
    yield delay(CHECK_AUTH_TOKEN_INTERVAL);

    const isLoggedIn = yield select(selectIsLoggedIn);
    if (!isLoggedIn) return;

    const tokenExpiresAt = yield select(selectExpiresAt);

    const dateNow = Date.now();
    const expiresAtTimestamp = new Date(tokenExpiresAt).getTime();

    if ((dateNow + AUTH_TOKEN_EXPIRATION_GRACE_PERIOD) > expiresAtTimestamp) {
      yield put(commonDapLoginTokenExpired());
    }
  }
}

export function* switchTokenChecker() {
  yield takeLatest([
    COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS, // We might be able to remove it later, TODO: Check and if not needed remove it
    COMMON_TOKENS_REFRESH_SUCCESS,
    COMMON_TOKENS_REFRESH_ERROR,
    COMMON_LOGIN_SUCCESS,
    COMMON_LOGIN_ERROR,
    COMMON_DAP_LOGIN_TOKEN_CHECKER_START
  ], checkAuthTokenPeriodicallySagas);
  yield put({ type: COMMON_DAP_LOGIN_TOKEN_CHECKER_START });
}
