import { LOCATION_CHANGE, push } from 'connected-react-router';
import { find, contains } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import { commonPatchOperation, commonGetLeavingAgreement, commonGetTenancyAgreement } from '../../common/redux/actions';
import {
  selectProtocols,
  selectIsOnline,
  selectOfflineProtocols,
  selectHasLeavingAgreement, selectIsLoggedIn, selectIsTokenExpired
} from "../../common/redux/selectors";
import { acceptanceProtocolRoutes } from '../routes';
import {
  ACCEPTANCE_PROTOCOL_SELECT_PROTOCOL,
  ACCEPTANCE_PROTOCOL_SELECTED_PROTOCOL,
} from './constants';
import { selectProtocolIdRoute } from './selectors';
import { homeRoutes } from '../../home/routes';
import { notifyError } from '../../common/redux/notifyError';
import { i18n } from '../../../common/i18n-loader';

export function acceptanceProtocolSelectProtocol(id) {
  return {
    type: ACCEPTANCE_PROTOCOL_SELECT_PROTOCOL,
    payload: id,
  };
}

export function acceptanceProtocolSelectedProtocol(id) {
  return {
    type: ACCEPTANCE_PROTOCOL_SELECTED_PROTOCOL,
    payload: id,
  };
}

function* doSelectProtocol({ payload }) {
  const offlineProtocols = yield select(selectOfflineProtocols);
  const hasLeavingAgreement = yield select(selectHasLeavingAgreement);
  const isOnline = yield select(selectIsOnline);
  const isLoggedIn = yield select(selectIsLoggedIn);
  const isTokenExpired = yield select(selectIsTokenExpired);

  if (!contains(payload, offlineProtocols)) {
    yield put(commonGetTenancyAgreement(payload));
  }

  if (hasLeavingAgreement && !contains(payload, offlineProtocols) && isOnline && isLoggedIn && !isTokenExpired) {
    yield put(commonGetLeavingAgreement(payload));
  }

  yield put(push(acceptanceProtocolRoutes.mainStep(payload)));
}

function* doSelectedProtocol() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route) {
    if (!!route.params.id) {
      const protocols = yield select(selectProtocols);
      const protocol = find((protocol) => protocol.id === route.params.id, protocols);
      if (!!protocol) {
        const isOnline = yield select(selectIsOnline);
        const offlineProtocols = yield select(selectOfflineProtocols);
        const isOfflineProtocol = offlineProtocols.indexOf(protocol.id) !== -1;

        if (isOnline || isOfflineProtocol) {
          yield put(acceptanceProtocolSelectedProtocol(protocol.id));
          if (!protocol.input.progressDetails.mainStep) {
            yield put(commonPatchOperation(protocol.id, 'progressDetails', { mainStep: true }));
          }
        } else {
          yield put(push('/not-found'));
        }
      } else {
        yield put(notifyError(i18n._('ERRORS.PROTOCOL-NOT-FOUND')));
        yield put(push(homeRoutes.home));
      }
    } else {
      yield put(push('/not-found'));
    }
  }
}

export function* switchSelectProtocol() {
  yield takeLatest(ACCEPTANCE_PROTOCOL_SELECT_PROTOCOL, doSelectProtocol);
  yield takeLatest(LOCATION_CHANGE, doSelectedProtocol);
}
