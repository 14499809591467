import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { join } from 'ramda';
import { DateTime } from 'luxon';

const initImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onerror = reject;
    image.onload = () => resolve(image);
  });
};

const setBackground = async (underlyingCanvas, pictureBase64) => {
  const ctx = underlyingCanvas.getContext('2d');

  const image = await initImage(pictureBase64);
  ctx.drawImage(image, 0, 0);
};

export const generateImageId = (date) => `ID${date.toFormat('yMMddHHmmss')}`;

export class ImageSketcherCanvas extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    drawingData: PropTypes.array,
    drawingEnabled: PropTypes.bool,
    pictureBase64: PropTypes.string,
    imageName: PropTypes.string,
    language: PropTypes.string,
    onSave: PropTypes.func,
    onClear: PropTypes.func,
    width: PropTypes.number,
    height: PropTypes.number,
    // the color of the line
    lineColor: PropTypes.string,
    // The width of the line
    lineWidth: PropTypes.number,
  };

  static defaultProps = {
    drawingEnabled: true,
  };

  constructor() {
    super();

    this.canvas = React.createRef();
    this.font = '16px "Rubik"';
    this.handleClear = this.handleClear.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  async componentDidMount() {
    await setBackground(this.canvas.getCanvas(), this.props.pictureBase64);
    document.fonts.load(this.font);

    this.props.onSave(this.handleSave);
    this.props.onClear(this.handleClear);
  }

  async handleClear() {
    await setBackground(this.canvas.getCanvas(), this.props.pictureBase64);
  }

  handleSave = () => {
    const date = DateTime.local().setLocale(`${this.props.language.toLowerCase()}-ch`);
    const textInfo = this.props.imageName;
    const textDate = join(' - ', [date.toFormat('ccc. dd LLL. y'), date.toFormat('HH:mm:ss')]);
    const textID = generateImageId(date);

    this.addSignature(textInfo, textDate, textID, this.font);

    return { dataURL: this.canvas.toDataURL(), id: textID };
  };

  addSignature = (textInfo, textDate, textID, font) => {
    const canvas = this.canvas.getCanvas();
    const ctx = canvas.getContext('2d');

    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const isPortrait = canvasHeight > canvasWidth;

    ctx.font = font;
    const textInfoMeasures = ctx.measureText('ABC');
    const padding = 8;
    const lineHeight =
      16 + textInfoMeasures.actualBoundingBoxAscent - textInfoMeasures.actualBoundingBoxDescent;

    ctx.fillStyle = 'rgba(0,0,0,0.6)';

    if (isPortrait) {
      ctx.fillRect(0, canvas.height - 3 * lineHeight, canvas.width, 3 * lineHeight);

      ctx.fillStyle = 'white';
      ctx.fillText(textInfo, padding, canvas.height - padding - 2 * lineHeight);

      ctx.fillText(textDate, padding, canvas.height - padding - lineHeight);
    } else {
      ctx.fillRect(0, canvas.height - lineHeight, canvas.width, lineHeight);

      ctx.fillStyle = 'white';
      ctx.fillText(join(' - ', [textInfo, textDate]), padding, canvas.height - padding);
    }

    ctx.textAlign = 'end';
    ctx.fillText(textID, canvas.width - padding, canvas.height - padding);
  };

  render() {
    const { className, onClick, lineColor, lineWidth } = this.props;

    return (
      <div
        className={`picture-canvas ${className}`}
        onClick={onClick}
        data-testid={'picture-canvas'}
      >
        <SignatureCanvas
          ref={(ref) => {
            !!ref && (this.canvas = ref);
          }}
          penColor={lineColor}
          minWidth={lineWidth}
          maxWidth={lineWidth}
          canvasProps={{ width: this.props.width, height: this.props.height, className: 'drawing' }}
        />
        {this.props.children}
      </div>
    );
  }
}

export default ImageSketcherCanvas;
