export const sortOptsByLanguage = (options, language) => {
  return options?.sort((a, b) => {
    const labelA = a.labels[language] || a.label || a.labels['default'];
    const labelB = b.labels[language] || b.label || b.labels['default'];

    // Use localeCompare() to compare the labels
    let result = 0;
    if (labelA && labelB) {
      result = labelA.localeCompare(labelB, language);
    } else if (!labelA && !labelB) {
      // If both labels are empty, consider them equal
      result = 0;
    } else if (!labelA) {
      // If only one label is empty, put the other first
      result = -1;
    } else {
      // If only one label has a translation, put the other first
      result = 1;
    }

    return result;
  });
};
