import { put, select, takeLatest } from 'redux-saga/effects';

import { commonPatchOperation } from '../../../common/redux/actions';
import { selectCurrentId, selectProgressDetails } from '../../redux/selectors';
import { COMMON_PATCH_OPERATION, COMMON_DELETE_OPERATION } from '../../../common/redux/constants';

function* doUpdateFillStep({ payload: { expression } }) {
  if (expression.startsWith('basedataInputs') || expression.startsWith('componentsInput')) {
    const progressDetails = yield select(selectProgressDetails);
    if (!!progressDetails && !progressDetails.fillStep) {
      const protocolId = yield select(selectCurrentId);

      yield put(
        commonPatchOperation(protocolId, 'progressDetails', {
          fillStep: true,
        }),
      );
    }
  }
}

export function* switchUpdateFillStep() {
  yield takeLatest([COMMON_PATCH_OPERATION, COMMON_DELETE_OPERATION], doUpdateFillStep);
}
