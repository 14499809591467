/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Component } from 'react';

import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';
import { Icon } from './Icon';

const styles = {
  select: {
    backgroundColor: colors.darker,
    color: colors.white,
    textTransform: 'uppercase',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    cursor: 'pointer',

    padding: `0.6em 0.6em`,
    [variables.breakpoints.md]: {
      padding: `0.6em 1.8em`,
    },
  },
  locked: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    gridGap: '.5em',
    color: colors.white,
    svg: {
      color: colors.white,
      gridColumn: '2/3',
      float: 'right',
    },
    backgroundColor: colors.darker,
    textTransform: 'uppercase',
    cursor: 'not-allowed',

    padding: `0.6em 0.6em`,
    [variables.breakpoints.md]: {
      padding: `0.6em 1.3em`,
    },
  },
};

export class TopBarLanguage extends Component {
  static propTypes = {
    language: PropTypes.string.isRequired,
    languages: PropTypes.array.isRequired,
    isLanguageLocked: PropTypes.bool,
    onSetLanguage: PropTypes.func.isRequired,
  };

  render() {
    const { isLanguageLocked } = this.props;

    return !!isLanguageLocked ? this.lockedLanguage(this.props) : this.unlockedLanguage(this.props);
  }

  lockedLanguage = ({ language }) => {
    return (
      <div css={styles.locked}>
        <span>{language}</span>
        <span>
          <Icon icon="lock" size="sm" title="toggle" />
        </span>
      </div>
    );
  };

  unlockedLanguage = ({ language, languages }) => {
    return (
      <select css={styles.select} value={language} onChange={this.onLanguageChange}>
        {languages?.map((l) => (
          <option value={l} key={l}>
            {l}
          </option>
        ))}
      </select>
    );
  };

  onLanguageChange = (e, v) => {
    const { onSetLanguage } = this.props;
    onSetLanguage(e.currentTarget.value);
  };
}
