import produce from 'immer';
import { MIGRATION_TOOGLE_SHOW_SUCCESS_LOGS } from './constants';

export function migrationToogleShowSuccessLogs() {
  return {
    type: MIGRATION_TOOGLE_SHOW_SUCCESS_LOGS,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_TOOGLE_SHOW_SUCCESS_LOGS:
        draft.toogleShowSuccessLogs = !draft.toogleShowSuccessLogs;
        break;
      default:
        return state;
    }
  });
