/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Component } from 'react';
import Spinner from '../common/Spinner';
import { SelectedProtocol } from './SelectedProtocol.js';

const styles = {
  container: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'min-content min-content',
    paddingBottom: '1.5em',
    results: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridTemplateRows: 'auto',
      result: {
        alignItems: 'center',
        display: 'grid',
        gridAutoFlow: 'column',
        gridGap: '0.5em',
        gridTemplateColumns: 'min-content auto min-content',
      },
    },
  },
};

export class SelectedProtocols extends Component {
  static propTypes = {
    isBusy: PropTypes.bool.isRequired,
    selectedProtocols: PropTypes.array,
    queues: PropTypes.object,
    offlineProtocols: PropTypes.array,
    errorSynchronizationProtocols: PropTypes.array,
    isOnline: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    onSelectProtocol: PropTypes.func,
    onMarkAsOffline: PropTypes.func,
    onSyncOffline: PropTypes.func,
    onSoftPush: PropTypes.func,
  };

  render() {
    const {
      isBusy,
      selectedProtocols,
      offlineProtocols,
      errorSynchronizationProtocols,
      queues,
      isOnline,
      isMarkAsOfflineBusy,
      isSyncOfflineBusy,
      isAnyMarkAsOfflineBusy,
      isAnySyncOfflineBusy,
      hasAnyQueue,
      onSelectProtocol,
      onMarkAsOffline,
      onSyncOffline,
      onSoftPush,
      isLoggedIn,
    } = this.props;
    return (
      <div css={styles.container.results}>
        <Spinner show={isBusy}>
          {!!selectedProtocols &&
            !!selectedProtocols.length &&
            selectedProtocols.map((item, itemIndex) => (
              <SelectedProtocol
                key={item.id}
                protocol={item}
                queueCount={!!queues[item.id] ? queues[item.id].operations.length : 0}
                isOnline={isOnline}
                isAvailableOffline={offlineProtocols.indexOf(item.id) !== -1}
                isErrorSynchronization={errorSynchronizationProtocols.indexOf(item.id) !== -1}
                otherOfflineProtocolsCount={offlineProtocols.filter((id) => id !== item.id).length}
                index={itemIndex}
                isMarkAsOfflineBusy={isMarkAsOfflineBusy}
                isSyncOfflineBusy={isSyncOfflineBusy}
                isAnyMarkAsOfflineBusy={isAnyMarkAsOfflineBusy}
                isAnySyncOfflineBusy={isAnySyncOfflineBusy}
                hasAnyQueue={hasAnyQueue}
                onSelectProtocol={onSelectProtocol}
                onMarkAsOffline={onMarkAsOffline}
                onSyncOffline={onSyncOffline}
                onSoftPush={onSoftPush}
                isLoggedIn={isLoggedIn}
              />
            ))}
        </Spinner>
      </div>
    );
  }
}
