import { createMatchSelector } from 'connected-react-router';
import { createSelector } from 'reselect';
import { map, filter, flatten, sortWith, ascend, prop } from 'ramda';

const selectMigration = (state) => state.migration;

export const selectCurrentMigration = createSelector(selectMigration, (state) => state.migration);

export const selectMigrationRoute = createSelector(
  (state) => state,
  createMatchSelector({ path: '/migration' }),
);

export const selectRetainMigration = createSelector(
  selectMigration,
  (state) => state.retainMigration,
);

export const selectDeleteMigration = createSelector(
  selectMigration,
  (state) => state.deleteMigration,
);

export const selectIsLoading = createSelector(selectMigration, (state) => state.ui.busy.loading);

export const selectMigrationIsRunning = createSelector(
  selectMigration,
  (state) => state.ui.busy.isRunning,
);

export const selectMigrationIsSuccess = createSelector(
  selectMigration,
  (state) => state.migration.success,
);

export const selectImportMigration = createSelector(selectMigration, (state) => {
  if (state.importMigration && state.importMigration.length) {
    const filterByTemplate = (x) => !x.templateName || x.templateName === state.selectedTemplate;
    const filterByStatus = (x) =>
      state.toogleShowSuccessLogs || x.success === state.toogleShowSuccessLogs;

    const filterTemplateLevel = flatten(
      map(
        (m) => m.results,
        filter((f) => filterByTemplate(f) && filterByStatus(f), state.importMigration),
      ),
    );

    const filterProtocolLevel = filter(
      (x) => !!x.results && !!x.results.length,
      map(
        (m) => ({
          ...m,
          results: sortWith([ascend(prop('success')), ascend(prop('skipped'))])(
            filter(filterByStatus, m.results || []),
          ),
        }),
        filterTemplateLevel,
      ),
    );

    return sortWith([ascend(prop('success')), ascend(prop('skipped'))])(filterProtocolLevel);
  }
  return null;
});

export const selectSelectedTemplateName = createSelector(
  selectMigration,
  (state) => state.selectedTemplate,
);

export const selectToogleShowSuccessLogs = createSelector(
  selectMigration,
  (state) => state.toogleShowSuccessLogs,
);

export const selectMigrationStatus = createSelector(selectMigration, (state) => {
  return state.migration.status;
});

export const selectIsRetainModalOpen = createSelector(
  selectMigration,
  (state) => state.ui.modal.retain,
);

export const selectIsDeleteModalOpen = createSelector(
  selectMigration,
  (state) => state.ui.modal.delete,
);

export const selectProgressPercentage = createSelector(
  selectMigration,
  (state) => state.ui.busy.progressPercentage,
);

export const selectIsFault = createSelector(selectMigration, (state) => state.isFault);

export const selectFaultMessage = createSelector(selectMigration, (state) => state.faultMessage);

export const selectHubConnection = createSelector(selectMigration, (state) => state.hubConnection);
