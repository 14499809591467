import { select, call, put, takeLatest } from 'redux-saga/effects';
import { patch } from '../../../common/services/api';

import { MAIN_STEP_ASSIGN_USER_PROTOCOL, MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR } from './constants';
import { selectCurrentId } from '../../redux/selectors';
import { mainStepCloseAssignmentModal } from './mainStepCloseAssignmentModal';
import { homeRoutes } from '../../../home/routes';
import { push } from 'connected-react-router';
import { commonGetProtocols } from '../../../common/redux/actions';

export function assignUserProtocol(userId) {
  return {
    type: MAIN_STEP_ASSIGN_USER_PROTOCOL,
    payload: userId,
  };
}

function* doAssignUserProtocol({ payload }) {
  try {
    // const confirmed = yield call(askForConfirmation, {
    //   title: i18n._('FILL-STEP.DELETE-IMAGE.TITLE'),
    //   message: i18n._('FILL-STEP.DELETE-IMAGE.MESSAGE'),
    // });
    // if (!confirmed) {
    //   return;
    // }

    const currentProtocolId = yield select(selectCurrentId);

    yield call(patch, 'protocol/assignprotocol', {
      userId: payload,
      protocolId: currentProtocolId,
    });

    yield put(mainStepCloseAssignmentModal());
    yield put(push(homeRoutes.home));
    yield put(commonGetProtocols());
  } catch (error) {
    yield put({
      type: MAIN_STEP_ASSIGN_USER_PROTOCOL_ERROR,
      payload: error.message,
    });
  }
}

export function* switchAssignUserProtocol() {
  yield takeLatest(MAIN_STEP_ASSIGN_USER_PROTOCOL, doAssignUserProtocol);
}
