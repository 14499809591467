import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';

import { post } from '../../common/services/api';
import { MIGRATION_RETAIN_MIGRATION, MIGRATION_RETAIN_MIGRATION_ERROR } from './constants';

export function migrationRetainMigration(id) {
  return {
    type: MIGRATION_RETAIN_MIGRATION,
    payload: id,
  };
}

function* doRetainMigration({ payload }) {
  try {
    yield call(post, 'migration/retain', { migrationId: payload });
  } catch (error) {
    yield put({
      type: MIGRATION_RETAIN_MIGRATION_ERROR,
      payload: error.message,
    });
  }
}

export function* switchRetainMigration() {
  yield takeLatest(MIGRATION_RETAIN_MIGRATION, doRetainMigration);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_RETAIN_MIGRATION:
        draft.ui.busy.loading = true;
        break;
      case MIGRATION_RETAIN_MIGRATION_ERROR:
        draft.ui.busy.loading = false;
        break;
      default:
        return state;
    }
  });
