/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { colors } from '../../common/theme/colors';
import arrowInSmall from '../../images/arrow-in-small.svg';
import arrowOutSmall from '../../images/arrow-out-small.svg';
import commercial from '../../images/commercial.svg';
import apartment from '../../images/apartment.svg';

const styles = {
  bold: {
    fontWeight: 'bold',
    color: colors.darker,
  },
  container: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  result: {
    flex: 1,
    display: 'flex',
    gap: '20px',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '5px',
  },
  marginRight: {
    marginRight: '5px',
  },
  marginLeft: {
    marginLeft: '5px',
  },
  icons: {
    verticalAlign: 'text-top',
  },
};

export class SearchResultCard extends Component {
  isNotEmpty(value) {
    return Array.isArray(value) ? !!value && !!value.length : !!value;
  }

  getAddSeparator() {
    let count = -1;

    return (value) => {
      count++;
      return (
        <span type="separator" key={count + '-separator'}>
          {value}
        </span>
      );
    };
  }

  getTextBuilder() {
    return (...values) => {
      const result = values.reduce((accumulator, current, index) => {
        if (!!current && !!current.props && current.props.type === 'separator') {
          if (
            !!accumulator.length &&
            index < values.length - 1 &&
            this.isNotEmpty(values[index + 1])
          ) {
            accumulator.push(current);
          }
          return accumulator;
        } else {
          return this.isNotEmpty(current) ? accumulator.concat(current) : accumulator;
        }
      }, []);
      return this.isNotEmpty(result) ? result : null;
    };
  }

  static propTypes = {
    formerTenantReferenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tenantReferenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buildingStreet: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buildingZipcode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    buildingPlace: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    numberOfRoomsCode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    floorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tenantForname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tenantSurname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    previousProtocolId: PropTypes.string,
    previousProtocolTemplate: PropTypes.shape({}),
    previousProtocolCreatedAt: PropTypes.string,
    language: PropTypes.string,
    formerTenantForename: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    formerTenantSurname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    arrowIcon: PropTypes.node,
    addIcon: PropTypes.node,
  };

  render() {
    const {
      formerTenantReferenceNumber,
      tenantReferenceNumber,
      buildingStreet,
      buildingZipcode,
      buildingPlace,
      numberOfRoomsCode,
      floorCode,
      tenantForname,
      tenantSurname,
      previousProtocolId,
      previousProtocolTemplate,
      formerTenantForename,
      formerTenantSurname,
      arrowIcon,
      addIcon,
    } = this.props;

    const home = 'wohnung';
    const previousTemplateType =
      previousProtocolTemplate?.templateName &&
      (previousProtocolTemplate?.templateName?.includes(home) ? (
        <img src={apartment} alt="apartment" />
      ) : (
        <img src={commercial} alt="commercial" />
      ));

    const textBuilder = this.getTextBuilder();
    const addSeparator = this.getAddSeparator();

    return (
      <div css={styles.container}>
        <div css={styles.result}>
          <div>{arrowIcon}</div>
          <div style={{ flex: 1 }}>
            <div css={styles.topSection}>
              <div>
                <span css={styles.bold}>
                  {textBuilder(
                    formerTenantReferenceNumber,
                    addSeparator(' / '),
                    tenantReferenceNumber,
                  )}
                </span>
              </div>
              <div>
                <span>
                  <div css={styles.topSection}>
                    {previousProtocolId && (
                      <div css={styles.marginRight}>{previousTemplateType}</div>
                    )}
                    <div css={styles.marginLeft}>{addIcon}</div>
                  </div>
                </span>
              </div>
            </div>
            <div>
              <span css={styles.marginRight}>
                {buildingStreet}, {buildingZipcode} {buildingPlace};
              </span>

              <span style={styles.marginRight}>
                {numberOfRoomsCode && (
                  <>
                    <Trans>LABELS.ROOM</Trans>: {numberOfRoomsCode},{' '}
                  </>
                )}{' '}
                <Trans>LABELS.FLOOR</Trans>: {floorCode};
              </span>

              {(formerTenantForename || formerTenantSurname) && (
                <span css={[styles.marginRight, styles.marginLeft]}>
                  <img css={styles.icons} src={arrowOutSmall} alt="old-tenant" />{' '}
                  {formerTenantForename} {formerTenantSurname};
                </span>
              )}

              {(tenantForname || tenantSurname) && (
                <span css={styles.marginLeft}>
                  <img css={styles.icons} src={arrowInSmall} alt="new-tenant" /> {tenantForname}{' '}
                  {tenantSurname}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
