import produce from 'immer';
import { filter, map, reduce, contains, uniq, keys, without } from 'ramda';
import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { get } from '../services/api';
import { getImageReferences } from '../../../common/code/get-image-references';
import { purgeAllOtherImages } from '../../../common/code/image.repository';
import { getById } from '../Functions';
import {
  COMMON_FLUSHED_QUEUES,
  COMMON_GET_PROTOCOLS,
  COMMON_GET_PROTOCOLS_ERROR,
  COMMON_GET_PROTOCOLS_SUCCESS,
  COMMON_IS_LOGGED_IN,
  COMMON_LOGIN_SUCCESS,
} from './constants';
import {
  selectHasAnyQueue,
  selectOfflineProtocols,
  selectProtocols,
  selectQueues,
} from './selectors';

export function commonGetProtocols() {
  return {
    type: COMMON_GET_PROTOCOLS,
  };
}

function* doGetProtocolsAfter() {
  if (yield select(selectHasAnyQueue)) {
    yield take(COMMON_FLUSHED_QUEUES);
  }

  yield put(commonGetProtocols());
}

function* doGetProtocols() {
  try {
    const offlineProtocols = yield select(selectOfflineProtocols);
    const stateProtocols = yield select(selectProtocols);
    const serverProtocols = yield call(get, 'protocol');
    const queues = yield select(selectQueues);
    const queueProtocols = keys(queues);

    // make error check on completeProtocol
    // put version below language

    const toExcludeProtocols = uniq([...offlineProtocols, ...queueProtocols]);

    // if we have to exclude protocol, we get it from the state instead of from server
    const protocols = map(
      (serverProtocol) =>
        contains(serverProtocol.id, toExcludeProtocols)
          ? getById(serverProtocol.id)(stateProtocols)
          : serverProtocol,
      serverProtocols,
    );

    const serverProtocolIds = map((serverProtocol) => serverProtocol.id, serverProtocols);
    const stateProtocolIds = map((stateProtocol) => stateProtocol.id, stateProtocols);

    // should not happen, but...
    const removedFromServerProtocolsButStillInState = without(serverProtocolIds, stateProtocolIds);

    // add back those stateProtocols that for some reason are no more on server
    for (const protocolId of removedFromServerProtocolsButStillInState) {
      const stateProtocol = getById(protocolId)(stateProtocols);
      const stillHasQueue = queueProtocols.indexOf(protocolId) !== -1;
      if (!!stateProtocol && stillHasQueue) {
        protocols.push(stateProtocol);
      }
    }

    const images = reduce((acc, cur) => acc.concat(getImageReferences(cur)), [], protocols);
    yield purgeAllOtherImages(images);

    yield put({
      type: COMMON_GET_PROTOCOLS_SUCCESS,
      payload: protocols,
    });
  } catch (error) {
    yield put({
      type: COMMON_GET_PROTOCOLS_ERROR,
      payload: error.message,
    });
  }
}

export function* switchGetProtocols() {
  yield takeLatest(
    [COMMON_IS_LOGGED_IN, COMMON_LOGIN_SUCCESS],
    doGetProtocolsAfter,
  );
  yield takeLatest(COMMON_GET_PROTOCOLS, doGetProtocols);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_GET_PROTOCOLS:
        draft.ui.busy.getProtocols = true;
        break;
      case COMMON_GET_PROTOCOLS_SUCCESS:
        draft.ui.busy.getProtocols = false;
        draft.protocols = action.payload;

        const protocolIds = map((p) => p.id, action.payload);

        draft.offlineProtocols = filter(
          (op) => protocolIds.indexOf(op) !== -1,
          state.offlineProtocols,
        );

        break;
      case COMMON_GET_PROTOCOLS_ERROR:
        draft.ui.busy.getProtocols = false;
        break;
      default:
        return state;
    }
  });
