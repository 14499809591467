import produce from 'immer';

import { FILL_STEP_OPEN_COST_MODAL } from './constants';

export function fillStepOpenCostModal(id) {
  return {
    type: FILL_STEP_OPEN_COST_MODAL,
    payload: id,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_OPEN_COST_MODAL:
        draft.ui.modal.cost = true;
        draft.selectedIds.attributeId = action.payload;
        break;
      default:
        return state;
    }
  });
