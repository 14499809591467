import { createSelector } from 'reselect';

const selectMainStep = (state) => state.mainStep;

export const selectIsDeleteModalOpen = createSelector(
  selectMainStep,
  (state) => state.ui.modal.delete,
);

export const selectIsAssignmentModalOpen = createSelector(
  selectMainStep,
  (state) => state.ui.modal.assignment,
);
