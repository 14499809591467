/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';

import { variables } from '../../common/theme/variables';
import Button from '../common/Button';
import Modal from '../common/Modal';

const styles = {
  content: {
    whiteSpace: 'pre-wrap',
  },
  buttons: {
    display: 'grid',
    gridGap: '0 1em',

    [variables.breakpoints.md]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
};

const ConfirmModal = (props) => {
  const { title, isOpen, onConfirm, onClose, children, cancel, ok, isDisabledOk } = props;

  return (
    <Modal title={title} isOpen={isOpen} onClose={onClose} width="50vw">
      <div css={styles.content}>{children}</div>
      <div css={styles.buttons}>
        <Button secondary onClick={onClose}>
          {!!cancel ? cancel : <Trans>BUTTONS.CANCEL</Trans>}
        </Button>
        <Button default onClick={onConfirm} disabled={isDisabledOk}>
          {!!ok ? ok : <Trans>BUTTONS.OK</Trans>}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ConfirmModal.defaultProps = {
  isOpen: false,
};

export default ConfirmModal;
