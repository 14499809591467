import { all } from 'redux-saga/effects';

import * as fillStepSagas from '../features/acceptance-protocol/fill-step/redux/sagas';
import * as mainStepSagas from '../features/acceptance-protocol/main-step/redux/sagas';
import * as acceptanceProtocolSagas from '../features/acceptance-protocol/redux/sagas';
import * as sendStepSagas from '../features/acceptance-protocol/send-step/redux/sagas';
import * as signStepSagas from '../features/acceptance-protocol/sign-step/redux/sagas';
import * as leavingAgreementSagas from '../features/acceptance-protocol/leaving-agreement-step/redux/sagas';
import * as commonSagas from '../features/common/redux/sagas';
import * as homeSagas from '../features/home/redux/sagas';
import * as migrationSagas from '../features/migration/redux/sagas';

// This file is auto maintained by rekit-plugin-redux-saga,
// you usually don't need to manually edit it.

// NOTE: DO NOT chanage featureSagas declearation pattern, it's used by rekit-plugin-redux-saga.
const featureSagas = [
  commonSagas,
  homeSagas,
  acceptanceProtocolSagas,
  mainStepSagas,
  fillStepSagas,
  signStepSagas,
  leavingAgreementSagas,
  sendStepSagas,
  migrationSagas,
];

const sagas = featureSagas
  .reduce((prev, curr) => [...prev, ...Object.keys(curr).map((k) => curr[k])], [])
  // a saga should be function, below filter avoids error if redux/sagas.js is empty;
  .filter((s) => typeof s === 'function');

function* rootSaga() {
  yield all(sagas.map((saga) => saga()));
}

export default rootSaga;
