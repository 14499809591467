import { call, put, select, takeLatest } from 'redux-saga/effects';

import { i18n } from '../../../../common/i18n-loader';
import { askForConfirmation } from '../../../common/redux/openConfirmationModal';
import { selectCurrentId } from '../../redux/selectors';
import { SIGN_STEP_GO_TO_SEND_STEP } from './constants';
import { push } from 'connected-react-router';
import { acceptanceProtocolRoutes } from '../../routes';

export function signStepGoToSendStep() {
  return {
    type: SIGN_STEP_GO_TO_SEND_STEP,
  };
}

function* doGoToSendStep() {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('SIGN-STEP.CLOSE_PROTOCOL.TITLE'),
    message: i18n._('SIGN-STEP.CLOSE_PROTOCOL.MESSAGE'),
    ok: i18n._('BUTTONS.CLOSE-PROTOCOL'),
    cancel: i18n._('BUTTONS.CANCEL'),
  });

  if (!confirmed) {
    return;
  }

  const protocolId = yield select(selectCurrentId);

  yield put(push(acceptanceProtocolRoutes.sendStep(protocolId)));
}

export function* switchGoToSendStep() {
  yield takeLatest(SIGN_STEP_GO_TO_SEND_STEP, doGoToSendStep);
}
