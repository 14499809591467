/** @jsxImportSource @emotion/react */
import { routerActions } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useCallback, useState } from 'react';
import { variables } from '../../../common/theme/variables';
import { PugRenderer } from 'dap.report';
import * as commonActions from '../../common/redux/actions';
import {
  selectCurrentLeavingAgreement,
  selectIsProtocolReadonly,
  selectPersonList,
  selectRentalData,
  selectRentalObjectId,
  selectProtocolTemplateName,
} from '../redux/selectors';
import * as actions from './redux/actions';
import { selectScrollToSign } from './redux/selectors';
import { selectLocales, selectUsername } from '../../common/redux/selectors';

const styles = {
  container: {
    fontFamily: '"Rubik", sans-serif !important',

    margin: '0 auto',
    display: 'grid',
    gridGap: '1em',
    paddingBottom: '1.5em',

    overflowY: 'scroll',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    // TODO: add also for firefox
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
    height: '100%',

    paddingLeft: '3vw',
    paddingRight: '3vw',

    [variables.breakpoints.md]: {
      paddingLeft: '8vw',
      paddingRight: '8vw',
    },
    [variables.breakpoints.xl]: {
      paddingLeft: '15vw',
      paddingRight: '15vw',
    },
    '&:last-child:after': {
      content: '""',
      display: 'block',
      height: '8px',
      marginBottom: '-8px',
    },
  },
  closeButton: {
    width: '100%',
  },
};

const LeavingAgreementStep = (props) => {
  const {
    data,
    locales,
    templateName,
    isProtocolReadonly,
    rentalObjectId,
    personList,
    rentalData,
    scrollToSign,
    username,
    actions: {
      push,
      commonDeleteSign,
      leavingAgreementPatchOperation,
      leavingAgreementDeleteOperation,
      commonPatchOperation,
      commonDeleteOperation,
      commonSetCurrentSigner,
      leavingAgreementStepScrolled,
      leavingAgreementSkip,
    },
  } = props;

  const [defectsAreValid, setDefectsAreValid] = useState(true);
  const [attributesAreValid, setAttributesAreValid] = useState(true);

  const handleValidation = useCallback(
    (name, value) => {
      switch (name) {
        case 'defects':
          setDefectsAreValid(value);
          break;
        case 'attributes':
          setAttributesAreValid(value);
          break;
        default:
      }
    },
    [setDefectsAreValid, setAttributesAreValid],
  );

  return (
    <div css={styles.container}>
      <PugRenderer
        data={{
          ...data,
          isProtocolReadonly,
          rentalObjectId,
          personList,
          rentalData,
          username,
        }}
        language={'fr'} // the language is always French instead of being taken from the protocol
        locales={locales}
        templateName={templateName}
        isValid={defectsAreValid && attributesAreValid}
        actions={{
          onPatchOperation: commonPatchOperation,
          onDeleteOperation: commonDeleteOperation,
          onChange: leavingAgreementPatchOperation,
          onDelete: leavingAgreementDeleteOperation,
          onNavigateTo: push,
          onDeleteSign: commonDeleteSign,
          onSetCurrentSigner: commonSetCurrentSigner,
          onScrolled: leavingAgreementStepScrolled,
          onValidation: handleValidation,
          onLeavingAgreementSkip: leavingAgreementSkip,
        }}
        options={{ scrollToSign: scrollToSign }}
      />
    </div>
  );
};

/* istanbul ignore next */
export function mapStateToProps(state) {
  return {
    data: selectCurrentLeavingAgreement(state),
    locales: selectLocales(state),
    templateName: selectProtocolTemplateName(state),
    isProtocolReadonly: selectIsProtocolReadonly(state),
    rentalObjectId: selectRentalObjectId(state),
    personList: selectPersonList(state),
    rentalData: selectRentalData(state),
    scrollToSign: selectScrollToSign(state),
    username: selectUsername(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...routerActions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeavingAgreementStep);
