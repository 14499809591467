import { actions as toastrActions } from 'react-redux-toastr';
import { put } from 'redux-saga/effects';

export function* doToast({ payload }) {
  if (
    typeof payload === 'object' &&
    payload.message !== null &&
    payload.message.includes('Connection to Indexed Database server lost')
  ) {
    yield put(
      toastrActions.add({
        options: {
          timeOut: 5000,
          showCloseButton: false,
          onHideComplete: () => {
            window.location.reload();
          },
        },
        type: 'error',
        attention: true,
        message: 'Refreshing the page to reconnect with IndexedDB server.',
      }),
    );
  }

  yield put(toastrActions.add(payload));
}
