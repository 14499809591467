/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import EditComponentModal from '../../common/EditComponentModal';
import ImageSketcherModal from '../../common/ImageSketcherModal';
import * as commonActions from '../../common/redux/actions';
import Spinner from '../../common/Spinner';
import ViewImageModal from '../../common/ViewImageModal';
import {
  selectBasedataTemplate,
  selectComponentsTemplate,
  selectCurrentId,
  selectIndexedElementsTemplate,
  selectIsCurrentProtocolOffline,
  selectIsProtocolReadonly,
  selectProtocolLanguage,
  selectTemplateCosts,
} from '../redux/selectors';
import CostModal from './CostModal';
import InputAreaAcceptanceProtocol from './InputAreaAcceptanceProtocol';
import NavigationAcceptanceProtocol from './NavigationAcceptanceProtocol';
import * as actions from './redux/actions';
import {
  selectCurrentEditComponent,
  selectCurrentEditImage,
  selectCurrentViewImage,
  selectIsCostModalBusy,
  selectIsCostModalOpen,
  selectIsEditComponentModalOpen,
  selectIsImageSketcherModalOpen,
  selectIsShowSpinner,
  selectIsViewImageOpen,
  selectSelected,
} from './redux/selectors';

const styles = {
  body: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    height: '100%',
    width: '100%',
  },
};

export class FillStep extends Component {
  render() {
    const {
      templateCosts,
      selected,
      protocolId,
      language,
      isCostModalOpen,
      isCostModalBusy,
      isImageSketcherModalOpen,
      currentEditImage,
      isViewImageModalOpen,
      currentViewImage,
      isProtocolReadonly,
      isEditComponentModalOpen,
      currentEditComponent,
      componentsTemplate,
      indexedElementsTemplate,
      isShowSpinner,
      isOfflineProtocol,
      actions: {
        commonPatchOperation,
        commonDeleteOperation,
        fillStepCloseCostModal,
        fillStepCloseImageSketcherModal,
        fillStepSaveImage,
        fillStepCloseViewImageModal,
        fillStepDeleteImage,
        fillStepCloseEditComponentModal,
        fillStepSaveEditComponent,
      },
    } = this.props;

    return !isProtocolReadonly ? (
      <Spinner show={isShowSpinner}>
        <div css={styles.body}>
          <NavigationAcceptanceProtocol />
          <InputAreaAcceptanceProtocol />

          {!!selected && !!selected.component && !!selected.element && !!selected.attribute && (
            <CostModal
              isOpen={isCostModalOpen}
              isBusy={isCostModalBusy}
              protocolId={protocolId}
              selected={selected}
              templateCosts={templateCosts}
              language={language}
              onClose={fillStepCloseCostModal}
              onChangeValue={commonPatchOperation}
              onDeleteValue={commonDeleteOperation}
            />
          )}

          {!!selected && !!selected.component && !!selected.element && isImageSketcherModalOpen && (
            <ImageSketcherModal
              isOpen={isImageSketcherModalOpen}
              image={currentEditImage}
              component={selected.component}
              element={selected.element.template}
              onCancel={fillStepCloseImageSketcherModal}
              onSave={fillStepSaveImage}
              language={language}
            />
          )}

          <ViewImageModal
            isOpen={isViewImageModalOpen}
            image={currentViewImage}
            isOfflineProtocol={isOfflineProtocol}
            onClose={fillStepCloseViewImageModal}
            onCancel={fillStepCloseViewImageModal}
            onDelete={fillStepDeleteImage}
          />
          <EditComponentModal
            isOpen={isEditComponentModalOpen}
            language={language}
            component={currentEditComponent}
            componentsTemplate={componentsTemplate}
            indexedElementsTemplate={indexedElementsTemplate}
            onCancel={fillStepCloseEditComponentModal}
            onSave={fillStepSaveEditComponent}
          />
        </div>
      </Spinner>
    ) : (
      <span>
        Protocol blocked because one of the people involved in this deal signed the protocol
      </span>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    protocolId: selectCurrentId(state),
    selected: selectSelected(state),

    isCostModalOpen: selectIsCostModalOpen(state),
    isCostModalBusy: selectIsCostModalBusy(state),
    isShowSpinner: selectIsShowSpinner(state),

    baseData: selectBasedataTemplate(state),
    templateCosts: selectTemplateCosts(state),
    language: selectProtocolLanguage(state),
    isImageSketcherModalOpen: selectIsImageSketcherModalOpen(state),
    currentEditImage: selectCurrentEditImage(state),
    isViewImageModalOpen: selectIsViewImageOpen(state),
    currentViewImage: selectCurrentViewImage(state),
    isProtocolReadonly: selectIsProtocolReadonly(state),
    isEditComponentModalOpen: selectIsEditComponentModalOpen(state),
    currentEditComponent: selectCurrentEditComponent(state),
    componentsTemplate: selectComponentsTemplate(state),
    indexedElementsTemplate: selectIndexedElementsTemplate(state),
    isOfflineProtocol: selectIsCurrentProtocolOffline(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FillStep);
