import produce from 'immer';
import { put, take, takeLatest, race, select } from 'redux-saga/effects';
import {
  COMMON_FLUSHED_QUEUES,
  COMMON_FLUSH_QUEUES,
  COMMON_SYNC_OFFLINE,
  COMMON_SYNC_OFFLINE_START,
  COMMON_SYNC_OFFLINE_SUCCESS,
  COMMON_SYNC_OFFLINE_ERROR,
  COMMON_POP_QUEUE_ERROR,
  COMMON_TOKENS_REFRESH_ERROR, COMMON_TOKENS_REFRESH_SUCCESS, COMMON_SYNC_OFFLINE_TRIGGERED
} from "./constants";
import { omit } from 'ramda';
import {
  selectIsLoggedIn,
  selectIsOnline,
  selectIsTokenExpired,
  selectSessionId
} from "./selectors";
import {
  commonDapLoginTokenExpired,
  commonRemoveFromDownload,
} from "./actions";

export function commonSyncOffline(protocolId) {
  return { type: COMMON_SYNC_OFFLINE, payload: protocolId };
}

function* doSyncOffline({ payload }) {
  const sessionId = yield select(selectSessionId);
  const isOnline = yield select(selectIsOnline);
  let isLoggedIn = yield select(selectIsLoggedIn);
  let isTokenExpired = yield select(selectIsTokenExpired);

  yield put({
    type: COMMON_SYNC_OFFLINE_TRIGGERED,
    payload,
  });


  if (isLoggedIn && isTokenExpired){
    yield put(commonDapLoginTokenExpired());

    const { success } = yield race({
      success: take(COMMON_TOKENS_REFRESH_SUCCESS),
      error: take(COMMON_TOKENS_REFRESH_ERROR),
    });

    if (success) {
      isLoggedIn = yield select(selectIsLoggedIn);
      isTokenExpired = yield select(selectIsTokenExpired);
    } else {
      yield put({
        type: COMMON_SYNC_OFFLINE_ERROR,
        payload,
      });
    }
  }

  if (isOnline && isLoggedIn && !isTokenExpired) {
    yield put({
      type: COMMON_SYNC_OFFLINE_START,
      payload,
    });

    yield put(commonRemoveFromDownload({ sessionId, protocolId: payload }));

    yield put({
      type: COMMON_FLUSH_QUEUES,
      payload,
    });

    const { success } = yield race({
      success: take(COMMON_FLUSHED_QUEUES),
      error: take(COMMON_POP_QUEUE_ERROR),
    });

    if (success) {
      yield put({
        type: COMMON_SYNC_OFFLINE_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: COMMON_SYNC_OFFLINE_ERROR,
        payload,
      });
    }
  }
}

export function* switchSyncOffline() {
  yield takeLatest(COMMON_SYNC_OFFLINE, doSyncOffline);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_SYNC_OFFLINE_TRIGGERED:
        draft.ui.busy.syncOffline[action.payload] = true;
        break;
        case COMMON_SYNC_OFFLINE_START:
        draft.ui.busy.syncOffline[action.payload] = true;
        draft.offlineProtocols = draft.offlineProtocols.filter((p) => p.id === action.payload);
        break;
      case COMMON_SYNC_OFFLINE_SUCCESS:
        draft.ui.busy.syncOffline[action.payload] = false;
        draft.pdfs = omit([action.payload], draft.pdfs);
        break;
      case COMMON_SYNC_OFFLINE_ERROR:
        draft.ui.busy.syncOffline[action.payload] = false;
        break;
      default:
        return state;
    }
  });
