/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import { Fraction } from './Fraction';
import { formatAsDecimals } from '../../common/Functions';

const styles = {
  label: {
    padding: '0.125em 0',
  },
  costText: {
    padding: '0.125em 0',
  },
  costValue: {
    fontWeight: 'bold',
    padding: '0.125em 0',
  },
};

const InputAttribute = (props) => {
  const { id, label, cost, text, onSelectAttribute } = props;

  const handleSelectAttribute = () => {
    onSelectAttribute(id);
  };

  return (
    <button onClick={handleSelectAttribute}>
      <div>
        <div css={styles.label}>{label}</div>
        {!!text && <div css={styles.costText}>{text}</div>}
        {!!cost && !!cost.value && cost.value !== '0' && (
          <div css={styles.costValue}>
            {cost.type !== 'x/y' ? (
              formatAsDecimals(cost.value) + ' ' + cost.type
            ) : (
              <Fraction value={cost.value} withPercentage={true} />
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export default memo(InputAttribute);
