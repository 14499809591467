import produce from 'immer';

import { HOME_OPEN_CHANGE_PROTOCOL_ID_MODAL } from './constants';

export function homeOpenChangeProtocolIdModal() {
  return { type: HOME_OPEN_CHANGE_PROTOCOL_ID_MODAL };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_OPEN_CHANGE_PROTOCOL_ID_MODAL:
        draft.ui.modal.changeProtocolId = true;
        break;
      default:
        return state;
    }
  });
