import { takeLatest } from 'redux-saga/effects';
import { SEND_STEP_COMPLETE_PROTOCOL_ERROR } from './constants';
import { doToast } from "../../../../common/rootToastr";

function* doNotifyError({ payload }) {
  yield* doToast({
    payload: {
      type: 'error',
      attention: true,
      message: payload,
    }
  });
}

export function* switchNotifyError() {
  yield takeLatest([SEND_STEP_COMPLETE_PROTOCOL_ERROR], doNotifyError);
}
