import { filter, find, propEq } from 'ramda';
import { i18n } from '../../../common/i18n-loader';

export const getLocalizedLabel = (element, language) => {
  switch (language) {
    case 'fr':
      return element.labelFr;
    case 'it':
      return element.labelIt;
    case 'en':
      return element.labelEn ? element.labelEn : element.label;
    default:
      return element.label;
  }
};

export const getLocalizedNameForComponent = (componentInput, componentTemplate, language) => {
  if (!!componentInput && !!componentInput.localName) {
    return componentInput.localName;
  }

  let suffix = '';
  if (!!componentInput && !!componentInput.countingRoomNumber) {
    suffix = ` ${componentInput.countingRoomNumber}`;
  }

  if (!componentTemplate) {
    return i18n._('FILL-STEP.ADD-COMPONENT.ADDITIONAL-ROOM');
  }

  switch (language) {
    case 'fr':
      return componentTemplate.nameFr + suffix;
    case 'it':
      return componentTemplate.nameIt + suffix;
    case 'en':
      return componentTemplate.nameEn
        ? componentTemplate.nameEn + suffix
        : componentTemplate.name + suffix;
    default:
      return componentTemplate.name + suffix;
  }
};

export const getLocalizedName = (item, language) => {
  switch (language) {
    case 'fr':
      return item.nameFr;
    case 'it':
      return item.nameIt;
    case 'en':
      return item.nameEn ? item.nameEn : item.name;
    default:
      return item.name;
  }
};

export const getLocalizedNameForCost = (cost, language) => {
  switch (language) {
    case 'fr':
      return cost.nameFr;
    case 'it':
      return cost.nameIt;
    case 'en':
      return cost.nameEn ? cost.nameEn : cost.name;
    default:
      return cost.name;
  }
};

export const getLocalizedCostName = (cost, templateCosts, language) => {
  const currentTemplateCost =
    Boolean(cost) && find((templateCost) => templateCost.id === cost.id, templateCosts);

  const localizedTemplateText =
    currentTemplateCost && getLocalizedNameForCost(currentTemplateCost, language);

  return localizedTemplateText;
};

export const getAllId = (items, id) => {
  return !!items && filter(propEq('typeId', id), items);
};

export const getFirstId = (items, id) => {
  return !!items && find(propEq('id', id), items);
};

export const getFirstFieldName = (items, fieldname) => {
  return !!items && find(propEq('fieldname', fieldname), items);
};

export const getNestedFirstFieldName = (items, fieldname) => {
  items = items.map((elm) => elm.fields).flat();
  return !!items && find(propEq('fieldname', fieldname), items);
};

export const getSecurityTypeAmount = (items, fieldname) => {
  const field = getNestedFirstFieldName(items, fieldname);
  return !!field && field.value !== '' ? field : { value: 0 };
};

const parseFloatOrReturnString = (num, fixedDecimals) => {
  const parsedNum = parseFloat(num);
  if (!isNaN(parsedNum)) {
    return fixedDecimals ? parsedNum.toFixed(fixedDecimals) : parsedNum;
  }
  return num;
};

export const getFraction = (value, fixedDecimals = undefined) => {
  if (!!value) {
    const result = /(.+)\/(.+)/gi.exec(value);

    if (!result) return;

    const [, sup, sub] = result;

    return {
      sup: parseFloatOrReturnString(sup, fixedDecimals),
      sub: parseFloatOrReturnString(sub, fixedDecimals),
    };
  }
};

export const getLocalName = (input) => {
  return !!input ? input.localName : null;
};
