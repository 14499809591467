import { DateTime } from 'luxon';
import { generateImageId } from '../ImageSketcherCanvas';

export function performTakeover(protocol, componentInputsToTakeOver) {
  /**
   * performTakeover assumes that the previous component inputs have been purged by the backend correctly.
   * Check the backend for further details.
   */

  if (!protocol || !protocol.input) {
    return [];
  }

  const componentInputs = componentInputsToTakeOver || protocol.input.previousComponentsInput || [];

  if (componentInputs.length === 0) {
    return [];
  }

  const takenOverComponents = takeOverComponents(componentInputs);

  return takenOverComponents;
}

function takeOverComponents(componentInputsToTakeOver) {
  const takenOverComponents = [];

  for (const componentInputToTakeOver of componentInputsToTakeOver) {
    const takenOverElements = takeOverElements(componentInputToTakeOver.elements);

    const takenOverComponent = {
      ...componentInputToTakeOver,
      elements: takenOverElements,
    };

    takenOverComponents.push(takenOverComponent);
  }

  return takenOverComponents;
}

function takeOverElements(elementsToTakeOver) {
  const takenOverElements = [];

  for (const elementToTakeOver of elementsToTakeOver) {
    const takenOverAttributes = takeOverAttributes(elementToTakeOver.attributesSelected);

    const images = elementToTakeOver.images
      ? elementToTakeOver.images.map((image, index) => {
          if (image.id) return image;

          const newId = `${generateImageId(DateTime.local())}${index}`;

          return {
            ...image,
            id: newId,
          };
        })
      : null;

    const takenOverElement = {
      ...elementToTakeOver,
      attributesSelected: takenOverAttributes,
      images,
    };

    takenOverElements.push(takenOverElement);
  }

  return takenOverElements;
}

function takeOverAttributes(attributesToTakeOver) {
  const takenOverAttributes = [];

  for (const attributeToTakeOver of attributesToTakeOver) {
    const takenOverAttribute = {
      ...attributeToTakeOver,
    };

    takenOverAttributes.push(takenOverAttribute);
  }

  return takenOverAttributes;
}

export function getPreviousComponentInputsToTakeover(
  protocol,
  previousComponentInputsIdsToTakeover,
) {
  if (!protocol && !protocol.input && !protocol.input.previousComponentsInput) {
    return [];
  }

  return protocol.input.previousComponentsInput.filter((previousComponentInput) =>
    previousComponentInputsIdsToTakeover.includes(previousComponentInput.id),
  );
}
