import produce from 'immer';

import { FILL_STEP_HIDE_SPINNER } from './constants';

export function fillStepHideSpinner() {
  return {
    type: FILL_STEP_HIDE_SPINNER,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_HIDE_SPINNER:
        draft.ui.busy.spinner = false;
        break;
      default:
        return state;
    }
  });
