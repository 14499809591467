/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { i18n } from '../../common/i18n-loader';

import Modal from '../common/Modal';
import Button from '../common/Button';
import { Results } from './Results';

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content min-content',
    gridGap: '0.5em',
    gridAutoFlow: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  bold: { fontWeight: 'bold' },
};

const RetainModal = (props) => {
  const { isOpen, onClose, results } = props;

  return (
    <Modal title={i18n._('MODAL.TITLE.MIGRATIONS.RETAIN')} isOpen={isOpen} onClose={onClose}>
      <div css={styles.container}>
        <Results results={results} />
        <div>
          <Button default onClick={onClose}>
            <Trans>BUTTONS.OK</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

RetainModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
};

RetainModal.defaultProps = {
  isOpen: false,
};

export default RetainModal;
