import produce from 'immer';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { downloadFile } from '../services/api';
import {
  COMMON_GET_PDF_FROM_PROTOCOL,
  COMMON_GET_PDF_FROM_PROTOCOL_ERROR,
  COMMON_GET_PDF_FROM_PROTOCOL_SUCCESS,
} from './constants';
import { selectProtocols, selectOfflineProtocols, selectPdfs } from './selectors';
import { map, find, propEq } from 'ramda';

export function commonGetPdfsFromProtocol() {
  return {
    type: COMMON_GET_PDF_FROM_PROTOCOL,
  };
}

function getPdfDownloadUrlByType(type) {
  switch (type) {
    case 'leavingagreement':
      return 'pdf/download/leavingagreement';
    default:
    case 'protocol':
      return 'pdf/fromprotocol';
  }
}

async function downloadPdfBase64(id, type) {
  const pdf = await downloadFile(`${getPdfDownloadUrlByType(type)}/${id}`);
  const pdfBase64 = new Buffer(pdf, 'binary').toString('base64');
  return pdfBase64;
}

function* doGetPdfFromProtocol() {
  try {
    const protocols = yield select(selectProtocols);
    const offlineProtocols = yield select(selectOfflineProtocols);
    const pdfs = yield select(selectPdfs);

    const updatedPdfs = {};
    const matchingProtocols = [];

    for (let matchingProtocol of protocols) {
      const hasMatchedProtocol = find(
        propEq('id', matchingProtocol.id),
        map((protocolId) => ({ id: protocolId }), offlineProtocols),
      );
      if (hasMatchedProtocol) {
        matchingProtocols.push(matchingProtocol);
      }
    }

    for (let protocol of matchingProtocols) {
      if (!!protocol.isDownloadPreviousProtocol && !pdfs[protocol.id]) {
        const pdfBase64 = yield call(downloadPdfBase64, protocol.previousProtocol.id, 'protocol');
        updatedPdfs[protocol.id] = pdfBase64;

        if (protocol.previousProtocol.hasLeavingAgreement) {
          const leavingAgreementPdfBase64 = yield call(
            downloadPdfBase64,
            protocol.previousProtocol.id,
            'leavingagreement',
          );
          updatedPdfs[`${protocol.id}_leavingagreement`] = leavingAgreementPdfBase64;
        }
      }
    }

    yield put({
      type: COMMON_GET_PDF_FROM_PROTOCOL_SUCCESS,
      payload: updatedPdfs,
    });
  } catch (error) {
    yield put({
      type: COMMON_GET_PDF_FROM_PROTOCOL_ERROR,
      payload: error.message,
    });
  }
}

export function* switchGetPdfFromProtocols() {
  yield takeLatest(COMMON_GET_PDF_FROM_PROTOCOL, doGetPdfFromProtocol);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_GET_PDF_FROM_PROTOCOL:
        draft.ui.busy.getPdfsFromProtocol = true;
        break;
      case COMMON_GET_PDF_FROM_PROTOCOL_SUCCESS:
        draft.ui.busy.getPdfsFromProtocol = false;
        draft.pdfs = { ...draft.pdfs, ...action.payload };
        break;
      case COMMON_GET_PDF_FROM_PROTOCOL_ERROR:
        draft.ui.busy.getPdfsFromProtocol = false;
        break;
      default:
        return state;
    }
  });
