import produce from 'immer';

import { COMMON_CLOSE_CONFIRMATION_MODAL } from './constants';

export function commonCloseConfirmationModal() {
  return {
    type: COMMON_CLOSE_CONFIRMATION_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_CLOSE_CONFIRMATION_MODAL:
        draft.ui.modal.confirmation = false;
        draft.ui.confirmation.title = null;
        draft.ui.confirmation.message = null;
        break;
      default:
        return state;
    }
  });
