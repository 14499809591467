/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import { nanoid } from 'nanoid';

import { createContext, useState, useContext, useEffect } from 'react';
import { TabsContext } from './Tabs';

export const TabContext = createContext({ id: null });

const Tab = (props) => {
  const { children, defaultSelected } = props;
  const [id] = useState(nanoid());
  const { selectTab } = useContext(TabsContext);
  useEffect(() => {
    if (!!defaultSelected) {
      selectTab(id);
    }
  }, [defaultSelected, selectTab, id]);

  return <TabContext.Provider value={{ id }}>{children}</TabContext.Provider>;
};

Tab.propTypes = {};

Tab.defaultProps = {};

export default memo(Tab);
