/** @jsxImportSource @emotion/react */

import { any } from 'ramda';
import { useMemo, useCallback, useRef } from 'react';

import { getLocalizedName, getLocalName } from '../acceptance-protocol/fill-step/Functions';
import { CashIcon } from './CashIcon';
import {
  getCopiedStyle,
  getStateFontStyle,
  getStateIndex,
  getStateStyle,
  getStateSymbol,
} from './Functions';
import { colors } from '../../common/theme/colors';
import { Icon } from './Icon';
import { WriteIcon } from './WriteIcon';

const styles = {
  container: {
    display: 'grid',

    borderBottom: `1px ${colors.greyBorderAcceptanceProtocol} solid`,

    content: {
      display: 'grid',
      minHeight: '3em',
      gridTemplateColumns: '5px 1em auto min-content min-content min-content',
      gridGap: '5px',
      paddingRight: '1em',

      borderLeft: 'none',

      symbol: {
        display: 'grid',
        alignItems: 'center',
        color: `${colors.whiteSmoke}`,
      },

      text: {
        padding: '0.25em',
        paddingLeft: '1em',

        textAlign: 'left',
        backgroundColor: `${colors.dark}`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        alignSelf: 'center',
      },
      input: {
        padding: '0.25em',
        paddingLeft: '47px',
        backgroundColor: 'transparent',
        color: colors.white,
        width: '100%',
        height: '100%',
      },
      costIcon: {
        paddingRight: '48px',
        justifySelf: 'right',
        alignSelf: 'center',
        backgroundColor: 'transparent',
        width: '32px',
        height: '32px',
      },

      minusIcon: {
        backgroundColor: 'transparent',
        color: `${colors.white}`,
        alignSelf: 'center',
        height: '100%',
      },
      icon: {
        backgroundColor: 'transparent',
        color: `${colors.white}`,
        width: '32px',
        height: '32px',
        alignSelf: 'center',
      },
    },
  },
};

const EditComponentElement = ({
  language,
  input,
  template,
  onNameElementChange,
  onChange,
  onRemove,
}) => {
  const stateIndex = useMemo(() => !!input && getStateIndex(input.state), [input]);

  const inputRef = useRef(null);

  const hasCost = useMemo(
    () =>
      !!input &&
      !!input.attributesSelected &&
      any(
        (x) =>
          !!x.cost &&
          ((!!x.cost.value && (x.cost.value > 0 || x.cost.value !== '')) ||
            (!!x.cost.id && x.cost.id > 0)),
      )(input.attributesSelected),
    [input],
  );

  const handleRemove = useCallback(() => onRemove(template.id), [onRemove, template.id]);

  const handleEdit = useCallback(() => {
    if (!!inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const handleOnBlur = useCallback(
    (evt) => onNameElementChange(template.id, evt.currentTarget.value),
    [onNameElementChange, template.id],
  );

  return (
    <div css={styles.container.content}>
      <div
        css={styles.container.content.state}
        style={getCopiedStyle(input) || getStateStyle(stateIndex)}
      />
      <div css={styles.container.content.symbol}>{getStateSymbol(stateIndex)}</div>
      <input
        css={styles.container.content.input}
        style={getStateFontStyle(stateIndex)}
        defaultValue={getLocalName(input) || getLocalizedName(template, language)}
        onBlur={handleOnBlur}
        onChange={onChange}
        ref={inputRef}
      />
      <div css={styles.container.content.costIcon}>{hasCost && <CashIcon />}</div>
      <button css={styles.container.content.icon} onClick={handleEdit}>
        <WriteIcon />
      </button>
      <button css={styles.container.content.minusIcon} onClick={handleRemove}>
        <Icon icon="minus" />
      </button>
    </div>
  );
};

export default EditComponentElement;
