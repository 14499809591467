import { call, put, putResolve, select, takeLatest } from 'redux-saga/effects';
import { getConfigForClient } from '../services/config';
import {
  COMMON_LOAD_KEYCLOAK_CONFIG,
  COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS,
  COMMON_LOAD_KEYCLOAK_CONFIG_ERROR,
  COMMON_KEYCLOAK_DO_LOGIN,
  COMMON_KEYCLOAK_TOKENS,
  COMMON_LOGIN_ERROR,
  COMMON_KEYCLOAK_TOKENS_ERROR,
  COMMON_TOKENS_REFRESH_SUCCESS, COMMON_TOKENS_REFRESH_ERROR, COMMON_KEYCLOAK_TOKENS_REFRESH
} from "./constants";
import {
  selectIsKeycloakActive,
  selectIsOnline, selectIsSyncOfflineBusy,
  selectKeycloak,
  selectLoginWorkflow
} from "./selectors";
import { keycloakAuthLogin } from '../services/auth';
import {
  commonKeycloakDoLogin,
  commonKeycloakOnTokens,
  commonLoadKeycloakConfig,
  commonLogout,
  commonTokenSet
} from "./actions";
import { commonFetchConfiguration } from "./loginSuccess";
import { selectCurrentId, selectIsCurrentProtocolOffline } from "../../acceptance-protocol/redux/selectors";
import { LOGIN_WORKFLOW_EXPIRED_TOKEN } from "./loginWorkflowConstants";
import Keycloak from "keycloak-js";
import { KEYCLOAK_REALM } from "../../../common/constants";
import { store } from "../../../common/configStore";
import { commonKeycloakDoTokenRefresh, commonKeycloakOnSet, removeKeycloakTokens } from "./keycloak";

export function* handleKeycloakTokens({ payload: { tokens, workflow } }) {
  try {
    const response = yield call(keycloakAuthLogin, tokens);

    yield put(commonTokenSet(response));

    if (workflow === LOGIN_WORKFLOW_EXPIRED_TOKEN) {
      yield put({
        type: COMMON_TOKENS_REFRESH_SUCCESS,
      });
    } else {
      yield put(commonFetchConfiguration(workflow, response.username, response.email, response.client));
    }
  } catch (error) {
    yield put({
      type: COMMON_KEYCLOAK_TOKENS_ERROR,
      payload: error.message,
    });

    yield putResolve({
      type: COMMON_LOGIN_ERROR,
      payload: error.message,
    });

    // Remove tokens since there was an error
    yield put(removeKeycloakTokens());
  }
}

function* setKeycloakConfig() {
  try {
    const keycloakConfig = yield call(getConfigForClient);

    yield put({
      type: COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS,
      payload: {
        keycloakConfig,
      },
    });
  } catch (error) {
    yield put({
      type: COMMON_LOAD_KEYCLOAK_CONFIG_ERROR,
      payload: error.message,
    });
  }
}

function* doKeycloakLogin() {
  const isKeycloakActive = yield select(selectIsKeycloakActive);
  const keycloak = yield select(selectKeycloak);
  const isOnline = yield select(selectIsOnline);

  if (isOnline && isKeycloakActive && keycloak) {
    yield call([keycloak, keycloak.login]);
  }
}

function* doKeycloakTokenRefresh() {
  const keycloak = yield select(selectKeycloak);
  const isOnline = yield select(selectIsOnline);
  const isCurrentProtocolOffline = yield select(selectIsCurrentProtocolOffline);
  const isSyncOfflineBusy = yield select(selectIsSyncOfflineBusy);
  const currentProtocolId = yield select(selectCurrentId);
  const dispatch = store.dispatch;

  if (isOnline && (!!!isCurrentProtocolOffline || !!isSyncOfflineBusy[currentProtocolId])) {
    try {
      keycloak.updateToken(30).then((refreshed) => {
        if (refreshed) {
          dispatch(commonKeycloakOnTokens(
            {
              token: keycloak.token,
              idToken: keycloak.idToken,
              refreshToken: keycloak.refreshToken,
            },
            LOGIN_WORKFLOW_EXPIRED_TOKEN,
          ));
        }
      }).catch((error) => {
        console.debug('Keycloak token refresh failed. Redirecting to Keycloak login page. Error: ', error);
        dispatch(commonKeycloakDoLogin());
        dispatch({
          type: COMMON_TOKENS_REFRESH_ERROR,
        });
      });
    } catch (error) {
      console.debug('Keycloak token refresh failed. Redirecting to Keycloak login page. Error: ', error);
      yield put(commonKeycloakDoLogin());
      yield put({
        type: COMMON_TOKENS_REFRESH_ERROR,
      });
    }
  }
}

function* doPrepareKeycloak({ payload: { keycloakConfig } }){
  if (keycloakConfig !== null && keycloakConfig.isKeycloakActive) {
    const dispatch = store.dispatch;
    const isOnline = yield select(selectIsOnline);
    const logInWorkflow = yield select(selectLoginWorkflow);

    let keycloak = new Keycloak({
      realm: KEYCLOAK_REALM,
      clientId: keycloakConfig.clientId,
      url: keycloakConfig.keycloakAuthServerUrl,
    });

    if (isOnline) {
      keycloak.init({
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
        enableLogging: true,
      });
    }

    // Event listeners
    keycloak.onReady = () => {
      dispatch(commonKeycloakOnSet(keycloak));
    };

    keycloak.onAuthSuccess = () => {
      dispatch(commonKeycloakOnTokens(
          {
            token: keycloak.token,
            idToken: keycloak.idToken,
            refreshToken: keycloak.refreshToken,
          },
          logInWorkflow,
        ),
      );
    };

    keycloak.onAuthError = () => {
      console.debug('Keycloak authentication error.');
      dispatch(commonKeycloakDoLogin());
    };

    keycloak.onAuthRefreshSuccess = () => {
      console.debug('Keycloak token refresh success.');
      dispatch(
        commonKeycloakOnTokens(
          {
            token: keycloak.token,
            idToken: keycloak.idToken,
            refreshToken: keycloak.refreshToken,
          },
          LOGIN_WORKFLOW_EXPIRED_TOKEN,
        ),
      );
    };

    keycloak.onAuthLogout = () => {
      console.debug('Keycloak logout method initiated.');
      dispatch(commonLogout());
      dispatch(removeKeycloakTokens());
    };

    keycloak.onAuthRefreshError = () => {
      console.debug('Keycloak token refresh failed.');
      dispatch(commonKeycloakDoLogin());
    };

    keycloak.onTokenExpired = () => {
      console.debug('Keycloak token expired.');
      dispatch(commonKeycloakDoTokenRefresh());
    };
  }
}

export function* switchKeycloakClient() {
  yield takeLatest(COMMON_LOAD_KEYCLOAK_CONFIG, setKeycloakConfig);
  yield takeLatest(COMMON_KEYCLOAK_TOKENS, handleKeycloakTokens);
  yield takeLatest(COMMON_KEYCLOAK_TOKENS_REFRESH, doKeycloakTokenRefresh);
  yield takeLatest(COMMON_KEYCLOAK_DO_LOGIN, doKeycloakLogin);
  yield takeLatest(COMMON_LOAD_KEYCLOAK_CONFIG_SUCCESS, doPrepareKeycloak);
  yield put(commonLoadKeycloakConfig());
}
