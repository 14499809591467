import produce from 'immer';

import { COMMON_SET_CURRENT_SIGNER } from './constants';

export function commonSetCurrentSigner(signerId) {
  return {
    type: COMMON_SET_CURRENT_SIGNER,
    payload: signerId,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_SET_CURRENT_SIGNER:
        draft.currentSigner = action.payload;
        break;
      default:
        return state;
    }
  });
