/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { TopBarBaseStep } from './TopBarBaseStep';
import { acceptanceProtocolRoutes } from '../acceptance-protocol/routes';
import { Icon } from './Icon';

const styles = {
  container: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    alignItems: 'center',
  },
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
};

export const TopBarLeavingAgreementStep = (props) => {
  const { currentStep, onNavigateTo, protocolId, isLast, hasManagerSigned } = props;

  const handleNavigateToSingStep = useCallback(() => {
    onNavigateTo(acceptanceProtocolRoutes.leavingAgreementStep(protocolId));
  }, [onNavigateTo, protocolId]);

  const isActive = useMemo(
    () =>
      !!currentStep &&
      (currentStep === 'leaving-agreement-step' || currentStep === 'leaving-agreement-sign'),
    [currentStep],
  );

  return (
    <TopBarBaseStep
      step="leaving-agreement-step"
      isActive={isActive}
      hasManagerSigned={hasManagerSigned}
      onSelectStep={handleNavigateToSingStep}
      isLast={isLast}
    >
      <div css={styles.container}>
        <div css={styles.label}>
          <Trans>LABELS.LEAVING-AGREEMENT-STEP</Trans>
        </div>
        {!hasManagerSigned && <Icon icon="forward" size="sm" title="Skipped" />}
      </div>
    </TopBarBaseStep>
  );
};
