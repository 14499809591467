import produce from 'immer';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';
import { authLogin } from '../services/auth';
import {
  COMMON_DAP_LOGIN_TOKEN_EXPIRED,
  COMMON_LOGIN,
  COMMON_LOGIN_ERROR,
  COMMON_LOGIN_SUCCESS,
  COMMON_LOGIN_TOKEN_SET,
  COMMON_LOGIN_TOKEN_UNSET, COMMON_TOKENS_REFRESH_SUCCESS
} from "./constants";
import { commonFetchConfiguration } from "./loginSuccess";
import { LOGIN_WORKFLOW_DEFAULT, LOGIN_WORKFLOW_EXPIRED_TOKEN } from "./loginWorkflowConstants";
import { commonKeycloakDoTokenRefresh } from "./keycloak";
import {
  selectIsLoggedIn,
  selectIsOnline,
  selectIsSsoLogin,
  selectIsSyncOfflineBusy,
  selectIsTokenExpired,
} from "./selectors";
import { commonOpenLoginModal } from "./openLoginModal";
import { selectCurrentId, selectIsCurrentProtocolOffline } from "../../acceptance-protocol/redux/selectors";

export function commonLogin(loginWorkflow, username, password, client) {
  return {
    type: COMMON_LOGIN,
    payload: { loginWorkflow, username, password, client },
  };
}

export function commonTokenSet(tokens) {
  return {
    type: COMMON_LOGIN_TOKEN_SET,
    payload: tokens,
  };
}
export function commonTokenUnset() {
  return {
    type: COMMON_LOGIN_TOKEN_UNSET,
  };
}
export function commonDapLoginTokenExpired(data) {
  return {
    type: COMMON_DAP_LOGIN_TOKEN_EXPIRED,
    payload: { data },
  };
}

function* doLogin({ payload: { loginWorkflow, username, password, client } }) {
  try {
    //  username and password are taken from the server due to case insentitive on client side
    const response = yield call(authLogin, username, password, client);

    yield put(commonTokenSet(response));

    if (loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN){
      yield put({
        type: COMMON_TOKENS_REFRESH_SUCCESS,
      });
    } else {
      yield put(commonFetchConfiguration(loginWorkflow, response.username, response.email, response.client));
    }
  } catch (error) {
    yield put({
      type: COMMON_LOGIN_ERROR,
      payload: error.message,
    });
  }
}

function* doInitiateLoginWorkflowExpiredToken() {
  const isOnline = yield select(selectIsOnline);
  const isLoggedIn = yield select(selectIsLoggedIn);
  const isTokenExpired = yield select(selectIsTokenExpired);

  if (!isOnline) return;
  if (!isLoggedIn) return;
  if (!isTokenExpired) return;

  const isSsoLogin = yield select(selectIsSsoLogin);
  const isCurrentProtocolOffline = yield select(selectIsCurrentProtocolOffline);
  const currentProtocolId = yield select(selectCurrentId);
  const isSyncOfflineBusy = yield select(selectIsSyncOfflineBusy);

  if (isSsoLogin && (!isCurrentProtocolOffline || isSyncOfflineBusy[currentProtocolId])) {
    yield put(commonKeycloakDoTokenRefresh());
  }

  if (!isSsoLogin && (!isCurrentProtocolOffline || isSyncOfflineBusy[currentProtocolId])) {
    yield put(commonOpenLoginModal(LOGIN_WORKFLOW_EXPIRED_TOKEN));
  }
}

export function* switchLogin() {
  yield takeLatest(COMMON_LOGIN, doLogin);
  yield takeLatest(COMMON_DAP_LOGIN_TOKEN_EXPIRED, doInitiateLoginWorkflowExpiredToken);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_LOGIN:
        draft.ui.busy.login = true;
        break;
      case COMMON_LOGIN_SUCCESS:
        draft.ui.busy.login = false;
        draft.ui.modal.login = false;
        draft.authInfo.isLoggedIn = true;
        draft.username = action.payload.username;
        draft.email = action.payload.email;
        draft.locales = action.payload.locales;
        draft.securityDepotTypes = action.payload.securityDepotTypes;
        draft.client = action.payload.client;
        draft.clientConfig = action.payload.clientConfig;
        draft.sessionId = uuid();
        setLoginInfo(draft, action.payload.client, action.payload.username);
        break;
      case COMMON_LOGIN_ERROR:
        draft.ui.busy.login = false;
        draft.authInfo.isLoggedIn = false;
        break;
      case COMMON_LOGIN_TOKEN_SET:
        draft.authInfo.accessToken = action.payload.tokens.token;
        draft.authInfo.expiresAt = action.payload.tokens.expiresAt;
        draft.authInfo.isLoggedIn = true;
        draft.authInfo.isTokenExpired = false;
        draft.authInfo.refreshToken = action.payload.tokens.refreshToken;
        draft.authInfo.idToken = action.payload.tokens.idToken;
        draft.authInfo.isSsoLogin = action.payload.tokens.isSsoLogin;

        if (draft.loginInfo.lastUsername !== action.payload.username){
          draft.protocols = [];
          draft.queues = {};
          draft.offlineProtocols = [];
          draft.errorSynchronizationProtocols = [];
          draft.leavingAgreements = [];
          draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        }
        break;
      case COMMON_LOGIN_TOKEN_UNSET:
        draft.authInfo.accessToken = null;
        draft.authInfo.refreshToken = null;
        draft.authInfo.idToken = null;
        draft.authInfo.expiresAt = null;
        draft.authInfo.isLoggedIn = false;
        draft.authInfo.isSsoLogin = false;
        draft.authInfo.isTokenExpired = false;
        break;
      case COMMON_TOKENS_REFRESH_SUCCESS:
        draft.ui.busy.login = false;
        draft.ui.modal.login = false;
        draft.authInfo.isLoggedIn = true;
        draft.loginWorkflow = LOGIN_WORKFLOW_DEFAULT;
        break;
      case COMMON_DAP_LOGIN_TOKEN_EXPIRED:
        draft.loginWorkflow = LOGIN_WORKFLOW_EXPIRED_TOKEN;
        draft.authInfo.isTokenExpired = true;
        break;
      default:
        return state;
    }
  });

function setLoginInfo(draft, client, username) {
  const loginInfoCustomer = draft.loginInfo.customers.find((x) => x.name === client);
  draft.loginInfo.lastClient = client;
  draft.loginInfo.lastUsername = username;

  if (!!loginInfoCustomer) {
    const loginInfoUsername = loginInfoCustomer.usernames.find((x) => x.username === username);

    if (!loginInfoUsername) {
      loginInfoCustomer.usernames.push({ username });
    }
  } else {
    draft.loginInfo.customers.push({
      name: client,
      usernames: [{ username }],
    });
  }
}
