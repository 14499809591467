import styled from '@emotion/styled';
import { colors } from '../../common/theme/colors';

export const inputStyle = {
  padding: '8px',
  marginTop: '0',
  marginBottom: '0.5EM',
  border: `solid 1px ${colors.darkGrey}`,
};

export default styled.input(inputStyle);
