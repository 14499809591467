import { takeLatest, select, put } from 'redux-saga/effects';
import { saveAs } from 'file-saver';

import { COMMON_DOWNLOAD_PDF } from './constants';
import { selectPdfs, selectOfflineProtocols, selectIsOnline } from './selectors';
import {
  selectCurrentId,
  selectPreviousProtocolId,
} from '../../acceptance-protocol/redux/selectors';
import { has, find } from 'ramda';
import { i18n } from '../../../common/i18n-loader';
import { commonOpenInformationModal } from './openInformationModal';

export function commonDownloadPdf() {
  return {
    type: COMMON_DOWNLOAD_PDF,
  };
}

function* doDownloadPdf() {
  const pdfs = yield select(selectPdfs);
  const protocolId = yield select(selectCurrentId);
  const previousProtocolId = yield select(selectPreviousProtocolId);
  const offlineProtocols = yield select(selectOfflineProtocols);
  const isCurrentProtocolOffline = !!find((p) => p === protocolId, offlineProtocols);
  const isDeviceOnline = yield select(selectIsOnline);

  if (isCurrentProtocolOffline) {
    if (has(protocolId, pdfs)) {
      const base64 = pdfs[protocolId];

      const binary = atob(base64);
      const len = binary.length;
      const buffer = new ArrayBuffer(len);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      const blob = new Blob([view], { type: 'application/pdf' });

      saveAs(blob, `${previousProtocolId}.pdf`);
    } else {
      yield put(
        commonOpenInformationModal({
          title: i18n._('MESSAGES.PDF-NOT-AVAILABLE-TITLE'),
          message: i18n._('MESSAGES.PDF-NOT-AVAILABLE-ERROR'),
        }),
      );
    }
  } else if (!isDeviceOnline) {
    yield put(
      commonOpenInformationModal({
        title: i18n._('MESSAGES.PDF-NOT-AVAILABLE-TITLE'),
        message: i18n._('MESSAGES.PDF-NOT-AVAILABLE-OFFLINE'),
      }),
    );
  } else {
    window.open(`/api/pdf/${previousProtocolId}`, '_blank');
  }
}

export function* switchDownloadPdf() {
  yield takeLatest(COMMON_DOWNLOAD_PDF, doDownloadPdf);
}
