
const initialState = {
  user: null,
  rentalObjects: [],
  search: null,
  templates: [],

  ui: {
    busy: {
      rentalObjects: false,
      createProtocolWithoutREMReference: false,
      createProtocolWithREMReference: false,
      createProtocolFromPreviousProtocol: false,
      templates: false,
      createProtocolWithREMReferenceFromAnotherProtocol: false,
    },
    modal: {
      protocolWithoutREMReference: false,
      protocolWithREMReference: false,
      protocolFromPreviousProtocol: false,
      changeProtocolId: false,
    },
  },
};

export default initialState;
