/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

const styles = {
  color: {
    border: '1px solid #333',
    borderRadius: '100%',
    width: '50px',
    height: '50px',
  },
};

export const ImageSketcherColor = ({ color, onChange }) => {
  const handleClick = useCallback(() => {
    onChange(color);
  }, [color, onChange]);

  return <button css={styles.color} style={{ backgroundColor: color }} onClick={handleClick} />;
};
