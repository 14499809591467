import { setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { equals } from 'ramda';
import React from 'react';
import { connect } from 'react-redux';

import {
  selectProtocolLanguage,
  selectProtocolTemplateName,
} from '../features/acceptance-protocol/redux/selectors';
import {
  selectLanguage,
  selectLocales,
  selectSecurityDepotTypes,
} from '../features/common/redux/selectors';
import de from '../locales/de/messages';
import fr from '../locales/fr/messages';
import it from '../locales/it/messages';
import en from '../locales/en/messages';
import { mapSecurityDepotTypesToI18n } from './code/i18n.helper';

export const i18n = setupI18n();

export class I18nLoader extends React.Component {
  componentDidMount() {
    this.updateLanguage();
    this.updateCatalog();
  }

  shouldComponentUpdate(nextProps) {
    const { language, protocolLanguage, locales } = nextProps;

    if (language !== this.props.language || protocolLanguage !== this.props.protocolLanguage) {
      return true;
    }

    if (!equals(locales, this.props.locales)) {
      return true;
    }

    return true;
  }

  render() {
    const { children } = this.props;

    this.updateLanguage();
    this.updateCatalog();

    return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
  }

  updateLanguage() {
    const { language, protocolLanguage } = this.props;

    i18n.activate(!!protocolLanguage ? protocolLanguage.toLowerCase() : language);
  }

  updateCatalog() {
    const { locales, securityDepotTypes, currentTemplateName } = this.props;

    const mergedDe = { ...de };
    const mergedFr = { ...fr };
    const mergedIt = { ...it };
    const mergedEn = { ...en };

    if (!!locales) {
      if (!!locales.default) {
        mergedDe.messages = { ...mergedDe.messages, ...locales.default.de };
        mergedFr.messages = { ...mergedFr.messages, ...locales.default.fr };
        mergedIt.messages = { ...mergedIt.messages, ...locales.default.it };
        mergedEn.messages = { ...mergedEn.messages, ...locales.default.en };
      }

      const templateLocales = locales[currentTemplateName];

      if (!!templateLocales) {
        mergedDe.messages = { ...mergedDe.messages, ...templateLocales.de };
        mergedFr.messages = { ...mergedFr.messages, ...templateLocales.fr };
        mergedIt.messages = { ...mergedIt.messages, ...templateLocales.it };
        mergedEn.messages = { ...mergedEn.messages, ...templateLocales.en };
      }
      if (!!securityDepotTypes) {
        const securityDepotTypeMessages = mapSecurityDepotTypesToI18n(securityDepotTypes);

        mergedDe.messages = { ...mergedDe.messages, ...securityDepotTypeMessages.de };
        mergedFr.messages = { ...mergedFr.messages, ...securityDepotTypeMessages.fr };
        mergedIt.messages = { ...mergedIt.messages, ...securityDepotTypeMessages.it };
        mergedEn.messages = { ...mergedEn.messages, ...securityDepotTypeMessages.en };
      }
    }

    i18n.load({
      de: mergedDe,
      fr: mergedFr,
      it: mergedIt,
      en: mergedEn,
    });
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    language: selectLanguage(state),
    locales: selectLocales(state),
    securityDepotTypes: selectSecurityDepotTypes(state),
    protocolLanguage: selectProtocolLanguage(state),
    currentTemplateName: selectProtocolTemplateName(state),
  };
}

export default connect(mapStateToProps)(I18nLoader);
