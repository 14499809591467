import HttpStatus from 'http-status-codes';
import produce from 'immer';
import { filter, find, forEach, keys, map, values, concat } from 'ramda';
import { call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { convertArrayBufferToBlob } from '../../../common/code/convertBase64ToFile';
import { getImageReferences } from '../../../common/code/get-image-references';
import { deleteImages, getImages, saveImages } from '../../../common/code/image.repository';
import { getById, getByProperty } from '../Functions';
import { patch, post } from '../services/api';
import {
  COMMON_DELETE_OPERATION,
  COMMON_FLUSHED_QUEUES,
  COMMON_FLUSH_QUEUES,
  COMMON_FLUSH_QUEUES_ERROR,
  COMMON_IS_ONLINE,
  COMMON_PATCH_OPERATION,
  COMMON_POPPED_QUEUE,
  COMMON_POP_QUEUE,
  COMMON_POP_QUEUE_ERROR,
  COMMON_RETRY_FLUSH_QUEUES,
  COMMON_MARK_AS_ERROR,
  COMMON_UNMARK_ERROR,
  COMMON_DELETE_PROTOCOL_CONFIRM, COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS,
} from './constants';
import {
  selectHasAnyQueue,
  selectIsOnline,
  selectOfflineProtocols,
  selectProtocols,
  selectQueues,
  selectLeavingAgreements,
  selectErrorSynchronizationProtocols,
  selectIsTokenExpired,
  selectIsLoggedIn,
} from "./selectors";

export function commonRetryFlushQueues(protocolId) {
  return {
    type: COMMON_RETRY_FLUSH_QUEUES,
    payload: protocolId,
  };
}

function commonPopQueue() {
  return {
    type: COMMON_POP_QUEUE,
  };
}

function commonMarkProtocolAsError(protocolId) {
  return {
    type: COMMON_MARK_AS_ERROR,
    payload: protocolId,
  };
}

export function commonUnmarkProtocolError(protocolId) {
  return {
    type: COMMON_UNMARK_ERROR,
    payload: protocolId,
  };
}

function* doFlushQueues() {
  const isOnline = yield select(selectIsOnline);
  const hasAnyQueue = yield select(selectHasAnyQueue);
  const isLoggedIn = yield select(selectIsLoggedIn);
  const isTokenExpired = yield select(selectIsTokenExpired);

  if (isOnline && isLoggedIn && !isTokenExpired) {
    if (hasAnyQueue) {
      yield put(commonPopQueue());
    } else {
      yield put({
        type: COMMON_FLUSHED_QUEUES,
      });
    }
  }
}

export function* doPopQueue() {
  const queues = yield select(selectQueues);
  const offlineProtocols = yield select(selectOfflineProtocols);
  const errorSynchronizationProtocols = yield select(selectErrorSynchronizationProtocols);
  const onlyValidQueues = findOnlyValidQueues(queues, [
    ...offlineProtocols,
    ...errorSynchronizationProtocols,
  ]);
  // filter by non error queues
  const nonEmptyQueue = findNonEmptyQueue(onlyValidQueues);
  if (!!nonEmptyQueue) {
    const protocols = yield select(selectProtocols);
    const { protocolId, queue } = nonEmptyQueue;
    const protocol = getById(protocolId)(protocols);
    const leavingAgreements = yield select(selectLeavingAgreements);
    const leavingAgreement = getByProperty('protocolId', protocolId)(leavingAgreements);

    try {
      // get offline images and save them to backend
      const imageOperations = filter((op) => op.isImage, queue.operations);
      const imageReferences = map((op) => op.value.reference, imageOperations);

      const externalImages = yield getImages(imageReferences, true, true);

      // get only the offline images!
      const images = map(
        (imageOperation) => {
          const blob = convertArrayBufferToBlob(externalImages[imageOperation.value.reference]);
          const patchedImage = {
            ...imageOperation.value,
            data: blob,
          };

          return patchedImage;
        },
        filter((io) => !!externalImages[io.value.reference], imageOperations),
      );

      // we save only offline images on server
      yield saveImages(images, false);

      // we delete all images locally
      const protocolImageReferences = getImageReferences(protocol);
      yield deleteImages(protocolImageReferences, true, true);

      const result = { success: [], failed: [] };
      const skipUpdate = { protocol: true, leavingAgreement: true };

      const protocolQueueOperations = filter((op) => !op.isLeavingAgreement && !op.isUpdateLettingDetails, queue.operations);

      if (protocolQueueOperations.length > 0) {
        const protocolResult = yield call(patch, 'protocol', {
          protocolId,
          operations: protocolQueueOperations,
        });

        result.success = concat(result.success, protocolResult.success);
        result.failed = concat(result.failed, protocolResult.failed);

        skipUpdate.protocol = protocolResult.failed.length === 0;
      }

      const leavingAgreementQueueOperations = filter(
        (op) => op.isLeavingAgreement,
        queue.operations,
      );

      if (leavingAgreementQueueOperations.length > 0) {
        const leavingAgreementResult = yield call(patch, 'leavingagreement', {
          leavingAgreementId: leavingAgreement.id,
          operations: leavingAgreementQueueOperations,
        });

        result.success = concat(result.success, leavingAgreementResult.success);
        result.failed = concat(result.failed, leavingAgreementResult.failed);

        skipUpdate.leavingAgreement = leavingAgreementResult.failed.length === 0;
      }

      const tenancyAgreementQueueOperation = find(
        (op) => op.isUpdateLettingDetails,
        queue.operations,
      );

      if (!!tenancyAgreementQueueOperation) {
        if (tenancyAgreementQueueOperation.actionType === COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS) {
          yield post('letting/tenancyagreementdetails', {
            ProtocolId: protocolId,
            IsOldTenant: tenancyAgreementQueueOperation.isOldTenant,
            LiabilityInsuranceChecked: tenancyAgreementQueueOperation.liabilityInsuranceChecked,
          });
        }
        else {
          yield post('letting/tenancyagreementsecuritydepot', {
            ProtocolId: protocolId,
            DepositTypeCode: tenancyAgreementQueueOperation.depositTypeCode,
            DepositAmount: parseFloat(tenancyAgreementQueueOperation.depositAmount),
            PaidAmount: parseFloat(tenancyAgreementQueueOperation.paidAmount),
            IsOldTenant: tenancyAgreementQueueOperation.isOldTenant,
          });
        }

        result.success = concat(result.success, [ tenancyAgreementQueueOperation.id ]);
      }

      if (result.failed.length === 0) {
        yield put({
          type: COMMON_POPPED_QUEUE,
          payload: { protocolId, result },
        });
      } else {
        yield put({
          type: COMMON_POP_QUEUE_ERROR,
          payload: {
            error: `Patch operation failed ${result.failed}`,
            protocolId,
            skipUpdate,
            toRemove: result.success,
          },
        });
      }
    } catch (error) {
      if (error.status === HttpStatus.BAD_REQUEST) {
        if (!!find((x) => error.type, ['NotFoundError', 'AlreadyCompletedError'])) {
          yield put({
            type: COMMON_DELETE_PROTOCOL_CONFIRM,
            payload: {
              error: error.message,
              protocolId,
            },
          });
        }
        yield put({
          type: COMMON_POP_QUEUE_ERROR,
          payload: { error: error.message, protocolId, skipUpdate: false, toRemove: [] },
        });
      } else {
        if (queue.retry <= 2) {
          yield put(commonRetryFlushQueues(protocolId));
        } else {
          yield put({
            type: COMMON_POP_QUEUE_ERROR,
            payload: {
              error: `Max retries reached for patching`,
              protocolId,
              skipUpdate: false,
              toRemove: [],
            },
          });
        }
      }
    }
  } else {
    yield put({
      type: COMMON_FLUSHED_QUEUES,
    });
  }
}

function* doMarkProtocolAsError({ payload: { protocolId, skipUpdate } }) {
  if (!skipUpdate || !skipUpdate.protocol) {
    yield put(commonMarkProtocolAsError(protocolId));
  }
  if (!skipUpdate || !skipUpdate.leavingAgreement) {
    yield put(commonMarkProtocolAsError(protocolId));
  }
}

export function* switchFlushQueues() {
  yield debounce(2000, [COMMON_PATCH_OPERATION, COMMON_DELETE_OPERATION], doFlushQueues);
  yield debounce(10000, COMMON_RETRY_FLUSH_QUEUES, doFlushQueues);
  yield takeLatest(
    [COMMON_IS_ONLINE, COMMON_POPPED_QUEUE, COMMON_FLUSH_QUEUES, COMMON_UNMARK_ERROR],
    doFlushQueues,
  );
  yield takeLatest(COMMON_POP_QUEUE_ERROR, doMarkProtocolAsError);
  yield takeLatest(COMMON_POP_QUEUE, doPopQueue);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_POP_QUEUE:
        draft.ui.busy.queue = true;
        break;
      case COMMON_RETRY_FLUSH_QUEUES: {
        draft.ui.busy.queue = false;

        const protocolQueue = draft.queues[action.payload];
        protocolQueue.retry++;
        break;
      }
      case COMMON_POPPED_QUEUE: {
        const {
          payload: {
            protocolId,
            result: { success, failed },
          },
        } = action;

        const idsToBeRemoved = [...success, ...failed];

        const protocolQueue = draft.queues[protocolId];

        removeOperations(protocolQueue, idsToBeRemoved);

        protocolQueue.retry = 0;

        if (protocolQueue.operations.length === 0) {
          delete draft.queues[protocolId];
        }

        break;
      }
      case COMMON_POP_QUEUE_ERROR:
        draft.ui.busy.queue = false;

        const {
          payload: { protocolId, toRemove },
        } = action;

        const protocolQueue = draft.queues[protocolId];

        removeOperations(protocolQueue, toRemove);

        break;
      case COMMON_FLUSH_QUEUES_ERROR:
      case COMMON_FLUSHED_QUEUES:
        draft.ui.busy.queue = false;
        break;
      case COMMON_MARK_AS_ERROR:
        if (draft.errorSynchronizationProtocols.indexOf(action.payload) === -1) {
          draft.errorSynchronizationProtocols.push(action.payload);
        }
        break;
      case COMMON_UNMARK_ERROR:
        const index = draft.errorSynchronizationProtocols.indexOf(action.payload);
        if (index !== -1) {
          draft.errorSynchronizationProtocols.splice(index, 1);
        }
        break;
      default:
        return state;
    }
  });

function findNonEmptyQueue(queues) {
  const queueKeys = keys(queues);
  const queueValues = values(queues);
  const filterEmptyQueues = filter((q) => q.operations.length > 0, queueValues);
  const lowestRetry = Math.min.apply(
    window,
    map((q) => q.retry, filterEmptyQueues),
  );
  const lowestNonEmptyQueue = find((q) => q.retry === lowestRetry, filterEmptyQueues);
  const lowestNonEmptyQueueKey = find((key) => queues[key] === lowestNonEmptyQueue, queueKeys);
  if (!!lowestNonEmptyQueue) {
    return { protocolId: lowestNonEmptyQueueKey, queue: lowestNonEmptyQueue };
  }
  return null;
}

function findOnlyValidQueues(queues, toExcludeProtocolIds) {
  const result = {};
  for (const queueKey in queues) {
    if (toExcludeProtocolIds.indexOf(queueKey) === -1) {
      result[queueKey] = queues[queueKey];
    }
  }
  return result;
}

function removeOperations(protocolQueue, toRemoveIds) {
  if (!protocolQueue) {
    return;
  }

  const toBeRemoveds = filter(
    (queue) => toRemoveIds.indexOf(queue.id) !== -1,
    protocolQueue.operations,
  );

  forEach((toBeRemoved) => {
    const index = protocolQueue.operations.indexOf(toBeRemoved);
    if (index > -1) {
      protocolQueue.operations.splice(index, 1);
    }
  }, toBeRemoveds);
}
