import produce from 'immer';

import { MAIN_STEP_OPEN_DELETE_MODAL } from './constants';

export function mainStepOpenDeleteModal() {
  return {
    type: MAIN_STEP_OPEN_DELETE_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MAIN_STEP_OPEN_DELETE_MODAL:
        draft.ui.modal.delete = true;
        break;
      default:
        return state;
    }
  });
