import produce from 'immer';

import { HOME_CLOSE_PROTOCOL_WITH_REM_REFERENCE_MODAL } from './constants';

export function homeCloseProtocolWithREMReferenceModal() {
  return { type: HOME_CLOSE_PROTOCOL_WITH_REM_REFERENCE_MODAL };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CLOSE_PROTOCOL_WITH_REM_REFERENCE_MODAL:
        draft.ui.modal.protocolWithREMReference = false;
        break;
      default:
        return state;
    }
  });
