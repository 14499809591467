import { LOCATION_CHANGE } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';

import { extractNames } from '../../../../common/code/extractNames';
import { commonPatchOperation, commonDeleteOperation } from '../../../common/redux/actions';
import {
  selectCurrentProtocol,
  selectIndexedBasedataInput,
  selectProtocolIdRoute,
} from '../../redux/selectors';
import { getByProperty } from '../../../common/Functions';

function checkForUpdate(oldValue, value, fieldname) {
  const emailsValue = !!value ? extractNames(value.fields, fieldname) : null;
  return {
    isUpdated: (!oldValue && !emailsValue) || (!!oldValue && oldValue.emails === emailsValue),
    value: emailsValue,
  };
}

export function* doUpdateEmails() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route) {
    if (!!route.params.id && route.params.step && route.params.step === 'send-step') {
      const protocol = yield select(selectCurrentProtocol);

      if (!protocol) {
        return;
      }

      const indexedBasedataInput = yield select(selectIndexedBasedataInput);

      for (const key in indexedBasedataInput) {
        if (key.includes('RentalPerson')) {
          const rentalPerson = indexedBasedataInput[key];
          const tenantKey = key.replace('RentalPerson', 'Tenant')
          const tenant = getByProperty('type', tenantKey)(protocol.input.sendStep);

          const tenantResult = checkForUpdate(tenant, rentalPerson, key.includes('old') ? 'VormieterEmail' : 'NachmieterEmail');

          if (!tenantResult.isUpdated) {
            const firstName = extractNames(rentalPerson.fields, key.includes('old') ? 'VormieterName' : 'NachmieterName')
            const lastName = extractNames(rentalPerson.fields, key.includes('old') ? 'VormieterVorname' : 'NachmieterVorname')
            
            yield put(
              !!tenantResult.value 
                ? commonPatchOperation(protocol.id, `sendStep/[${tenantKey}]`, {
                    emails: tenantResult.value,
                    fullName: `${firstName || ''} ${lastName || ''}`.trim(),
                    ...(!tenantResult.value && { send: false, sendAroov: false }),
                  })
                : commonDeleteOperation(protocol.id, `sendStep/[${tenantKey}]`),
            );
          }
        }
      }
    }
  }
}

export function* switchUpdateEmails() {
  yield takeLatest(LOCATION_CHANGE, doUpdateEmails);
}
