import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';

import { get } from '../../common/services/api';
import {
  MIGRATION_GET_MIGRATION,
  MIGRATION_GET_MIGRATION_SUCCESS,
  MIGRATION_GET_MIGRATION_ERROR,
} from './constants';

export function migrationGetMigration() {
  return {
    type: MIGRATION_GET_MIGRATION,
  };
}

function* doGetMigration() {
  try {
    const migration = yield call(get, 'migration');
    yield put({
      type: MIGRATION_GET_MIGRATION_SUCCESS,
      payload: migration,
    });
  } catch (error) {
    yield put({
      type: MIGRATION_GET_MIGRATION_ERROR,
      payload: error.message,
    });
  }
}

export function* switchGetMigration() {
  yield takeLatest(MIGRATION_GET_MIGRATION, doGetMigration);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_GET_MIGRATION:
        draft.ui.busy.loading = true;
        break;
      case MIGRATION_GET_MIGRATION_SUCCESS:
        draft.ui.busy.loading = false;
        draft.migration = action.payload;
        draft.importMigration = action.payload.results;
        draft.selectedTemplate = action.payload.items && action.payload.items[0].name;
        break;
      case MIGRATION_GET_MIGRATION_ERROR:
        draft.ui.busy.loading = false;
        break;
      default:
        return state;
    }
  });
