//This compnents shows the upload and download button for a test protocol to be injected into the client
/** @jsxImportSource @emotion/react */

import { useState } from 'react';

import Modal from '../common/Modal';
import Dropzone from './dropZone';
import fileDownload from 'js-file-download';

import { routerActions } from 'connected-react-router';
import { migrationRoutes } from '../migration/routes';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectCurrentProtocol } from '../acceptance-protocol/redux/selectors';
import * as commonActions from '../common/redux/actions';
import { colors } from '../../common/theme/colors';

const style = {
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '0.5em',
    button: {
      background: colors.brand_dark,
      color: '#fff',
      padding: '4px 12px',
      borderRadius: '4px',
    },
  },
};

const ProtocolIO = (props) => {
  const {
    protocol,
    actions: { commonInjectProtocol, push },
  } = props;

  const [showModal, setShowModal] = useState(false);
  const [fileName, setFileName] = useState('no file.');

  function handleOnUpload() {
    setFileName('no file.');
    setShowModal(true);
  }

  function handleOnMigration() {
    push(migrationRoutes.migration);
  }

  function handleOnClose() {
    setShowModal(false);
  }

  function hanldeOnDownload() {
    if (protocol !== null) {
      fileDownload(JSON.stringify(protocol, null, '\t'), protocol.id + '.json');
    } else {
      alert('No current protocol to download found. Please open a protocol and try again.');
    }
  }

  function handleFilesAdded(files) {
    if (files[0] !== undefined) {
      setFileName(files[0].name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const protocol = JSON.parse(reader.result);
        commonInjectProtocol(protocol);
      };
      reader.readAsText(files[0]);
    }
  }

  return (
    <div>
      <Modal isOpen={showModal} title={'Upload a protocol'} onClose={handleOnClose}>
        <Dropzone onFilesAdded={handleFilesAdded} />
        <div>
          <label>{fileName}</label>
        </div>
      </Modal>
      <div css={style.container}>
        <button onClick={handleOnMigration}>Migration</button>
        <button onClick={handleOnUpload}>Upload</button>
        <button onClick={hanldeOnDownload}>Download</button>
      </div>
    </div>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    protocol: selectCurrentProtocol(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...commonActions, ...routerActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolIO);
