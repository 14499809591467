import produce from 'immer';

import { reducer as mainStepOpenDeleteModal } from './mainStepOpenDeleteModal';
import { reducer as mainStepCloseDeleteModal } from './mainStepCloseDeleteModal';
import { reducer as mainStepOpenAssignmentModal } from './mainStepOpenAssignmentModal';
import { reducer as mainStepCloseAssignmentModal } from './mainStepCloseAssignmentModal';
import initialState from './initialState';

const reducers = [
  mainStepOpenDeleteModal,
  mainStepCloseDeleteModal,
  mainStepOpenAssignmentModal,
  mainStepCloseAssignmentModal,
];

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      // Handle cross-topic actions here
      default:
        break;
    }
    return reducers.reduce((s, r) => r(s, action), draft);
  });

export default reducer;
