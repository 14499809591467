/** @jsxImportSource @emotion/react */
import './Spinner.scss';

import { keyframes } from '@emotion/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { SpinnerIcon } from './SpinnerIcon';

const blurry = keyframes`
  0% {
    filter: grayscale(0) blur(0px);
  }
  100% {
    filter: grayscale(1) blur(2px);
  }
`;

const styles = {
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  blocker: {
    width: '100%',
    height: '100%',
    '&.read-only': {
      animation: `${blurry} 0.5s forwards`,
      '& > *': {
        pointerEvents: 'none',
      },
    },
    '&.has-spinner': {
      cursor: 'wait',
      animation: `${blurry} 0.5s forwards`,
      '& > *': {
        pointerEvents: 'none',
      },
    },
  },
  spinner: {
    cursor: 'wait',
    position: 'absolute',
    left: 'calc(50% - 16px)',
    top: 'calc(50% - 16px)',
  },
};

const Spinner = (props) => {
  const { children, show, isBlocked } = props;

  return (
    <div css={styles.container}>
      <div css={styles.blocker} className={classNames({ 'read-only': isBlocked }, { 'has-spinner': show })}>
        {children}
      </div>
      {show && (
        <div css={styles.spinner}>
          <SpinnerIcon />
        </div>
      )}
    </div>
  );
};

Spinner.propTypes = {
  show: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Spinner.defaultProps = {
  show: false,
};

export default Spinner;
