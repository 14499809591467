import { push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';
import { head } from 'ramda';

import { selectIndexedComponentsTemplate } from '../../redux/selectors';
import { FILL_STEP_NAVIGATE_COMPONENT } from './constants';
import { fillStepSelectElement } from './fillStepSelectElement';
import { acceptanceProtocolRoutes } from '../../routes';

export function fillStepNavigateComponent(protocolId, componentId) {
  return {
    type: FILL_STEP_NAVIGATE_COMPONENT,
    payload: { protocolId, componentId },
  };
}

function* doNavigateComponent({ payload: { protocolId, componentId } }) {
  const components = yield select(selectIndexedComponentsTemplate);
  const component = components[componentId];

  yield put(push(acceptanceProtocolRoutes.fillStep(protocolId)));

  yield put(
    fillStepSelectElement({
      componentId: componentId,
      elementId: !!component && !!component.elements ? head(component.elements) : null,
      type: 'component',
    }),
  );
}

export function* switchNavigateComponent() {
  yield takeLatest(FILL_STEP_NAVIGATE_COMPONENT, doNavigateComponent);
}
