import produce from 'immer';

import { HOME_OPEN_PROTOCOL_WITHOUT_REM_REFERENCE_MODAL } from './constants';

export function homeOpenProtocolWithoutREMReferenceModal() {
  return { type: HOME_OPEN_PROTOCOL_WITHOUT_REM_REFERENCE_MODAL };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_OPEN_PROTOCOL_WITHOUT_REM_REFERENCE_MODAL:
        draft.ui.modal.protocolWithoutREMReference = true;
        break;
      default:
        return state;
    }
  });
