/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import * as actions from './redux/actions';
import * as commonActions from '../common/redux/actions';
import {
  selectRentalObjects,
  selectSearch,
  selectTemplates,
  selectIsProtocolWithREMReferenceModalOpen,
  selectIsGetTemplatesBusy,
  selectIsGetRentalObjectBusy,
  selectCreateProtocolWithRemReferenceBusy,
  selectIsProtocolFromPreviousProtocolModalOpen,
  selectIsProtocolWithoutREMReferenceWithPreviousBusy,
  selectCreateProtocolWithREMReferenceFromAnotherProtocol,
} from './redux/selectors';
import { SearchRentalObjects } from './SearchRentalObjects';
import {
  selectIsOnline,
  selectHasInitialAcceptance,
  selectLanguage,
} from '../common/redux/selectors';

export class SearchPage extends Component {
  static propTypes = {
    isOnline: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired,
    search: PropTypes.string,
    rentalObjects: PropTypes.array,
    onSearch: PropTypes.func,
    onOpenNotImplemented: PropTypes.func,
    isProtocolWithREMReferenceModalOpen: PropTypes.bool,
  };

  render() {
    const {
      isOnline,
      language,
      search,
      rentalObjects,
      templates,
      isProtocolWithREMReferenceModalOpen,
      isGetTemplatesBusy,
      isGetRentalObjectsBusy,
      isCreateProtocolWithRemReferenceBusy,
      isProtocolFromPreviousProtocolModalOpen,
      isCreateProtocolFromPreviousProtocolBusy,
      isCreateProtocolWitREMReferenceFromAnotherProtocolBusy,
      hasInitialAcceptance,
      actions: {
        homeSearchRentalObjects,
        homeOpenProtocolWithREMReferenceModal,
        homeCloseProtocolWithREMReferenceModal,
        homeCreateProtocolWithREMReference,
        homeOpenProtocolFromPreviousProtocolModal,
        homeCloseProtocolFromPreviousProtocolModal,
        homeCreateProtocolFromPreviousProtocol,
        homeCreateProtocolWithREMReferenceFromAnotherProtocol,
      },
    } = this.props;

    return (
      <SearchRentalObjects
        isOnline={isOnline}
        language={language}
        search={search}
        isBusyTemplates={isGetTemplatesBusy}
        isBusyRentalObjects={isGetRentalObjectsBusy}
        isBusyCreateProtocolWithREMReference={isCreateProtocolWithRemReferenceBusy}
        rentalObjects={rentalObjects}
        onSearch={homeSearchRentalObjects}
        templates={templates}
        onOpenProtocolWithREMReferenceModal={homeOpenProtocolWithREMReferenceModal}
        onCloseProtocolWithREMReferenceModal={homeCloseProtocolWithREMReferenceModal}
        onOpenProtocolFromPreviousProtocolModal={homeOpenProtocolFromPreviousProtocolModal}
        isCreateWithREMReferenceModalOpen={isProtocolWithREMReferenceModalOpen}
        isCreateProtocolFromPreviousProtocolModalOpen={isProtocolFromPreviousProtocolModalOpen}
        onCloseProtocolFromPreviousProtocolModal={homeCloseProtocolFromPreviousProtocolModal}
        onCreateProtocolWithREMReference={homeCreateProtocolWithREMReference}
        onCreateProtocolFromPreviousProtocol={homeCreateProtocolFromPreviousProtocol}
        createProtocolFromPreviousProtocolBusy={isCreateProtocolFromPreviousProtocolBusy}
        isCreateProtocolWitREMReferenceFromAnotherProtocolBusy={
          isCreateProtocolWitREMReferenceFromAnotherProtocolBusy
        }
        onCreateProtocolWithREMReferenceFromAnotherProtocol={
          homeCreateProtocolWithREMReferenceFromAnotherProtocol
        }
        hasInitialAcceptance={hasInitialAcceptance}
      />
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    rentalObjects: selectRentalObjects(state),
    search: selectSearch(state),
    isOnline: selectIsOnline(state),
    language: selectLanguage(state),
    templates: selectTemplates(state),
    isProtocolWithREMReferenceModalOpen: selectIsProtocolWithREMReferenceModalOpen(state),
    isProtocolFromPreviousProtocolModalOpen: selectIsProtocolFromPreviousProtocolModalOpen(state),
    isGetTemplatesBusy: selectIsGetTemplatesBusy(state),
    isGetRentalObjectsBusy: selectIsGetRentalObjectBusy(state),
    isCreateProtocolWithRemReferenceBusy: selectCreateProtocolWithRemReferenceBusy(state),
    isCreateProtocolFromPreviousProtocolBusy:
      selectIsProtocolWithoutREMReferenceWithPreviousBusy(state),
    isCreateProtocolWitREMReferenceFromAnotherProtocolBusy:
      selectCreateProtocolWithREMReferenceFromAnotherProtocol(state),
    hasInitialAcceptance: selectHasInitialAcceptance(state),
  };
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
