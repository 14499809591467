import { push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';

import { commonPatchOperation } from './actions';
import {
  selectCurrentId,
  selectIsLeavingAgreementStep,
} from '../../acceptance-protocol/redux/selectors';
import { acceptanceProtocolRoutes } from '../../acceptance-protocol/routes';
import { COMMON_CONFIRM_SIGN, COMMON_CONFIRMED_SIGN } from './constants';

export function commonConfirmSign(signerId, data) {
  return {
    type: COMMON_CONFIRM_SIGN,
    payload: { signerId, data },
  };
}

export function commonConfirmedSign() {
  return {
    type: COMMON_CONFIRMED_SIGN,
  };
}

function* doConfirmSign({ payload: { signerId, data } }) {
  const protocolId = yield select(selectCurrentId);
  const isLeavingAgreementStep = yield select(selectIsLeavingAgreementStep);

  yield put(
    commonPatchOperation(
      protocolId,
      `signatures/${signerId}`,
      {
        data,
        signatureRefused: false,
      },
      isLeavingAgreementStep,
    ),
  );

  yield put(commonConfirmedSign());

  const route = isLeavingAgreementStep
    ? acceptanceProtocolRoutes.leavingAgreementStep
    : acceptanceProtocolRoutes.signStep;

  yield put(push(route(protocolId)));
}

export function* switchConfirmSign() {
  yield takeLatest(COMMON_CONFIRM_SIGN, doConfirmSign);
}
