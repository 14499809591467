/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../common/Button';
import Modal from './Modal';
import { getImages } from '../../common/code/image.repository';
import { ImageViewer } from '../acceptance-protocol/fill-step/ImageViewer';
import { Icon } from './Icon';
import { colors } from '../../common/theme/colors';
import { SpinnerIcon } from './SpinnerIcon';

const styles = {
  container: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateRows: 'auto min-content',
  },
  scrollZone: { overflow: 'auto' },
  content: {
    maxHeight: '480px',
    overflow: 'auto',
    margin: 'auto',
    alignSelf: 'center',
    textAlign: 'center',
  },
  image: {
    height: '470px',
  },
  buttons: {
    display: 'grid',
    gridGap: '0 1em',
    gridTemplateColumns: 'min-content min-content',
    justifyContent: 'right',
  },
  errorIcon: {
    display: 'grid',
    color: colors.red,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
  },
  spinnerIcon: {
    margin: '0 auto',
  },
};

const ViewImageModal = (props) => {
  const { isOpen, image, isOfflineProtocol, onClose, onCancel, onDelete } = props;

  const [externalImage, setExternalImage] = useState(null);
  const [isBusy, setIsBusy] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleDelete = useCallback(() => {
    if (!!image) {
      onDelete(image);
    }
  }, [image, onDelete]);

  useEffect(() => {
    setIsBusy(true);
    setIsError(false);
    async function asyncGetImage() {
      if (!!image) {
        try {
          const externalImages = await getImages([image.reference], isOfflineProtocol);

          setExternalImage(externalImages[image.reference]);
          setIsBusy(false);
        } catch (error) {
          console.error(error);
          setIsBusy(false);
          setIsError(true);
        }
      }
    }

    asyncGetImage();
  }, [image, isOfflineProtocol]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div css={styles.container}>
        <div css={styles.scrollZone}>
          {isError ? (
            <div css={styles.errorIcon}>
              <Icon icon={'times'} size="4x" />
            </div>
          ) : isBusy ? (
            <SpinnerIcon style={styles.spinnerIcon} />
          ) : (
            !!image &&
            !!externalImage && (
              <div css={styles.content}>
                <div css={styles.image}>
                  <ImageViewer image={image} imageData={externalImage} />
                </div>
              </div>
            )
          )}
        </div>
        <div css={styles.buttons}>
          <Button secondary onClick={handleDelete}>
            <Trans>BUTTONS.DELETE</Trans>
          </Button>
          <Button default onClick={onCancel}>
            <Trans>BUTTONS.CANCEL</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

ViewImageModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ViewImageModal.defaultProps = {
  isOpen: false,
};

export default ViewImageModal;
