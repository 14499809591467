import produce from 'immer';

import { FILL_STEP_CLOSE_VIEW_IMAGE_MODAL } from './constants';

export function fillStepCloseViewImageModal() {
  return {
    type: FILL_STEP_CLOSE_VIEW_IMAGE_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_CLOSE_VIEW_IMAGE_MODAL:
        draft.ui.modal.viewImage = false;
        draft.currentViewImage = null;
        break;
      default:
        return state;
    }
  });
