import produce from 'immer';

import { HOME_CLOSE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL } from './constants';
export function homeCloseProtocolFromPreviousProtocolModal() {
  return { type: HOME_CLOSE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CLOSE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_MODAL:
        draft.ui.modal.protocolFromPreviousProtocol = false;
        break;
      default:
        return state;
    }
  });
