import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';

import { selectIsLeavingAgreementStep } from '../../../acceptance-protocol/redux/selectors';

import {
  LEAVING_AGREEMENT_STEP_SCROLL_TO_SIGN,
  LEAVING_AGREEMENT_STEP_SCROLLED,
} from './constants';

import {
  COMMON_CONFIRM_SIGN,
  COMMON_ABORT_AND_CLOSE_SIGN_PAGE,
  COMMON_REJECT_SIGN,
} from '../../../common/redux/constants';

export function leavingAgreementStepScrollToSign() {
  return {
    type: LEAVING_AGREEMENT_STEP_SCROLL_TO_SIGN,
  };
}
export function leavingAgreementStepScrolled() {
  return {
    type: LEAVING_AGREEMENT_STEP_SCROLLED,
  };
}

function* doLeavingAgreementStepAfterSignOperation() {
  const isLeavingAgreementStep = yield select(selectIsLeavingAgreementStep);

  if (isLeavingAgreementStep) {
    yield put(leavingAgreementStepScrollToSign());
  }
}

export function* switchLeavingAgreementStepAfterSignOperation() {
  yield takeLatest(
    [COMMON_CONFIRM_SIGN, COMMON_ABORT_AND_CLOSE_SIGN_PAGE, COMMON_REJECT_SIGN],
    doLeavingAgreementStepAfterSignOperation,
  );
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case LEAVING_AGREEMENT_STEP_SCROLL_TO_SIGN:
        draft.scrollToSign = true;
        break;
      case LEAVING_AGREEMENT_STEP_SCROLLED:
        draft.scrollToSign = false;
        break;
      default:
        return state;
    }
  });
