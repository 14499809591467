import produce from 'immer';

import { COMMON_SET_VERSION } from './constants';

export function commonSetVersion(version) {
  return {
    type: COMMON_SET_VERSION,
    payload: version,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_SET_VERSION:
        draft.version = action.payload;

        if (action.payload.endsWith('dev') || action.payload.endsWith('test')) {
          draft.languages = ['de', 'fr', 'it', 'en'];
        } else {
          draft.languages = ['de', 'fr', 'it'];
        }
        break;
      default:
        return state;
    }
  });
