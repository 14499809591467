/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { colors } from '../../common/theme/colors';
import { i18n } from '../../common/i18n-loader';

export const styles = {
  wrapper: {
    background: colors.dark,
    width: '100%',
    height: '100%',
    padding: '64px 8vw',
  },
  wrapperFullWidth: {
    padding: '12px 0',
  },
  container: (containerColors) => ({
    fontFamily: '"Rubik", sans-serif !important',
    padding: '20px 24px 24px',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '32px',
    border: `2px solid ${containerColors.color}`,
    boxShadow: `0 0 0 6px  ${containerColors.transparent}`,
    background: colors.darker,
    borderRadius: '16px',
    color: '#fff',
  }),
  headerContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '16px',
  },
  title: {
    fontSize: '32px',
  },
  subtitle: {
    fontSize: '18px',
    color: colors.grey80,
    maxWidth: '50ch',
  },
  buttonContainer: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'min-content',
    gridColumnGap: '16px',
  },
  button: {
    borderRadius: '4px',
    padding: '8px 16px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    whiteSpace: 'nowrap',

    border: '2px solid transparent',
    color: '#fff',
    background: '#000',
  },
  buttonPrimary: {
    borderColor: colors.brand,
    background: 'rgba(49, 175, 225, 0.16)',
    color: '#fff',
  },
  buttonSecondary: {
    borderColor: 'transparent',
    background: 'transparent',
    color: colors.brand,
  },
};

const types = {
  info: {
    color: colors.brand,
    transparent: 'rgba(49, 175, 225, 0.16)',
  },
  error: {
    color: colors.errors,
    transparent: 'rgba(252, 84, 83, 0.16)',
  },
};

function checkIfTranslated() {
  // Access the root HTML element
  const rootHtml = document.documentElement;

  // Check for 'translated-ltr' class and 'en-US' language
  return rootHtml.classList.contains('translated-ltr') || rootHtml.lang === 'en-US';
}

export const ErrorBoundaryTemplate = (props) => {
  const {
    title = 'Something went unexpectedly wrong.',
    subtitle = 'We will work at our best to fix this error.',
    showGoBackButton = true,
    fullWidth = false,
    type = 'error',
  } = props;

  const [isTranslated, setIsTranslated] = useState(false);

  useEffect(() => {
    // Check if the page is translated when the component mounts
    const translated = checkIfTranslated();
    setIsTranslated(translated);

    // Optionally, set up a MutationObserver to detect changes in classList if dynamic translation can occur
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        // Check for added nodes that might be part of the translation
        if (mutation.addedNodes && mutation.addedNodes.length > 0) {
          for (const node of mutation.addedNodes) {
            if (node instanceof HTMLElement) {
              // Recursively check if the added node or its descendants contain translated content
              if (hasTranslatedContent(node)) {
                setIsTranslated(true);
                return; // No need to check further if translated content is found
              }
            }
          }
        }
      });
    });

    // Observe the entire document body for changes
    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up the observer on component unmount
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helper function to recursively check for translated content within an element
  function hasTranslatedContent(element) {
    try {
      // Check if the element itself has translation-related attributes or classes
      if (
        element.hasAttribute('translate') ||
        (element.lang && !['de', 'it', 'fr', 'en'].includes(element.lang))
      ) {
        return true;
      }

      // Recursively check child elements
      for (const child of element.children) {
        if (hasTranslatedContent(child)) {
          return true;
        }
      }

      return false;
    } catch (error) {
      console.error('Error while checking for translated content:', error);
      return false; // Assume not translated in case of an error
    }
  }

  return (
    <div css={{ ...styles.wrapper, ...(!!fullWidth && styles.wrapperAdaptiveWidth) }}>
      <div css={styles.container(types[type])}>
        {isTranslated ? (
          <>
            <div css={styles.subtitle}>{i18n._('MESSAGES.AUTO-TRANSLATION-ERROR')}</div>
            <div css={styles.buttonContainer}>
              <button
                css={{ ...styles.button, ...styles.buttonSecondary }}
                onClick={() => window.location.reload()}
              >
                {i18n._('LABELS.RELOAD')}
              </button>
            </div>
          </>
        ) : (
          <>
            <div css={styles.headerContainer}>
              <div css={styles.title}>{title}</div>
              <div css={styles.subtitle}>{subtitle}</div>
            </div>
            <div css={styles.buttonContainer}>
              {showGoBackButton && (
                <a css={{ ...styles.button, ...styles.buttonSecondary }} href="/">
                  Go back home
                </a>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
