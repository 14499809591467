import localforage from 'localforage';
import { keys, omit, pick, reduce, without } from 'ramda';
import { deleteById, downloadFiles, uploadFile } from '../../features/common/services/api';
import { blobToArrayBuffer, convertArrayBufferToBase64 } from './convertBase64ToFile';
import { unzipImages } from './zip.helper';

let cachedImages = {};

const updateCacheReference = (reference) => (image) =>
  (cachedImages[reference] = convertArrayBufferToBase64(image));

const globalCache = () => {
  return {
    add: updateCacheReference,
    get: () => cachedImages,
  };
};

const localCache = () => {
  const cache = {};
  return {
    add: (key) => (value) => (cache[key] = value),
    get: () => cache,
  };
};

export const saveImages = async (images, asOffline) => {
  const promises = [];
  for (const image of images) {
    const arrayBuffer = await blobToArrayBuffer(image.data);
    if (asOffline) {
      promises.push(localforage.setItem(image.reference, arrayBuffer));
    } else {
      promises.push(uploadFile(`image/${image.reference}`, image.data));
    }
    updateCacheReference(image.reference)(arrayBuffer);
  }
  return Promise.all(promises);
};

export const saveImage = async (image) => {
  await localforage.setItem(image.reference, image.data);
  updateCacheReference(image.reference)(image.data);
};

export const getImages = async (references, asOffline, useLocalCache = false) => {
  const cache = !!useLocalCache ? localCache() : globalCache();
  const promises = [];
  const referencesToDownload = without(keys(cache.get()), references);

  if (asOffline) {
    for (const reference of referencesToDownload) {
      promises.push(localforage.getItem(reference).then(cache.add(reference)));
    }
  } else {
    promises.push(
      downloadFiles(`image`, referencesToDownload).then(async (images) => {
        const unzippedImages = await unzipImages(images);

        for (const image of unzippedImages) {
          cache.add(image.reference)(image.data);
        }
      }),
    );
  }
  await Promise.all(promises);
  return pick(references, cache.get());
};

export const deleteImages = async (references, asOffline, preserveCachedImages = false) => {
  const promises = [];
  for (const reference of references) {
    if (asOffline) {
      promises.push(localforage.removeItem(reference));
    } else {
      promises.push(deleteById('image', reference));
    }
  }
  if (!preserveCachedImages) {
    cachedImages = omit(references, cachedImages);
  }
  return Promise.all(promises);
};

const removeKeys = async (omitKeys) => {
  const allKeys = await localforage.keys();
  return reduce(
    (promises, key) => {
      if (key !== 'persist:common') {
        promises.push(localforage.removeItem(key));
      }
      return promises;
    },
    [],
    !!omitKeys ? without(omitKeys, allKeys) : allKeys,
  );
};

export const purgeAllOtherImages = async (references) => {
  const promises = await removeKeys(references);
  cachedImages = omit(references, cachedImages);
  return Promise.all(promises);
};

export const purgeAllImages = async () => {
  const promises = await removeKeys();
  cachedImages = {};
  return Promise.all(promises);
};
