import React from 'react';
import { Sentry } from '../../common/sentry-loader';
import { ErrorBoundaryTemplate } from './ErrorBoundaryTemplate';

export default class PageNotFound extends React.Component {
  handleShowReportDialog() {
    const eventId = Sentry.captureException('Page not found');
    Sentry.showReportDialog({ eventId: eventId });
  }

  render() {
    return (
      <ErrorBoundaryTemplate
        title="Ugh, the page you're looking for does not exists!"
        subtitle="Let us know what brought you here."
        onReportFeedback={this.handleShowReportDialog}
      />
    );
  }
}
