/** @jsxImportSource @emotion/react */
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { i18n } from '../../../common/i18n-loader';
import { colors } from '../../../common/theme/colors';
import { variables } from '../../../common/theme/variables';
import Input from '../../common/Input';
import { ToggleButton } from '../../common/ToggleSwitch';
import { Icon } from "../../common/Icon";

const styles = {
  email: (hasErrors) => ({
    display: 'grid',
    gridTemplateColumns: `1fr min-content`,
    gridTemplateRows: `min-content min-content ${hasErrors ? 'min-content' : ''}`,
    gridTemplateAreas: `"label buttons" "field buttons" ${hasErrors ? '"error buttons"' : ''}`,
    rowGap: '16px',
    columnGap: '24px',

    alignItems: 'center',

    [variables.breakpoints.md]: {
      gridTemplateColumns: `1fr 3fr min-content`,
      gridTemplateRows: `min-content ${hasErrors ? 'min-content' : ''}`,
      gridTemplateAreas: `"label field buttons" ${hasErrors ? '". error ."' : ''}`,
      rowGap: '12px',
      columnGap: '24px',
    },

    padding: '16px 24px',

    borderRight: `solid 1px ${colors.lighterGrey}`,
    borderLeft: `solid 1px ${colors.lighterGrey}`,
    borderBottom: `solid 1px ${colors.lighterGrey}`,
  }),
  label: {
    gridArea: 'label',

    [variables.breakpoints.md]: {
      borderRight: 'none',
    },

    '& > label': {
      fontWeight: 'bold',
      wordBreak: 'break-all',
    },
  },
  field: {
    gridArea: 'field',

    '& > input': {
      width: '100%',
      margin: '0',
    },

    '& > input.has-error': {
      border: `2px solid ${colors.error}`,
    },

    '& > input.is-readonly': {
      backgroundColor: colors.lighterGrey,
    },
  },
  buttons: {
    gridArea: 'buttons',
    display: 'flex',
    flexFlow: 'column nowrap',
    rowGap: '8px',

    [variables.breakpoints.lg]: {
      flexFlow: 'row nowrap',
      columnGap: '12px',
    },
  },
  error: {
    gridArea: 'error',

    padding: '0em 1em 0em 1em',
    alignItems: 'center',

    '& > div': {
      width: '100%',
      wordBreak: 'break-all',
      fontSize: '0.8rem',
      minHeight: '0.8rem',
      color: colors.error,
      fontWeight: 'bold',
      textAlign: 'right',
    },
  },
  toLowerCase: {
    textTransform: 'lowercase',
  },
  personName: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px',
    marginTop: '5px',
    color: '#4A4A4A'
  }
};

export const EMail = (props) => {
  const {
    label,
    personName,
    name,
    value,
    error,
    onBlur,
    onChange,
    sendEmail,
    sendAroovEmail,
    readOnly,
    onSendEmailChange,
    onSendAroovEmailChange,
    isAroovCustomer,
  } = props;

  const onEMailChange = useCallback(
    (evt) => {
      onChange(evt.currentTarget.value);
    },
    [onChange],
  );

  const [sendEmailSelected, setSendEmailSelected] = useState(sendEmail);
  const [sendAroovEmailSelected, setSendAroovEmailSelected] = useState(sendAroovEmail);

  const handleSendEmailChange = useCallback(
    (value) => {
      setSendEmailSelected(value);
      onSendEmailChange(name, value);
    },
    [name, onSendEmailChange],
  );

  const handleSendAroovEmailChange = useCallback(
    (value) => {
      setSendAroovEmailSelected(value);
      onSendAroovEmailChange(name, value);
    },
    [name, onSendAroovEmailChange],
  );

  const hasError = Boolean(error);

  return (
    <div css={styles.email(hasError)}>
      <div css={styles.label}>
        {label}
        {
          !!personName && (
            <div style={styles.personName}>
              <Icon icon={faUser} color={colors.dark} size={'sm'} />
              {personName}
            </div>
          )
        }
      </div>
      <div css={styles.field}>
        <Input
          placeholder={label}
          type="text"
          name={name}
          onBlur={onBlur}
          onChange={onEMailChange}
          value={value}
          autoComplete="off"
          className={classNames({
            'has-error': hasError,
            'is-readonly': readOnly,
          })}
          readOnly={readOnly}
          style={styles.toLowerCase}
        />
      </div>

      <div css={styles.buttons}>
        <ToggleButton
          defaultChecked={sendEmailSelected}
          title="Email"
          onChange={handleSendEmailChange}
        />
        {isAroovCustomer && (name === 'oldTenant' || name === 'newTenant') && (
          <ToggleButton
            defaultChecked={sendAroovEmailSelected}
            title="Aroov"
            onChange={handleSendAroovEmailChange}
          />
        )}
      </div>

      {hasError && (
        <div css={styles.error}>
          <div>{i18n._(error)}</div>
        </div>
      )}
    </div>
  );
};
