import React, { useCallback, useState, useEffect } from 'react';
import {
  isAnyCameraAvailable,
  getFullImageData,
  getOrientedImage,
  debugCamera,
} from './CameraHelpers';
import { SpinnerIcon } from '../SpinnerIcon';
import { colors } from '../../../common/theme/colors';

export const CameraPicker = (props) => {
  const {
    inputFileStyle,
    containerStyle,
    spinnerContainerStyle,
    labelInput,
    showSpinner,
    hideSpinner,
    onCameraOpen,
    onUploadImage,
    notifyError,
    width,
    height,
    debug,
    children,
    disabled,
  } = props;

  const [isCameraAvailable, setIsCameraAvailable] = useState(null);

  const handleUploadImage = useCallback(
    async (event) => {
      if (disabled) return;

      const { target } = event;
      const { files } = target;

      if (files && files[0]) {
        showSpinner();

        const file = files[0];

        const filename = file.name;
        const lastDot = filename.lastIndexOf('.');
        const format = filename.substring(lastDot + 1);

        try {
          const { image, imageData } = await getFullImageData(file);

          const finalImageData = await getOrientedImage(
            image,
            imageData.image,
            filename,
            width,
            height,
            format,
          );
          debugCamera(debug, 'finalImageData', finalImageData);

          onUploadImage(filename, finalImageData);
        } catch (e) {
          hideSpinner();
          notifyError(
            'Error while validating the uploaded image. Please try uploading another image or changing browser.',
          );
          debugCamera(debug, 'Error in getFullImageData: ', e);
        }
      }
    },
    [showSpinner, disabled, debug, height, hideSpinner, notifyError, onUploadImage, width],
  );

  const handleClick = useCallback(() => {
    if (isCameraAvailable !== null && isCameraAvailable && !disabled) {
      onCameraOpen();
    }
  }, [isCameraAvailable, onCameraOpen, disabled]);

  useEffect(() => {
    let didCancel = false;

    setIsCameraAvailable(null);

    async function getIfIsAnyCameraAvailable() {
      try {
        const resultIsAnyCameraAvailable = await isAnyCameraAvailable();
        if (!didCancel) {
          setIsCameraAvailable(resultIsAnyCameraAvailable);
        }
      } catch (e) {
        if (!didCancel) {
          setIsCameraAvailable(false);
        }
      }
    }

    getIfIsAnyCameraAvailable();

    return () => {
      didCancel = true;
    };
  }, []);

  return (
    <div style={containerStyle} onClick={handleClick}>
      {isCameraAvailable === false && (
        <input
          id={labelInput}
          type="file"
          accept="image/*"
          capture="camera"
          style={inputFileStyle}
          onChange={handleUploadImage}
          disabled={disabled}
        />
      )}
      {isCameraAvailable === null ? (
        <div style={spinnerContainerStyle}>
          <SpinnerIcon loading={true} color={colors.darkGrey} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

CameraPicker.defaultProps = {
  width: 800,
  height: 600,
};
