import produce from 'immer';

import { COMMON_OPEN_LOGIN_MODAL } from './constants';
import { LOGIN_WORKFLOW_DEFAULT } from './loginWorkflowConstants';

export function commonOpenLoginModal(workflow) {
  return {
    type: COMMON_OPEN_LOGIN_MODAL,
    payload: { loginWorkflow:  workflow !== undefined ? workflow : LOGIN_WORKFLOW_DEFAULT },
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_OPEN_LOGIN_MODAL:
        draft.ui.modal.login = true;
        draft.loginWorkflow = action.payload.loginWorkflow;
        break;
      default:
        return state;
    }
  });
