import produce from 'immer';

import { FILL_STEP_SHOW_SPINNER } from './constants';

export function fillStepShowSpinner() {
  return {
    type: FILL_STEP_SHOW_SPINNER,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_SHOW_SPINNER:
        draft.ui.busy.spinner = true;
        break;
      default:
        return state;
    }
  });
