import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { MIGRATION_CLOSE_DELETE_MODAL } from './constants';
import { migrationGetMigration } from './actions';

export function migrationCloseDeleteModal() {
  return {
    type: MIGRATION_CLOSE_DELETE_MODAL,
  };
}

function* doCloseDelete() {
  yield put(migrationGetMigration());
}

export function* switchCloseDelete() {
  yield takeLatest(MIGRATION_CLOSE_DELETE_MODAL, doCloseDelete);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_CLOSE_DELETE_MODAL:
        draft.ui.modal.delete = false;
        break;
      default:
        return state;
    }
  });
