import React from 'react';

export const CashIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 17"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M27,17H0V0H27ZM1,16H26V1H1Z" />
    <rect x="3" y="2" width="2" height="1" />
    <rect x="2" y="3" width="1" height="2" />
    <rect x="22" y="2" width="2" height="1" />
    <rect x="24" y="3" width="1" height="2" />
    <rect x="3" y="14" width="2" height="1" />
    <rect x="2" y="12" width="1" height="2" />
    <rect x="22" y="14" width="2" height="1" />
    <rect x="24" y="12" width="1" height="2" />
    <rect x="10" y="7" width="1" height="1" transform="translate(3 18) rotate(-90)" />
    <rect x="10" y="9" width="1" height="1" transform="translate(1 20) rotate(-90)" />
    <rect x="8" y="6" width="2" height="1" transform="translate(18 13) rotate(-180)" />
    <rect x="8" y="10" width="2" height="1" transform="translate(18 21) rotate(-180)" />
    <rect x="6" y="8" width="3" height="1" transform="translate(16 1) rotate(90)" />
    <polygon points="15 6 15 8 13 8 13 6 12 6 12 11 13 11 13 9 15 9 15 11 16 11 16 6 15 6" />
    <polygon points="20 7 20 6 17 6 17 11 18 11 18 9 20 9 20 8 18 8 18 7 20 7" />
  </svg>
);
