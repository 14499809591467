import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import { MIGRATION_CLOSE_RETAIN_MODAL } from './constants';
import { migrationGetMigration } from './actions';

export function migrationCloseRetainModal() {
  return {
    type: MIGRATION_CLOSE_RETAIN_MODAL,
  };
}

function* doCloseRetain() {
  yield put(migrationGetMigration());
}

export function* switchCloseRetain() {
  yield takeLatest(MIGRATION_CLOSE_RETAIN_MODAL, doCloseRetain);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_CLOSE_RETAIN_MODAL:
        draft.ui.modal.retain = false;
        break;
      default:
        return state;
    }
  });
