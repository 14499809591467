import produce from 'immer';
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';

import { commonGetProtocols } from '../../common/redux/actions';
import { post } from '../../common/services/api';
import {
  HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE,
  HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR,
  HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_SUCCESS,
} from './constants';

export function homeCreateProtocolWithoutREMReference(data) {
  return {
    type: HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE,
    payload: data,
  };
}

function* doHomeCreateProtocolWithoutREMReference({ payload }) {
  try {
    yield call(post, 'protocol/withoutremreference', payload);

    yield put({
      type: HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR,
      payload: error.message,
    });
  }
}

function* doRefreshProtocols() {
  yield put(commonGetProtocols());
}

export function* switchCreateProtocolWithoutREMReference() {
  yield takeEvery(
    HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE,
    doHomeCreateProtocolWithoutREMReference,
  );
  yield takeLatest(HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_SUCCESS, doRefreshProtocols);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE:
        draft.ui.busy.createProtocolWithoutREMReference = true;
        break;
      case HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_SUCCESS:
        draft.ui.busy.createProtocolWithoutREMReference = false;
        draft.ui.modal.protocolWithoutREMReference = false;
        break;
      case HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR:
        draft.ui.busy.createProtocolWithoutREMReference = false;
        break;
      default:
        return state;
    }
  });
