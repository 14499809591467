import React from 'react';

export const MoreIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 4 20"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <circle cx="2" cy="2" r="2" />
    <circle cx="2" cy="10" r="2" />
    <circle cx="2" cy="18" r="2" />
  </svg>
);
