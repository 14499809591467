import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';

import { SIGN_STEP_SCROLL_TO_SIGN, SIGN_STEP_SCROLLED } from './constants';

import {
  COMMON_CONFIRM_SIGN,
  COMMON_ABORT_AND_CLOSE_SIGN_PAGE,
  COMMON_REJECT_SIGN,
} from '../../../common/redux/constants';

export function signStepScrollToSign() {
  return {
    type: SIGN_STEP_SCROLL_TO_SIGN,
  };
}
export function signStepScrolled() {
  return {
    type: SIGN_STEP_SCROLLED,
  };
}

function* doSignStepAfterSignOperation() {
  yield put(signStepScrollToSign());
}

export function* switchSignStepAfterSignOperation() {
  yield takeLatest(
    [COMMON_CONFIRM_SIGN, COMMON_ABORT_AND_CLOSE_SIGN_PAGE, COMMON_REJECT_SIGN],
    doSignStepAfterSignOperation,
  );
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SIGN_STEP_SCROLL_TO_SIGN:
        draft.scrollToSign = true;
        break;
      case SIGN_STEP_SCROLLED:
        draft.scrollToSign = false;
        break;
      default:
        return state;
    }
  });
