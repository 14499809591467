import produce from 'immer';
import { select, put, takeLatest } from 'redux-saga/effects';
import {
  MIGRATION_HUB_DISCONNECT,
  MIGRATION_HUB_DISCONNECT_SUCCESS,
  MIGRATION_HUB_DISCONNECT_ERROR,
} from './constants';

import { selectHubConnection } from './selectors';

export function migrationHubDisconnect() {
  return {
    type: MIGRATION_HUB_DISCONNECT,
  };
}

function* doHubDisconnect() {
  try {
    const connection = yield select(selectHubConnection);

    if (!connection) {
      throw new Error('Error: disconnect failed, connection not valid.');
    }

    connection.exit();

    yield put({
      type: MIGRATION_HUB_DISCONNECT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: MIGRATION_HUB_DISCONNECT_ERROR,
      payload: error.message,
    });
  }
}

export function* switchHubDisconnect() {
  yield takeLatest(MIGRATION_HUB_DISCONNECT, doHubDisconnect);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_HUB_DISCONNECT_SUCCESS:
      case MIGRATION_HUB_DISCONNECT_ERROR:
        draft.hubConnection = null;
        break;
      default:
        return state;
    }
  });
