import produce from 'immer';

import { COMMON_IS_OFFLINE } from './constants';

export function commonIsOffline() {
  return {
    type: COMMON_IS_OFFLINE,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_IS_OFFLINE:
        draft.isOnline = false;
        break;
      default:
        return state;
    }
  });
