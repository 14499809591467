/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { acceptanceProtocolRoutes } from '../acceptance-protocol/routes';
import { TopBarBaseStep } from './TopBarBaseStep';

const styles = {
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
};

export const TopBarMainStep = (props) => {
  const { vormieterReferenznummer, protocolId, currentStep, onNavigateTo, isLast } = props;

  const handleNavigateToMainStep = useCallback(() => {
    onNavigateTo(acceptanceProtocolRoutes.mainStep(protocolId));
  }, [onNavigateTo, protocolId]);

  return (
    <TopBarBaseStep
      step="main-step"
      isActive={!!currentStep && currentStep === 'main-step'}
      onSelectStep={handleNavigateToMainStep}
      isLast={isLast}
    >
      <div css={styles.label}>{vormieterReferenznummer}</div>
    </TopBarBaseStep>
  );
};
