import { last, split, map } from 'ramda';

import { getByProperty, getIndexByProperty } from '../../features/common/Functions';

export class ProtocolPatcher {
  delete(protocol, expression) {
    if (!!protocol) {
      const tokens = map(decodeURIComponent, split('/', expression));

      // we don't navigate to last token as will be used for deleting
      const toDeleteToken = tokens.splice(tokens.length - 1, 1);
      // we need the last token for determining id
      const lastToken = last(tokens);

      const shouldRemoveFromArray = this.isId(toDeleteToken);

      let navigator = protocol.input;

      let shouldRemove = true;

      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];

        if (i + 1 < tokens.length) {
          const nextToken = tokens[i + 1];

          if (this.isId(nextToken)) {
            const id = this.getId(nextToken);

            let array = navigator[token];

            if (!array) {
              shouldRemove = false;
              break;
            }

            navigator = getByProperty(this.getProperty(token), id)(array);
            if (!navigator) {
              shouldRemove = false;
              break;
            }
          } else {
            navigator = !!navigator[token] ? navigator[token] : navigator;
          }
        } else {
          navigator = !!navigator[token] ? navigator[token] : navigator;
        }
      }

      if (shouldRemove) {
        if (!shouldRemoveFromArray) {
          delete navigator[toDeleteToken];
        } else {
          const lastId = this.getId(toDeleteToken);

          const indexToRemove = getIndexByProperty(this.getProperty(lastToken), lastId)(navigator);

          if (indexToRemove !== -1) {
            navigator.splice(indexToRemove, 1);
          }
        }
      }
    }
  }

  patch(protocol, expression, value) {
    if (!!protocol) {
      const tokens = map(decodeURIComponent, split('/', expression));
      const lastToken = last(tokens);

      const shouldAddToArray = this.isId(lastToken);

      let navigator = protocol.input;

      for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        // console.log(1, token);
        if (i + 1 < tokens.length) {
          const nextToken = tokens[i + 1];

          if (this.isId(nextToken)) {
            const id = this.getId(nextToken);

            // console.log(1, token, navigator);
            let array = navigator[token];

            if (!array) {
              array = [];
              navigator[token] = array;
            }

            navigator = getByProperty(this.getProperty(token), id)(array);
            // console.log(2, navigator);
            if (!navigator) {
              // console.log(3);
              navigator = this.ensureObject(token, id);
              array.push(navigator);
            }
          } else {
            // console.log(4, token);
            navigator = !!navigator[token] ? navigator[token] : navigator;
          }
        }
      }

      if (!shouldAddToArray) {
        let toUpdateValue = value;
        if (toUpdateValue === null) {
          toUpdateValue = this.getDefaultValue(lastToken);
        }

        const currentValue = navigator[lastToken];
        if (
          !currentValue ||
          !toUpdateValue ||
          typeof toUpdateValue !== 'object' ||
          Array.isArray(toUpdateValue)
        ) {
          navigator[lastToken] = toUpdateValue;
        } else {
          Object.assign(navigator[lastToken], toUpdateValue);
        }
      } else {
        // console.log(5, navigator)
        Object.assign(navigator, value);
      }
    }
  }

  getId(nextToken) {
    const [, idAsString] = /\[(.+?)\]/gi.exec(nextToken);
    const id = !isNaN(idAsString) ? parseInt(idAsString, 10) : idAsString;
    return id;
  }

  isId(lastToken) {
    return /\[(.+?)\]/gi.test(lastToken);
  }

  getProperty(token) {
    switch (token) {
      case 'basedataInputs':
      case 'componentsInput':
      case 'elements':
      case 'attributesSelected':
      case 'previousComponentsInput':
      case 'images':
        return 'id';
      case 'fields':
      case 'siblings':
        return 'fieldname';
      case 'sendStep':
        return 'type';
      default:
        throw Error(`invalid token for getProperty ${token}`);
    }
  }

  getDefaultValue(token) {
    switch (token) {
      case 'signedAt':
      case 'cost':
      case 'data':
      case 'images':
      case 'state':
      case 'previousComponentOrderInput':
      case 'previousComponentsInput':
      case 'componentsOrderInput':
        return null;
      case 'fields':
      case 'siblings':
        return [];
      default:
        throw Error(`invalid token for getDefaultValue ${token}`);
    }
  }

  ensureObject(token, id) {
    switch (token) {
      case 'componentsInput':
      case 'elements':
      case 'attributesSelected':
      case 'images':
        return { id };
      case 'basedataInputs':
        return { id, typeId: this.getTypeIdFromId(id), fields: [] };
      case 'fields':
        return { fieldname: id };
      case 'siblings':
        return { fieldname: id };
      case 'sendStep':
        return { type: id };
      default:
        throw Error(`invalid token for ensureObject ${token}`);
    }
  }

  getTypeIdFromId(id) {
    switch (id) {
      case 'oldRentalPerson':
      case 'oldRentalPerson_2':
        return 'oldRentalPerson';
      case 'newRentalPerson':
      case 'newRentalPerson_2':
        return 'newRentalPerson';
      default:
        return id;
    }
  }
}
