export const FILL_STEP_SELECT_ELEMENT = '[Fill Step] select element';
export const FILL_STEP_CLEAR_SELECTED_ELEMENT = '[Fill Step] Clear Selected element';

export const FILL_STEP_OPEN_COST_MODAL = '[Fill Step] Open cost modal';
export const FILL_STEP_CLOSE_COST_MODAL = '[Fill Step] Close cost modal';

export const FILL_STEP_SELECT_ATTRIBUTE = '[Fill Step] Select attribute';

export const FILL_STEP_NAVIGATE_COMPONENT = '[Fill Step] Navigate to component';
export const FILL_STEP_NAVIGATE_ELEMENT = '[Fill Step] Navigate to element';
export const FILL_STEP_NAVIGATE_NEXT_ELEMENT = '[Fill Step] Navigate to next element';
export const FILL_STEP_SCROLL_TO_COMPONENT = '[Fill Step] Scroll to component';
export const FILL_STEP_SCROLLED_TO_COMPONENT = '[Fill Step] Scrolled to component';

export const FILL_STEP_OPEN_IMAGE_SKETCHER_MODAL = '[Fill Step] Open image sketcher modal';
export const FILL_STEP_CLOSE_IMAGE_SKETCHER_MODAL = '[Fill Step] Close image sketcher modal';
export const FILL_STEP_SAVE_IMAGE = '[Fill Step] Save image';
export const FILL_STEP_SAVE_IMAGE_ERROR = '[Fill Step] Save image Error';
export const FILL_STEP_DELETE_ALL_IMAGES = '[Fill Step] Delete all images';
export const FILL_STEP_DELETE_IMAGE = '[Fill Step] Delete image';
export const FILL_STEP_OPEN_VIEW_IMAGE_MODAL = '[Fill Step] Open view image modal';
export const FILL_STEP_CLOSE_VIEW_IMAGE_MODAL = '[Fill Step] Close view image modal';
export const FILL_STEP_DELETE_COMMENT = '[Fill Step] Delete comment';

export const FILL_STEP_EDIT_COMPONENT = '[Fill Step] Edit Component';
export const FILL_STEP_OPEN_EDIT_COMPONENT_MODAL = '[Fill Step] Open Edit Component Modal';
export const FILL_STEP_CLOSE_EDIT_COMPONENT_MODAL = '[Fill Step] Close Edit Component Modal';
export const FILL_STEP_SAVE_EDIT_COMPONENT = '[Fill Step] Save Edit Component';

export const FILL_STEP_CONFIRM_MODAL = '[Fill Step] Confirm Modal';

export const FILL_STEP_SHOW_SPINNER = '[Fill Step] Show Spinner';
export const FILL_STEP_HIDE_SPINNER = '[Fill Step] Hide Spinner';

export const FILL_STEP_OPEN_TAKE_PHOTO_MODAL = '[Fill Step] Open take photo modal';
export const FILL_STEP_CLOSE_TAKE_PHOTO_MODAL = '[Fill Step] Close take photo modal';

export const FILL_STEP_NOTIFY_ERROR = '[Fill Step] Notify error';
