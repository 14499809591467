/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import classNames from 'classnames';
import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';
import { Icon } from './Icon';

const styles = {
  button: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',

    textTransform: 'uppercase',

    cursor: 'pointer',
    boxShadow: '0 0 5px #333',
    border: 'none',
    borderRadius: '3px',
    transition: '0.5s background-position, 0.5s background-color',
    backgroundColor: colors.whiteSmoke,
    height: '5em',

    [variables.breakpoints.xl]: {
      height: '6em',
    },

    '&:hover': {
      backgroundPosition: 'right center',
    },
    '&.is-selected': {
      backgroundColor: colors.tileSelectedBackgroundColor,
      backgroundImage: 'none',
      color: colors.white,
      '&:hover': {
        backgroundPosition: 'right center',
      },
    },

    content: {
      justifySelf: 'center',
      alignSelf: 'center',
      textAlign: 'center',
      gridRow: '1/5',
      gridColumn: '1/3',
      padding: '0 28px',
    },
    icon: {
      justifySelf: 'center',
      alignSelf: 'center',
      gridRow: '2/4',
      gridColumn: '2/3',
    },
  },
};
const ToggleButton = (props) => {
  const { id, onClick, children, isSelected } = props;

  const handleClick = useCallback(() => {
    onClick(!isSelected);
  }, [isSelected, onClick]);

  return (
    <div
      id={id}
      css={styles.button}
      className={classNames({ 'is-selected': isSelected })}
      onClick={handleClick}
    >
      <div css={styles.button.content}>{children}</div>
      <Icon css={styles.button.icon} icon={'chevron-right'} color={colors.dark} />
    </div>
  );
};

export default ToggleButton;
