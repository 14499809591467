/** @jsxImportSource @emotion/react */
import { useMemo, Fragment } from 'react';
import { getFraction } from './Functions';
import { formatAsDecimals } from '../../common/Functions';

const styles = {
  fractionContainer: {
    display: 'inline-block',
    verticalAlign: 'sub',
    textAlign: 'center',
    fontSize: 'x-small',
  },
  fractionSup: {
    display: 'block',
  },
  fractionSub: {
    display: 'block',
    borderTop: 'thin solid black',
    paddingTop: '0.1em',
    paddingLeft: '0.3em',
    paddingRight: '0.3em',
  },
  fractionLarge: {
    fontSize: 'x-large',
    position: 'relative',
    top: '1px',
  },
  fractionNowrap: {
    whiteSpace: 'nowrap',
  },
};

export const Fraction = (props) => {
  const { value, withPercentage, withText } = props;

  const fraction = useMemo(() => getFraction(value, 2), [value]);

  const percentage = useMemo(() => {
    if (!withPercentage || !fraction) return '';

    const sup = parseFloat(fraction.sup) || 0;
    const sub = parseFloat(fraction.sub) || 0;
    return sup > 0 ? (sup / sub) * 100 : '';
  }, [withPercentage, fraction]);

  return (
    <div style={styles.fractionNowrap}>
      {!!withText ? `${withText} ` : null}
      {!!withPercentage ? `${formatAsDecimals(percentage || 0)}% ` : null}
      {!!fraction ? (
        <Fragment>
          <span style={styles.fractionLarge}>(</span>
          <div style={styles.fractionContainer}>
            <span style={styles.fractionSup}>{fraction.sup}</span>
            <span style={styles.fractionSub}>{fraction.sub}</span>
          </div>
          <span style={styles.fractionLarge}>)</span>
        </Fragment>
      ) : null}
    </div>
  );
};
