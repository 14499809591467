/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

const styles = {
  spinner: (props) => ({
    display: 'flex',
    justifyontent: 'center',
    alignItems: 'center',
    width: `${props.size}${props.sizeUnit}`,
    height: `${props.size}${props.sizeUnit}`,
    border: `${props.size / 5}${props.sizeUnit} solid ${props.color}`,
    borderRightColor: 'transparent',
    borderRadius: '50%',
    animation: 'spinner 0.75s linear infinite',
  }),
};

export const SpinnerIcon = (props) => {
  const { size, color, loading } = props;
  return loading && <div css={styles.spinner(props)} size={size} color={color} />;
};

SpinnerIcon.defaultProps = {
  loading: true,
  size: '32',
  color: '#000',
  sizeUnit: 'px',
};

SpinnerIcon.propTypes = {
  loading: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  sizeUnit: PropTypes.string,
};
