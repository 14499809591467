import { push } from 'connected-react-router';
import produce from 'immer';
import HttpStatus from 'http-status-codes';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { askForConfirmation } from './openConfirmationModal';
import { i18n } from '../../../common/i18n-loader';
import { getImageReferences } from '../../../common/code/get-image-references';
import { deleteImages } from '../../../common/code/image.repository';
import { homeRoutes } from '../../home/routes';
import { getById, getByProperty } from '../Functions';
import { deleteById, get } from '../services/api';
import {
  COMMON_DELETE_PROTOCOL,
  COMMON_DELETE_PROTOCOL_ERROR,
  COMMON_DELETE_PROTOCOL_SUCCESS,
  COMMON_DELETE_PROTOCOL_CONFIRM,
  COMMON_DELETE_PROTOCOL_DELETE,
  COMMON_DELETE_PROTOCOL_CANCEL,
} from './constants';
import { commonGetProtocols, commonRemoveFromDownload } from './actions';
import { selectProtocols, selectLeavingAgreements, selectSessionId } from './selectors';

export function commonDeleteProtocol(protocolId) {
  return { type: COMMON_DELETE_PROTOCOL, payload: protocolId };
}

function* doDeleteProtocol({ payload }) {
  try {
    const sessionId = yield select(selectSessionId);

    const otherExists = yield call(get, `download/otherexists/${sessionId}/${payload}`);

    if (otherExists) {
      yield put({
        type: COMMON_DELETE_PROTOCOL_ERROR,
        payload: i18n._('ERRORS.PROTOCOL-ALREADY-DOWNLOADED'),
      });
      return;
    }

    yield call(deleteById, 'protocol', payload);
    const protocols = yield select(selectProtocols);
    const protocol = getById(payload)(protocols);

    const references = getImageReferences(protocol);
    yield deleteImages(references, true);

    yield put(commonRemoveFromDownload({ sessionId, protocolId: payload }));

    const leavingAgreements = yield select(selectLeavingAgreements);
    const leavingAgreement = getByProperty('protocolId', payload)(leavingAgreements);
    if (!!leavingAgreement) {
      yield call(deleteById, 'leavingagreement', leavingAgreement.id);
    }

    yield put({
      type: COMMON_DELETE_PROTOCOL_SUCCESS,
      payload,
    });
  } catch (error) {
    // if protocol is completed on server, we still remove it from local
    if (error.status === HttpStatus.CONFLICT) {
      yield put({
        type: COMMON_DELETE_PROTOCOL_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: COMMON_DELETE_PROTOCOL_ERROR,
        payload: error.message,
      });
    }
  }
}

function* doDeleteProtocolConfirm({ payload }) {
  const confirmed = yield call(askForConfirmation, {
    title: i18n._('DELETE-PROTOCOL.TEXTS.TITLE'),
    message: payload.error,
    ok: i18n._('BUTTONS.DELETE'),
    cancel: i18n._('BUTTONS.CANCEL'),
  });

  if (confirmed) {
    yield put({
      type: COMMON_DELETE_PROTOCOL_DELETE,
      payload: payload.protocolId,
    });
  } else {
    yield put({
      type: COMMON_DELETE_PROTOCOL_CANCEL,
    });
  }
}

function* doRedirectAfterDeleteProtocol() {
  yield put(push(homeRoutes.home));
  yield put(commonGetProtocols());
}

export function* switchDeleteProtocol() {
  yield takeEvery(COMMON_DELETE_PROTOCOL, doDeleteProtocol);
  yield takeLatest(
    [COMMON_DELETE_PROTOCOL_SUCCESS, COMMON_DELETE_PROTOCOL_DELETE],
    doRedirectAfterDeleteProtocol,
  );
  yield takeEvery(COMMON_DELETE_PROTOCOL_CONFIRM, doDeleteProtocolConfirm);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_DELETE_PROTOCOL:
      case COMMON_DELETE_PROTOCOL_CONFIRM:
        draft.ui.busy.deleteProtocol = true;
        break;

      case COMMON_DELETE_PROTOCOL_SUCCESS:
      case COMMON_DELETE_PROTOCOL_DELETE:
        draft.ui.busy.deleteProtocol = false;
        let protocolIndex = draft.protocols.findIndex((x) => x.id === action.payload);
        if (protocolIndex > -1) {
          draft.protocols.splice(protocolIndex, 1);
          delete draft.queues[action.payload];
        }

        let leavingAgreementIndex = draft.leavingAgreements.findIndex(
          (x) => x.protocolId === action.payload,
        );
        if (leavingAgreementIndex > -1) {
          draft.leavingAgreements.splice(leavingAgreementIndex, 1);
        }

        break;
      case COMMON_DELETE_PROTOCOL_ERROR:
      case COMMON_DELETE_PROTOCOL_CANCEL:
        draft.ui.busy.deleteProtocol = false;
        break;

      default:
        return state;
    }
  });
