/** @jsxImportSource @emotion/react */
import { Icon } from '../common/Icon';
import { SpinnerIcon } from './SpinnerIcon';
import { colors } from '../../common/theme/colors';

const styles = {
  root: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  spinner: {
    justifySelf: 'center',
    alignSelf: 'center',
    transform: 'scale(0.4)',
    pointerEvents: 'none',
  },
  downloadButton: {
    padding: '10px 32px',
    background: `rgba(${colors.brand_rgb}, 0.1)`,
    border: `2px solid ${colors.brand}`,
    borderRadius: '4px',
    minWidth: '88px',
    '&:disabled': {
      borderColor: colors.lighterGrey,
      background: 'rgba(0,0,0,0.05)',
      pointerEvents: 'none',
    },
  },
};

const DownloadButtonSpinnerComponent = (props) => {
  const { isBusy, disabled, onClick, style, icon, iconColor, borderColor, background } = props;

  return (
    <div css={{ ...styles.root, ...style }}>
      <button
        css={styles.downloadButton}
        onClick={onClick}
        disabled={isBusy || disabled}
        style={{ borderColor, backgroundColor: !!background && background }}
      >
        {isBusy ? (
          <SpinnerIcon css={styles.spinner} color="#333" size="18" />
        ) : (
          <Icon icon={icon} color={isBusy || disabled ? colors.lighterGrey : iconColor} />
        )}
      </button>
    </div>
  );
};

DownloadButtonSpinnerComponent.defaultProps = {
  isBusy: false,
  disabled: false,
  iconColor: '#333',
  borderColor: colors.brand,
};

export const DownloadButtonSpinner = DownloadButtonSpinnerComponent;
