/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import Button from '../common/Button';
import { i18n } from '../../common/i18n-loader';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { inputStyle } from '../common/Input';

const initialValues = {
  sourceRentalObjectReference: '',
};

const validationSchema = yup.object().shape({
  sourceRentalObjectReference: yup.string().required(),
});

const otherStyles = {
  form: {
    display: 'grid',
    gridAutoRows: 'auto',
    gridAutoFlow: 'row',
    gridGap: '16px',
  },
  fieldRow: {
    display: 'flex',
    flexFlow: 'column nowrap',

    '& .has-error': {
      border: '2px solid red',
    },
  },
  errorMessage: {
    color: 'red',
    textAlign: 'right',
  },
};

function NewProtocolWithREMReferenceFromAnotherProtocol(props) {
  const { onSwitchProtocolCreationMode, onCancel, onSubmit, styles, hasInitialAcceptance } = props;

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ errors, touched }) => {
        const sourceRentalObjectReference =
          touched.sourceRentalObjectReference && errors.sourceRentalObjectReference;

        return (
          <Form css={otherStyles.form} autoComplete="off">
            <div css={otherStyles.fieldRow}>
              <Field
                css={inputStyle}
                placeholder={i18n._('LABELS.SOURCE-RENTAL-OBJECT-REFERENCE')}
                type="text"
                name="sourceRentalObjectReference"
                className={sourceRentalObjectReference ? 'has-error' : null}
              />

              {sourceRentalObjectReference && (
                <span css={otherStyles.errorMessage}>{errors.sourceRentalObjectReference}</span>
              )}
            </div>

            <div css={styles.modal.buttons}>
              {hasInitialAcceptance && (
                <button css={styles.modal.modeSwitchButton} onClick={onSwitchProtocolCreationMode}>
                  <Trans>SELECT-TEMPLATE-MODAL.SWITCH-TO-TEMPLATE</Trans>
                </button>
              )}
              <Button secondary onClick={onCancel} type="reset" className="leftButton">
                <Trans>SELECT-TEMPLATE-MODAL.ABORT</Trans>
              </Button>
              <Button default type="submit" className="rightButton">
                <Trans>SELECT-TEMPLATE-MODAL.CONFIRM</Trans>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default NewProtocolWithREMReferenceFromAnotherProtocol;
