import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';

import { post } from '../../common/services/api';
import {
  MIGRATION_STOP_MIGRATION,
  MIGRATION_STOP_MIGRATION_SUCCESS,
  MIGRATION_STOP_MIGRATION_ERROR,
} from './constants';

export function migrationStopMigration(id) {
  return {
    type: MIGRATION_STOP_MIGRATION,
    payload: id,
  };
}

function* doStopMigration({ payload }) {
  try {
    yield call(post, 'migration/stop', { migrationId: payload });

    yield put({
      type: MIGRATION_STOP_MIGRATION_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: MIGRATION_STOP_MIGRATION_ERROR,
      payload: error.message,
    });
  }
}

export function* switchStopMigration() {
  yield takeLatest(MIGRATION_STOP_MIGRATION, doStopMigration);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_STOP_MIGRATION:
        draft.ui.busy.loading = true;
        break;
      case MIGRATION_STOP_MIGRATION_SUCCESS:
        draft.ui.busy.loading = false;
        break;
      case MIGRATION_STOP_MIGRATION_ERROR:
        draft.ui.busy.loading = false;
        break;
      default:
        return state;
    }
  });
