import React from 'react';

export const IndustryInOut = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 50"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M10,26a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1,0-1.42l8-8a1,1,0,0,1,1.09-.21A1,1,0,0,1,11,9v4h9a1,1,0,0,1,1,1v2.59L26.59,11,21,5.41V8a1,1,0,0,1-1,1H14V7h5V3a1,1,0,0,1,.62-.92,1,1,0,0,1,1.09.21l8,8a1,1,0,0,1,0,1.42l-8,8a1,1,0,0,1-1.09.21A1,1,0,0,1,19,19V15H10a1,1,0,0,1-1-1V11.41L3.41,17,9,22.59V20a1,1,0,0,1,1-1h7v2H11v4a1,1,0,0,1-.62.92A.84.84,0,0,1,10,26Z" />
    <rect x="24" y="38" width="2" height="1" />
    <rect x="24" y="45" width="2" height="1" />
    <path d="M49,34V32h8V20H37V32h8v2H28V28H19v6H17V48h2V43H31v5h2V36H61V48h2V34ZM39,22H55v8H39ZM20,29h7v2H20Zm0,3h7v2H20Zm11,9H19V36H31Z" />
  </svg>
);
