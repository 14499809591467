import produce from 'immer';
import { put, takeLatest, delay } from 'redux-saga/effects';
import { HubConnectionBuilder } from '@aspnet/signalr';
import { authGetToken } from '../../common/services/auth';
import {
  MIGRATION_HUB_CONNECT,
  MIGRATION_HUB_CONNECT_SUCCESS,
  MIGRATION_HUB_CONNECT_ERROR,
} from './constants';

import { migrationGetMigration, migrationHubListener } from './actions';

export function migrationHubConnect() {
  return {
    type: MIGRATION_HUB_CONNECT,
  };
}

function* doHubConnect() {
  try {
    const connection = connect();

    if (!connection) {
      throw new Error('Error: connect failed, connection not established.');
    }

    yield put({
      type: MIGRATION_HUB_CONNECT_SUCCESS,
      payload: connection,
    });

    yield put(migrationGetMigration());
    yield put(migrationHubListener());
  } catch (error) {
    yield put({
      type: MIGRATION_HUB_CONNECT_ERROR,
      payload: error.message,
    });
  }
}

export function* switchHubConnect() {
  yield takeLatest(MIGRATION_HUB_CONNECT, doHubConnect);
}

function connect() {
  const token = authGetToken();
  const connection = new HubConnectionBuilder()
    .withUrl('/ws/migration', { accessTokenFactory: () => token })
    .build();

  let attempt = 0;
  let connected = false;
  while (attempt < 10 && !connected) {
    attempt++;
    connected = true;
    try {
      connection.start(() => console.log('starting!'));
      console.log('Connection started!');
    } catch (err) {
      console.log(`Error: attempt ${attempt} connection failed.`);
      delay(1000);
      connected = false;
    }
  }

  return connected ? connection : null;
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_HUB_CONNECT_SUCCESS:
        draft.hubConnection = action.payload;
        break;
      case MIGRATION_HUB_CONNECT_ERROR:
        draft.hubConnection = null;
        break;
      default:
        return state;
    }
  });
