import Axios from 'axios';
import HttpStatus from 'http-status-codes';

import { i18n } from '../../../common/i18n-loader';
import { genericError, notFoundError, unavailableError } from '../../../common/messages';

export async function getConfigForClient() {
  try {
    const response = await Axios.get('/api/clientconfig/try');

    return response.data;
  } catch (error) {
    let message;

    if (!!error.response) {
      const { response } = error;
      switch (response.status) {
        case HttpStatus.NOT_FOUND:
          message = notFoundError;
          break;

        default:
          message = genericError;
      }
    } else {
      message = unavailableError;
    }

    throw Error(i18n._(message));
  }
}
