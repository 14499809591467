import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getOrderedElementsTemplate } from '../../../common/Functions';
import { COMMON_ADDED_COMPONENT, COMMON_COPIED_COMPONENT } from '../../../common/redux/constants';
import {
  selectIndexedComponentsInput,
  selectIndexedComponentsTemplate,
  selectIndexedElementsTemplate,
} from '../../redux/selectors';
import { FILL_STEP_SCROLL_TO_COMPONENT, FILL_STEP_SCROLLED_TO_COMPONENT } from './constants';
import { fillStepSelectElement } from './fillStepSelectElement';

export function fillStepScrollToComponent(componentId) {
  return {
    type: FILL_STEP_SCROLL_TO_COMPONENT,
    payload: componentId,
  };
}

export function fillStepScrolledToComponent() {
  return {
    type: FILL_STEP_SCROLLED_TO_COMPONENT,
  };
}

function* doScrollToComponent({ payload }) {
  yield put(fillStepScrollToComponent(payload));
}

function* doSelectFirstElementOfScrolledComponent({ payload }) {
  const indexedComponentsInput = yield select(selectIndexedComponentsInput);

  const componentInput = indexedComponentsInput[payload];

  if (!!componentInput) {
    const indexedElementsTemplate = yield select(selectIndexedElementsTemplate);
    const indexedComponentsTemplate = yield select(selectIndexedComponentsTemplate);

    const elements = getOrderedElementsTemplate(
      componentInput.elements,
      componentInput.elementsOrder,
      indexedElementsTemplate,
      indexedComponentsTemplate[componentInput.typeId],
    );

    if (!!elements.length) {
      yield put(
        fillStepSelectElement({
          elementId: elements[0].id,
          componentId: payload,
          type: 'component',
        }),
      );
    }
  }
}

export function* switchScrollToComponent() {
  yield takeLatest([COMMON_ADDED_COMPONENT, COMMON_COPIED_COMPONENT], doScrollToComponent);
}

export function* switchSelectFirstElementOfComponentCopied() {
  yield takeLatest(FILL_STEP_SCROLL_TO_COMPONENT, doSelectFirstElementOfScrolledComponent);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_SCROLL_TO_COMPONENT:
        draft.scrollTo = action.payload;
        break;
      case FILL_STEP_SCROLLED_TO_COMPONENT:
        draft.scrollTo = null;
        break;
      default:
        return state;
    }
  });
