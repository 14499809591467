import { map, max, reduce, sortBy } from 'ramda';
import { put, select, takeEvery } from 'redux-saga/effects';

import { i18n } from '../../../common/i18n-loader';
import {
  selectIndexedComponentsTemplate,
  selectCurrentProtocol,
  selectComponentById,
} from '../../acceptance-protocol/redux/selectors';
import { COMMON_COPY_COMPONENT, COMMON_COPIED_COMPONENT } from './constants';
import { commonPatchOperation } from './patchOperation';

export function commonCopyComponent(componentId, title) {
  return { type: COMMON_COPY_COMPONENT, payload: { componentId, title } };
}

export function commonCopiedComponent(componentId) {
  return { type: COMMON_COPIED_COMPONENT, payload: componentId };
}

function* doCopyComponent({ payload }) {
  const protocol = yield select(selectCurrentProtocol);
  const indexedComponentsTemplate = yield select(selectIndexedComponentsTemplate);
  const highestId = reduce(
    max,
    -Infinity,
    map((ci) => ci.id, protocol.input.componentsInput),
  );

  const componentToBeCopied = yield select(selectComponentById(payload.componentId));

  const componentCopied = {
    ...componentToBeCopied,
    elements: !!componentToBeCopied.elements
      ? componentToBeCopied.elements.map((e) => ({ id: e.id, removed: e.removed }))
      : null,
    id: highestId + 1,
    localName: `${payload.title} (${i18n._('FILL-STEP.COPY-COMPONENT.COPY-LABEL')})`,
  };

  yield put(
    commonPatchOperation(protocol.id, `componentsInput/[${componentCopied.id}]`, componentCopied),
  );

  let order = protocol.input.componentsOrderInput;

  if (!order) {
    order = map(
      (s) => s.id,
      sortBy(
        (m) => m.order,
        map(
          (ci) => ({ id: ci.id, order: indexedComponentsTemplate[ci.typeId].order }),
          protocol.input.componentsInput,
        ),
      ),
    );
  }

  yield put(
    commonPatchOperation(protocol.id, 'componentsOrderInput', [...order, componentCopied.id]),
  );

  yield put(commonCopiedComponent(componentCopied.id));
}

export function* switchCopyComponent() {
  yield takeEvery(COMMON_COPY_COMPONENT, doCopyComponent);
}
