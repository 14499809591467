import { LOCATION_CHANGE } from 'connected-react-router';
import { put, take } from 'redux-saga/effects';
import { store } from '../../../common/configStore';
import { COMMON_IS_OFFLINE, COMMON_IS_ONLINE } from './constants';

export function* isOnlineSagas() {
  yield take(LOCATION_CHANGE);

  navigator.onLine ? yield put({ type: COMMON_IS_ONLINE }) : yield put({ type: COMMON_IS_OFFLINE });

  window.addEventListener('online', () => store.dispatch({ type: COMMON_IS_ONLINE }), false);
  window.addEventListener('offline', () => store.dispatch({ type: COMMON_IS_OFFLINE }), false);
}
