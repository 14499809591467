/** @jsxImportSource @emotion/react */
import { useCallback, useMemo, useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Fraction } from './Fraction';
import { colors } from '../../../common/theme/colors';
import { sharedContainerStyle } from '../../acceptance-protocol/fill-step/TabsCost';

const styles = {
  container: {
    gridColumn: '1 / span 3',
    minHeight: '330px',
    height: '100%',
    display: 'grid',
    gridAutoFlow: 'row',
    gridAutoRows: 'min-content',
    rowGap: '16px',
    padding: '1.5em',

    input: {
      width: '64px',
      textAlign: 'right',
      borderRadius: '4px',
      padding: '12px 8px',
      backgroundColor: colors.white,
      border: `solid 2px ${colors.grey80}`,
      '&:disabled': {
        backgroundColor: colors.input.disabled,
      },
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  rowContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto 136px',
    gridTemplateRows: 'minmax(40px, auto)',
    gridTemplateAreas: '"title fields"',
    alignItems: 'center',
  },
  rowContainerBaseline: {
    alignItems: 'baseline',
  },
  rowHeader: {
    marginTop: '-16px',
    height: '20px',
  },
  rowDivider: {
    width: '100%',
    height: '2px',
    backgroundColor: colors.grey80,
  },
  fieldsContainer: {
    gridArea: 'fields',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: '64px',
    columnGap: '8px',
  },
  fieldTextContainer: {
    gridArea: 'fields',
  },
  fieldsTitle: {
    textAlign: 'center',
    fontSize: '13px',
  },
};

const getDecimals = (num) => {
  if (!num) return 0;
  const fixed = Math.abs(parseFloat(num));
  return fixed - Math.floor(fixed);
};

const getYearAndMonthByYear = (val, full) => {
  if (/^(|[0-9]*)$/.test(val)) {
    let year = parseInt(val, 10);
    if (!year) year = 0;
    return year + getDecimals(full);
  }
};

const getYearAndMonthByMonth = (val, full) => {
  if (/^(|[0-9]*)$/.test(val)) {
    let month = parseInt(val, 10);
    if (!month) month = 0;
    month = month > 11 ? 11 : month;
    return Math.trunc(full) + month / 12;
  }
};

export const TabFraction = (props) => {
  const { value, life, age, setLife, setAge, onChangeCost } = props;

  const lifeYear = useMemo(() => Math.trunc(life), [life]);
  const lifeMonth = useMemo(() => Math.round(getDecimals(life) * 12), [life]);

  const ageYear = useMemo(() => Math.trunc(age), [age]);
  const ageMonth = useMemo(() => Math.round(getDecimals(age) * 12), [age]);

  const remainingYearAndMonth = useMemo(() => {
    const remainingValue = life - age;
    return remainingValue > 0 ? remainingValue : 0;
  }, [life, age]);

  const remainingYear = useMemo(() => Math.trunc(remainingYearAndMonth), [remainingYearAndMonth]);
  const remainingMonth = useMemo(
    () => Math.round(getDecimals(remainingYearAndMonth) * 12),
    [remainingYearAndMonth],
  );

  const handleChangeAgeYearValue = useCallback(
    (event) => {
      const yearVal = getYearAndMonthByYear(event.currentTarget.value, age);
      if (!!yearVal || yearVal === 0) setAge(yearVal);
    },
    [age, setAge],
  );

  const handleChangeAgeMonthValue = useCallback(
    (event) => {
      const monthVal = getYearAndMonthByMonth(event.currentTarget.value, age);
      if (!!monthVal || monthVal === 0) setAge(monthVal);
    },
    [age, setAge],
  );

  const handleChangeLifeYearValue = useCallback(
    (event) => {
      const yearVal = getYearAndMonthByYear(event.currentTarget.value, life);
      if (!!yearVal || yearVal === 0) setLife(yearVal);
    },
    [life, setLife],
  );

  const handleChangeLifeMonthValue = useCallback(
    (event) => {
      const monthVal = getYearAndMonthByMonth(event.currentTarget.value, life);
      if (!!monthVal || monthVal === 0) setLife(monthVal);
    },
    [life, setLife],
  );

  useEffect(() => {
    const ageValue = parseFloat(age) || 0;
    const lifeValue = parseFloat(life) || 0;
    const remainingValue = lifeValue - ageValue;
    const newValue = remainingValue > 0 ? `${remainingValue}/${lifeValue}` : '';
    if (value !== newValue) {
      onChangeCost({ value: newValue });
    }
  }, [value, age, life, onChangeCost]);

  return (
    <div css={{ ...styles.container, ...sharedContainerStyle }}>
      <div css={{ ...styles.rowContainer, ...styles.rowHeader }}>
        <div css={styles.fieldsContainer}>
          <div css={styles.fieldsTitle}>
            <Trans>LABELS.YEARS</Trans>
          </div>
          <div css={styles.fieldsTitle}>
            <Trans>LABELS.MONTHS</Trans>
          </div>
        </div>
      </div>

      <div css={styles.rowContainer}>
        <div>
          <Trans>LABELS.LIFE-EXPECTANCY</Trans>
        </div>
        <div css={styles.fieldsContainer}>
          <input name="lifeYear" value={lifeYear} onChange={handleChangeLifeYearValue} />
          <input name="lifeMonth" value={lifeMonth} onChange={handleChangeLifeMonthValue} />
        </div>
      </div>

      <div css={styles.rowContainer}>
        <div>
          <Trans>LABELS.AGE</Trans>
        </div>
        <div css={styles.fieldsContainer}>
          <input name="ageYear" value={ageYear} onChange={handleChangeAgeYearValue} />
          <input name="ageMonth" value={ageMonth} onChange={handleChangeAgeMonthValue} />
        </div>
      </div>

      <div css={styles.rowDivider} />

      <div css={styles.rowContainer}>
        <div>
          <Trans>LABELS.REMAINING-LIVE-EXPECTANCY</Trans>
        </div>
        <div css={styles.fieldsContainer}>
          <input value={remainingYear} disabled />
          <input value={remainingMonth} disabled />
        </div>
      </div>

      <div css={styles.rowContainer}>
        <div css={styles.fieldTextContainer}>
          {parseFloat(remainingYearAndMonth).toFixed(2)} <Trans>LABELS.YEARS</Trans>
        </div>
      </div>

      <div css={{ ...styles.rowContainer, ...styles.rowContainerBaseline }}>
        <div css={styles.bold}>
          <Trans>LABELS.COST-PROPORTION</Trans>
        </div>
        <div css={styles.fieldsContainer}>
          <Fraction value={value} withPercentage={true} />
        </div>
      </div>
    </div>
  );
};
