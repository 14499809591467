/** @jsxImportSource @emotion/react */
import { map, indexBy, prop, addIndex, values, split, filter, indexOf } from 'ramda';
import { useCallback, useState, useRef, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import Accordion from './Accordion';
import * as commonActions from '../../common/redux/actions';
import * as actions from './redux/actions';
import { colors } from '../../../common/theme/colors';
import { i18n } from '../../../common/i18n-loader';
import { getLocalizedLabel, getLocalizedName, getLocalizedNameForComponent } from './Functions';
import {
  selectBasedataTemplate,
  selectProtocolLanguage,
  selectComponentsInput,
  selectIndexedPreviousComponentsInput,
  selectIndexedComponentsTemplate,
  selectIndexedElementsTemplate,
  selectIndexedBasedataInput,
  selectComponentsOrderInput,
  selectCurrentId,
  selectCurrentProtocolHasPreviousComponentsInput,
  selectIndexedComponentsInput,
  selectOrderedComponents,
} from '../redux/selectors';
import { bindActionCreators } from 'redux';
import { selectSelected, selectScrollTo } from './redux/selectors';
import { getTitleByScroll } from '../../../common/code/getTitleByScroll';
import { ReorderIcon } from '../../common/ReorderIcon';
import { DuplicateIcon } from '../../common/DuplicateIcon';
import { PlusIcon } from '../../common/PlusIcon';
import { move, getOrderedElementsTemplate, adjustElementsOrder } from '../../common/Functions';
import { variables } from '../../../common/theme/variables';

const navigationHeaderHeight = 48;

const styles = {
  body: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
    maxHeight: '100%',
  },

  top: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    height: '100%',
    backgroundColor: `${colors.brand}`,
    paddingLeft: '16px',
    paddingRight: '8px',

    title: {
      color: `${colors.whiteSmoke}`,
      alignSelf: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    buttons: {
      display: 'grid',
      justifyContent: 'right',
      alignContent: 'center',
      gridAutoFlow: 'column',
      gridGap: '8px',

      button: {
        backgroundColor: 'transparent',
        color: 'white',
        width: '32px',
        height: '32px',
      },
    },
  },
};

const NavigationAcceptanceProtocol = (props) => {
  const {
    protocolId,
    basedataTemplate,
    indexedBasedataInput,
    orderedComponents,
    componentsInput,
    componentsOrderInput,
    indexedPreviousComponentsInput,
    indexedComponentsInput,
    indexedComponentsTemplate,
    indexedElementsTemplate,
    language,
    selected,
    scrollTo,
    protocolHasPreviousComponentsInput,
    actions: {
      commonPatchOperation,
      commonAddComponent,
      fillStepSelectElement,
      fillStepScrolledToComponent,
      commonDuplicateAllComponents,
      commonDuplicateComponent,
      commonCopyComponent,
      commonRemoveComponent,
      fillStepEditComponent,
    },
  } = props;

  //TODO: FIX it
  // if (!basedataTemplate || !componentsInput) return null;

  const [title, setTitle] = useState(null);
  const [isReordering, setIsReordering] = useState(false);
  const [accordionReorderingId, setAccordionReorderingId] = useState(null);
  const [isOpen, setIsOpen] = useState({ basedata: true });
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const accordionComponents = useRef({});

  const bodyRef = useRef(null);
  const accordionRef = useRef({});

  const containerStyle = useCallback(
    () => ({
      display: 'grid',
      gridTemplateRows: `${navigationHeaderHeight}px calc(100% - ${navigationHeaderHeight}px)`,
      backgroundColor: `${colors.dark}`,
      overflow: 'hidden',
      // Using a defined height for avoiding a Safari bug on iOS 12.4 (iPad)
      height: `calc(${windowHeight}px - ${variables.topbar})`,
    }),
    [windowHeight],
  );

  const handleEditComponent = useCallback(
    (id) => {
      adjustElementsOrder(
        id,
        indexedComponentsInput,
        indexedComponentsTemplate,
        protocolId,
        commonPatchOperation,
      );
      fillStepEditComponent(id);
    },
    [
      indexedComponentsInput,
      indexedComponentsTemplate,
      protocolId,
      commonPatchOperation,
      fillStepEditComponent,
    ],
  );

  const [handleDebouncedScroll] = useDebouncedCallback(
    useCallback(
      (scrollTop) => {
        const newTitle = getTitleByScroll(
          values(accordionComponents.current),
          bodyRef.current.offsetTop,
          scrollTop + navigationHeaderHeight - 50,
        );
        if (newTitle !== title) {
          setTitle(newTitle);
        }
      },
      [title],
    ),
    250,
  );

  const handleAddAccordion = useCallback(
    (id, title, ref) => {
      accordionComponents.current[id] = { title, ref };
      // when we add a component and we scroll to it
      if (!!scrollTo) {
        const accordion = accordionComponents.current[scrollTo];
        if (!!accordion) {
          scrollBody(bodyRef, accordion.ref.current.offsetTop);
          fillStepScrolledToComponent();
          handleEditComponent(id);
        }
      }
    },
    [accordionComponents, fillStepScrolledToComponent, handleEditComponent, scrollTo],
  );

  const handleRemoveAccordion = useCallback(
    (id) => {
      delete accordionComponents.current[id];
      commonRemoveComponent(id);
    },
    [commonRemoveComponent],
  );

  const handleScroll = useCallback(
    (evt) => handleDebouncedScroll(evt.currentTarget.scrollTop),
    [handleDebouncedScroll],
  );

  const handleOpen = useCallback(
    (id) => {
      if (!isReordering) {
        setIsOpen({ ...isOpen, [id]: !isOpen[id] });
      } else {
        setIsReordering(false);
      }
    },
    [isReordering, isOpen],
  );

  const handleReorder = useCallback(() => {
    setIsReordering(!isReordering);

    if (!isReordering) {
      let current = componentsOrderInput;
      if (!current || current.length === 0) {
        current = map((ci) => ci.componentInput.id, orderedComponents);
      }

      // Clean up not valid ids
      const validIds = map(
        (c) => c.id,
        filter(
          (c) => !c.removed && (!!indexedComponentsTemplate[c.typeId] || !!c.localName),
          componentsInput,
        ),
      );
      current = filter((id) => indexOf(id, validIds) > -1, current);

      // Add valid components in the componentsOrderInput
      map((id) => {
        if (indexOf(id, current) === -1) {
          current.push(id);
        }
      }, validIds);

      commonPatchOperation(protocolId, 'componentsOrderInput', current);
    }
  }, [
    isReordering,
    setIsReordering,
    indexedComponentsTemplate,
    orderedComponents,
    componentsOrderInput,
    componentsInput,
    commonPatchOperation,
    protocolId,
  ]);

  const handleDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      if (result.type === 'component') {
        let current = componentsOrderInput;
        current = move(result.source.index - 1, result.destination.index - 1, current);

        commonPatchOperation(protocolId, 'componentsOrderInput', current);
      } else {
        const [, componentId, ,] = split('-', result.draggableId);
        let componentInput = indexedComponentsInput[componentId];
        let current = componentInput.elementsOrder;

        current = move(result.source.index, result.destination.index, current);

        commonPatchOperation(protocolId, `componentsInput/[${componentId}]/elementsOrder`, current);
      }
    },
    [protocolId, indexedComponentsInput, componentsOrderInput, commonPatchOperation],
  );

  const handleAddComponent = useCallback(() => {
    commonAddComponent();
  }, [commonAddComponent]);

  const handleAccordionReorder = useCallback(
    (id) => {
      setIsOpen({ ...isOpen, [id]: true });
      setAccordionReorderingId(id);

      if (!!id) {
        adjustElementsOrder(
          id,
          indexedComponentsInput,
          indexedComponentsTemplate,
          protocolId,
          commonPatchOperation,
        );
      }
    },
    [
      setAccordionReorderingId,
      indexedComponentsInput,
      indexedComponentsTemplate,
      protocolId,
      isOpen,
      commonPatchOperation,
    ],
  );

  useLayoutEffect(() => {
    if (!!scrollTo) {
      const accordion = accordionComponents.current[scrollTo];
      if (!!accordion) {
        scrollBody(bodyRef, accordion.offset);
        fillStepScrolledToComponent();
      }
    }
  }, [scrollTo, fillStepScrolledToComponent]);

  const handleDuplicateAll = useCallback(() => {
    commonDuplicateAllComponents();
  }, [commonDuplicateAllComponents]);

  const getIsDuplicable = useCallback(
    (id) => {
      const previousComponentsInput = indexedPreviousComponentsInput[id];
      return (
        !!previousComponentsInput &&
        !!previousComponentsInput.elements &&
        !!previousComponentsInput.elements.length
      );
    },
    [indexedPreviousComponentsInput],
  );

  const handleResize = useCallback(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <div css={containerStyle}>
      <div css={styles.top}>
        <div css={styles.top.title}>{title}</div>
        <div css={styles.top.buttons}>
          {!isReordering && !accordionReorderingId && (
            <button onClick={handleDuplicateAll} disabled={!protocolHasPreviousComponentsInput}>
              <DuplicateIcon disabled={!protocolHasPreviousComponentsInput} />
            </button>
          )}
          {!accordionReorderingId && (
            <button onClick={handleReorder}>
              <ReorderIcon />
            </button>
          )}
          {!isReordering && !accordionReorderingId && (
            <button onClick={handleAddComponent}>
              <PlusIcon />
            </button>
          )}
        </div>
      </div>
      <div css={styles.body} onScroll={handleScroll} ref={bodyRef}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="components" type="component">
            {(provided) => (
              <div css={styles.content} ref={provided.innerRef} {...provided.droppableProps}>
                <Accordion
                  key="basedata"
                  title={i18n._('LABELS.BASE-DATA')}
                  id="basedata"
                  type="basedata"
                  elementTemplates={basedataTemplate}
                  indexedElementInputs={indexedBasedataInput}
                  language={language}
                  selectedComponentId={!!selected && selected.component.id}
                  selectedElementId={!!selected && !!selected.element && selected.element.id}
                  getLocalized={getLocalizedLabel}
                  isOpen={
                    isOpen['basedata'] &&
                    !isReordering &&
                    (!accordionReorderingId || accordionReorderingId === 'basedata')
                  }
                  isReordering={isReordering}
                  isAccordionReordering={accordionReorderingId === 'basedata'}
                  index={0}
                  onSelectElement={fillStepSelectElement}
                  onAddAccordion={handleAddAccordion}
                  onOpen={handleOpen}
                  onAccordionReorder={handleAccordionReorder}
                />
                {addIndex(map)(({ componentInput, componentTemplate }, index) => {
                  const elementTemplates = getOrderedElementsTemplate(
                    componentInput.elements,
                    componentInput.elementsOrder,
                    indexedElementsTemplate,
                    componentTemplate,
                  );

                  const indexedElementInputs =
                    componentInput.elements && indexBy(prop('id'), componentInput.elements);

                  if (isOpen[componentInput.id] === undefined) {
                    isOpen[componentInput.id] = true;
                  }

                  return (
                    <Accordion
                      key={componentInput.id}
                      title={getLocalizedNameForComponent(
                        componentInput,
                        componentTemplate,
                        language,
                      )}
                      id={componentInput.id}
                      type="component"
                      elementTemplates={elementTemplates}
                      indexedElementInputs={indexedElementInputs}
                      language={language}
                      selectedComponentId={!!selected && selected.component.id}
                      selectedElementId={!!selected && !!selected.element && selected.element.id}
                      getLocalized={getLocalizedName}
                      elementsOrder={componentInput.elementsOrder}
                      isOpen={
                        isOpen[componentInput.id] &&
                        !isReordering &&
                        (!accordionReorderingId || accordionReorderingId === componentInput.id)
                      }
                      isReordering={isReordering}
                      accordionReorderingId={accordionReorderingId}
                      index={index + 1}
                      ref={accordionRef}
                      isDuplicable={getIsDuplicable(componentInput.id)}
                      onSelectElement={fillStepSelectElement}
                      onAddAccordion={handleAddAccordion}
                      onOpen={handleOpen}
                      onDuplicate={commonDuplicateComponent}
                      onCopy={commonCopyComponent}
                      onRemove={handleRemoveAccordion}
                      onAccordionReorder={handleAccordionReorder}
                      onEditComponent={handleEditComponent}
                    />
                  );
                }, orderedComponents)}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

function scrollBody(bodyRef, offsetTop, isElement = false) {
  if (!!bodyRef.current) {
    bodyRef.current.scrollTop =
      offsetTop - bodyRef.current.offsetTop + (!isElement ? navigationHeaderHeight : 0);
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    protocolId: selectCurrentId(state),
    orderedComponents: selectOrderedComponents(state),
    basedataTemplate: selectBasedataTemplate(state),
    indexedBasedataInput: selectIndexedBasedataInput(state),
    componentsInput: selectComponentsInput(state),
    componentsOrderInput: selectComponentsOrderInput(state),
    indexedPreviousComponentsInput: selectIndexedPreviousComponentsInput(state),
    indexedComponentsInput: selectIndexedComponentsInput(state),
    indexedComponentsTemplate: selectIndexedComponentsTemplate(state),
    indexedElementsTemplate: selectIndexedElementsTemplate(state),
    language: selectProtocolLanguage(state),
    selected: selectSelected(state),
    scrollTo: selectScrollTo(state),
    protocolHasPreviousComponentsInput: selectCurrentProtocolHasPreviousComponentsInput(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationAcceptanceProtocol);
