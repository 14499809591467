/** @jsxImportSource @emotion/react */

import classNames from 'classnames';
import Fade from 'react-reveal/Fade';
import { useMemo, memo, Fragment, useState, useCallback, useEffect, useRef } from 'react';

import { Icon } from '../../../features/common/Icon';
import { colors } from '../../../common/theme/colors';
import { MoreIcon } from '../../common/MoreIcon';
import { ReorderIcon } from '../../common/ReorderIcon';
import { DuplicateIcon } from '../../common/DuplicateIcon';
import { CopyIcon } from '../../common/CopyIcon';
import { MinusIcon } from '../../common/MinusIcon';
import { WriteIcon } from '../../common/WriteIcon';
import { ReorderSmallIcon } from '../../common/ReorderSmallIcon';
import { variables } from '../../../common/theme/variables';

const styles = {
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateRows: '50px',
    gridTemplateColumns: 'auto min-content',
    alignItems: 'center',
    paddingRight: '20px',

    backgroundColor: `${colors.darkAccordion}`,

    ':first-of-type': {
      borderTop: `1px ${colors.greyBorderAcceptanceProtocol} solid`,
    },
    borderBottom: `1px ${colors.greyBorderAcceptanceProtocol} solid`,
  },

  title: {
    color: `${colors.whiteSmoke}`,
    textAlign: 'left',
    userSelect: 'none',
    backgroundColor: 'transparent',
    '& > svg': {
      margin: '0 10px 0 5px',
    },
  },

  menuButtonsContainer: {
    position: 'absolute',
    right: '56px',
  },
  menuButtons: {
    backgroundColor: `${colors.darkAccordion}`,
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '0.4em',
    alignContent: 'center',
    button: {
      backgroundColor: 'transparent',
      color: 'white',
      width: '32px',
      height: '32px',
    },

    [variables.breakpoints.xl]: {
      gridGap: '1em',
    },
  },
  menuButton: {
    backgroundColor: 'transparent',
    color: 'white',
    width: '24px',
    height: '24px',
    '&.is-active': {
      color: colors.grey80,
    },
  },
  arrow: {
    transition: '0.5s transform',
    '&.is-open': {
      transform: 'rotate(90deg)',
    },
  },
};

const AccordionHeader = ({
  id,
  isOpen,
  isReordering,
  title,
  type,
  dragHandleProps,
  accordionReorderingId,
  isDuplicable,
  onOpen,
  onDuplicate,
  onRemove,
  onCopy,
  onAccordionReorder,
  onEditComponent,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const handleMenuClick = useCallback(() => setShowMenu(!showMenu), [showMenu, setShowMenu]);
  const menuRef = useRef(null);
  const buttonMenuRef = useRef(null);

  const handleOnDuplicate = useCallback(() => {
    onDuplicate();
    setShowMenu(false);
  }, [onDuplicate, setShowMenu]);

  const handleOnCopy = useCallback(() => {
    onCopy();
    setShowMenu(false);
  }, [onCopy, setShowMenu]);

  const headerButtons = useMemo(() => {
    if (type.toLowerCase() !== 'basedata') {
      if (isReordering) {
        return isReorderingHeader(dragHandleProps);
      }

      if (accordionReorderingId === id) {
        return isMyAccordionReordering(onAccordionReorder);
      }

      if (!accordionReorderingId) {
        return isNotAccordionReordering(
          menuRef,
          buttonMenuRef,
          showMenu,
          isDuplicable,
          onAccordionReorder,
          handleOnDuplicate,
          handleOnCopy,
          onRemove,
          handleMenuClick,
          onEditComponent,
        );
      }

      return false;
    }
  }, [
    type,
    showMenu,
    isReordering,
    handleMenuClick,
    accordionReorderingId,
    isDuplicable,
    onRemove,
    onAccordionReorder,
    onEditComponent,
    handleOnCopy,
    handleOnDuplicate,
    id,
    dragHandleProps,
  ]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        showMenu &&
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        buttonMenuRef.current &&
        !buttonMenuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    },
    [menuRef, buttonMenuRef, showMenu, setShowMenu],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    // returned function will be called on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <div css={styles.container}>
      <button onClick={onOpen} css={styles.title}>
        <Icon
          css={styles.arrow}
          className={classNames({ 'is-open': isOpen })}
          icon={'chevron-right'}
        />
        {title}
      </button>
      {headerButtons}
    </div>
  );
};

export default memo(AccordionHeader);

function isMyAccordionReordering(onAccordionReorder) {
  return (
    <button css={styles.menuButtons.button} onClick={onAccordionReorder}>
      <ReorderIcon />
    </button>
  );
}

function isNotAccordionReordering(
  menuRef,
  buttonMenuRef,
  showMenu,
  isDuplicable,
  onAccordionReorder,
  onDuplicate,
  onCopy,
  onRemove,
  handleMenuClick,
  onEditComponent,
) {
  return (
    <Fragment>
      <div css={styles.menuButtonsContainer}>
        <Fade right when={showMenu} collapse>
          <div ref={menuRef} css={styles.menuButtons}>
            <button onClick={onDuplicate} disabled={!isDuplicable}>
              <DuplicateIcon disabled={!isDuplicable} />
            </button>
            <button onClick={onAccordionReorder}>
              <ReorderIcon />
            </button>
            <button onClick={onCopy}>
              <CopyIcon />
            </button>
            <button onClick={onRemove}>
              <MinusIcon />
            </button>
            <button onClick={onEditComponent}>
              <WriteIcon />
            </button>
          </div>
        </Fade>
      </div>
      <button
        css={styles.menuButton}
        ref={buttonMenuRef}
        onClick={handleMenuClick}
        className={classNames({ 'is-active': showMenu })}
      >
        <MoreIcon />
      </button>
    </Fragment>
  );
}

function isReorderingHeader(dragHandleProps) {
  return (
    <div css={styles.menuButtons.button} {...dragHandleProps}>
      <ReorderSmallIcon />
    </div>
  );
}
