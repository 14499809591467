import produce from 'immer';
import { reject, findLast, filter, pipe, map, uniq } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import {
  selectIndexedComponentsInput,
  selectIndexedComponentsTemplate,
  selectCurrentId,
  selectProtocolLanguage,
} from '../../redux/selectors';
import {
  FILL_STEP_CLOSE_EDIT_COMPONENT_MODAL,
  FILL_STEP_EDIT_COMPONENT,
  FILL_STEP_OPEN_EDIT_COMPONENT_MODAL,
  FILL_STEP_SAVE_EDIT_COMPONENT,
} from './constants';
import { getLocalizedNameForComponent } from '../Functions';
import { COMMON_PATCH_OPERATION } from '../../../common/redux/constants';
import { EditComponentOperations } from '../../../common/EditComponentModal';

export function fillStepEditComponent(componentId) {
  return {
    type: FILL_STEP_EDIT_COMPONENT,
    payload: componentId,
  };
}

export function fillStepOpenEditComponentModal(editComponent) {
  return {
    type: FILL_STEP_OPEN_EDIT_COMPONENT_MODAL,
    payload: editComponent,
  };
}

export function fillStepCloseEditComponentModal() {
  return {
    type: FILL_STEP_CLOSE_EDIT_COMPONENT_MODAL,
  };
}

export function fillStepSaveEditComponent(componentId, queue) {
  return {
    type: FILL_STEP_SAVE_EDIT_COMPONENT,
    payload: { componentId, queue },
  };
}

function* doOpenModal({ payload }) {
  const indexedComponentsInput = yield select(selectIndexedComponentsInput);
  const indexedComponentsTemplate = yield select(selectIndexedComponentsTemplate);
  const language = yield select(selectProtocolLanguage);

  const componentInput = indexedComponentsInput[payload];
  const componentTemplate = indexedComponentsTemplate[componentInput.typeId];

  yield put(
    fillStepOpenEditComponentModal({
      id: payload,
      componentHeader: getLocalizedNameForComponent(componentInput, componentTemplate, language),
      componentInput,
      componentTemplate,
    }),
  );
}

function* doSaveEditComponent({ payload: { componentId, queue } }) {
  const protocolId = yield select(selectCurrentId);
  const indexedComponentsInput = yield select(selectIndexedComponentsInput);
  const componentInput = indexedComponentsInput[componentId];
  let updatedElementsOrder = componentInput.elementsOrder;

  for (const queueEl of queue) {
    switch (queueEl.type) {
      case EditComponentOperations.REMOVE:
        if (!!updatedElementsOrder) {
          updatedElementsOrder = reject((elId) => elId === queueEl.elementId)(updatedElementsOrder);
        }

        const removeElementExpression =
          // prettier-ignore
          `componentsInput/[${componentId}]/elements/[${queueEl.elementId}]`;

        yield put({
          type: COMMON_PATCH_OPERATION,
          payload: {
            protocolId,
            expression: removeElementExpression,
            value: {
              removed: true,
              copied: false,
              attributesSelected: null,
              images: null,
              comment: null,
              state: null,
            },
          },
        });
        break;
      case EditComponentOperations.ADD:
        if (!!updatedElementsOrder) {
          updatedElementsOrder.push(queueEl.elementId);
        }

        const addElementExpression =
          // prettier-ignore
          `componentsInput/[${componentId}]/elements/[${queueEl.elementId}]`;

        yield put({
          type: COMMON_PATCH_OPERATION,
          payload: {
            protocolId,
            expression: addElementExpression,
            value: {
              removed: false,
              copied: false,
              attributesSelected: null,
              images: null,
              comment: null,
              state: null,
            },
          },
        });
        break;
      default:
        break;
    }
  }

  if (!!updatedElementsOrder) {
    const updateOrderExpression =
      // prettier-ignore
      `componentsInput/[${componentId}]/elementsOrder`;

    yield put({
      type: COMMON_PATCH_OPERATION,
      payload: {
        protocolId,
        expression: updateOrderExpression,
        value: updatedElementsOrder,
      },
    });
  }

  const lastLocalName = findLast((q) => q.type === EditComponentOperations.EDIT_LOCAL_NAME, queue);

  if (!!lastLocalName) {
    const updateLocalNameExpression =
      // prettier-ignore
      `componentsInput/[${lastLocalName.componentId}]/localName`;
    if (!!lastLocalName.name) {
      yield put({
        type: COMMON_PATCH_OPERATION,
        payload: {
          protocolId,
          expression: updateLocalNameExpression,
          value: lastLocalName.name,
        },
      });
    }
  }

  const getElementIds = pipe(
    filter((q) => q.type === EditComponentOperations.EDIT_ELEMENT_LOCAL_NAME),
    map((q) => q.elementId),
    uniq,
  );

  for (const elementId of getElementIds(queue)) {
    const queueEl = findLast(
      (q) =>
        q.type === EditComponentOperations.EDIT_ELEMENT_LOCAL_NAME && q.elementId === elementId,
      queue,
    );

    if (!!queueEl) {
      const editElementExpression =
        // prettier-ignore
        `componentsInput/[${componentId}]/elements/[${queueEl.elementId}]/localName`;

      yield put({
        type: COMMON_PATCH_OPERATION,
        payload: {
          protocolId,
          expression: editElementExpression,
          value: queueEl.name,
        },
      });
    }
  }

  yield put(fillStepCloseEditComponentModal());
}

export function* switchEditComponent() {
  yield takeLatest(FILL_STEP_EDIT_COMPONENT, doOpenModal);
  yield takeLatest(FILL_STEP_SAVE_EDIT_COMPONENT, doSaveEditComponent);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_OPEN_EDIT_COMPONENT_MODAL:
        draft.ui.modal.editComponent = true;
        draft.currentEditComponent = action.payload;
        break;
      case FILL_STEP_CLOSE_EDIT_COMPONENT_MODAL:
        draft.ui.modal.editComponent = false;
        draft.currentEditComponent = null;
        break;
      default:
        return state;
    }
  });
