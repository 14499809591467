import { createSelector } from 'reselect';

import { getById } from '../../../common/Functions';
import {
  selectIndexedBasedataInput,
  selectIndexedBasedataTemplate,
  selectIndexedComponentsInput,
  selectIndexedComponentsTemplate,
  selectIndexedElementsTemplate,
  selectIndexedAttributesTemplate,
} from '../../redux/selectors';

const selectFillStep = (state) => state.fillStep;

export const selectSelectedIds = createSelector(selectFillStep, (fillStep) => fillStep.selectedIds);

export const selectSelected = createSelector(
  selectSelectedIds,
  selectIndexedBasedataInput,
  selectIndexedBasedataTemplate,
  selectIndexedComponentsInput,
  selectIndexedComponentsTemplate,
  selectIndexedElementsTemplate,
  selectIndexedAttributesTemplate,
  (
    elementSelectedIds,
    indexedBasedataInput,
    indexedBasedataTemplate,
    indexedComponentsInput,
    indexedComponentsTemplate,
    indexedElementsTemplate,
    indexedAttributesTemplate,
  ) => {
    const { type, componentId, elementId, attributeId } = elementSelectedIds;

    let result = null;
    let component = null;
    let element = null;
    let attribute = null;

    if (type === 'basedata') {
      if (!!componentId) {
        component = {
          id: componentId,
        };

        if (!!elementId) {
          const basedataInput = indexedBasedataInput[elementId];

          if (!!basedataInput) {
            const basedataTemplate = indexedBasedataTemplate[basedataInput.typeId];
            element = {
              id: elementId,
              template: basedataTemplate,
              input: basedataInput,
            };
          }
        }
      }
    } else {
      if (!!componentId) {
        const input = indexedComponentsInput[componentId];
        const template = indexedComponentsTemplate[input.typeId];
        component = { id: componentId, template, input };

        if (!!elementId) {
          element = {
            id: elementId,
            template: indexedElementsTemplate[elementId],
            input:
              component.input &&
              component.input.elements &&
              getById(elementId)(component.input.elements),
          };

          if (!!attributeId) {
            attribute = {
              id: attributeId,
              template: indexedAttributesTemplate[attributeId],
              input:
                element.input &&
                element.input.attributesSelected &&
                getById(attributeId)(element.input.attributesSelected),
            };
          }
        }
      }
    }

    if (!!component) {
      result = { component, type };

      if (!!element) {
        result.element = element;

        if (!!attribute) {
          result.attribute = attribute;
        }
      }
    }

    return result;
  },
);

export const selectScrollTo = createSelector(selectFillStep, (state) => state.scrollTo);

export const selectIsCostModalOpen = createSelector(selectFillStep, (state) => state.ui.modal.cost);

export const selectIsCostModalBusy = createSelector(selectFillStep, (state) => state.ui.busy.cost);

export const selectIsImageSketcherModalOpen = createSelector(
  selectFillStep,
  (state) => state.ui.modal.imageSketcher,
);

export const selectIsImageTakePhotoModalOpen = createSelector(
  selectFillStep,
  (state) => state.ui.modal.takePhoto,
);

export const selectIsShowSpinner = createSelector(selectFillStep, (state) => state.ui.busy.spinner);

export const selectCurrentEditImage = createSelector(
  selectFillStep,
  (state) => state.currentEditImage,
);

export const selectIsViewImageOpen = createSelector(
  selectFillStep,
  (state) => state.ui.modal.viewImage,
);

export const selectCurrentViewImage = createSelector(
  selectFillStep,
  (state) => state.currentViewImage,
);

export const selectIsEditComponentModalOpen = createSelector(
  selectFillStep,
  (state) => state.ui.modal.editComponent,
);

export const selectCurrentEditComponent = createSelector(
  selectFillStep,
  (state) => state.currentEditComponent,
);
