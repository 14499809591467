import React from 'react';

export const ReorderSmallIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <rect x="16" y="5" width="5" height="1" />
    <rect x="16" y="8" width="5" height="1" />
    <rect x="16" y="11" width="5" height="1" />
    <rect x="16" y="14" width="5" height="1" />
    <rect x="16" y="17" width="5" height="1" />
    <rect x="16" y="20" width="5" height="1" />
    <path d="M9.81,13A.43.43,0,0,0,10,13a.47.47,0,0,0,.35-.15l4-4a.48.48,0,0,0,0-.7l-4-4A.47.47,0,0,0,9.81,4a.5.5,0,0,0-.31.46V8H5V18h9V17H6V9H9.5v3.5A.5.5,0,0,0,9.81,13Zm.69-7.25L13.29,8.5,10.5,11.29Z" />
  </svg>
);
