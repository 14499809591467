import { DateTime } from 'luxon';
import {
  curry,
  find,
  findIndex,
  insert,
  lensIndex,
  map,
  nth,
  pipe,
  remove,
  set,
  unionWith,
  filter,
  reduce,
  union,
  indexBy,
  keys,
  sortBy,
  difference,
  concat,
  toString,
  isEmpty,
  pluck,
  without,
  indexOf,
} from 'ramda';
import React from 'react';

import { colors } from '../../common/theme/colors';
import { Icon } from './Icon';

export const getBasedataInput = (protocols, id) => {
  const protocol = protocols.find((x) => x.id === id);
  return (!!protocol && !!protocol.input && protocol.input.basedataInputs) || null;
};

export const getComponentsInput = (protocols, id) => {
  const protocol = protocols.find((x) => x.id === id);
  return (!!protocol && !!protocol.input && protocol.input.componentsInput) || null;
};

export const getProtocolSignatures = (protocols, id) => {
  const protocol = protocols.find((x) => x.id === id);
  return (!!protocol && !!protocol.input && protocol.input.signatures) || null;
};

export const getElement = (input, id, index) => {
  const elements = (!!input && input.filter((x) => x.id === id)) || null;
  return (!!elements && elements[index || 0]) || null;
};

export const getField = (element, fieldname) => {
  return (
    (!!element && !!element.fields && element.fields.find((x) => x.fieldname === fieldname)) || null
  );
};

export const getSibling = (field, siblingFieldname) => {
  return (
    (!!field && !!field.siblings && field.siblings.find((x) => x.fieldname === siblingFieldname)) ||
    null
  );
};

export const getFindById = (items, id) => {
  return (!!items && items.find((x) => x.id === id)) || null;
};

export const getFindIndexById = (items, id) => {
  return !!items ? items.findIndex((x) => x.id === id) : -1;
};

export const addOrUpdateElement = (basedataInput, id, index) => {
  let element = getElement(basedataInput, id, index);
  if (!element) {
    element = { id, fields: [] };
    basedataInput.push(element);
  }
  return element;
};

export const addOrUpdateField = (element, fieldname) => {
  let field = getField(element, fieldname);
  if (!field) {
    field = { fieldname };
    element.fields.push(field);
  }
  return field;
};

export const addOrUpdateSibling = (field, siblingFieldname) => {
  let sibling = getSibling(field, siblingFieldname);
  if (!sibling) {
    sibling = { siblingFieldname };
    if (!field.siblings) field.siblings = [];
    field.siblings.push(sibling);
  }
  return sibling;
};

export const getById = (id) => find((p) => p.id === id);
export const getByProperty = (property, id) => find((p) => p[property] === id);
export const getIndexByProperty = (property, id) => findIndex((p) => p[property] === id);

export const getElementByIds = (protocols, protocolId, componentId, elementId) => {
  const protocol = getById(protocolId)(protocols);
  const component = getById(componentId)(protocol.input.componentsInput);

  return getById(elementId)(component.elements);
};

export const getOrCreateElementByIds = (protocols, protocolId, componentId, elementId) => {
  const protocol = getById(protocolId)(protocols);
  const component = getById(componentId)(protocol.input.componentsInput);

  if (!component.elements) {
    const element = { id: elementId };
    component.elements = [element];
    return element;
  }

  const element = getById(elementId)(component.elements);

  if (!element) {
    const element = { id: elementId };
    component.elements.push(element);
    return element;
  }

  return element;
};

export const stateIndexes = ['not available', 'ok', 'normal usage', 'defect'];
export function getStateIndex(state) {
  return stateIndexes.indexOf(state || -1);
}

const stateBackgroundColors = [colors.success, colors.partiallySuccess, colors.error];
export const getStateStyle = (index) => {
  return index > 0
    ? {
        backgroundColor: stateBackgroundColors[index - 1],
      }
    : {};
};

const stateSymbols = [
  <Icon icon="plus" size="xs" />,
  <Icon icon="minus" size="xs" />,
  <Icon icon="times" size="xs" />,
];
export function getStateSymbol(index) {
  return stateSymbols[index - 1];
}

export function getStateFontStyle(index) {
  return index === 0 ? { textDecoration: 'line-through' } : {};
}

export function formatAsDecimals(value, decimals = 2) {
  return parseFloat(value, 10).toFixed(decimals);
}

export function getFloatFractionValues(fraction) {
  if (!fraction) return;

  let { sup, sub } = getFraction(fraction, 2);

  sup = parseFloat(sup) || 0;
  sub = parseFloat(sub) || 0;

  return { sup, sub };
}

export function getFormattedFractionInline(fraction) {
  const fractionValues = getFloatFractionValues(fraction);

  return `${formatAsDecimals(fractionValues.sup)}/${formatAsDecimals(fractionValues.sub)}`;
}

export function getPercentageFromFractionString(fraction) {
  const fractionValues = getFloatFractionValues(fraction);

  if (fractionValues) {
    return formatAsDecimals(
      fractionValues.sup > 0 ? (fractionValues.sup / fractionValues.sub) * 100 : '',
    );
  }
  return '';
}

export function formatAsDate(date) {
  return DateTime.fromISO(date).toFormat(dateFormat);
}

export const jsonDateFormat = 'MM/dd/yyyy hh:mm:ss';
export const dateFormat = 'dd.MM.yyyy';

export const swap = curry((index1, index2, list) => {
  if (index1 < 0 || index2 < 0 || index1 > list.length - 1 || index2 > list.length - 1) {
    return list; // index out of bound
  }
  const value1 = list[index1];
  const value2 = list[index2];
  return pipe(set(lensIndex(index1), value2), set(lensIndex(index2), value1))(list);
});

export const move = curry((at, to, list) => insert(to, nth(at, list), remove(at, 1, list)));

export function getCopiedStyle(input) {
  return !!input && !!input.copied && input.copied ? { backgroundColor: `${colors.brand}` } : null;
}

export function getOrderedComponents(
  componentsInput,
  componentsOrderInput,
  indexedComponentsTemplate,
) {
  const indexedPairedComponents = indexBy(
    (ci) => ci.componentInput.id,
    map((componentInput) => {
      const componentTemplate = indexedComponentsTemplate[componentInput.typeId];
      return { componentInput, componentTemplate: componentTemplate };
    }, filter((ci) => !ci.removed && (!!indexedComponentsTemplate[ci.typeId] || !!ci.localName))(componentsInput)),
  );

  const orderedComponents = map(
    (orderId) => indexedPairedComponents[orderId],
    componentsOrderInput || [],
  );

  const unorderedComponents = sortBy(
    (p) => p.componentTemplate && p.componentTemplate.order,
    map(
      (orderId) => indexedPairedComponents[orderId],
      difference(keys(indexedPairedComponents), map(toString, componentsOrderInput || [])),
    ),
  );

  return filter((comp) => !!comp)(concat(orderedComponents, unorderedComponents));
}

export function getOrderedElementsTemplate(
  componentInputElements,
  componentInputElementsOrder,
  indexedElementsTemplate,
  componentTemplate,
) {
  const elements = unionWith(
    (t, i) => t.id === i.id,
    map(
      (elId) => {
        const ci = !!componentInputElements && getById(elId)(componentInputElements);
        return !!ci
          ? ci
          : {
              id: elId,
              removed: false,
            };
      },
      !!componentTemplate ? componentTemplate.elements : [],
    ),
    componentInputElements,
  );

  let elementTemplates = map(
    (el) => indexedElementsTemplate[el.id],
    filter((el) => !el.removed)(elements),
  );

  if (!!componentInputElementsOrder) {
    const { ordered, unordered } = reduce(
      (acc, cur) => {
        const index = componentInputElementsOrder.indexOf(cur.id);
        if (index > -1) {
          acc.ordered[index] = cur;
        } else {
          acc.unordered.push(cur);
        }
        return acc;
      },
      { ordered: [], unordered: [] },
      elementTemplates,
    );

    elementTemplates = union(
      filter((el) => !!el, ordered),
      unordered,
    );
  }

  return filter((el) => !!el)(elementTemplates);
}

export const isNotEmptyArray = (array) => !!array && !isEmpty(array);

export const isNotEmptyElementInput = (elem) =>
  !!elem &&
  (isNotEmptyArray(elem.attributesSelected) ||
    isNotEmptyArray(elem.images) ||
    !!elem.comment ||
    !!elem.state);

export const getIndexedElements = (componentInputsIndexed, elementId, componentId) => {
  const element = find((ie) => {
    return ie.elementId === elementId && ie.componentId === componentId;
  }, componentInputsIndexed);
  if (!!element) {
    return element.index;
  }
  return null;
};

export const adjustElementsOrder = (
  id,
  indexedComponentsInput,
  indexedComponentsTemplate,
  protocolId,
  patch,
) => {
  let componentInput = indexedComponentsInput[id];
  let current = componentInput.elementsOrder;

  const elementsInput = componentInput.elements || [];
  const componentTemplateIds =
    !!indexedComponentsTemplate &&
    !!indexedComponentsTemplate[componentInput.typeId] &&
    !!indexedComponentsTemplate[componentInput.typeId].elements
      ? indexedComponentsTemplate[componentInput.typeId].elements
      : [];
  const pluckId = pluck('id');
  const removedIds = map(
    (c) => c.id,
    filter((c) => !!c.removed, elementsInput),
  );
  const validIds = without(removedIds, union(componentTemplateIds, pluckId(elementsInput)));

  if (!current || current.length === 0) {
    current = validIds;
  } else {
    current = concat(current, without(current, componentTemplateIds));
    current = filter((currentId) => indexOf(currentId, validIds) > -1, current);
  }

  patch(protocolId, `componentsInput/[${id}]/elementsOrder`, current);
};

export const getFraction = (value, fixedDecimals = undefined) => {
  if (!!value) {
    const result = /(.+)\/(.+)/gi.exec(value);

    if (!result) return;

    const [, sup, sub] = result;

    return {
      sup: parseFloatOrReturnString(sup, fixedDecimals),
      sub: parseFloatOrReturnString(sub, fixedDecimals),
    };
  }
};

const parseFloatOrReturnString = (num, fixedDecimals) => {
  const parsedNum = parseFloat(num);
  if (!isNaN(parsedNum)) {
    return fixedDecimals ? parsedNum.toFixed(fixedDecimals) : parsedNum;
  }
  return num;
};
