/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as commonActions from '../../common/redux/actions';
import Tab from '../../common/Tab';
import TabContent from '../../common/TabContent';
import Tabs from '../../common/Tabs';
import TabTitle from '../../common/TabTitle';
import ToggleButton from '../../common/ToggleButton';
import { getById } from '../../common/Functions';
import {
  selectCurrentId,
  selectIndexedAttributesTemplate,
  selectIndexedAttributeCategoriesTemplate,
  selectIsCurrentProtocolOffline,
  selectProtocolLanguage,
  selectCurrentProtocolTotalImages,
  selectTemplateCosts,
} from '../redux/selectors';
import InputAttributes from './InputAttributes';
import { InputComment } from './InputComment';
import InputImages from './InputImages';
import * as actions from './redux/actions';
import { selectIsImageTakePhotoModalOpen, selectSelected } from './redux/selectors';
import { colors } from '../../../common/theme/colors';

const styles = {
  container: {
    display: 'grid',
    height: '100%',
  },
  content: {
    padding: '1.5em',
    display: 'grid',
    gridTemplateRows: 'auto min-content',
    gridGap: '1em 0',
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '0 1em',
  },
};

export const InputElement = (props) => {
  const {
    templateCosts,
    protocolId,
    selected,
    language,
    indexedAttributesTemplate,
    indexedAttributeCategoriesTemplate,
    isTakePhotoModalOpen,
    isOfflineProtocol,
    totalProtocolImages,
    actions: {
      fillStepNavigateNextElement,
      commonPatchOperation,
      commonDeleteOperation,
      fillStepOpenImageSketcherModal,
      fillStepOpenViewImageModal,
      fillStepDeleteAllImages,
      fillStepOpenCostModal,
      fillStepOpenTakePhotoModal,
      fillStepCloseTakePhotoModal,
      fillStepDeleteComment,
      fillStepShowSpinner,
      fillStepHideSpinner,
      notifyError,
    },
  } = props;

  const handleActivated = useCallback(
    (id) => {
      const expression =
        // prettier-ignore
        `componentsInput/[${selected.component.id}]/elements/[${selected.element.id}]/state`;

      commonPatchOperation(protocolId, expression, id);
      fillStepNavigateNextElement(selected.component.id, selected.element.id);
    },
    [protocolId, commonPatchOperation, fillStepNavigateNextElement, selected],
  );

  const handleDeactivated = useCallback(
    (id) => {
      const expression =
        // prettier-ignore
        `componentsInput/[${selected.component.id}]/elements/[${selected.element.id}]/state`;

      commonPatchOperation(protocolId, expression, null);
    },
    [protocolId, commonPatchOperation, selected],
  );

  const getAttributeCategoryTemplate = useCallback(
    (id) => {
      const attributeTemplate = indexedAttributesTemplate[id];
      const attributeCategoryTemplate =
        !!attributeTemplate &&
        !!attributeTemplate.attributeCategory &&
        indexedAttributeCategoriesTemplate[attributeTemplate.attributeCategory];
      return attributeCategoryTemplate;
    },
    [indexedAttributeCategoriesTemplate, indexedAttributesTemplate],
  );

  const getAttributeBgColor = useCallback(
    (id) => {
      const attributeCategoryTemplate = getAttributeCategoryTemplate(id);
      const { bgColor } = attributeCategoryTemplate;
      return !!bgColor ? bgColor : colors.tileUnselectedBackgroundColor;
    },
    [getAttributeCategoryTemplate],
  );

  const handleSelectAttribute = useCallback(
    (id) => {
      const attributeCategoryTemplate = getAttributeCategoryTemplate(id);
      const skipCostModal =
        !!attributeCategoryTemplate && attributeCategoryTemplate.hasCost === false;

      if (skipCostModal) {
        const { component, element } = selected;
        const attributesSelected = !!element && !!element.input && element.input.attributesSelected;
        const cost = !!attributesSelected && getById(id)(attributesSelected);

        // prettier-ignore
        const expression =
          `componentsInput/[${component.id}]/elements/[${element.id}]/attributesSelected/[${id}]`;

        if (!cost) {
          commonPatchOperation(protocolId, expression, { id, cost: null });
        } else {
          commonDeleteOperation(protocolId, expression);
        }
      } else {
        fillStepOpenCostModal(id);
      }
    },
    [
      getAttributeCategoryTemplate,
      protocolId,
      fillStepOpenCostModal,
      commonPatchOperation,
      commonDeleteOperation,
      selected,
    ],
  );

  return (
    <div css={styles.container}>
      <div css={styles.content}>
        <Tabs>
          <Tab defaultSelected={selected.element.id}>
            <TabTitle>
              <Trans>MAIN-STEP.TEXTS.PROTOCOL-TAB-TITLE</Trans>
            </TabTitle>
            <TabContent>
              <InputAttributes
                attributes={selected.element.template.attributes}
                attributesSelected={
                  selected.element.input && selected.element.input.attributesSelected
                }
                indexedAttributesTemplate={indexedAttributesTemplate}
                language={language}
                onSelectAttribute={handleSelectAttribute}
                getAttributeBgColor={getAttributeBgColor}
                templateCosts={templateCosts}
              />
            </TabContent>
          </Tab>
          <Tab>
            <TabTitle
              indicator={
                !!selected.element.input &&
                !!selected.element.input.images &&
                !!selected.element.input.images.length
                  ? selected.element.input.images.length
                  : null
              }
            >
              <Trans>MAIN-STEP.TEXTS.IMAGE-TAB-TITLE</Trans>
            </TabTitle>
            <TabContent>
              <InputImages
                images={
                  selected.element.input && selected.element.input.images
                    ? selected.element.input.images
                    : []
                }
                totalProtocolImages={totalProtocolImages}
                isOfflineProtocol={isOfflineProtocol}
                onViewImage={fillStepOpenViewImageModal}
                onDeleteAllImages={fillStepDeleteAllImages}
                isTakePhotoModalOpen={isTakePhotoModalOpen}
                fillStepOpenImageSketcherModal={fillStepOpenImageSketcherModal}
                fillStepOpenTakePhotoModal={fillStepOpenTakePhotoModal}
                fillStepCloseTakePhotoModal={fillStepCloseTakePhotoModal}
                fillStepShowSpinner={fillStepShowSpinner}
                fillStepHideSpinner={fillStepHideSpinner}
                notifyError={notifyError}
              />
            </TabContent>
          </Tab>
          <Tab>
            <TabTitle
              indicator={
                !!selected.element.input && !!selected.element.input.comment ? '...' : null
              }
            >
              <Trans>MAIN-STEP.TEXTS.COMMENT-TAB-TITLE</Trans>
            </TabTitle>
            <TabContent>
              <InputComment
                protocolId={protocolId}
                selected={selected}
                onChange={commonPatchOperation}
                onDelete={commonDeleteOperation}
                onDeleteComment={fillStepDeleteComment}
              />
            </TabContent>
          </Tab>
        </Tabs>

        <div css={styles.buttons}>
          <StateToggleButton
            id="not available"
            selected={selected}
            onActivated={handleActivated}
            onDeactivated={handleDeactivated}
          >
            <Trans>BUTTONS.NOT-AVAILABLE</Trans>
          </StateToggleButton>
          <StateToggleButton
            id="defect"
            selected={selected}
            onActivated={handleActivated}
            onDeactivated={handleDeactivated}
          >
            <Trans>BUTTONS.DEFECT</Trans>
          </StateToggleButton>
          <StateToggleButton
            id="normal usage"
            selected={selected}
            onActivated={handleActivated}
            onDeactivated={handleDeactivated}
          >
            <Trans>BUTTONS.NORMAL-USAGE</Trans>
          </StateToggleButton>
          <StateToggleButton
            id="ok"
            selected={selected}
            onActivated={handleActivated}
            onDeactivated={handleDeactivated}
          >
            <Trans>BUTTONS.FINE</Trans>
          </StateToggleButton>
        </div>
      </div>
    </div>
  );
};

const StateToggleButton = ({ children, selected, id, onActivated, onDeactivated }) => {
  const handleToggleClick = useCallback(
    (active) => {
      if (active) {
        onActivated(id);
      } else {
        onDeactivated(id);
      }
    },
    [onActivated, onDeactivated, id],
  );

  const elementInput = !!selected.element.input ? selected.element.input : null;

  const state = (!!elementInput && !!elementInput.state && elementInput.state) || null;

  const isSelected = state === id;

  return (
    <ToggleButton id={id} onClick={handleToggleClick} isSelected={isSelected}>
      {children}
    </ToggleButton>
  );
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    templateCosts: selectTemplateCosts(state),
    selected: selectSelected(state),
    protocolId: selectCurrentId(state),
    language: selectProtocolLanguage(state),
    indexedAttributesTemplate: selectIndexedAttributesTemplate(state),
    indexedAttributeCategoriesTemplate: selectIndexedAttributeCategoriesTemplate(state),
    isTakePhotoModalOpen: selectIsImageTakePhotoModalOpen(state),
    isOfflineProtocol: selectIsCurrentProtocolOffline(state),
    totalProtocolImages: selectCurrentProtocolTotalImages(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputElement);
