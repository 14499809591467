import JSZip from 'jszip';

export const unzipImages = async (images) => {
  var zip = new JSZip();

  const unzipped = await zip.loadAsync(images);
  const fileNames = Object.keys(unzipped.files);
  const promises = [];
  const results = [];

  for (const fileName of fileNames) {
    promises.push(
      results.push({
        reference: fileName,
        data: await unzipped.file(fileName).async('arraybuffer'),
      }),
    );
  }

  await Promise.all(promises);
  return results;
};
