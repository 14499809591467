import produce from 'immer';

import { COMMON_CLOSE_INFORMATION_MODAL } from './constants';

export function commonCloseInformationModal() {
  return {
    type: COMMON_CLOSE_INFORMATION_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_CLOSE_INFORMATION_MODAL:
        draft.ui.modal.information = false;
        draft.ui.information.title = null;
        draft.ui.information.message = null;
        draft.ui.information.ok = null;
        break;
      default:
        return state;
    }
  });
