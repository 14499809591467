/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { TopBarBaseStep } from './TopBarBaseStep';
import { acceptanceProtocolRoutes } from '../acceptance-protocol/routes';

const styles = {
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
};

export const TopBarSendStep = (props) => {
  const { currentStep, onNavigateTo, protocolId, isLast } = props;

  const handleNavigateToSendStep = useCallback(() => {
    onNavigateTo(acceptanceProtocolRoutes.sendStep(protocolId));
  }, [onNavigateTo, protocolId]);

  return (
    <TopBarBaseStep
      step="send-step"
      isActive={!!currentStep && currentStep === 'send-step'}
      onSelectStep={handleNavigateToSendStep}
      isLast={isLast}
    >
      <div css={styles.label}>
        <Trans>LABELS.SEND-STEP</Trans>
      </div>
    </TopBarBaseStep>
  );
};
