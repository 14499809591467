/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';

export const InputCost = (props) => {
  const { id, text, isSelected, onChange, onClear } = props;

  const handleChangeCost = useCallback(() => {
    onClear();
    if (!isSelected) {
      onChange({ id });
    }
  }, [id, isSelected, onChange, onClear]);

  return <button onClick={handleChangeCost}>{text}</button>;
};
