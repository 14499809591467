import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';

export const fieldRow = {
  display: 'grid',

  gridTemplateColumns: '0.25fr 0.75fr',
  gridTemplateRows: 'min-content 1fr 1fr',

  gridGap: '0 1em',

  '& > label': {
    display: 'grid',
    alignItems: 'center',
    fontWeight: 'bold',
    wordBreak: 'break-all',

    gridColumn: '1/3',
    gridRow: '1/2',

    [variables.breakpoints.md]: {
      gridColumn: '1/2',
      gridRow: '1/3',
    },
  },

  '& > div': {
    display: 'grid',
    gridColumn: '1/3',
    gridRow: '2/3',

    [variables.breakpoints.md]: {
      gridColumn: '2/3',
      gridRow: '1/3',
    },
  },

  '& > input': {
    display: 'grid',
    gridColumn: '1/3',
    gridRow: '2/3',
    alignSelf: 'center',

    [variables.breakpoints.md]: {
      gridColumn: '2/3',
      gridRow: '1/3',
    },
  },

  '& input.has-error': {
    border: `2px solid ${colors.error}`,
  },

  '& > span': {
    display: 'grid',

    wordBreak: 'break-all',
    fontSize: '0.8rem',
    color: colors.error,
    marginTop: '5px',
    fontWeight: 'bold',
    textAlign: 'right',

    gridColumn: '2/3',
    gridRow: '3/4',
  },
};

export const dropdownFieldRow = {
  ...fieldRow,
  gridTemplateColumns: '1fr',
  gridTemplateRows: '0.4fr 0.4fr 0.4fr',

  '& > div': {
    [variables.breakpoints.md]: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr 1fr 1fr',
      gridColumn: '1/3',
      gridGap: '0 1em',
    },
  },
  '& > div.has-error > div > div > div': {
    border: `2px solid ${colors.error}`,
  },
  '& > div.has-error > div > select': {
    border: `2px solid ${colors.error}`,
    borderRadius: '3px',
  },
  '& > span': {
    ...fieldRow['& > span'],
    marginTop: '5px',
    textAlign: 'right',

    gridColumn: '1/2',
    gridRow: '2/4',
  },
};

export const fieldWithoutLabelRow = {
  display: 'flex',
  flexFlow: 'column nowrap',
  marginBottom: '16px',
};
