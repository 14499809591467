import { call, put, takeLatest } from 'redux-saga/effects';
import { post } from '../services/api';
import {
  COMMON_ADD_TO_DOWNLOAD,
  COMMON_ADD_TO_DOWNLOAD_SUCCESS,
  COMMON_ADD_TO_DOWNLOAD_ERROR,
} from './constants';

export function commonAddToDownload(payload) {
  return { type: COMMON_ADD_TO_DOWNLOAD, payload };
}

function* doAddToDownload({ payload }) {
  try {
    yield call(post, 'download', payload);

    yield put({
      type: COMMON_ADD_TO_DOWNLOAD_SUCCESS,
      payload,
    });
  } catch (error) {
    yield put({
      type: COMMON_ADD_TO_DOWNLOAD_ERROR,
      payload: { error: error.message, id: payload },
    });
  }
}

export function* switchAddToDownload() {
  yield takeLatest(COMMON_ADD_TO_DOWNLOAD, doAddToDownload);
}
