import { push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';

import { commonPatchOperation } from './actions';
import {
  selectCurrentId,
  selectIsLeavingAgreementStep,
} from '../../acceptance-protocol/redux/selectors';
import { acceptanceProtocolRoutes } from '../../acceptance-protocol/routes';
import { COMMON_REJECT_SIGN, COMMON_REJECTED_SIGN } from './constants';

export function commonRejectSign(signerId) {
  return {
    type: COMMON_REJECT_SIGN,
    payload: signerId,
  };
}

export function commonRejectedSign() {
  return {
    type: COMMON_REJECTED_SIGN,
  };
}

function* doRejectSign({ payload }) {
  const protocolId = yield select(selectCurrentId);
  const isLeavingAgreementStep = yield select(selectIsLeavingAgreementStep);

  yield put(
    commonPatchOperation(
      protocolId,
      `signatures/${payload}`,
      {
        data: null,
        signatureRefused: true,
      },
      isLeavingAgreementStep,
    ),
  );

  yield put(commonRejectedSign());

  const route = isLeavingAgreementStep
    ? acceptanceProtocolRoutes.leavingAgreementStep
    : acceptanceProtocolRoutes.signStep;

  yield put(push(route(protocolId)));
}

export function* switchRejectSign() {
  yield takeLatest(COMMON_REJECT_SIGN, doRejectSign);
}
