/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Fragment, useState } from 'react';
import { object, string, date } from 'yup';
import { i18n } from '../../common/i18n-loader';
import { sortOptsByLanguage } from './utils';
import { variables } from '../../common/theme/variables';
import Button from '../common/Button';
import classNames from 'classnames';
import Input from '../common/Input';
import Modal from '../common/Modal';
import Spinner from '../common/Spinner';
import { fieldRow } from '../common/form';
import ConfirmChangeProtocolIDModal from './ConfirmChangeProtocolIDModal';
import Dropdown from '../common/Dropdown';
import { DatePicker } from '../common/DatePicker';

const styles = {
  body: {
    display: 'grid',
    gridTemplateRows: 'auto min-content',
  },

  formContainer: {
    gridGap: '0.5em;',
    input: {
      width: '100%',
    },
  },

  twoFields: {
    display: 'grid',
    gridTemplateColumns: '0.5fr 0.5fr',
    gridGap: '1em',

    leftFieldRow: {
      ...fieldRow,

      gridTemplateColumns: '0.25fr 0.75fr',
      gridTemplateRows: '0.4fr 0.4fr 0.4fr',
      gridGap: '0.5em 2em',

      [variables.breakpoints.md]: {
        gridTemplateColumns: '0.5fr 0.5fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gridGap: '0 2em',
      },
    },

    rightFieldRow: {
      ...fieldRow,

      gridTemplateColumns: '0.25fr 0.75fr',
      gridTemplateRows: '0.4fr 0.4fr 0.4fr',
      gridGap: '0.5em 1em',

      [variables.breakpoints.md]: {
        gridTemplateColumns: '0.20fr 1fr',
        gridTemplateRows: '1fr 1fr 1fr',
        gridGap: '0 1em',
      },
    },
  },

  fieldRow: {
    ...fieldRow,
    gridTemplateRows: '0.4fr 0.4fr 0.4fr',
    gridGap: '0.5em 1em',

    [variables.breakpoints.md]: {
      gridTemplateColumns: '0.25fr 0.75fr',
      gridTemplateRows: '1fr 1fr 1fr',
      gridGap: '0 1em',
    },
  },

  protocolFieldRow: {
    ...fieldRow,

    gridTemplateRows: '0.4fr 0.4fr 0.4fr',
    gridGap: '0.5em 2em',
    [variables.breakpoints.md]: {
      gridTemplateColumns: '0.25fr 0.75fr',
      gridTemplateRows: '1fr 1fr 1fr',
      gridGap: '0 1em',
    },

    content: {
      display: 'grid',
      gridTemplateColumns: '1fr 0.25fr',
      gridGap: '1em',
    },
  },

  calendar: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content min-content',
    gridGap: '0em 1em',
    alignItems: 'center',
  },

  buttonsContainer: {
    display: 'grid',
    gridGap: '0 1em',

    [variables.breakpoints.md]: {
      gridTemplateColumns: '0.25fr 0.75fr',
    },
  },
  buttons: {
    display: 'grid',
    gridGap: '1em',

    gridAutoFlow: 'row',

    [variables.breakpoints.md]: {
      gridAutoFlow: 'column',
    },
  },
};

const ProtocolWithoutREMReferenceModal = (props) => {
  const [disableButtonModifyProtocolId, setDisableButtonModifyProtocolId] = useState(false);

  const {
    language,
    isOpen,
    onClose,
    onCreateProtocolWithoutRemReference,
    isOpenChangeProtocolIdModal,
    onCloseChangeProtocolId,
    onOpenChangeProtocolId,
    onGetTemplates,
    templates,
    isBusy,
  } = props;

  const sortedOptions = sortOptsByLanguage(templates, language);

  const adjustString = (value) => (value.toString().length === 1 ? `0${value}` : value);

  const protocolIdFormat = () => {
    // yyyy.ddmm.hhmm.ss
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const day = adjustString(currentDate.getDate());

    const month = adjustString(currentDate.getMonth() + 1);

    const hours = adjustString(currentDate.getHours());
    const minutes = adjustString(currentDate.getMinutes());
    const seconds = adjustString(currentDate.getSeconds());

    return `${year}.${day}${month}.${hours}${minutes}.${seconds}`;
  };

  function resetInternalState() {
    setDisableButtonModifyProtocolId(false);
  }

  let reset;
  function handleOnOpen() {
    if (!!reset) {
      resetInternalState();
      reset();
      onGetTemplates();
    }
  }

  function handleCloseChangeProtocolId(closePopup) {
    closePopup();
    setDisableButtonModifyProtocolId(true);
  }

  function handleOnClose(onClose) {
    resetInternalState();
    onClose();
  }

  const initialValues = {
    protocolId: protocolIdFormat(),
    agency: '',
    street: '',
    postalCode: '',
    place: '',
    phone: '',
    objectDesignationStreet: '',
    objectDesignationPostalCode: '',
    objectDesignationPlace: '',
    numberOfRooms: '',
    floor: '',
    responsibilityDate: '',
    templateId: '',
  };

  const validationSchema = object().shape({
    protocolId: string()
      .required(i18n._('VALIDATIONS.PROTOCOL-ID-FORMAT'))
      .matches(/^(\d{1,}\.\d{1,}\.\d{1,}\.\d{1,})$/, i18n._('VALIDATIONS.PROTOCOL-ID-FORMAT')),
    agency: string().required(i18n._('VALIDATIONS.REQUIRED.AGENCY')),
    street: string().required(i18n._('VALIDATIONS.REQUIRED.STREET')),
    postalCode: string()
      .required(i18n._('VALIDATIONS.REQUIRED.POSTAL-CODE'))
      .matches(/^[0-9]{4}$/, i18n._('VALIDATIONS.INVALID.POSTAL-CODE')),
    place: string().required(i18n._('VALIDATIONS.REQUIRED.PLACE')),
    phone: string().required(i18n._('VALIDATIONS.REQUIRED.PHONE')),
    objectDesignationStreet: string().required(
      i18n._('VALIDATIONS.REQUIRED.OBJECT-DESIGNATION-STREET'),
    ),
    objectDesignationPostalCode: string()
      .required(i18n._('VALIDATIONS.REQUIRED.OBJECT-DESIGNATION-POSTAL-CODE'))
      .matches(/^[0-9]{4}$/, i18n._('VALIDATIONS.INVALID.POSTAL-CODE')),
    objectDesignationPlace: string().required(
      i18n._('VALIDATIONS.REQUIRED.OBJECT-DESIGNATION-PLACE'),
    ),
    numberOfRooms: string()
      .required(i18n._('VALIDATIONS.REQUIRED.NUMBER-OF-ROOMS'))
      .matches(/^[0-9]+([.,]5)?$/, i18n._('VALIDATIONS.INVALID.NUMBER-OF-ROOMS')),
    floor: string().required(i18n._('VALIDATIONS.REQUIRED.FLOOR')),
    responsibilityDate: date()
      .required(i18n._('VALIDATIONS.REQUIRED.RESPONSIBILITY-DATE'))
      .nullable(),
    templateId: string()
      .notOneOf([-1], i18n._('VALIDATIONS.REQUIRED.TEMPLATE-NAME'))
      .required(i18n._('VALIDATIONS.REQUIRED.TEMPLATE-NAME'))
      .nullable(),
  });

  return (
    <Fragment>
      <Modal
        title={i18n._('PROTOCOl-WITHOUT-REM-REFERENCE-MODAL.TEXTS.TITLE')}
        isOpen={isOpen}
        onOpen={handleOnOpen}
        onClose={onClose}
      >
        <Spinner show={isBusy}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              onCreateProtocolWithoutRemReference({
                ...values,
                numberOfRooms: values.numberOfRooms.replace(',', '.'),
                language,
              });
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              setFieldValue,
              setFieldTouched,
            }) => {
              reset = handleReset;

              const handleDatePickerChange = (value) => {
                setFieldValue('responsibilityDate', value);
              };
              const handleDatePickerClose = () => setFieldTouched('responsibilityDate');

              const canModify = !disableButtonModifyProtocolId ? (
                <div css={styles.protocolFieldRow}>
                  <label>
                    <Trans>LABELS.PROTOCOl-ID</Trans>
                  </label>
                  <div css={styles.protocolFieldRow.content}>
                    <Input
                      placeholder={i18n._('LABELS.PROTOCOl-ID')}
                      type="text"
                      name="protocolId"
                      disabled={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.protocolId}
                      className={classNames({
                        'has-error': touched.protocolId && errors.protocolId,
                      })}
                    />
                    <Button secondary type="button" onClick={onOpenChangeProtocolId}>
                      <Trans>BUTTONS.MODIFY-PROTOCOl-ID</Trans>
                    </Button>
                  </div>
                </div>
              ) : (
                <div css={styles.fieldRow}>
                  <label>
                    <Trans>LABELS.PROTOCOl-ID</Trans>
                  </label>
                  <Input
                    placeholder={i18n._('LABELS.PROTOCOl-ID')}
                    type="text"
                    name="protocolId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.protocolId}
                    className={classNames({
                      'has-error': touched.protocolId && errors.protocolId,
                    })}
                  />
                  <Fade right when={!!touched.protocolId && !!errors.protocolId}>
                    <span>{errors.protocolId}</span>
                  </Fade>
                </div>
              );

              return (
                <form
                  onSubmit={handleSubmit}
                  onReset={handleReset}
                  css={styles.body}
                  autoComplete="off"
                >
                  <div css={styles.formContainer}>
                    <div css={styles.fieldRow}>
                      <label>
                        <Trans>LABELS.PROTOCOL-TEMPLATE</Trans>
                      </label>
                      <Dropdown
                        name="templateId"
                        options={sortedOptions}
                        language={language}
                        emptyValue={{
                          value: -1,
                          label: i18n._('DROPDOWN-TEMPLATE.DEFAULT-VALUE'),
                        }}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                        value={values.templateId}
                        className={classNames({
                          'has-error': touched.templateId && errors.templateId,
                        })}
                      />
                      <Fade right when={!!touched.templateId && !!errors.templateId}>
                        <span>{errors.templateId}</span>
                      </Fade>
                    </div>
                    {canModify}
                    <div css={styles.fieldRow}>
                      <label>
                        <Trans>LABELS.AGENCY</Trans>
                      </label>
                      <Input
                        placeholder={i18n._('LABELS.AGENCY')}
                        type="text"
                        name="agency"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.agency}
                        className={classNames({
                          'has-error': touched.agency && errors.agency,
                        })}
                      />
                      <Fade right when={!!touched.agency && !!errors.agency}>
                        <span>{errors.agency}</span>
                      </Fade>
                    </div>
                    <div css={styles.fieldRow}>
                      <label>
                        <Trans>LABELS.STREET</Trans>
                      </label>
                      <Input
                        placeholder={i18n._('LABELS.STREET')}
                        type="text"
                        name="street"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.street}
                        className={classNames({
                          'has-error': touched.street && errors.street,
                        })}
                      />
                      <Fade right when={!!touched.street && !!errors.street}>
                        <span>{errors.street}</span>
                      </Fade>
                    </div>
                    <div css={styles.twoFields}>
                      <div css={styles.twoFields.leftFieldRow}>
                        <label>
                          <Trans>LABELS.POSTAL-CODE</Trans>
                        </label>
                        <Input
                          placeholder={i18n._('LABELS.POSTAL-CODE')}
                          type="text"
                          name="postalCode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.postalCode}
                          className={classNames({
                            'has-error': touched.postalCode && errors.postalCode,
                          })}
                        />
                        <Fade right when={!!touched.postalCode && !!errors.postalCode}>
                          <span>{errors.postalCode}</span>
                        </Fade>
                      </div>
                      <div css={styles.twoFields.rightFieldRow}>
                        <label>
                          <Trans>LABELS.PLACE</Trans>
                        </label>
                        <Input
                          placeholder={i18n._('LABELS.PLACE')}
                          type="text"
                          name="place"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.place}
                          className={classNames({
                            'has-error': touched.place && errors.place,
                          })}
                        />
                        <Fade right when={!!touched.place && !!errors.place}>
                          <span>{errors.place}</span>
                        </Fade>
                      </div>
                    </div>
                    <div css={styles.fieldRow}>
                      <label>
                        <Trans>LABELS.PHONE</Trans>
                      </label>
                      <Input
                        placeholder={i18n._('LABELS.PHONE')}
                        type="text"
                        name="phone"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                        className={classNames({
                          'has-error': touched.phone && errors.phone,
                        })}
                      />
                      <Fade right when={!!touched.phone && !!errors.phone}>
                        <span>{errors.phone}</span>
                      </Fade>
                    </div>
                    <div css={styles.fieldRow}>
                      <label>
                        <Trans>LABELS.OBJECT-DESIGNATION-STREET</Trans>
                      </label>
                      <Input
                        placeholder={i18n._('LABELS.OBJECT-DESIGNATION-STREET')}
                        type="text"
                        name="objectDesignationStreet"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.objectDesignationStreet}
                        className={classNames({
                          'has-error':
                            touched.objectDesignationStreet && errors.objectDesignationStreet,
                        })}
                      />
                      <Fade
                        right
                        when={!!touched.objectDesignationStreet && !!errors.objectDesignationStreet}
                      >
                        <span>{errors.objectDesignationStreet}</span>
                      </Fade>
                    </div>
                    <div css={styles.twoFields}>
                      <div css={styles.twoFields.leftFieldRow}>
                        <label>
                          <Trans>LABELS.POSTAL-CODE</Trans>
                        </label>
                        <Input
                          placeholder={i18n._('LABELS.POSTAL-CODE')}
                          type="text"
                          name="objectDesignationPostalCode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.objectDesignationPostalCode}
                          className={classNames({
                            'has-error':
                              touched.objectDesignationPostalCode &&
                              errors.objectDesignationPostalCode,
                          })}
                        />
                        <Fade
                          right
                          when={
                            !!touched.objectDesignationPostalCode &&
                            !!errors.objectDesignationPostalCode
                          }
                        >
                          <span>{errors.objectDesignationPostalCode}</span>
                        </Fade>
                      </div>
                      <div css={styles.twoFields.rightFieldRow}>
                        <label>
                          <Trans>LABELS.PLACE</Trans>
                        </label>
                        <Input
                          placeholder={i18n._('LABELS.PLACE')}
                          type="text"
                          name="objectDesignationPlace"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.objectDesignationPlace}
                          className={classNames({
                            'has-error':
                              touched.objectDesignationPlace && errors.objectDesignationPlace,
                          })}
                        />
                        <Fade
                          right
                          when={!!touched.objectDesignationPlace && !!errors.objectDesignationPlace}
                        >
                          <span>{errors.objectDesignationPlace}</span>
                        </Fade>
                      </div>
                    </div>
                    <div css={styles.twoFields}>
                      <div css={styles.twoFields.leftFieldRow}>
                        <label>
                          <Trans>LABELS.NUMBER-ROOMS</Trans>
                        </label>
                        <Input
                          placeholder={i18n._('LABELS.NUMBER-ROOMS')}
                          type="text"
                          name="numberOfRooms"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.numberOfRooms}
                          className={classNames({
                            'has-error': touched.numberOfRooms && errors.numberOfRooms,
                          })}
                        />
                        <Fade right when={!!touched.numberOfRooms && !!errors.numberOfRooms}>
                          <span>{errors.numberOfRooms}</span>
                        </Fade>
                      </div>
                      <div css={styles.twoFields.rightFieldRow}>
                        <label>
                          <Trans>LABELS.FLOOR</Trans>
                        </label>
                        <Input
                          placeholder={i18n._('LABELS.FLOOR')}
                          type="text"
                          name="floor"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.floor}
                          className={classNames({
                            'has-error': touched.floor && errors.floor,
                          })}
                        />
                        <Fade right when={!!touched.floor && !!errors.floor}>
                          <span>{errors.floor}</span>
                        </Fade>
                      </div>
                    </div>
                    <div css={styles.fieldRow}>
                      <label>
                        <Trans>LABELS.DATE-RESPONSIBILITY</Trans>
                      </label>

                      <DatePicker
                        label={i18n._('LABELS.DATE-RESPONSIBILITY')}
                        name="responsibilityDate"
                        onClose={handleDatePickerClose}
                        onChange={handleDatePickerChange}
                        value={values.responsibilityDate}
                        className={classNames({
                          'has-error': touched.responsibilityDate && errors.responsibilityDate,
                        })}
                      />

                      <Fade
                        right
                        when={!!touched.responsibilityDate && !!errors.responsibilityDate}
                      >
                        <span>{errors.responsibilityDate}</span>
                      </Fade>
                    </div>
                  </div>
                  <div css={styles.buttonsContainer}>
                    <div />
                    <div css={styles.buttons}>
                      <Button secondary type="reset" onClick={() => handleOnClose(onClose)}>
                        <Trans>BUTTONS.ABORT</Trans>
                      </Button>
                      <Button default type="submit">
                        <Trans>BUTTONS.CREATE</Trans>
                      </Button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Spinner>
      </Modal>
      <ConfirmChangeProtocolIDModal
        isOpenChangeProtocolIdModal={isOpenChangeProtocolIdModal}
        onCloseChangeProtocolId={onCloseChangeProtocolId}
        handleCloseChangeProtocolId={() => handleCloseChangeProtocolId(onCloseChangeProtocolId)}
      />
    </Fragment>
  );
};
ProtocolWithoutREMReferenceModal.propTypes = {
  language: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateProtocolWithoutRemReference: PropTypes.func,
  onOpenNotImplemented: PropTypes.func,
  homeOpenChangeProtocolIdModal: PropTypes.func,
  templates: PropTypes.array,
  isBusy: PropTypes.bool,
  onGetTemplates: PropTypes.func,
};

ProtocolWithoutREMReferenceModal.defaultProps = {
  isOpen: false,
};

export default ProtocolWithoutREMReferenceModal;
