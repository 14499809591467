import produce from 'immer';

import { COMMON_INJECT_PROTOCOL } from './constants';

export function commonInjectProtocol(protocol) {
  return {
    type: COMMON_INJECT_PROTOCOL,
    payload: protocol,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_INJECT_PROTOCOL:
        const index = state.protocols.findIndex((p) => p.id === action.payload.id);
        if (index > -1) {
          draft.protocols[index] = action.payload;
        } else {
          draft.protocols.push(action.payload);
        }
        break;
      default:
        return state;
    }
  });
