/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import Dropdown from '../../common/Dropdown';
import { i18n } from '../../../common/i18n-loader';

export const FormSelectField = (props) => {
  const { name, setFieldValue, setFieldTouched, onChange, value, options, language, disabled } = props;

  const handleChange = useCallback(
    (_, value) => {
      setFieldValue(name, value);
      onChange({ currentTarget: { value } });
    },
    [name, setFieldValue, onChange],
  );

  const optionz = options.map((fo) => {
    return {
      value: fo.value,
      label: fo.label,
      labels: {
        default: fo.label,
        en: fo.labelEn,
        fr: fo.labelFr,
        it: fo.labelIt,
        de: fo.label,
      },
    };
  });

  return (
    <Dropdown
      disabled={disabled}
      name={name}
      options={optionz}
      language={language}
      emptyValue={{
        value: -1,
        label: i18n._('DROPDOWN-TEMPLATE.DEFAULT-VALUE'),
      }}
      setFieldValue={handleChange}
      setFieldTouched={setFieldTouched}
      value={value}
      className={'FormSelectField'}
    />
  );
};
