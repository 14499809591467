import { takeEvery } from 'redux-saga/effects';
import {
  COMMON_IS_ONLINE,
  COMMON_PERSIST_SENTRY_ERROR,
  COMMON_RESET_SENTRY_ERRORS,
} from './constants';
import produce from 'immer';
import { Sentry } from '../../../common/sentry-loader';

import { select, put } from 'redux-saga/effects';
import { selectSentryErrors, selectUsername, selectClient, selectEmail } from './selectors';

export function commonPersistSentryError(error) {
  return { type: COMMON_PERSIST_SENTRY_ERROR, payload: error };
}

export function commonResetSentryErrors() {
  return {
    type: COMMON_RESET_SENTRY_ERRORS,
  };
}

function* resumeSentryAfterOffline() {
  const sentryErrors = yield select(selectSentryErrors);
  const client = yield select(selectClient);
  const username = yield select(selectUsername);
  const email = yield select(selectEmail);

  if (!!sentryErrors) {
    Sentry.withScope((scope) => {
      scope.setUser({
        client,
        username,
        email,
      });
      scope.setTag('client', client);
      scope.setTag('side', 'client');
      sentryErrors.forEach((error) => Sentry.captureException(error, scope));
    });
  }

  yield put(commonResetSentryErrors);
}

export function* sentryConfSagas() {
  yield takeEvery(COMMON_IS_ONLINE, resumeSentryAfterOffline);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_PERSIST_SENTRY_ERROR:
        const draftSentryErrors = draft.sentryErrors || [];
        draftSentryErrors.push(action.payload);
        draft.sentryErrors = [...draftSentryErrors];
        break;
      case COMMON_RESET_SENTRY_ERRORS:
        draft.sentryErrors = [];
        break;
      default:
        return state;
    }
  });
