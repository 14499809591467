/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { colors } from '../../../common/theme/colors';
import { TabKeyPad } from './TabKeyPad';
import { TabFraction } from './TabFraction';
import { Fraction } from './Fraction';

export const sharedContainerStyle = {
  marginTop: '-1px',
  backgroundColor: colors.white,
  border: `solid 1px ${colors.lighterGrey}`,
};

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content auto',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  button: {
    display: 'grid',
    alignContent: 'center',
    justifyContent: 'center',
    padding: '16px',
    backgroundColor: 'transparent',
    color: colors.black,

    '&:not(.is-selected)': {
      border: 'solid 1px transparent',
    },

    '&.is-selected': {
      backgroundColor: colors.white,
      borderLeft: `solid 1px ${colors.lighterGrey}`,
      borderRight: `solid 1px ${colors.lighterGrey}`,
      boxShadow: 'inset 0px 4px 0 #31afe1',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      zIndex: '1',
    },
  },
  buttonFraction: {
    marginTop: '-3px',
  },
};

export const TabsCost = (props) => {
  const { type, value, age, life, setAge, setLife, onChangeCost } = props;

  const handleChangeType = useCallback(
    (event) => {
      if (type !== event.currentTarget.value) {
        if (event.currentTarget.value !== 'x/y') {
          setAge('');
          setLife('');
        }

        onChangeCost({ type: event.currentTarget.value, value: '' });
      }
    },
    [onChangeCost, type, setAge, setLife],
  );

  const translationRender = useCallback(({ translation }) => {
    const result = /^(.*)\((.+\/.+)\)$/gi.exec(translation);
    return !!result ? <Fraction value={result[2]} withText={result[1]} /> : translation;
  }, []);

  return (
    <div css={styles.container}>
      <button
        css={styles.button}
        value="CHF"
        className={classNames({ 'is-selected': type === 'CHF' })}
        onClick={handleChangeType}
      >
        <div css={styles.nowrap}>
          <Trans>MAIN-STEP.TEXTS.AMOUNT-TAB-TITLE</Trans>
        </div>
      </button>
      <button
        css={styles.button}
        value="%"
        className={classNames({ 'is-selected': type === '%' })}
        onClick={handleChangeType}
      >
        <div css={styles.nowrap}>
          <Trans>MAIN-STEP.TEXTS.PERC-TAB-TITLE</Trans>
        </div>
      </button>
      <button
        css={styles.button}
        value="x/y"
        className={classNames({ 'is-selected': type === 'x/y' })}
        onClick={handleChangeType}
      >
        <div css={{ ...styles.nowrap, ...styles.buttonFraction }}>
          <Trans render={translationRender}>MAIN-STEP.TEXTS.FRAC-TAB-TITLE</Trans>
        </div>
      </button>
      {type !== 'x/y' ? (
        <TabKeyPad value={value} onChangeCost={onChangeCost} />
      ) : (
        <TabFraction
          value={value}
          age={age}
          life={life}
          setAge={setAge}
          setLife={setLife}
          onChangeCost={onChangeCost}
        />
      )}
    </div>
  );
};
