import { map, max, reduce, sortBy, find } from 'ramda';
import { put, select, takeEvery } from 'redux-saga/effects';

import { i18n } from '../../../common/i18n-loader';
import {
  selectComponentsTemplate,
  selectCurrentProtocol,
} from '../../acceptance-protocol/redux/selectors';
import { COMMON_ADD_COMPONENT, COMMON_ADDED_COMPONENT } from './constants';
import { commonPatchOperation } from './patchOperation';

export function commonAddComponent() {
  return { type: COMMON_ADD_COMPONENT };
}

export function commonAddedComponent(componentId) {
  return { type: COMMON_ADDED_COMPONENT, payload: componentId };
}

function* doAddComponent() {
  const protocol = yield select(selectCurrentProtocol);
  const componentsTemplate = yield select(selectComponentsTemplate);
  const highestId = reduce(
    max,
    -Infinity,
    map((ci) => ci.id, protocol.input.componentsInput),
  );
  const newComponent = {
    id: highestId + 1,
    localName: i18n._('FILL-STEP.ADD-COMPONENT.ADDITIONAL-ROOM'),
  };

  yield put(
    commonPatchOperation(protocol.id, `componentsInput/[${newComponent.id}]`, newComponent),
  );

  let order = protocol.input.componentsOrderInput;
  let componentsInput = protocol.input.componentsInput;

  const findOrderFromTemplate = (ci) => {
    const componentTemplate = find((ct) => ct.id === ci.typeId, componentsTemplate);
    return !!componentTemplate ? componentTemplate.order : Infinity;
  };

  if (!order) {
    order = map((ci) => ci.id, sortBy(findOrderFromTemplate, componentsInput));
  }

  yield put(commonPatchOperation(protocol.id, 'componentsOrderInput', [...order, newComponent.id]));
  yield put(commonAddedComponent(newComponent.id));
}

export function* switchAddComponent() {
  yield takeEvery(COMMON_ADD_COMPONENT, doAddComponent);
}
