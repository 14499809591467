import React from 'react';

export const ResidenceIn = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 50"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M60,46h4V32H61V25H19v7H16V46h4v2h3V46H57v2h3ZM59,32H57v7H42V27H59ZM21,27H40V39H23V32H21Zm-3,7h3v7H59V34h3V44H18Z" />
    <path d="M20,20a.84.84,0,0,1-.38-.08A1,1,0,0,1,19,19V15H6V13H20a1,1,0,0,1,1,1v2.59L26.59,11,21,5.41V8a1,1,0,0,1-1,1H6V7H19V3a1,1,0,0,1,.62-.92,1,1,0,0,1,1.09.21l8,8a1,1,0,0,1,0,1.42l-8,8A1,1,0,0,1,20,20Z" />
  </svg>
);
