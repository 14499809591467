/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import FullScreenModal from '../common/FullScreenModal';
import { ImageTakePhoto } from './ImageTakePhoto';

const ImageTakePhotoModal = (props) => {
  const { isOpen, onClose, loadImage, fillStepHideSpinner, ...passThroughProps } = props;

  return (
    <FullScreenModal isOpen={isOpen}>
      <ImageTakePhoto
        onClose={onClose}
        loadImage={loadImage}
        fillStepHideSpinner={fillStepHideSpinner}
        {...passThroughProps}
      />
    </FullScreenModal>
  );
};

ImageTakePhotoModal.propTypes = {
  isOpen: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

ImageTakePhotoModal.defaultProps = {
  isOpen: false,
};

export default ImageTakePhotoModal;
