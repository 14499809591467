import React from 'react';
import { Sentry } from '../../common/sentry-loader';
import { ErrorBoundaryTemplate } from './ErrorBoundaryTemplate';
import { connect } from 'react-redux';
import { selectClient, selectEmail, selectUsername } from './redux/selectors';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, eventId: null };
    this.handleShowReportDialog = this.handleShowReportDialog.bind(this);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    console.error(error, info);
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      if (!!this.props.client) {
        scope.setUser({
          client: this.props.client,
        });
        scope.setTag('client', this.props.client);
      }
      if (!!this.props.username) {
        scope.setUser({
          username: this.props.username,
        });
      }
      if (!!this.props.email) {
        scope.setUser({
          email: this.props.email,
        });
      }
      scope.setTag('side', 'client');
      const eventId = Sentry.captureException(error, scope);
      this.setState({ eventId });
    });
  }

  handleShowReportDialog() {
    Sentry.showReportDialog({ eventId: this.state.eventId });
  }

  render() {
    const { title, showGoBackButton, fullWidth } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorBoundaryTemplate
          onReportFeedback={this.handleShowReportDialog}
          title={title}
          fullWidth={fullWidth}
          showGoBackButton={showGoBackButton}
        />
      );
    }
    return this.props.children;
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    client: selectClient(state),
    username: selectUsername(state),
    email: selectEmail(state),
  };
}

export const ProviderErrorBoundary = connect(mapStateToProps)(ErrorBoundary);
