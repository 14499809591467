export function getImageReferences(protocol) {
  const references = [];
  for (const keys of ['previousComponentsInput', 'componentsInput']) {
    if (!protocol.input[keys]) continue;
    for (const ci of protocol.input[keys]) {
      if (!ci.elements) continue;
      for (const el of ci.elements) {
        if (!el.images) continue;
        for (const img of el.images) {
          if (!img.reference) continue;
          references.push(img.reference);
        }
      }
    }
  }
  return references;
}
