/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { pluck } from 'ramda';
import { routerActions } from 'connected-react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getImages } from '../../../common/code/image.repository';
import { variables } from '../../../common/theme/variables';
import Button from '../../common/Button';
import { PugRenderer } from 'dap.report';
import * as commonActions from '../../common/redux/actions';
import * as fillStepActions from '../fill-step/redux/actions';
import {
  selectBasedataInput,
  selectCurrentProtocol,
  selectIsCurrentProtocolOffline,
  selectIsProtocolReadonly,
  selectProtocolImages,
  selectProtocolLanguage,
  selectProtocolTemplateName,
} from '../redux/selectors';
import * as actions from './redux/actions';
import { selectScrollToSign } from './redux/selectors';
import { selectLocales } from '../../common/redux/selectors';
import { object } from 'yup';
import { validation } from '../../common/Validations';
import { getBaseDataFromInput } from './redux/Functions';

const styles = {
  container: {
    fontFamily: '"Rubik", sans-serif !important',

    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridGap: '1em',
    paddingBottom: '1.5em',

    overflowY: 'auto',
    overflowScrolling: 'touch',
    WebkitOverflowScrolling: 'touch',
    // TODO: add also for firefox
    '::WebkitScrollbar': {
      width: '0px',
      height: '0px',
      background: 'transparent',
    },
    height: '100%',

    paddingLeft: '3vw',
    paddingRight: '3vw',

    [variables.breakpoints.md]: {
      paddingLeft: '8vw',
      paddingRight: '8vw',
    },
    [variables.breakpoints.xl]: {
      paddingLeft: '15vw',
      paddingRight: '15vw',
    },
    '&:last-child:after': {
      content: '""',
      display: 'block',
      height: '8px',
      marginBottom: '-8px',
    },
  },
  closeButton: {
    width: '100%',
  },
};

const validationSchema = object().shape({
  VormieterEmail: validation.email,
  VormieterHaftungsdatum: validation.date,
  NachmieterEmail: validation.email,
  NachmieterMietbeginn: validation.date,
  VormieterEmail_2: validation.email,
  VormieterHaftungsdatum_2: validation.date,
  NachmieterEmail_2: validation.email,
  NachmieterMietbeginn_2: validation.date,
});

const SignStep = (props) => {
  const {
    data,
    inputBaseData,
    language,
    locales,
    templateName,
    images,
    isOfflineProtocol,
    scrollToSign,
    isProtocolReadonly,
    actions: {
      push,
      commonSetCurrentSigner,
      fillStepNavigateComponent,
      fillStepNavigateElement,
      commonDeleteSign,
      signStepGoToSendStep,
      commonPatchOperation,
      commonDeleteOperation,
      signStepScrolled,
      notifyValidationError,
    },
  } = props;

  const [imgs, setImgs] = useState(
    images.map((image) => ({
      reference: image.reference,
      isReady: false,
      data: null,
    })),
  );

  useEffect(() => {
    if (!isProtocolReadonly) {
      const baseDataForValidation = getBaseDataFromInput(inputBaseData);

      validationSchema
        .validate(baseDataForValidation, { abortEarly: false, stripUnknown: true })
        .catch((err) => {
          err.inner.forEach((e) => {
            notifyValidationError(
              data.template.basedata,
              e.path,
              e.message,
              fillStepNavigateElement,
            );
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getImages(pluck('reference', images), isOfflineProtocol)
      .then((results) => {
        setImgs(
          images.map((image) => ({
            reference: image.reference,
            isReady: !!results[image.reference],
            data: results[image.reference],
          })),
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, [images, isOfflineProtocol]);

  return (
    <div css={styles.container}>
      <PugRenderer
        language={language}
        data={{ ...data, images: imgs }}
        locales={locales}
        templateName={templateName}
        options={{ scrollToSign: scrollToSign }}
        actions={{
          onChangeComment: commonPatchOperation,
          onDeleteComment: commonDeleteOperation,
          onNavigateComponent: fillStepNavigateComponent,
          onNavigateElement: fillStepNavigateElement,
          onNavigateTo: push,
          onDeleteSign: commonDeleteSign,
          onSetCurrentSigner: commonSetCurrentSigner,
          onScrolled: signStepScrolled,
        }}
      />
      <Button css={styles.closeButton} onClick={signStepGoToSendStep}>
        <Trans>BUTTONS.CLOSE-PROTOCOL</Trans>
      </Button>
    </div>
  );
};

/* istanbul ignore next */
export function mapStateToProps(state) {
  return {
    data: selectCurrentProtocol(state),
    inputBaseData: selectBasedataInput(state),
    language: selectProtocolLanguage(state),
    locales: selectLocales(state),
    templateName: selectProtocolTemplateName(state),
    images: selectProtocolImages(state),
    isOfflineProtocol: selectIsCurrentProtocolOffline(state),
    scrollToSign: selectScrollToSign(state),
    isProtocolReadonly: selectIsProtocolReadonly(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...actions, ...fillStepActions, ...routerActions, ...commonActions },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignStep);
