import produce from 'immer';

import { FILL_STEP_CLOSE_COST_MODAL } from './constants';

export function fillStepCloseCostModal() {
  return {
    type: FILL_STEP_CLOSE_COST_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_CLOSE_COST_MODAL:
        draft.ui.modal.cost = false;
        draft.selectedIds.attributeId = null;
        break;
      default:
        return state;
    }
  });
