import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';

import { post } from '../../common/services/api';
import { MIGRATION_DELETE_MIGRATION, MIGRATION_DELETE_MIGRATION_ERROR } from './constants';

export function migrationDeleteMigration(id) {
  return {
    type: MIGRATION_DELETE_MIGRATION,
    payload: id,
  };
}

function* doDeleteMigration({ payload }) {
  try {
    yield call(post, 'migration/delete', { migrationId: payload });
  } catch (error) {
    yield put({
      type: MIGRATION_DELETE_MIGRATION_ERROR,
      payload: error.message,
    });
  }
}

export function* switchDeleteMigration() {
  yield takeLatest(MIGRATION_DELETE_MIGRATION, doDeleteMigration);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_DELETE_MIGRATION:
        draft.ui.busy.loading = true;
        break;
      case MIGRATION_DELETE_MIGRATION_ERROR:
        draft.ui.busy.loading = false;
        break;
      default:
        return state;
    }
  });
