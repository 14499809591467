import {
  COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DEPOT,
  COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS,
} from './constants';

export function commonPatchTenancyAgreementSecurityDepot(protocolId, depositTypeCode, depositAmount, paidAmount, isOldTenant) {
  return {
    type: COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DEPOT,
    payload: {
      protocolId,
      depositTypeCode,
      depositAmount,
      paidAmount,
      isOldTenant
    },
  };
}

export function commonPatchTenancyAgreementSecurityDetails(protocolId, isOldTenant, liabilityInsuranceChecked) {
  return {
    type: COMMON_PATCH_TENANCY_AGREEMENT_SECURITY_DETAILS,
    payload: {
      protocolId,
      isOldTenant,
      liabilityInsuranceChecked
    },
  };
}
