import { push } from 'connected-react-router';
import { find, findIndex } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getOrderedElementsTemplate } from '../../../common/Functions';
import { selectIndexedElementsTemplate, selectOrderedComponents } from '../../redux/selectors';
import { acceptanceProtocolRoutes } from '../../routes';
import { FILL_STEP_NAVIGATE_ELEMENT, FILL_STEP_NAVIGATE_NEXT_ELEMENT } from './constants';
import { fillStepSelectElement } from './fillStepSelectElement';

export function fillStepNavigateElement(protocolId, componentId, elementId, type = 'component') {
  return {
    type: FILL_STEP_NAVIGATE_ELEMENT,
    payload: { protocolId, componentId, elementId, type },
  };
}

export function fillStepNavigateNextElement(componentId, elementId) {
  return {
    type: FILL_STEP_NAVIGATE_NEXT_ELEMENT,
    payload: { componentId, elementId },
  };
}

export function* doNavigateToNextElement({ payload: { componentId, elementId } }) {
  const orderedComponents = yield select(selectOrderedComponents);
  const indexedElementsTemplate = yield select(selectIndexedElementsTemplate);

  const component = find((c) => c.componentInput.id === componentId, orderedComponents);

  if (!!component) {
    const { componentInput, componentTemplate } = component;

    const currentOrderedElements = getOrderedElementsTemplate(
      componentInput.elements,
      componentInput.elementsOrder,
      indexedElementsTemplate,
      componentTemplate,
    );

    const currentElementIndex = findIndex((el) => el.id === elementId, currentOrderedElements);
    const currentComponentIndex = findIndex(
      (co) => co.componentInput.id === componentId,
      orderedComponents,
    );

    let nextComponent;
    let nextElement;

    // we can still navigate on next element
    if (currentElementIndex + 1 < currentOrderedElements.length) {
      nextElement = currentOrderedElements[currentElementIndex + 1];
      nextComponent = orderedComponents[currentComponentIndex];
    } else {
      // we can still navigate on next component
      if (currentComponentIndex + 1 < orderedComponents.length) {
        nextComponent = orderedComponents[currentComponentIndex + 1];

        const nextComponentOrderedElements = getOrderedElementsTemplate(
          nextComponent.componentInput.elements,
          nextComponent.componentInput.elementsOrder,
          indexedElementsTemplate,
          nextComponent.componentTemplate,
        );

        if (nextComponentOrderedElements.length > 0) {
          nextElement = nextComponentOrderedElements[0];
        }
      }
    }

    if (!!nextComponent && !!nextElement) {
      yield put(
        fillStepSelectElement({
          componentId: nextComponent.componentInput.id,
          elementId: nextElement.id,
          type: 'component',
        }),
      );
    }
  }
}

function* doNavigateElement({ payload: { protocolId, componentId, elementId, type } }) {
  yield put(push(acceptanceProtocolRoutes.fillStep(protocolId)));
  yield put(
    fillStepSelectElement({
      componentId,
      elementId,
      type,
    }),
  );
}

export function* switchNavigateElement() {
  yield takeLatest(FILL_STEP_NAVIGATE_ELEMENT, doNavigateElement);
  yield takeLatest(FILL_STEP_NAVIGATE_NEXT_ELEMENT, doNavigateToNextElement);
}
