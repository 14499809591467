/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';

import { i18n } from '../../common/i18n-loader';

import Button from '../common/Button';
import Modal from '../common/Modal';

const styles = {
  modifyProtocolId: {
    display: 'grid',
    gridGap: '1em',
    gridTemplateRows: 'auto auto auto',
    justifyContent: 'center',
    textAlign: 'center',

    buttonContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: '1em',
    },
  },
};

const ConfirmChangeProtocolIDModal = (props) => {
  const { isOpenChangeProtocolIdModal, onCloseChangeProtocolId, handleCloseChangeProtocolId } =
    props;
  return (
    <Modal
      title={i18n._('CONFIRM-CHANGE-PROTOCOL-ID.TITLE')}
      isOpen={isOpenChangeProtocolIdModal}
      onClose={onCloseChangeProtocolId}
      zIndex={1003}
      width={'50vw'}
    >
      <div css={styles.modifyProtocolId}>
        <label>
          <Trans>CONFIRM-CHANGE-PROTOCOL-ID.WARNING-MESSAGE</Trans>
        </label>
        <label>
          <Trans>CONFIRM-CHANGE-PROTOCOL-ID.MODIFY-ID</Trans>
        </label>
        <div css={styles.modifyProtocolId.buttonContainer}>
          <Button secondary onClick={onCloseChangeProtocolId}>
            <Trans>CONFIRM-CHANGE-PROTOCOL-ID.ABORT</Trans>
          </Button>
          <Button default onClick={handleCloseChangeProtocolId}>
            <Trans>CONFIRM-CHANGE-PROTOCOL-ID.CONFIRM</Trans>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmChangeProtocolIDModal;
