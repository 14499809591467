export const mapSecurityDepotTypesToI18n = (securityDepotTypes) => {
  let messages = {
    de: {},
    en: {},
    fr: {},
    it: {},
  };
  securityDepotTypes.forEach((e) => {
    messages.de[`${e.code}-SECURITY-DEPOT-TYPE`] = e.de;
    messages.en[`${e.code}-SECURITY-DEPOT-TYPE`] = e.en;
    messages.fr[`${e.code}-SECURITY-DEPOT-TYPE`] = e.fr;
    messages.it[`${e.code}-SECURITY-DEPOT-TYPE`] = e.it;
  });
  return messages;
};
