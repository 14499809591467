import React from 'react';
import { colors } from '../../common/theme/colors';

export const DuplicateIcon = (props) => {
  const { disabled } = props;
  return (
    <svg
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 26"
      height="100%"
      widht="100%"
      fill={disabled ? colors.disabledIcon : 'currentColor'}
    >
      <path d="M21,19H12v1.5a.5.5,0,0,1-.31.46.48.48,0,0,1-.54-.11l-5-5a.48.48,0,0,1,0-.7l5-5a.48.48,0,0,1,.54-.11.5.5,0,0,1,.31.46V12h9v1H11.5a.5.5,0,0,1-.5-.5v-.79L7.21,15.5,11,19.29V18.5a.5.5,0,0,1,.5-.5H21Z" />
      <polygon points="21 26 21 21 20 21 20 25 1 25 1 3 15 3 15 8 20 8 20 10 21 10 21 7.29 15.71 2 0 2 0 26 21 26" />
    </svg>
  );
};
