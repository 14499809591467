/** @jsxImportSource @emotion/react */
import { memo, useCallback } from 'react';

const styles = {
  img: {
    width: '100%',
    height: '100%',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    cursor: 'pointer',
  },
};

export const ImageViewer = memo(({ image, imageData, onView }) => {
  const handleViewImage = useCallback(() => !!onView && onView(image), [image, onView]);

  return (
    <div
      css={styles.img}
      style={{ backgroundImage: `url(${imageData})` }}
      onClick={handleViewImage}
    />
  );
});
