import { LOCATION_CHANGE, push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';

import { selectProtocolIdRoute } from '../../redux/selectors';
import { acceptanceProtocolRoutes } from '../../routes';
import { selectCurrentSigner } from '../../../common/redux/selectors';

function* doRedirectIfInvalid() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route) {
    if (!!route.params.id && route.params.step && route.params.step === 'sign') {
      const currentSigner = yield select(selectCurrentSigner);

      if (!currentSigner) {
        yield put(push(acceptanceProtocolRoutes.signStep(route.params.id)));
      }
    }
  }
}

export function* switchRedirectIfInvalid() {
  yield takeLatest(LOCATION_CHANGE, doRedirectIfInvalid);
}
