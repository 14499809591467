import produce from 'immer';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import {
  HOME_SEARCH_RENTAL_OBJECTS,
  HOME_SEARCH_RENTAL_OBJECTS_ERROR,
  HOME_SEARCH_RENTAL_OBJECTS_SUCCESS,
  HOME_CLEAR_RENTAL_OBJECTS,
} from './constants';
import { getById } from '../../common/services/api';
import { LOCATION_CHANGE } from 'connected-react-router';
import { selectSearchRentalObjectRoute } from './selectors';

export function homeSearchRentalObjects(search) {
  return {
    type: HOME_SEARCH_RENTAL_OBJECTS,
    payload: search.target.value,
  };
}

function* doSearchRentalObjects({ payload }) {
  try {
    const data = !!payload ? yield call(getById, 'rentalobject', payload) : [];

    yield put({
      type: HOME_SEARCH_RENTAL_OBJECTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: HOME_SEARCH_RENTAL_OBJECTS_ERROR,
      payload: error.message,
    });
  }
}

function* doClearRentalObjects() {
  const route = yield select(selectSearchRentalObjectRoute);

  if (!!route) {
    yield put({ type: HOME_CLEAR_RENTAL_OBJECTS });
  }
}

export function* switchSearchRentalObjects() {
  yield takeLatest(HOME_SEARCH_RENTAL_OBJECTS, doSearchRentalObjects);
  yield takeLatest(LOCATION_CHANGE, doClearRentalObjects);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CLEAR_RENTAL_OBJECTS:
        draft.rentalObjects = [];
        draft.search = '';
        break;
      case HOME_SEARCH_RENTAL_OBJECTS:
        draft.rentalObjects = [];
        draft.search = action.payload;
        draft.ui.busy.rentalObjects = true;
        break;
      case HOME_SEARCH_RENTAL_OBJECTS_SUCCESS:
        draft.rentalObjects = action.payload;
        draft.ui.busy.rentalObjects = false;
        break;
      case HOME_SEARCH_RENTAL_OBJECTS_ERROR:
        draft.ui.busy.rentalObjects = false;
        break;
      default:
        return state;
    }
  });
