/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { routerActions } from 'connected-react-router';
import PropTypes from 'prop-types';
import { equals, none } from 'ramda';
import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { i18n } from '../../../common/i18n-loader';
import { colors } from '../../../common/theme/colors';
import { variables } from '../../../common/theme/variables';
import Button from '../../common/Button';
import ConfirmModal from '../../common/ConfirmModal';
import { formatAsDate } from '../../common/Functions';
import { Icon } from '../../common/Icon';
import * as commonActions from '../../common/redux/actions';
import {
  selectHasAssignationWorkflow,
  selectIsDeleteProtocolBusy,
  selectIsOnline,
  selectOfflineProtocols,
} from '../../common/redux/selectors';
import { ResultBuilder } from '../../common/ResultBuilder';
import Spinner from '../../common/Spinner';
import {
  selectCurrentProtocol,
  selectCurrentProtocolHasPrevious,
  selectCurrentProtocolHasPreviousLeavingAgreement,
  selectNewTenantInfo,
  selectOldTenantInfo,
  selectSecondNewTenantInfo,
  selectSecondOldTenantInfo,
} from '../redux/selectors';
import AssignUserModal from './AssignUserModal';
import * as actions from './redux/actions';
import { selectIsAssignmentModalOpen, selectIsDeleteModalOpen } from './redux/selectors';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '5fr min-content',
    gridRowGap: '16px',
    info: {
      display: 'grid',
      gridTemplateRows: 'repeat(6,min-content)',
      overflow: 'overlay',
      overflowScrolling: 'touch',
      WebkitOverflowScrolling: 'touch',

      padding: `16px ${variables.gutter.md} 8px`,

      [variables.breakpoints.md]: {
        padding: `16px ${variables.gutter.lg} 8px`,
      },
    },
    overflow: 'hidden',
  },
  buttons: {
    padding: `8px ${variables.gutter.md} 16px`,

    [variables.breakpoints.md]: {
      padding: `8px ${variables.gutter.lg} 16px`,
    },

    display: 'grid',
    gridTemplateColumns: 'auto',
  },
  protocol: {
    backgroundColor: `${colors.lighterGrey}`,
    padding: '1.5em',
  },
  manager: {
    display: 'grid',
    gridTemplateColumns: 'auto min-content',
    gridTemplateRows: 'min-content min-content',
    gridTemplateAreas: '"title button" "owner button"',

    backgroundColor: `${colors.lightGrey}`,
    borderBottom: `solid 1px ${colors.lighterGrey}`,
    borderLeft: `solid 1px ${colors.lighterGrey}`,
    borderRight: `solid 1px ${colors.lighterGrey}`,
    padding: '1.5em',
    gridGap: '.5em',

    title: {
      gridArea: 'title',
    },
    owner: {
      gridArea: 'owner',
    },
  },
  assignButtonContainer: {
    gridArea: 'button',
    alignSelf: 'center',
  },
  assignButton: {
    width: 'min-content',
    justifySelf: 'end',
    padding: '8px 12px',
    fontSize: '14px',

    [variables.breakpoints.md]: { padding: '12px 16px', fontSize: '15px', whiteSpace: 'nowrap' },
  },
  oldTenants: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    backgroundColor: `${colors.lightGrey}`,
    borderLeft: `solid 1px ${colors.lighterGrey}`,
    borderRight: `solid 1px ${colors.lighterGrey}`,
    borderBottom: `solid 1px ${colors.lighterGrey}`,
    padding: '1.5em',
  },
  oldTenant: {
    display: 'grid',
    gridGap: '.5em',
  },
  newTenants: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '.5em',
    backgroundColor: `#D7E1EC`,
    borderLeft: `solid 1px ${colors.lighterGrey}`,
    borderRight: `solid 1px ${colors.lighterGrey}`,
    borderBottom: `solid 1px ${colors.lighterGrey}`,
    padding: '1.5em',
  },

  newTenant: {
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    gridGap: '.5em',
  },
  acceptanceProtocol: {
    display: 'grid',
    gridGap: '.5em',
    backgroundColor: `${colors.lightGrey}`,
    borderLeft: `solid 1px ${colors.lighterGrey}`,
    borderRight: `solid 1px ${colors.lighterGrey}`,
    borderBottom: `solid 1px ${colors.lighterGrey}`,
    padding: '1.5em',
  },
  gap: {
    height: '1em',
  },
  title: {
    fontWeight: 'bold',
  },
  pdf: {
    svg: {
      color: 'red',
    },
    span: {
      paddingTop: '4px',
    },
    display: 'grid',
    cursor: 'pointer',
    gridTemplateColumns: 'min-content 1fr',
    gridGap: '.3em',
  },
};

export class MainStep extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleOpenPreviousLeavingAgreement = this.handleOpenPreviousLeavingAgreement.bind(this);
  }

  onDelete = () => {
    const {
      protocol,
      actions: { commonDeleteProtocol, mainStepCloseDeleteModal },
    } = this.props;
    commonDeleteProtocol(protocol.id);
    mainStepCloseDeleteModal();
  };

  static propTypes = {
    protocol: PropTypes.object,
    actions: PropTypes.object.isRequired,
  };

  getOldTenantStreetInfo(street, postalCode, place) {
    if (!street && (!!postalCode || !!place)) {
      return `: ${postalCode} ${place}`;
    }

    if (!!street && !postalCode && !place) {
      return `: ${street}`;
    }

    return `: ${street}, ${postalCode} ${place}`;
  }

  getOldTenant(tenant) {
    if (!!tenant) {
      return (
        <Fragment>
          {!!tenant.name || tenant.surename ? (
            <span>{`${tenant.name} ${tenant.surename}`}</span>
          ) : (
            <Fragment />
          )}
          {!!tenant.street || !!tenant.postalCode || !!tenant.place ? (
            <span>
              <Trans>LABELS.OLD-TENANT-NEW-MAIN-STEP</Trans>
              {this.getOldTenantStreetInfo(tenant.street, tenant.postalCode, tenant.place)}
            </span>
          ) : (
            <Fragment />
          )}
          {!!tenant.privatePhone ? (
            <span>
              <Trans>LABELS.OLD-TENANT-PRIVATE-PHONE-MAIN-STEP</Trans>
              {`: ${tenant.privatePhone}`}
            </span>
          ) : (
            <Fragment />
          )}
          {!!tenant.privatePhone2 ? (
            <span>
              <Trans>LABELS.OLD-TENANT-MOBILE-MAIN-STEP</Trans>
              {tenant.privatePhone2}
            </span>
          ) : (
            <Fragment />
          )}
          {!!tenant.mobilePhone ? (
            <span>
              <Trans>LABELS.OLD-TENANT-MOBILE-PHONE-MAIN-STEP</Trans>
              {`: ${tenant.mobilePhone}`}
            </span>
          ) : (
            <Fragment />
          )}
        </Fragment>
      );
    }
  }

  getOldTenantNumberOfInfo(tenant) {
    if (!tenant) {
      return 0;
    }
    const nameSurename = !!tenant.name || !!tenant.surename ? 1 : 0;
    const streetInfo = !!tenant.street || !!tenant.postalCode || !!tenant.place ? 1 : 0;
    const privatePhone = !!tenant.privatePhone ? 1 : 0;
    const privatePhone2 = !!tenant.privatePhone2 ? 1 : 0;
    const mobilePhone = !!tenant.mobilePhone ? 1 : 0;

    return nameSurename + streetInfo + privatePhone + mobilePhone + privatePhone2 + 1;
  }

  getNewTenant(tenant) {
    if (!!tenant) {
      return (
        <span>
          {tenant.name} {tenant.surename}
        </span>
      );
    }
  }

  getNewTenantNumberOfInfo(tenant) {
    return !!tenant && (!!tenant.name || !!tenant.surename) ? 1 : 0;
  }

  getAcceptanceProtocolNumberOfInfo() {
    const { protocol } = this.props;

    const referenceNumbers =
      !!protocol.previousProtocol.nachmieterReferenznummer ||
      !!protocol.previousProtocol.vormieterReferenznummer
        ? 1
        : 0;
    const userInfo =
      !!protocol.previousProtocol.userPersonVorname || !!protocol.previousProtocol.userPersonName
        ? 1
        : 0;
    const signedAt = protocol.previousProtocol.signedAt ? 1 : 0;

    return referenceNumbers + userInfo + signedAt;
  }

  handleOpenPreviousLeavingAgreement() {
    const { protocol } = this.props;

    window.open(
      `/api/pdf/leavingagreement/${protocol.previousProtocol.id}`,
      '_blank',
      'noopener,noreferrer',
    );
  }

  getAcceptanceProtocol() {
    const {
      protocol,
      currentProtocolHasPrevious,
      currentProtocolHasPreviousLeavingAgreement,
      actions: { commonDownloadPdf, commonDownloadLeavingAgreementPdf },
    } = this.props;

    const referenceNumbers = !!protocol.previousProtocol.nachmieterReferenznummer
      ? `${protocol.previousProtocol.vormieterReferenznummer}/${protocol.previousProtocol.nachmieterReferenznummer}`
      : protocol.previousProtocol.vormieterReferenznummer;

    const signedAt =
      protocol.previousProtocol.signedAt ||
      protocol.input.signatures.timestamp ||
      protocol.createdAt;

    return (
      <Fragment>
        <span>
          <Trans>LABELS.REFERENCE-NUMBERS-MAIN-STEP</Trans>
          {`: ${referenceNumbers}`}
        </span>
        {!!signedAt ? (
          <span>
            <Trans>LABELS.SIGN-DATE-MAIN-STEP</Trans>
            {`: ${formatAsDate(signedAt)}`}
          </span>
        ) : (
          <Fragment />
        )}
        <span>
          <Trans>LABELS.ACCEPTANCE-CARRIED-OUT-BY</Trans>
          {`: ${protocol.previousProtocol.userPersonVorname} ${protocol.previousProtocol.userPersonName}`}
        </span>
        {currentProtocolHasPrevious && (
          <span css={styles.pdf} onClick={commonDownloadPdf}>
            <Icon icon={'file-pdf'} size="lg" />
            <span>
              <Trans>LABELS.DOWNLOAD-PDF-MAIN-STEP</Trans>
            </span>
          </span>
        )}
        {currentProtocolHasPreviousLeavingAgreement && (
          <span css={styles.pdf} onClick={commonDownloadLeavingAgreementPdf}>
            <Icon icon={'file-pdf'} size="lg" />
            <span>
              <Trans>LABELS.DOWNLOAD-LEAVING-AGREEMENT-MAIN-STEP</Trans>
            </span>
          </span>
        )}
      </Fragment>
    );
  }

  checkOldTenant(tenant) {
    return (
      !!tenant &&
      (!!tenant.mobilePhone ||
        !!tenant.name ||
        !!tenant.place ||
        !!tenant.postalCode ||
        !!tenant.privatePhone ||
        !!tenant.privatePhone2 ||
        !!tenant.street ||
        !!tenant.surename)
    );
  }

  handleOpenAssignmentModal = () => {
    const {
      isOnline,
      actions: { commonOpenInformationModal, mainStepOpenAssignmentModal },
    } = this.props;

    if (!isOnline) {
      commonOpenInformationModal({
        title: i18n._('MAIN-STEP.INFO-MODAL.TITLE'),
        message: i18n._('MESSAGES.MUST-BE-ON-LINE-TO-ASSIGN-PROTOCOL'),
      });
    } else {
      mainStepOpenAssignmentModal();
    }
  };

  render() {
    const {
      protocol,
      oldTenantInfo,
      secondOldTenantInfo,
      newTenantInfo,
      secondNewTenantInfo,
      isDeleteModalOpen,
      isDeleteProtocolBusy,
      hasAssignationWorkflow,
      offlineProtocols,
      actions: { mainStepOpenDeleteModal, mainStepCloseDeleteModal },
    } = this.props;

    const protocolId = !!protocol ? protocol.id : null;
    const isOnlineProtocol = none(equals(protocolId), offlineProtocols);

    return (
      !!protocol && (
        <div css={styles.container}>
          <div css={styles.container.info}>
            <div css={styles.protocol}>
              <span css={styles.title}>
                <Trans>LABELS.REAL-ESTATE</Trans>
              </span>
              <span> - </span>
              <ResultBuilder
                formerTenantReferenceNumber={protocol.rentalData.vormieterReferenznummer}
                tenantReferenceNumber={protocol.rentalData.nachmieterReferenznummer}
                buildingStreet={protocol.rentalData.hausStrasse}
                buildingZipcode={protocol.rentalData.hausPlz}
                buildingPlace={protocol.rentalData.hausOrt}
                numberOfRoomsCode={protocol.rentalData.anzahlZimmerCd}
                floorCode={protocol.rentalData.stockwerkCd}
                tenantForname={protocol.rentalData.vormieterVorname}
                tenantSurname={protocol.rentalData.vormieterName}
              />
            </div>
            {!!oldTenantInfo && this.checkOldTenant(oldTenantInfo) && (
              <div css={styles.oldTenants}>
                <div
                  css={styles.oldTenant}
                  style={{
                    gridTemplateRows: `repeat(${this.getOldTenantNumberOfInfo(
                      oldTenantInfo,
                    )}, min-content)`,
                  }}
                >
                  <div css={styles.title}>
                    <Trans>LABELS.OLD-TENANT-MAIN-STEP</Trans>
                  </div>
                  {this.getOldTenant(oldTenantInfo)}
                </div>
                {!!secondOldTenantInfo && this.checkOldTenant(secondOldTenantInfo) && (
                  <div
                    css={styles.oldTenant}
                    style={{
                      gridTemplateRows: `repeat(${this.getOldTenantNumberOfInfo(
                        secondOldTenantInfo,
                      )}, min-content)`,
                    }}
                  >
                    <div css={styles.title}>
                      <Trans>LABELS.SECOND-OLD-TENANT-MAIN-STEP</Trans>
                    </div>
                    {this.getOldTenant(secondOldTenantInfo)}
                  </div>
                )}
              </div>
            )}
            {!!newTenantInfo && (!!newTenantInfo.name || !!newTenantInfo.surename) && (
              <div css={styles.newTenants}>
                <div css={styles.newTenant}>
                  <div css={styles.title}>
                    <Trans>LABELS.NEW-TENANT-MAIN-STEP</Trans>
                  </div>
                  {this.getNewTenant(newTenantInfo)}
                </div>
                {!!secondNewTenantInfo && (
                  <div css={styles.newTenant}>
                    <div css={styles.title}>
                      <Trans>LABELS.SECOND-NEW-TENANT-MAIN-STEP</Trans>
                    </div>
                    {this.getNewTenant(secondNewTenantInfo)}
                  </div>
                )}
              </div>
            )}
            <div css={styles.manager}>
              <div css={{ ...styles.title, ...styles.manager.title }}>
                <Trans>LABELS.ACCEPTANCE-CARRIED-OUT-BY</Trans>
              </div>
              <div
                css={styles.manager.owner}
              >{`${protocol.user.personVorname} ${protocol.user.personName}`}</div>

              {hasAssignationWorkflow && isOnlineProtocol && (
                <div css={styles.assignButtonContainer}>
                  <Button
                    primary
                    css={styles.assignButton}
                    onClick={this.handleOpenAssignmentModal}
                  >
                    <Trans>BUTTONS.ASSIGN-USER</Trans>
                  </Button>
                </div>
              )}
            </div>
            {!!protocol.previousProtocol && (
              <div
                css={styles.acceptanceProtocol}
                style={{
                  gridTemplateRows: `repeat(${this.getAcceptanceProtocolNumberOfInfo()}, min-content)`,
                }}
              >
                <div css={styles.title}>
                  <Trans>LABELS.ACCEPTANCE-PROTOCOL-MAIN-STEP</Trans>
                </div>
                {this.getAcceptanceProtocol()}
              </div>
            )}
          </div>
          <div css={styles.buttons}>
            <Button primary onClick={mainStepOpenDeleteModal}>
              <Trans>BUTTONS.PROCOTOL-DELETE</Trans>
            </Button>
          </div>

          <ConfirmModal
            title={i18n._('DELETE-PROTOCOL.TEXTS.TITLE')}
            isOpen={isDeleteModalOpen}
            onConfirm={this.onDelete}
            onClose={mainStepCloseDeleteModal}
          >
            <Spinner show={isDeleteProtocolBusy}>
              <Trans>MESSAGES.DELETE-CONFIRM</Trans>
            </Spinner>
          </ConfirmModal>
          <AssignUserModal />
        </div>
      )
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    isOnline: selectIsOnline(state),
    protocol: selectCurrentProtocol(state),
    isDeleteModalOpen: selectIsDeleteModalOpen(state),
    isDeleteProtocolBusy: selectIsDeleteProtocolBusy(state),
    isAssignmentModalOpen: selectIsAssignmentModalOpen(state),
    oldTenantInfo: selectOldTenantInfo(state),
    secondOldTenantInfo: selectSecondOldTenantInfo(state),
    newTenantInfo: selectNewTenantInfo(state),
    secondNewTenantInfo: selectSecondNewTenantInfo(state),
    currentProtocolHasPrevious: selectCurrentProtocolHasPrevious(state),
    currentProtocolHasPreviousLeavingAgreement:
      selectCurrentProtocolHasPreviousLeavingAgreement(state),
    hasAssignationWorkflow: selectHasAssignationWorkflow(state),
    offlineProtocols: selectOfflineProtocols(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...routerActions, ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainStep);
