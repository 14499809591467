import { PROGRESS_STATUS_INITIAL } from './constants';

const initialState = {
  progress: {
    closed: PROGRESS_STATUS_INITIAL,
    emails: PROGRESS_STATUS_INITIAL,
    pdfs: PROGRESS_STATUS_INITIAL,
    sync: PROGRESS_STATUS_INITIAL,
    onSite: PROGRESS_STATUS_INITIAL,
  },
  successfulSteps: [],

  ui: {
    modal: {
      info: false,
      progress: false,

      message: null,
    },
  },
};

export default initialState;
