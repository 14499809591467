import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBackspace,
  faBug,
  faCalendar,
  faCamera,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronRight,
  faCircle,
  faCog,
  faCommentDots,
  faExclamation,
  faExternalLinkAlt,
  faFile,
  faFileAlt,
  faFileCode,
  faFileImage,
  faFilePdf,
  faFolder,
  faFolderOpen,
  faHome,
  faLock,
  faMinus,
  faMinusSquare,
  faPlus,
  faPlusSquare,
  faReply,
  faSquare,
  faSync,
  faTasks,
  faTimes,
  faWifi,
  faDownload,
  faUpload,
  faTrashAlt,
  faForward,
  faMoneyBill,
  faComment,
  faImage,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faWifi,
  faTimes,
  faChevronRight,
  faChevronDown,
  faTasks,
  faSync,
  faPlus,
  faMinus,
  faCalendar,
  faBackspace,
  faLock,
  faCheck,
  faFilePdf,
  faCheckSquare,
  faSquare,
  faPlusSquare,
  faMinusSquare,
  faFolder,
  faFolderOpen,
  faFile,
  faExclamation,
  faFileCode,
  faBug,
  faCommentDots,
  faFileImage,
  faFileAlt,
  faCog,
  faCamera,
  faCircle,
  faReply,
  faExternalLinkAlt,
  faDownload,
  faUpload,
  faTrashAlt,
  faForward,
  faMoneyBill,
  faComment,
  faImage,
);
