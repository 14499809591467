import produce from 'immer';
import { put, select, takeLatest, take } from 'redux-saga/effects';
import {
  COMMON_CHECK_AUTH,
} from "./constants";
import { LOCATION_CHANGE } from 'connected-react-router';
import { selectIsLoggedIn, selectIsTokenExpired } from "./selectors";
import { commonDapLoginTokenExpired } from "./actions";

export function commonCheckIsAuth() {
  return {
    type: COMMON_CHECK_AUTH,
  };
}

function* doCheckIsAuth() {
  const isLoggedIn = yield select(selectIsLoggedIn);
  const isTokenExpired = yield select(selectIsTokenExpired);

  if (isLoggedIn && isTokenExpired) {
    yield put(commonDapLoginTokenExpired());
  }
}

export function* switchCheckIsAuth() {
  yield takeLatest(COMMON_CHECK_AUTH, doCheckIsAuth);
  yield take(LOCATION_CHANGE);
  yield put(commonCheckIsAuth());
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      default:
        return state;
    }
  });
