/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { routerActions } from 'connected-react-router';
import * as actions from './redux/actions';
import * as commonActions from '../common/redux/actions';
import Spinner from '../common/Spinner';
import { variables } from '../../common/theme/variables';
import { colors } from '../../common/theme/colors';
import {
  selectCurrentMigration,
  selectImportMigration,
  selectRetainMigration,
  selectDeleteMigration,
  selectIsLoading,
  selectSelectedTemplateName,
  selectMigrationStatus,
  selectMigrationIsRunning,
  selectIsRetainModalOpen,
  selectIsDeleteModalOpen,
  selectToogleShowSuccessLogs,
  selectMigrationIsSuccess,
} from './redux/selectors';
import { selectClient } from '../common/redux/selectors';

import { MigrationList } from './MigrationList';
import { LogViewer } from './LogViewer';
import RetainModal from './RetainModal';
import DeleteModal from './DeleteModal';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateColumns: `2fr 8fr`,
  },

  migrationList: {
    padding: '20px',
  },
  logviewer: {
    backgroundColor: colors.whiteSmoke,
    padding: '20px',
  },

  buttons: {
    container: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: '10px',
      backgroundColor: 'white',

      padding: `15px ${variables.gutter.sm}`,

      [variables.breakpoints.md]: {
        padding: `15px ${variables.gutter.md}`,
        gridAutoFlow: 'column',
        gridGap: '30px',
      },
    },
    gap: {
      display: 'none',
      [variables.breakpoints.md]: {
        display: 'block',
      },
    },
  },
};

export class DefaultPage extends Component {
  static propTypes = {};

  render() {
    const {
      clientName,
      migration,
      importMigration,
      isLoading,
      migrationStatus,
      isMigrationRunning,
      isMigrationSuccess,
      selectedTemplate,
      isRetainModalOpen,
      isDeleteModalOpen,
      toogleShowSuccessLogs,
      retainMigration,
      deleteMigration,
      actions: {
        migrationStartMigration,
        migrationStopMigration,
        migrationRetainMigration,
        migrationDeleteMigration,
        migrationSetTemplateMigration,
        migrationCloseRetainModal,
        migrationCloseDeleteModal,
        migrationToogleShowSuccessLogs,
      },
    } = this.props;
    return (
      <Spinner show={isLoading}>
        <RetainModal
          isOpen={isRetainModalOpen}
          onClose={migrationCloseRetainModal}
          results={retainMigration}
        />
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={migrationCloseDeleteModal}
          results={deleteMigration}
        />
        <div css={styles.container}>
          <div css={styles.migrationList}>
            <MigrationList
              migration={migration}
              importMigration={importMigration}
              clientName={clientName}
              setTemplateMigration={migrationSetTemplateMigration}
              selectedTemplate={selectedTemplate}
              isMigrationRunning={isMigrationRunning}
            />
          </div>

          <div css={styles.logviewer}>
            <LogViewer
              migrationId={migration.id}
              importMigration={importMigration}
              migrationStartMigration={migrationStartMigration}
              migrationStopMigration={migrationStopMigration}
              migrationRetainMigration={migrationRetainMigration}
              migrationDeleteMigration={migrationDeleteMigration}
              migrationStatus={migrationStatus}
              isMigrationRunning={isMigrationRunning}
              isMigrationSuccess={isMigrationSuccess}
              migrationToogleShowSuccessLogs={migrationToogleShowSuccessLogs}
              toogleShowSuccessLogs={toogleShowSuccessLogs}
              selectedTemplate={selectedTemplate}
              isLoading={isLoading}
            />
          </div>
        </div>
      </Spinner>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    clientName: selectClient(state),
    migration: selectCurrentMigration(state),
    importMigration: selectImportMigration(state),
    retainMigration: selectRetainMigration(state),
    deleteMigration: selectDeleteMigration(state),
    isLoading: selectIsLoading(state),
    selectedTemplate: selectSelectedTemplateName(state),
    migrationStatus: selectMigrationStatus(state),
    isMigrationRunning: selectMigrationIsRunning(state),
    isMigrationSuccess: selectMigrationIsSuccess(state),
    isRetainModalOpen: selectIsRetainModalOpen(state),
    isDeleteModalOpen: selectIsDeleteModalOpen(state),
    toogleShowSuccessLogs: selectToogleShowSuccessLogs(state),
  };
}
/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...routerActions, ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultPage);
