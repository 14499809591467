import React from 'react';

export const MinusIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <rect x="15.5" y="6" width="8" height="1" />
    <rect x="15.5" y="9" width="8" height="1" />
    <rect x="15.5" y="12" width="8" height="1" />
    <rect x="15.5" y="15" width="8" height="1" />
    <rect x="15.5" y="18" width="8" height="1" />
    <rect x="15.5" y="21" width="8" height="1" />
    <polygon points="15 2 15 3 26 3 26 25 7 25 7 15 6 15 6 26 27 26 27 2 15 2" />
    <path d="M0,0V13H13V0ZM12,12H1V1H12Z" />
    <rect x="3" y="6" width="7" height="1" />
  </svg>
);
