import { isEmpty } from 'ramda';
import { string } from 'yup';
import { i18n } from '../../common/i18n-loader';
import isEmail from 'validator/es/lib/isEmail';

const isEmailWithASCII = (email) => {
  return isEmail(email, {
    allow_utf8_local_part: false,
  });
};
export const validation = {
  date: string()
    .matches(/^(|[0-9]{2}\/[0-9]{2}\/[0-9]{4} 00:00:00)$/, i18n._('VALIDATIONS.INVALID.DATE'))
    .nullable(),
  email: string()
    .test({
      name: 'email',
      test: function (value) {
        if (!value) return true;
        return isEmailWithASCII(value)
          ? true
          : this.createError({
              message: `${i18n._('VALIDATIONS.INVALID.EMAIL')}: ${value}`,
            });
      },
    })
    .nullable(),
  emailWithUtf8: string().email(i18n._('VALIDATIONS.INVALID.EMAIL')),
  multipleEmails: string().test({
    name: 'multipleEmails',
    test: function (value) {
      const firstInvalidEmail = value
        .split(',')
        .map((email) => email.trim())
        .filter((v) => !isEmpty(v))
        .find((v) => !isEmailWithASCII(v));

      return !firstInvalidEmail
        ? true
        : this.createError({
            message: `${i18n._('VALIDATIONS.INVALID.EMAIL')}: ${firstInvalidEmail}`,
          });
    },
  }),
  select: string().nullable(),
};
