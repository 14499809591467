/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { colors } from '../../common/theme/colors';
import { variables } from '../../common/theme/variables';

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    alignItems: 'center',
    [variables.breakpoints.md]: {
      gridGap: '.25em',
    },
  },
  bold: {
    fontWeight: 'bold',
    color: colors.darker,
  },
};

export class ResultBuilder extends Component {
  isNotEmpty(value) {
    return Array.isArray(value) ? !!value && !!value.length : !!value;
  }

  getAddSeparator() {
    let count = -1;

    return (value) => {
      count++;
      return (
        <span type="separator" key={count + '-separator'}>
          {value}
        </span>
      );
    };
  }

  getTextBuilder() {
    return (...values) => {
      const result = values.reduce((accumulator, current, index) => {
        if (!!current && !!current.props && current.props.type === 'separator') {
          if (
            !!accumulator.length &&
            index < values.length - 1 &&
            this.isNotEmpty(values[index + 1])
          ) {
            accumulator.push(current);
          }
          return accumulator;
        } else {
          return this.isNotEmpty(current) ? accumulator.concat(current) : accumulator;
        }
      }, []);
      return this.isNotEmpty(result) ? result : null;
    };
  }

  getTextWithLabel() {
    let count = -1;

    return (label, value) => {
      count++;
      return this.isNotEmpty(value)
        ? [<span key={count + '-label'}>{label}: </span>].concat(value)
        : null;
    };
  }

  static propTypes = {
    formerTenantReferenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tenantReferenceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buildingStreet: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buildingZipcode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    buildingPlace: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    numberOfRoomsCode: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
    floorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tenantForname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    tenantSurname: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  render() {
    const {
      formerTenantReferenceNumber,
      tenantReferenceNumber,
      buildingStreet,
      buildingZipcode,
      buildingPlace,
      numberOfRoomsCode,
      floorCode,
      tenantForname,
      tenantSurname,
    } = this.props;

    const textBuilder = this.getTextBuilder();
    const textWithLabel = this.getTextWithLabel();
    const addSeparator = this.getAddSeparator();

    return (
      <span css={styles.container}>
        <span css={styles.bold}>
          {textBuilder(formerTenantReferenceNumber, addSeparator(' / '), tenantReferenceNumber)}
        </span>
        <span>
          {textBuilder(
            textBuilder(
              buildingStreet,
              addSeparator(', '),
              textBuilder(buildingZipcode, addSeparator(' '), buildingPlace),
            ),
            addSeparator(', '),
            textWithLabel(<Trans>LABELS.ROOM</Trans>, numberOfRoomsCode),
            addSeparator(', '),
            textWithLabel(<Trans>LABELS.FLOOR</Trans>, floorCode),
            addSeparator('; '),
            textWithLabel(
              <Trans>LABELS.CURRENT</Trans>,
              textBuilder(tenantForname, addSeparator(' '), tenantSurname),
            ),
          )}
        </span>
      </span>
    );
  }
}
