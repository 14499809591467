/** @jsxImportSource @emotion/react */
import { createContext, useState, memo } from 'react';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content auto',
    gridAutoColumns: '1fr',
  },
};

export const TabsContext = createContext({ selectedTab: null, selectTab: null, tabs: 0 });

const Tabs = (props) => {
  const { children } = props;

  const [selectedTab, selectTab] = useState(null);
  const [tabs] = useState(children.length);

  return (
    <TabsContext.Provider value={{ selectedTab, selectTab, tabs }}>
      <div css={styles.container}>{children}</div>
    </TabsContext.Provider>
  );
};

Tabs.propTypes = {};

Tabs.defaultProps = {};

export default memo(Tabs);
