import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from 'react';
import { filter, find } from 'ramda';
import { usePrevious } from './usePrevious';
import { getOrientedImage, debugCamera } from './CameraHelpers';

export const FacingModes = {
  USER: 'user',
  ENVIRONMENT: 'environment',
};
export const ErrorTypes = Object.freeze({
  PERMISSION_DENIED: 1,
  GENERAL_ERROR: 2,
  NO_CAMERA_AVAILABLE: 3,
  CANVAS_NOT_SUPPORTED: 4,
  SWITCH_NOT_POSSIBLE: 5,
  CAMERA_NOT_INITIALIZED: 6,
  DEBUG: 7,
});

export const Camera = forwardRef((props, ref) => {
  const {
    style,
    facingMode,
    mirrored,
    numberOfCamerasCallback,
    onErrorMessage,
    format,
    width,
    height,
    imageQuality,
    debug,
  } = props;

  const player = useRef(null);
  const [cameras, setCameras] = useState();
  const [stream, setStream] = useState(null);
  const [currentFacingMode, setFacingMode] = useState(facingMode);
  const [cameraInitialized, setCameraInitialized] = useState(false);
  const previousFacingMode = usePrevious(currentFacingMode);
  const cameraStyle = useMemo(
    (mirrored) => ({
      width: '100%',
      height: '100%',
      objectFit: 'contain',
      transform: `rotateY(${mirrored ? '180deg' : '0deg'})`,
    }),
    [],
  );

  const getImage = useCallback(() => {
    const canvas = document.createElement('canvas');

    let canvasWidth = player.current.videoWidth;
    let canvasHeight = player.current.videoHeight;

    const aspectRatio = canvasWidth / canvasHeight;

    canvasWidth = width || player.current.clientWidth;
    canvasHeight = canvasWidth / aspectRatio;

    if (!!height && canvasHeight < height) {
      canvasHeight = height;
      canvasWidth = canvasHeight * aspectRatio;
    }

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const ctx = canvas.getContext('2d');

    if (ctx && canvas) {
      // mirror the screenshot
      if (mirrored) {
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      }

      ctx.imageSmoothingEnabled = true;
      ctx.drawImage(player.current, 0, 0, canvas.width, canvas.height);

      // invert mirroring
      if (props.mirrored) {
        ctx.scale(-1, 1);
        ctx.translate(-canvas.width, 0);
      }
    }

    return [canvas, canvas.toDataURL(format, imageQuality)];
  }, [player, format, height, imageQuality, mirrored, props.mirrored, width]);

  const handleSuccess = useCallback((stream) => setStream(stream), [setStream]);

  const handleError = useCallback(
    (error) => {
      console.error(error);
      //https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
      if (error.name === 'PermissionDeniedError' || error.name === 'NotAllowedError') {
        onErrorMessage(ErrorTypes.PERMISSION_DENIED);
      } else if (error.name === 'NotFoundError') {
        onErrorMessage(ErrorTypes.NO_CAMERA_AVAILABLE);
      } else {
        onErrorMessage(ErrorTypes.GENERAL_ERROR);
      }
    },
    [onErrorMessage],
  );

  useImperativeHandle(ref, () => ({
    takePhoto: async (filename) => {
      if (cameras.length < 1) {
        onErrorMessage(ErrorTypes.NO_CAMERA_AVAILABLE);
        return;
      }

      const [image, imageBase64] = getImage();

      if (!!image && image.height !== 0) {
        return await getOrientedImage(image, imageBase64, filename, width, height, format, debug);
      } else if (!!image) {
        onErrorMessage(ErrorTypes.CAMERA_NOT_INITIALIZED);
      } else {
        onErrorMessage(ErrorTypes.CANVAS_NOT_SUPPORTED);
      }
      return;
    },

    switchCamera: () => {
      if (!cameras || cameras.length < 1) {
        onErrorMessage(ErrorTypes.NO_CAMERA_AVAILABLE);
      } else if (cameras.length < 2) {
        onErrorMessage(ErrorTypes.SWITCH_NOT_POSSIBLE);
      } else {
        const newFacingMode = currentFacingMode === 'user' ? 'environment' : 'user';
        setFacingMode(newFacingMode);
      }
      return;
    },
  }));

  useEffect(() => {
    if (!cameras) return;

    if (currentFacingMode !== previousFacingMode || !cameraInitialized) {
      // Stop any active streams in the window
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      let worldFacingRightCameraId = null;
      debugCamera(debug, 'FACINGMODE', currentFacingMode);

      if (currentFacingMode === FacingModes.ENVIRONMENT) {
        // Looking for the deviceId of the camera for the edge case of the HP Elite X2
        const worldFacingRightCamera =
          !!cameras && find((camera) => camera.label === 'World Facing Right', cameras);
        debugCamera(debug, 'WORLD FACING CAMERA', worldFacingRightCamera);

        worldFacingRightCameraId =
          !!worldFacingRightCamera && !!worldFacingRightCamera.deviceId
            ? worldFacingRightCamera.deviceId
            : null;
        debugCamera(debug, 'WORLD FACING CAMERA ID', worldFacingRightCameraId);
      }

      const deviceId = !!worldFacingRightCameraId ? { exact: worldFacingRightCameraId } : undefined;

      const constraints = {
        audio: false,
        video: {
          deviceId: deviceId,
          facingMode: currentFacingMode,
        },
      };
      debugCamera(debug, 'CONSTRAINTS', constraints);

      if (!!navigator && !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => handleSuccess(stream))
          .catch((err) => handleError(err));
      } else {
        const getWebcam =
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia;
        if (getWebcam) {
          getWebcam(
            constraints,
            (stream) => handleSuccess(stream),
            (err) => handleError(err),
          );
        } else {
          onErrorMessage(ErrorTypes.GENERAL_ERROR);
        }
      }
      setCameraInitialized(true);
    }
  }, [
    stream,
    cameras,
    cameraInitialized,
    currentFacingMode,
    previousFacingMode,
    handleSuccess,
    handleError,
    onErrorMessage,
    debug,
  ]);

  useEffect(() => {
    numberOfCamerasCallback(!!cameras ? cameras.length : 0);
  }, [numberOfCamerasCallback, cameras]);

  useEffect(() => {
    async function getVideoInput() {
      await navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        if (!!mediaDevices) {
          const videoInputs = filter((i) => i.kind === 'videoinput', mediaDevices);
          setCameras(videoInputs);
        } else {
          onErrorMessage(ErrorTypes.NO_CAMERA_AVAILABLE);
        }
      });
    }
    getVideoInput();
  }, [setCameras, onErrorMessage]);

  useEffect(() => {
    if (stream && player && player.current) {
      player.current.srcObject = stream;
    }
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  return (
    <video
      autoPlay={true}
      playsInline={true}
      muted={true}
      ref={player}
      style={{ ...cameraStyle, ...(!!style && style) }}
    />
  );
});

Camera.displayName = 'Camera';
Camera.defaultProps = {
  facingMode: FacingModes.ENVIRONMENT,
  mirrored: false,
  numberOfCamerasCallback: () => null,
  style: {},
  onErrorMessage: () => null,
  format: 'image/jpeg',
  width: 800,
  height: 600,
  imageQuality: 1,
  debug: false,
};
