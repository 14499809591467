/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { any } from 'ramda';
import { Component } from 'react';
import { colors } from '../../common/theme/colors';
import { Icon } from '../common/Icon';
import { Industry } from '../common/Industry';
import { IndustryIn } from '../common/IndustryIn';
import { IndustryInOut } from '../common/IndustryInOut';
import { IndustryOut } from '../common/IndustryOut';
import { Residence } from '../common/Residence';
import { ResidenceIn } from '../common/ResidenceIn';
import { ResidenceInOut } from '../common/ResidenceInOut';
import { ResidenceOut } from '../common/ResidenceOut';
import { ResultBuilder } from '../common/ResultBuilder';
import { SignIcon } from '../common/SignIcon';
import { DownloadButtonSpinner } from '../common/DownloadButtonSpinner';
import { Trans } from '@lingui/macro';
import { getHandoverType } from '../../common/code/protocol.helper';

const baseResult = {
  alignItems: 'center',
};

const baseGridResult = {
  ...baseResult,
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '0.5em',
  gridTemplateColumns: 'min-content auto min-content',
  cursor: 'pointer',
  borderLeft: '4px solid transparent',
  borderBottom: '1px solid #EEE',
  padding: '8px 0',
};

const styles = {
  container: {
    downloadProtocol: {
      background: 'rgba(49,175,225,0.1)',
      borderLeftColor: colors.brand,
    },
    errorProtocol: {
      background: 'rgba(255, 85, 85, 0.1)',
      borderLeftColor: colors.error,
    },
    results: {
      result: {
        ...baseGridResult,
      },
      altResult: {
        ...baseGridResult,
        background: `${colors.whiteSmoke}`,
      },
      icons: {
        ...baseResult,
        display: 'grid',
        gridTemplateColumns: '40px 40px',
        height: '55px',
      },
      text: {
        display: 'grid',
        gridGap: '8px',
      },
      signed: {
        display: 'grid',
        height: '27px',
        position: 'absolute',
        padding: '0 0 5px 50px',
      },
      nornal: {
        fontWeight: 'normal',
      },
      bold: {
        fontWeight: 'bold',
      },
      actions: {
        color: colors.darker,
        display: 'grid',
        gridTemplateColumns: 'min-content 88px auto',
        gridTemplateAreas: '"changes button arrow"',
        gridTemplateRows: 'minmax(42px, auto)',
        gridColumnGap: '32px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '8px 32px 8px 16px',
      },
      arrowIcon: {
        gridArea: 'arrow',
        width: '24px !important',
        height: '24px',
      },
    },
  },
  button: {
    padding: '1em',
  },
  changesContainer: {
    gridArea: 'changes',
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumn: 'min-content',
    gridGap: '8px',
    alignItems: 'center',
  },
  changesStatus: {
    width: '8px',
    height: '8px',
    background: '#FFE600',
    border: '1px solid #FFC700',
    borderRadius: '6px',
  },
  changesText: {
    color: '#404040',
    whiteSpace: 'nowrap',
    fontSize: '15px',
  },
  downloadButton: {
    gridArea: 'button',
  },
  errorText: {
    background: colors.error,
    borderRadius: '4px',
    padding: '2px 8px',
    color: colors.white,
    justifySelf: 'start',
  },
};

const icons = {
  vacancy: {
    industry: <Industry />,
    residence: <Residence />,
    noType: null,
  },
  handout: {
    industry: <IndustryOut />,
    residence: <ResidenceOut />,
    noType: null,
  },
  acceptance: {
    industry: <IndustryIn />,
    residence: <ResidenceIn />,
    noType: null,
  },
  handover: {
    industry: <IndustryInOut />,
    residence: <ResidenceInOut />,
    noType: null,
  },
};

export class SelectedProtocol extends Component {
  getHandOverIcon(item) {
    const handoverIcon = getHandoverType(item.rentalData);

    const typeIcons = ['noType', 'industry', 'residence'];

    const indexType =
      !!item && !!item.template && !!item.template.templateName
        ? (item.template.templateName.search(/^wohnung_.+$/i) > -1 ? 2 : 0) +
          (item.template.templateName.search(/^gewerbe_.+$/i) > -1 ? 1 : 0)
        : 0;

    const typeIcon = typeIcons[indexType];
    return icons[handoverIcon][typeIcon];
  }

  getStatusIcon(item) {
    const index =
      !!item &&
      !!item.input &&
      !!item.input.signatures &&
      any((x) => !!item.input.signatures[x].data || item.input.signatures[x].signatureRefused)([
        'oldTenant',
        'newTenant',
        'manager',
      ])
        ? 1
        : 0;
    return index === 1 && <SignIcon />;
  }

  getStatusFont(item) {
    const signedFonts = [styles.container.results.nornal, styles.container.results.bold];
    const index =
      !!item && !!item.input && !!item.input.progressDetails && !item.input.progressDetails.mainStep
        ? 1
        : 0;
    return signedFonts[index];
  }

  canNavigate = () => {
    return this.props.isLoggedIn && (this.props.isOnline || this.props.isAvailableOffline);
  };

  handleClick = () => {
    if (this.canNavigate()) {
      this.props.onSelectProtocol(this.props.protocol.id);
    }
  };

  handleMarkAsOffline = (event) => {
    this.props.onMarkAsOffline(this.props.protocol.id);

    event.preventDefault();
    event.stopPropagation();
  };

  handleSync = (event) => {
    this.props.onSyncOffline(this.props.protocol.id);

    event.preventDefault();
    event.stopPropagation();
  };

  handleSoftPush = (event) => {
    this.props.onSoftPush(this.props.protocol.id);

    event.preventDefault();
    event.stopPropagation();
  };

  static propTypes = {
    isOnline: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isMarkAsOfflineBusy: PropTypes.object,
    isAvailableOffline: PropTypes.bool,
    isErrorSynchronization: PropTypes.bool,
    otherOfflineProtocolsCount: PropTypes.number,
    protocol: PropTypes.object,
    index: PropTypes.number,
    queueCount: PropTypes.number,
    onSelectProtocol: PropTypes.func,
    onMarkAsOffline: PropTypes.func,
    onSoftPush: PropTypes.func,
  };

  render() {
    const {
      protocol,
      isOnline,
      isAvailableOffline,
      isErrorSynchronization,
      otherOfflineProtocolsCount,
      queueCount,
      hasAnyQueue,
      isMarkAsOfflineBusy,
      isSyncOfflineBusy,
      isAnyMarkAsOfflineBusy,
      isAnySyncOfflineBusy,
    } = this.props;

    return (
      <div
        css={{
          ...styles.container.results.result,
          ...((isSyncOfflineBusy[protocol.id] || isAvailableOffline) &&
            styles.container.downloadProtocol),
          ...(isErrorSynchronization && styles.container.errorProtocol),
        }}
        onClick={this.handleClick}
      >
        <div css={styles.container.results.icons}>
          <span css={styles.container.results.icons}>{this.getHandOverIcon(protocol)}</span>
          <span css={styles.container.results.signed}>{this.getStatusIcon(protocol)}</span>
        </div>
        <div css={styles.container.results.text}>
          <ResultBuilder
            formerTenantReferenceNumber={protocol.rentalData.vormieterReferenznummer}
            tenantReferenceNumber={protocol.rentalData.nachmieterReferenznummer}
            buildingStreet={protocol.rentalData.hausStrasse}
            buildingZipcode={protocol.rentalData.hausPlz}
            buildingPlace={protocol.rentalData.hausOrt}
            numberOfRoomsCode={protocol.rentalData.anzahlZimmerCd}
            floorCode={protocol.rentalData.stockwerkCd}
            tenantForname={protocol.rentalData.vormieterVorname}
            tenantSurname={protocol.rentalData.vormieterName}
          />
          {isErrorSynchronization && (
            <span css={styles.errorText}>
              <Trans>MESSAGES.PROTOCOL-SYNCHRONIZATION-ERROR</Trans>
            </span>
          )}
        </div>

        <div css={styles.container.results.actions}>
          {queueCount > 0 && (
            <div css={styles.changesContainer}>
              <div css={styles.changesStatus} />
              <div css={styles.changesText}>{`${queueCount} Changes`}</div>
            </div>
          )}
          {isErrorSynchronization ? (
            <DownloadButtonSpinner
              icon="upload"
              iconColor={colors.error}
              borderColor={colors.error}
              background="transparent"
              onClick={this.handleSoftPush}
              style={styles.downloadButton}
              isBusy={isSyncOfflineBusy[protocol.id]}
              disabled={!isOnline}
            />
          ) : isSyncOfflineBusy[protocol.id] || isAvailableOffline ? (
            <DownloadButtonSpinner
              icon="upload"
              onClick={this.handleSync}
              style={styles.downloadButton}
              isBusy={isSyncOfflineBusy[protocol.id]}
              disabled={!isOnline}
            />
          ) : (
            otherOfflineProtocolsCount === 0 && (
              <DownloadButtonSpinner
                icon="download"
                onClick={this.handleMarkAsOffline}
                style={styles.downloadButton}
                isBusy={isMarkAsOfflineBusy[protocol.id]}
                disabled={
                  !isOnline || hasAnyQueue || isAnyMarkAsOfflineBusy || isAnySyncOfflineBusy
                }
              />
            )
          )}
          <Icon
            icon={'chevron-right'}
            disabled={!this.canNavigate()}
            css={styles.container.results.arrowIcon}
          />
        </div>
      </div>
    );
  }
}
