/** @jsxImportSource @emotion/react */

import PropTypes from "prop-types";
import { useEffect } from "react";
import { connect } from "react-redux";
import Fade from "react-reveal/Fade";
import { bindActionCreators } from "redux";
import { colors } from "../../common/theme/colors";
import { variables } from "../../common/theme/variables";
import versionData from "../../version";
import { selectCurrentQueueLength } from "../acceptance-protocol/redux/selectors";
import ProtocolIO from "../special-functions/protocolIO";
import ConfirmModal from "./ConfirmModal";
import InfoModal from "./InfoModal";
import { Icon } from "./Icon";
import "./icons";
import Modal from "./Modal";
import { QuotaCalculator } from "./QuotaCalculator";
import * as commonActions from "./redux/actions";
import {
  selectConfirmationModalCancel,
  selectConfirmationModalMessage,
  selectConfirmationModalOk,
  selectConfirmationModalTitle,
  selectIsConfirmationModalOpen,
  selectInformationModalMessage,
  selectInformationModalOk,
  selectInformationModalTitle,
  selectIsInformationModalOpen,
  selectIsDevUser,
  selectIsNotImplementedModalOpen,
  selectIsOnline,
  selectIsQueueBusy,
  selectLanguage,
  selectVersion,
  selectIsKeycloakActive,
  selectIsLoggedIn,
  selectIsLoginBusy,
  selectClient,
  selectLoginInfo,
  selectLoginWorkflow,
  selectIsLoginModalOpen,
  selectIsTokenExpired,
  selectKeycloak,
} from "./redux/selectors";
import TopBar from "./TopBar";
import * as actions from "../home/redux/actions";
import LoginModal from "./LoginModal";
import { LOGIN_WORKFLOW_EXPIRED_TOKEN } from "./redux/loginWorkflowConstants";

const styles = {
  container: {
    display: "grid",
    gridTemplateRows: `${variables.topbar} calc(100% - ${variables.topbar})`,
    gridTemplateColumns: "100%",
    height: "100%",
    width: "100%",
    overflow: "hidden"
  },
  footer: {
    background: colors.darker,
    display: "grid",
    gridTemplateColumns: "auto min-content min-content",
    gridColumnGap: "1em",
    padding: `8px ${variables.gutter.sm}`,
    position: "fixed",
    width: "100%",
    bottom: 0,
    [variables.breakpoints.md]: {
      padding: `8px ${variables.gutter.md}`
    },
    quotaCalculator: {},
    counter: {
      color: "white",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridColumnGap: "1em",
      alignItems: "center"
    },
    version: {
      whiteSpace: "nowrap",
      container: {
        color: "white",
        display: "grid",
        gridTemplateColumns: "2fr 1fr",
        gridColumnGap: "1em",
        alignItems: "center"
      }
    }
  }
};

export const App = (props) => {
  const {
    version,
    queueCount,
    isQueueBusy,
    isNotImplementedModalOpen,
    isConfirmationModalOpen,
    confirmationModalTitle,
    confirmationModalMessage,
    confirmationModalOk,
    confirmationModalCancel,
    isInformationModalOpen,
    informationModalTitle,
    informationModalMessage,
    informationModalOk,
    isDevUser,
    isKeycloakActive,
    client,
    isLoginModalOpen,
    isLoginModalBusy,
    loginInfo,
    loginWorkflow,
    isOnline,
    isLoggedIn,
    keycloak,
    actions: {
      commonCloseNotImplementedModal,
      commonConfirmationApproved,
      commonConfirmationRejected,
      commonCloseInformationModal,
      commonSetVersion,
      commonLogin,
      commonCloseLoginModal,
      commonLogout,
      commonKeycloakDoLogin,
    }
  } = props;

  useEffect(() => {
    commonSetVersion(versionData.version);
  }, [commonSetVersion]);

  useEffect(() => {
    if (isLoginModalOpen && isKeycloakActive
      && keycloak && !isLoggedIn && isOnline){
      commonKeycloakDoLogin();
    }
  }, [isLoginModalOpen, loginWorkflow, isLoggedIn, keycloak, isOnline, isKeycloakActive, commonKeycloakDoLogin]);

  const handleLoginModalClose = () => {
    if (loginWorkflow === LOGIN_WORKFLOW_EXPIRED_TOKEN) {
      commonLogout();
    }

    commonCloseLoginModal();
  };

  const handleLogin = (username, password, client) => {
    commonLogin(loginWorkflow, username, password, client);
  };

  return (
    <div css={styles.container}>
      <TopBar />
        <main>
          {props.children}
        </main>

        {!isKeycloakActive && <LoginModal
          isOpen={isLoginModalOpen}
          onClose={handleLoginModalClose}
          onLogin={handleLogin}
          isBusy={isLoginModalBusy}
          loginInfo={loginInfo}
          client={client}
          loginWorkflow={loginWorkflow}
        />}
      {isDevUser && (
        <footer css={styles.footer}>
          <QuotaCalculator />
          <div css={styles.footer.counter}>
            <Fade right when={queueCount > 0}>
              <Icon icon={"tasks"} color={colors.lighterGrey} />
              <span>{queueCount}</span>
            </Fade>
            <Fade when={isQueueBusy} collapse>
              {<Icon icon={"sync"} color={colors.lighterGrey} spin />}
            </Fade>
          </div>
          <div css={styles.footer.version.container}>
            <ProtocolIO />
            <span css={styles.footer.version}>{version}</span>
          </div>
        </footer>
      )}

      <Modal
        title="Not Implemented"
        isOpen={isNotImplementedModalOpen}
        onClose={commonCloseNotImplementedModal}
        zIndex={1003}
      >
        Not implemented
      </Modal>

      <ConfirmModal
        title={confirmationModalTitle}
        isOpen={isConfirmationModalOpen}
        onConfirm={commonConfirmationApproved}
        onClose={commonConfirmationRejected}
        zIndex={1004}
        ok={confirmationModalOk}
        cancel={confirmationModalCancel}
      >
        {confirmationModalMessage}
      </ConfirmModal>

      <InfoModal
        title={informationModalTitle}
        isOpen={isInformationModalOpen}
        onClose={commonCloseInformationModal}
        zIndex={1005}
        ok={informationModalOk}
      >
        {informationModalMessage}
      </InfoModal>
    </div>
  );
};

App.propTypes = {
  isOnline: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  isNotImplementedModalOpen: PropTypes.bool.isRequired,
  version: PropTypes.string.isRequired,
  isQueueBusy: PropTypes.bool.isRequired,
  queueCount: PropTypes.number.isRequired,
  children: PropTypes.node,
  username: PropTypes.string,
  currentProtocol: PropTypes.object,
  isLoggedIn: PropTypes.bool.isRequired,
  keycloak: PropTypes.object,

  actions: PropTypes.object.isRequired,
  onOpenNotImplemented: PropTypes.func
};

App.defaultProps = {
  children: ""
};

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    version: selectVersion(state),
    language: selectLanguage(state),
    isOnline: selectIsOnline(state),
    isQueueBusy: selectIsQueueBusy(state),
    queueCount: selectCurrentQueueLength(state),
    isNotImplementedModalOpen: selectIsNotImplementedModalOpen(state),
    isConfirmationModalOpen: selectIsConfirmationModalOpen(state),
    confirmationModalTitle: selectConfirmationModalTitle(state),
    confirmationModalMessage: selectConfirmationModalMessage(state),
    confirmationModalOk: selectConfirmationModalOk(state),
    confirmationModalCancel: selectConfirmationModalCancel(state),
    isInformationModalOpen: selectIsInformationModalOpen(state),
    informationModalTitle: selectInformationModalTitle(state),
    informationModalMessage: selectInformationModalMessage(state),
    informationModalOk: selectInformationModalOk(state),
    isDevUser: selectIsDevUser(state),
    isKeycloakActive: selectIsKeycloakActive(state),
    isLoggedIn: selectIsLoggedIn(state),
    isTokenExpired: selectIsTokenExpired(state),
    loginWorkflow: selectLoginWorkflow(state),
    client: selectClient(state),
    isLoginModalOpen: selectIsLoginModalOpen(state),
    isLoginModalBusy: selectIsLoginBusy(state),
    loginInfo: selectLoginInfo(state),
    keycloak: selectKeycloak(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
