import React from 'react';

export const AddTenantIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <polygon points="19 18 22 18 22 23 23 23 23 17 19 17 19 18" />
    <rect x="18" y="18" width="1" height="1" transform="translate(0 37) rotate(-90)" />
    <polygon points="16 18 13 18 13 23 12 23 12 17 16 17 16 18" />
    <rect x="16" y="18" width="1" height="1" transform="translate(-2 35) rotate(-90)" />
    <rect x="17" y="19" width="1" height="1" transform="translate(-2 37) rotate(-90)" />
    <polygon points="15 2 15 3 26 3 26 25 7 25 7 15 6 15 6 26 27 26 27 2 15 2" />
    <path d="M0,0V13H13V0ZM12,12H1V1H12Z" />
    <polygon points="10 6 7 6 7 3 6 3 6 6 3 6 3 7 6 7 6 10 7 10 7 7 10 7 10 6" />
    <rect x="16" y="10" width="3" height="1" transform="translate(35 21) rotate(-180)" />
    <rect x="16" y="15" width="3" height="1" transform="translate(35 31) rotate(-180)" />
    <rect x="13.5" y="12.5" width="4" height="1" transform="translate(28.5 -2.5) rotate(90)" />
    <rect x="17.5" y="12.5" width="4" height="1" transform="translate(32.5 -6.5) rotate(90)" />
  </svg>
);
