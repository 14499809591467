/** @jsxImportSource @emotion/react */
import { useContext, memo } from 'react';
import { keyframes } from '@emotion/react';
import classNames from 'classnames';
import { TabsContext } from './Tabs';
import { TabContext } from './Tab';

const showTab = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const styles = {
  tab: {
    gridRow: '2/3',
    background: 'transparent',
    display: 'none',

    '.content': {
      height: '100%',
    },

    '&.is-open': {
      display: 'block',

      '> .content': {
        animation: `${showTab} 0.2s`,
      },
    },
  },
};

const TabContent = (props) => {
  const { children } = props;
  const { selectedTab, tabs } = useContext(TabsContext);
  const { id } = useContext(TabContext);

  const isOpen = selectedTab === id;
  return (
    <div
      css={{ ...styles.tab, gridColumn: `1/${tabs + 1}` }}
      className={classNames({ 'is-open': isOpen })}
    >
      <div className="content">{children}</div>
    </div>
  );
};

TabContent.propTypes = {};

TabContent.defaultProps = {};

export default memo(TabContent);
