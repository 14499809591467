/** @jsxImportSource @emotion/react */
import { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'rc-progress';
import { selectProgressPercentage, selectMigrationIsRunning } from './redux/selectors';

const styles = {
  container: {
    display: 'grid',
    gridTemplateRows: 'max-content',
    gridAutoFlow: 'row',
    marginTop: '1em',
  },
};

export class ProgressMigration extends Component {
  render() {
    const { progressPercentage, isRunning } = this.props;

    return (
      <div css={styles.container}>
        {isRunning && (
          <Line percent={progressPercentage} trailWidth="2" strokeWidth="1" strokeColor="#2db7f5" />
        )}
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    progressPercentage: selectProgressPercentage(state),
    isRunning: selectMigrationIsRunning(state),
  };
}

export default connect(mapStateToProps, null)(ProgressMigration);
