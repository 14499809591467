import produce from 'immer';
import { COMMON_IS_ONLINE } from './constants';

export function commonIsOnline() {
  return {
    type: COMMON_IS_ONLINE,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_IS_ONLINE:
        draft.isOnline = true;
        break;
      default:
        return state;
    }
  });
