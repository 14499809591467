import produce from 'immer';
import { put, race, take } from 'redux-saga/effects';

import { commonCloseConfirmationModal } from './closeConfirmationModal';
import {
  COMMON_CONFIRMATION_APPROVED,
  COMMON_CONFIRMATION_REJECTED,
  COMMON_OPEN_CONFIRMATION_MODAL,
} from './constants';

export function commonOpenConfirmationModal({ title, message, ok, cancel }) {
  return {
    type: COMMON_OPEN_CONFIRMATION_MODAL,
    payload: { title, message, ok, cancel },
  };
}

export function commonConfirmationApproved() {
  return {
    type: COMMON_CONFIRMATION_APPROVED,
  };
}

export function commonConfirmationRejected() {
  return {
    type: COMMON_CONFIRMATION_REJECTED,
  };
}

export function* askForConfirmation(messages) {
  yield put(commonOpenConfirmationModal(messages));

  const { yes } = yield race({
    yes: take(COMMON_CONFIRMATION_APPROVED),
    no: take(COMMON_CONFIRMATION_REJECTED),
  });

  yield put(commonCloseConfirmationModal());

  return !!yes;
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_OPEN_CONFIRMATION_MODAL:
        const { title, message, ok, cancel } = action.payload;

        draft.ui.modal.confirmation = true;
        draft.ui.confirmation.title = title;
        draft.ui.confirmation.message = message;
        draft.ui.confirmation.ok = ok;
        draft.ui.confirmation.cancel = cancel;
        break;
      default:
        return state;
    }
  });
