/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from './redux/actions';
import * as commonActions from '../../common/redux/actions';
import { colors } from '../../../common/theme/colors';
import { Form } from './Form';
import {
  getFirstId,
  getLocalizedLabel,
  getNestedFirstFieldName,
  getSecurityTypeAmount,
} from './Functions';
import { bindActionCreators } from 'redux';
import { selectSelected } from './redux/selectors';
import {
  selectProtocolLanguage,
  selectBasedataInput,
  selectCurrentId,
  selectIsWithRemReference,
} from '../redux/selectors';
import {
  isOldTenantSecurityDepot,
  MBusMessagesFields,
  MBusMessagesFieldsOperation,
} from '../../common/redux/mBusMessageFields';

const styles = {
  container: {
    display: 'grid',
    height: '100%',
  },
  section: {
    backgroundColor: `${colors.lighterGrey}`,
    alignItems: 'center',
    fontWeight: 'bold',
    padding: '1.5em 1.0em',
  },
  content: {
    padding: '1.5em',
    display: 'grid',
    gridAutoFlow: 'row',
    gridRowGap: '24px',
  },
};

export class InputBaseData extends Component {
  onModifyField = (id, fieldname, value) => {
    const {
      protocolId,
      input,
      isWithRemReference,
      actions: {
        commonPatchOperation,
        commonPatchTenancyAgreementSecurityDepot,
        commonPatchTenancyAgreementSecurityDetails
      },
    } = this.props;

    commonPatchOperation(
      protocolId,
      `basedataInputs/[${id}]/fields/[${encodeURIComponent(fieldname)}]`,
      {
        value,
        isChanged: true,
      },
    );

    if (isWithRemReference && MBusMessagesFieldsOperation(fieldname)) {
      const actions = {
        UpdateDepot: () => {
          const isOldTenant = isOldTenantSecurityDepot(fieldname);
          const securityDepotType = isOldTenant
            ? MBusMessagesFields.TENANT_SECURITY_DEPOT_TYPE_OLD_TENANT
            : MBusMessagesFields.TENANT_SECURITY_DEPOT_TYPE;
          const securityDepositAmountType = isOldTenant
            ? MBusMessagesFields.TENANT_SECURITY_DEPOSIT_AMOUNT_OLD_TENANT
            : MBusMessagesFields.TENANT_SECURITY_DEPOSIT_AMOUNT;
          const securityPaidAmountType = isOldTenant
            ? MBusMessagesFields.TENANT_SECURITY_PAID_AMOUNT_OLD_TENANT
            : MBusMessagesFields.TENANT_SECURITY_PAID_AMOUNT;

          const depotType =
            fieldname === securityDepotType
              ? value
              : getNestedFirstFieldName(input, securityDepotType).value;
          const securityDepositAmount =
            fieldname === securityDepositAmountType
              ? value
              : getSecurityTypeAmount(input, securityDepositAmountType).value;
          const securityPaidAmount =
            fieldname === securityPaidAmountType
              ? value
              : getSecurityTypeAmount(input, securityPaidAmountType).value;

          if (depotType) {
            commonPatchTenancyAgreementSecurityDepot(
              protocolId,
              depotType,
              securityDepositAmount,
              securityPaidAmount,
              isOldTenant,
            );
          }
        },
        UpdateDetails: () => {
          const isOldTenant = isOldTenantSecurityDepot(fieldname);
          commonPatchTenancyAgreementSecurityDetails(protocolId, isOldTenant, value);
        },
      };

      const operation = MBusMessagesFieldsOperation(fieldname);
      if (operation in actions) {
        actions[operation]();
      }
    }
  };

  onModifySibling = (id, fieldname, siblingFieldname, value) => {
    const {
      protocolId,
      actions: { commonPatchOperation },
    } = this.props;

    commonPatchOperation(
      protocolId,
      `basedataInputs/[${id}]/fields/[${encodeURIComponent(
        fieldname,
      )}]/siblings/[${encodeURIComponent(siblingFieldname)}]`,
      { value, isChanged: true },
    );
  };

  static propTypes = {
    protocolId: PropTypes.string.isRequired,
    selected: PropTypes.object.isRequired,
    input: PropTypes.array.isRequired,
    language: PropTypes.string.isRequired,
  };

  render() {
    const { selected, input, language } = this.props;

    const title = getLocalizedLabel(selected.element.template, language);
    const fields = !!selected.element.template ? selected.element.template.fields : null;
    const values = getFirstId(input, selected.element.id);

    return (
      <div css={styles.container}>
        <div css={styles.content}>
          <Form
            id={selected.element.id}
            fields={fields}
            values={values}
            language={language}
            onModifyField={this.onModifyField}
            onModifySibling={this.onModifySibling}
          >
            {() => <div css={styles.section}>{title}</div>}
          </Form>
        </div>
      </div>
    );
  }
}

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    selected: selectSelected(state),
    protocolId: selectCurrentId(state),
    language: selectProtocolLanguage(state),
    input: selectBasedataInput(state),
    isWithRemReference: selectIsWithRemReference(state),
  };
}

/* istanbul ignore next */
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...actions, ...commonActions }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InputBaseData);
