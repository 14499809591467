/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { TopBarBaseStep } from './TopBarBaseStep';
import { acceptanceProtocolRoutes } from '../acceptance-protocol/routes';

const styles = {
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textTransform: 'uppercase',
  },
};

export const TopBarSignStep = (props) => {
  const { currentStep, onNavigateTo, protocolId, isLast } = props;

  const handleNavigateToSingStep = useCallback(() => {
    onNavigateTo(acceptanceProtocolRoutes.signStep(protocolId));
  }, [onNavigateTo, protocolId]);

  return (
    <TopBarBaseStep
      step="sign-step"
      isActive={!!currentStep && (currentStep === 'sign-step' || currentStep === 'sign')}
      onSelectStep={handleNavigateToSingStep}
      isLast={isLast}
    >
      <div css={styles.label}>
        <Trans>LABELS.SIGN-STEP</Trans>
      </div>
    </TopBarBaseStep>
  );
};
