import { LOCATION_CHANGE } from 'connected-react-router';
import { anyPass, isEmpty, isNil, join, reject } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';
import {
  getLocalizedCostName,
  getLocalizedName,
} from '../../acceptance-protocol/fill-step/Functions';
import { LEAVING_AGREEMENT_STEP_SYNC } from '../../acceptance-protocol/leaving-agreement-step/redux/constants';
import {
  selectCurrentId,
  selectCurrentLeavingAgreementHasManagerSigned,
  selectIndexedAttributesTemplate,
  selectIsLeavingAgreementStep,
  selectIsProtocolReadonly,
  selectProtocolDefectsWithCost,
  selectProtocolIdRoute,
  selectProtocolLanguage,
  selectTemplateCosts,
} from '../../acceptance-protocol/redux/selectors';
import {
  getFormattedFractionInline,
  getPercentageFromFractionString,
} from '../../common/Functions';
import {
  COMMON_CONFIRMED_SIGN,
  COMMON_DELETED_SIGN,
  COMMON_REJECTED_SIGN,
  COMMON_SET_PROTOCOL_LANGUAGE,
} from './constants';
import { commonPatchOperation } from './patchOperation';
import { selectHasLeavingAgreement } from './selectors';

export function* syncLeavingAgreementOnLocationChange() {
  const route = yield select(selectProtocolIdRoute);

  if (
    !!route &&
    !!route.params.id &&
    !!route.params.step &&
    route.params.step === 'leaving-agreement-step'
  ) {
    const isProtocolReadonly = yield select(selectIsProtocolReadonly);

    if (!isProtocolReadonly) {
      yield put({
        type: LEAVING_AGREEMENT_STEP_SYNC,
      });
    }
  }
}

export function* syncLeavingAgreementOnConfirmedSign() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route && !!route.params.id && !!route.params.step && route.params.step === 'sign') {
    yield put({
      type: LEAVING_AGREEMENT_STEP_SYNC,
    });
  }
}

export function* syncLeavingAgreementOnDeletedProtocolSign({ payload: { signerId } }) {
  const hasLeavingAgreement = yield select(selectHasLeavingAgreement);
  const isLeavingAgreementStep = yield select(selectIsLeavingAgreementStep);

  if (
    !hasLeavingAgreement ||
    isLeavingAgreementStep ||
    (signerId !== 'oldTenant' && signerId !== 'manager')
  )
    return;

  const protocolId = yield select(selectCurrentId);

  // Delete leaving agreement signature
  yield put(
    commonPatchOperation(
      protocolId,
      `signatures/${signerId}`,
      {
        data: null,
        signatureRefused: false,
      },
      true,
    ),
  );

  // If the user removes both oldTenant and manager signatures, the leaving agreement will be reset
  yield put({
    type: LEAVING_AGREEMENT_STEP_SYNC,
  });
}

export function* syncLeavingAgreement() {
  const hasLeavingAgreement = yield select(selectHasLeavingAgreement);
  const leavingAgreementSigned = yield select(selectCurrentLeavingAgreementHasManagerSigned);

  if (hasLeavingAgreement && !leavingAgreementSigned) {
    const defects = yield select(selectProtocolDefectsWithCost);
    const currentProtocolId = yield select(selectCurrentId);
    const language = yield select(selectProtocolLanguage);
    const indexedAttributesTemplate = yield select(selectIndexedAttributesTemplate);
    const templateCosts = yield select(selectTemplateCosts);

    yield put(commonPatchOperation(currentProtocolId, `defects`, [], true));
    let defectIndex = 0;
    for (let index = 0; index < defects.length; index++) {
      const defect = defects[index];
      for (const attributes of defect.elementInput.attributesSelected) {
        const attributeLocalizedText = getLocalizedName(
          indexedAttributesTemplate[attributes.id],
          language,
        );
        const defectLocalizedName = getLocalizedName(defect, language);
        const isNilOrEmpty = anyPass([isNil, isEmpty]);
        const descriptionResolver = {
          CHF: (cost) => getLocalizedCostName(cost, templateCosts, language) || '',
          '%': (cost) =>
            join(
              ': ',
              reject(isNilOrEmpty, [
                getLocalizedCostName(cost, templateCosts, language),
                Boolean(cost.value) ? `${cost.value}  %` : cost.value,
              ]),
            ),
          'x/y': (cost) =>
            join(
              ': ',
              reject(isNilOrEmpty, [
                getLocalizedCostName(cost, templateCosts, language),
                `${getPercentageFromFractionString(cost.value)}%`,
                getFormattedFractionInline(cost.value),
              ]),
            ),
        };
        const description = Boolean(attributes.cost)
          ? descriptionResolver[attributes.cost.type](attributes.cost)
          : '';
        const value = attributes.cost.type === 'CHF' ? attributes.cost.value : '';

        yield put(
          commonPatchOperation(
            currentProtocolId,
            `defects/[${defectIndex}]`,
            {
              name: `${defect.componentLabel} / ${defectLocalizedName} / ${attributeLocalizedText}`,
              description: description,
              value: value,
              selectedAttribute: null,
            },
            true,
          ),
        );

        defectIndex++;
      }
    }

    // Reset leaving agreement attributes with empty attributes
    yield put(
      commonPatchOperation(
        currentProtocolId,
        `attributes`,
        {
          attributeA: {
            name: 'A',
          },
          attributeB: {
            date: null,
            name: 'B',
          },
          attributeC: {
            days: null,
            date: null,
            name: 'C',
          },
          attributeD: {
            days: null,
            name: 'D',
          },
          attributeE: {
            name: 'E',
          },
        },
        true,
      ),
    );
  }
}

export function* switchSyncLeavingAgreement() {
  yield takeLatest(LOCATION_CHANGE, syncLeavingAgreementOnLocationChange);
  yield takeLatest(
    [COMMON_CONFIRMED_SIGN, COMMON_REJECTED_SIGN],
    syncLeavingAgreementOnConfirmedSign,
  );
  yield takeLatest([COMMON_DELETED_SIGN], syncLeavingAgreementOnDeletedProtocolSign);
  yield takeLatest(
    [COMMON_SET_PROTOCOL_LANGUAGE, LEAVING_AGREEMENT_STEP_SYNC],
    syncLeavingAgreement,
  );
}
