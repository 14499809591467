import { convertBase64ToFile } from '../../../common/code/convertBase64ToFile';
import pica from 'pica/dist/pica.min';

/**
 * Debug.
 */
export const debugCamera = (debug, name, value) => debug && console.warn('CAM DEBUG:', name, value);

/**
 * Give an image and get it orientated.
 */
export const getOrientedImage = async (
  image,
  imageBase64,
  filename,
  width,
  height,
  format,
  debug,
) => {
  const source = document.createElement('canvas');
  const dest = document.createElement('canvas');
  const ctx = source.getContext('2d', { alpha: false });

  debugCamera(debug, 'width', width);
  debugCamera(debug, 'height', height);

  const browserVersion = getBrowser;
  debugCamera(debug, 'browserVersion', browserVersion);

  if (browserVersion[0] === 'Safari' && browserVersion[1] === '12') {
    const blob = convertBase64ToFile(imageBase64);
    const file = blobToFile(blob, filename);
    const orientation = await getOrientation(file);
    debugCamera(debug, 'orientation', orientation);

    // set source landscape or portrait based on orientation and image aspect ratio
    const isPortrait = 4 < orientation && orientation < 9;
    debugCamera(debug, 'isPortrait', isPortrait);

    // set proprtional width and height
    if ((orientation === -1 && image.width < image.height) || isPortrait) {
      dest.width = height;
      dest.height = width;
    } else {
      dest.width = width;
      dest.height = height;
    }
    debugCamera(debug, 'dest.width', dest.width);
    debugCamera(debug, 'dest.height', dest.height);
    const direction = image.width / dest.width > image.height / dest.height;
    debugCamera(debug, 'direction', direction);

    const imageWidth = direction ? image.width : (dest.width / dest.height) * image.height;
    const imageHeight = direction ? (dest.height / dest.width) * image.width : image.height;
    debugCamera(debug, 'imageWidth', imageWidth);
    debugCamera(debug, 'imageHeight', imageHeight);

    if (isPortrait) {
      source.width = imageHeight;
      source.height = imageWidth;
    } else {
      source.width = imageWidth;
      source.height = imageHeight;
    }
    debugCamera(debug, 'source.width', source.width);
    debugCamera(debug, 'source.height', source.height);

    // transform context based on orientation before drawing image
    switch (orientation) {
      case 2:
        ctx.transform(-1, 0, 0, 1, imageWidth, 0);
        break;
      case 3:
        ctx.transform(-1, 0, 0, -1, imageWidth, imageHeight);
        break;
      case 4:
        ctx.transform(1, 0, 0, -1, 0, imageHeight);
        break;
      case 5:
        ctx.transform(0, 1, 1, 0, 0, 0);
        break;
      case 6:
        ctx.transform(0, 1, -1, 0, imageHeight, 0);
        break;
      case 7:
        ctx.transform(0, -1, -1, 0, imageHeight, imageWidth);
        break;
      case 8:
        ctx.transform(0, -1, 1, 0, 0, imageWidth);
        break;
      default:
        break;
    }

    const imgWidth = image.width;
    const imgHeight = isPortrait ? (image.width / image.height) * image.width : image.height;
    debugCamera(debug, 'imgWidth', imgWidth);
    debugCamera(debug, 'imgHeight', imgHeight);

    const { startX, startY } = direction
      ? { startX: 0, startY: (imageHeight - imgHeight) / 2 }
      : { startX: (imageWidth - imgWidth) / 2, startY: 0 };
    debugCamera(debug, 'startX', startX);
    debugCamera(debug, 'startY', startY);

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, source.width, source.height);

    ctx.drawImage(image, startX, startY, imgWidth, imgHeight);
  } else {
    // set source landscape or portrait based on orientation
    const isPortrait = image.height > image.width;
    // set proprtional width and height
    if (isPortrait) {
      dest.width = height;
      dest.height = width;
    } else {
      dest.width = width;
      dest.height = height;
    }

    const direction = image.width / dest.width > image.height / dest.height;

    source.width = direction ? image.width : (dest.width / dest.height) * image.height;
    source.height = direction ? (dest.height / dest.width) * image.width : image.height;

    ctx.fillStyle = '#FFFFFF';
    ctx.fillRect(0, 0, source.width, source.height);

    ctx.drawImage(image, 0, 0, source.width, source.height);
  }

  const result = await pica().resize(source, dest);
  debugCamera(debug, 'RESULT WIDTH', dest.width);
  debugCamera(debug, 'RESULT HEIGHT', dest.height);

  return {
    image: result.toDataURL(format),
    size: { width: dest.width, height: dest.height },
  };
};

/**
 * Gets current browser with the version.
 * https://stackoverflow.com/a/5918791
 */
export const getBrowser = (function () {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return M;
})();

/**
 * Reads the image uploaded and returns the data needed
 * for calling the method getOrientedImage.
 */
export const getFullImageData = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = function () {
      const image = reader.result;
      var img = new Image();
      img.src = image;

      img.onload = function () {
        try {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);

          resolve({
            image: canvas,
            imageData: {
              image: image,
              size: { width: this.width, height: this.height },
            },
          });
        } catch (exception) {
          return reject(exception);
        }
      };
    };
    reader.onerror = (error) => reject(error);
  });

/**
 * Converts a blob into a file type
 */
export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

/**
 * Gets the orientation of an image on older devices (e.g. iPad 12.x)
 */
export const getOrientation = async (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (event) {
      try {
        const view = new DataView(event.target.result);

        if (view.getUint16(0, false) !== 0xffd8) return resolve(-2);

        const length = view.byteLength;
        let offset = 2;

        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;

          if (marker === 0xffe1) {
            if (view.getUint32((offset += 2), false) !== 0x45786966) {
              return resolve(-1);
            }

            const little = view.getUint16((offset += 6), false) === 0x4949;
            offset += view.getUint32(offset + 4, little);

            const tags = view.getUint16(offset, little);
            offset += 2;

            for (let i = 0; i < tags; i++)
              if (view.getUint16(offset + i * 12, little) === 0x0112)
                return resolve(view.getUint16(offset + i * 12 + 8, little));
          } else if ((marker & 0xff00) !== 0xff00) break;
          else offset += view.getUint16(offset, false);
        }

        return resolve(-1);
      } catch (error) {
        reject(error);
      }
    };

    reader.onerror = function () {
      reject(new Error(`error on getting orientation on file ${file.name}`));
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  });

/**
 * Checks if there is atleast one camera available
 */
export const isAnyCameraAvailable = () =>
  new Promise(async (resolve, reject) => {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }

    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        const getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        if (!getUserMedia) {
          throw new Error('getUserMedia is not implemented in this browser');
        }

        return getUserMedia.call(navigator, constraints, resolve, reject);
      };
    }

    try {
      await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
      resolve(true);
    } catch (e) {
      reject(e);
    }
  });
