import React from 'react';

export const WriteIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M21,26H0V2H21ZM1,25H20V3H1Z" />
    <path d="M3,22a.47.47,0,0,1-.35-.15.49.49,0,0,1-.14-.47l1-4A.5.5,0,0,1,3.87,17a.48.48,0,0,1,.48.13l3,3a.48.48,0,0,1,.13.48.5.5,0,0,1-.36.36l-4,1Zm1.27-3.52-.58,2.33L6,20.23Z" />
    <polygon points="8.5 19.71 4.79 16 11.15 9.65 11.85 10.35 6.21 16 8.5 18.29 14.15 12.65 14.85 13.35 8.5 19.71" />
    <path d="M15.5,12.71,11.79,9,14.5,6.29,18.21,10ZM13.21,9l2.29,2.29L16.79,10,14.5,7.71Z" />
  </svg>
);
