import React from 'react';

export const CopyIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 27 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <polygon points="21.71 2 19 2 19 3 21 3 21 8 26 8 26 25 19 25 19 26 27 26 27 7.29 21.71 2" />
    <path d="M17,26H0V2H17ZM1,25H16V3H1Z" />
    <rect x="4" y="12" width="9" height="1" />
    <rect x="4" y="15" width="9" height="1" />
    <rect x="4" y="18" width="9" height="1" />
    <rect x="4" y="21" width="9" height="1" />
    <rect x="19" y="12" width="4" height="1" />
    <rect x="19" y="15" width="4" height="1" />
    <rect x="19" y="18" width="4" height="1" />
    <rect x="19" y="21" width="4" height="1" />
  </svg>
);
