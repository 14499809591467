import { takeLatest } from 'redux-saga/effects';
import {
  HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR,
  HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR,
  HOME_GET_TEMPLATES_ERROR,
  HOME_SEARCH_RENTAL_OBJECTS_ERROR,
} from './constants';
import { doToast } from "../../../common/rootToastr";

function* doNotifyError({ payload }) {
  yield* doToast({
    payload: {
      type: 'error',
      attention: true,
      message: payload,
    }
  });
}

export function* switchNotifyError() {
  yield takeLatest(
    [
      HOME_SEARCH_RENTAL_OBJECTS_ERROR,
      HOME_CREATE_PROTOCOL_WITHOUT_REM_REFERENCE_ERROR,
      HOME_GET_TEMPLATES_ERROR,
      HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_ERROR,
      HOME_CREATE_PROTOCOL_FROM_PREVIOUS_PROTOCOL_ERROR,
      HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR,
    ],
    doNotifyError,
  );
}
