import { sortBy } from 'ramda';

export function getTitleByScroll(elements, topOffset, top) {
  let foundTitle = null;

  const sortedElements = sortBy((el) => {
    if (!!el && !!el.ref && !!el.ref.current && !!el.ref.current.offsetTop) {
      return el.ref.current.offsetTop;
    }
    return null;
  }, elements);

  if (sortedElements.length === 0) {
    return null;
  }

  if (sortedElements[0].ref.current.offsetTop - topOffset > top) {
    return null;
  }

  if (sortedElements[sortedElements.length - 1].ref.current.offsetTop < top) {
    return sortedElements[sortedElements.length - 1].title;
  }

  // we skip last
  for (let i = 0; i < sortedElements.length - 1; i++) {
    const element = sortedElements[i];
    const nextElement = sortedElements[i + 1];
    if (top > element.ref.current.offsetTop && top <= nextElement.ref.current.offsetTop) {
      foundTitle = element.title;
      break;
    }
  }
  return foundTitle;
}
