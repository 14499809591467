import { call, put, select, takeLatest } from 'redux-saga/effects';

import { i18n } from '../../../common/i18n-loader';
import {
  selectCurrentId,
  selectCurrentLeavingAgreementHasManagerSigned,
  selectCurrentLeavingAgreementHasOldTenantSigned,
  selectIsLeavingAgreementStep,
} from '../../acceptance-protocol/redux/selectors';
import { commonPatchOperation } from './actions';
import { COMMON_DELETED_SIGN, COMMON_DELETE_SIGN } from './constants';
import { askForConfirmation } from './openConfirmationModal';

export function commonDeleteSign(signerId, personList) {
  return {
    type: COMMON_DELETE_SIGN,
    payload: { signerId, personList },
  };
}

export function commonDeletedSign(signerId) {
  return {
    type: COMMON_DELETED_SIGN,
    payload: { signerId },
  };
}

function* doConfirmDeleteSign({ payload: { signerId, personList } }) {
  const protocolId = yield select(selectCurrentId);
  const isLeavingAgreementStep = yield select(selectIsLeavingAgreementStep);

  const hasLeavingAgreementOldTenantSigned = yield select(
    selectCurrentLeavingAgreementHasOldTenantSigned,
  );
  const hasLeavingAgreementManagerSigned = yield select(
    selectCurrentLeavingAgreementHasManagerSigned,
  );

  const showLeavingAgreementWarning =
    !isLeavingAgreementStep &&
    ((signerId === 'oldTenant' && hasLeavingAgreementOldTenantSigned) ||
      (signerId === 'manager' && hasLeavingAgreementManagerSigned));

  const leavingAgreementWarning = i18n._('WRITE-SIGN.DELETE-SIGN.LEAVING-AGREEMENT-WARNING');

  const message = i18n._('WRITE-SIGN.DELETE-SIGN.MESSAGE', {
    name: !!personList ? personList.join(', ') : '',
  });

  const confirmed = yield call(askForConfirmation, {
    title: i18n._('WRITE-SIGN.DELETE-SIGN.TITLE'),
    message: showLeavingAgreementWarning ? message + '\n\n' + leavingAgreementWarning : message,
    ok: i18n._('BUTTONS.DELETE'),
  });

  if (!confirmed) {
    return;
  }

  yield put(
    commonPatchOperation(
      protocolId,
      `signatures/${signerId}`,
      {
        data: null,
        signatureRefused: false,
      },
      isLeavingAgreementStep,
    ),
  );

  yield put(commonDeletedSign(signerId));
}

export function* switchDeleteSign() {
  yield takeLatest(COMMON_DELETE_SIGN, doConfirmDeleteSign);
}
