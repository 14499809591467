/* eslint-disable react-hooks/rules-of-hooks */
/** @jsxImportSource @emotion/react */
import classNames from 'classnames';
import { useCallback, useRef, useState, useMemo } from 'react';
import { values, prop, sortBy, pluck, pipe, indexOf, __, findIndex } from 'ramda';
import EditComponentSubModal from './EditComponentSubModal';
import EditComponentCurrentElements from './EditComponentCurrentElements';
import EditComponentToAddElements from './EditComponentToAddElements';
import { getOrderedElementsTemplate } from './Functions';

const styles = {
  modal: {
    position: 'fixed',
    backgroundColor: 'rgba(50, 50, 50, 0.25)',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    visibility: 'visible',
    display: 'none',
    '&.is-open': {
      display: 'block',
    },
  },
};

const EditComponentModal = (props) => {
  const {
    isOpen,
    language,
    component,
    componentsTemplate,
    indexedElementsTemplate,
    onCancel,
    onSave,
  } = props;

  if (!component) {
    return false;
  }

  const queueRef = useRef([]);

  const [clonedIndexedElementInputs, setClonedIndexedElementInputs] = useState(
    !!component.componentInput.elements
      ? component.componentInput.elements && component.componentInput.elements
      : [],
  );

  const [isAddElementSubModalOpen, setIsAddElementSubModalOpen] = useState(false);

  const queue = queueRef.current;

  const handleNameChange = useCallback(
    (name) =>
      queue.push({
        type: EditComponentOperations.EDIT_LOCAL_NAME,
        componentId: component.id,
        name,
      }),
    [queue, component.id],
  );

  const handleRemove = useCallback(
    (id) => {
      queue.push({
        type: EditComponentOperations.REMOVE,
        componentId: component.id,
        elementId: id,
      });

      let inputIndex = findIndex((element) => element.id === id, clonedIndexedElementInputs);

      if (inputIndex >= 0) {
        setClonedIndexedElementInputs(
          Object.assign([], clonedIndexedElementInputs, {
            [inputIndex]: { ...clonedIndexedElementInputs[inputIndex], removed: true },
          }),
        );
      } else {
        setClonedIndexedElementInputs([...clonedIndexedElementInputs, { id, removed: true }]);
      }
    },
    [queue, clonedIndexedElementInputs, component.id],
  );

  const handleOpenAddModal = useCallback(
    () => setIsAddElementSubModalOpen(!isAddElementSubModalOpen),
    [setIsAddElementSubModalOpen, isAddElementSubModalOpen],
  );

  const handleAdd = useCallback(
    (id) => {
      queue.push({ type: EditComponentOperations.ADD, componentId: component.id, elementId: id });

      let inputIndex = findIndex((element) => element.id === id, clonedIndexedElementInputs);

      if (inputIndex === -1) {
        setClonedIndexedElementInputs([...clonedIndexedElementInputs, { id, removed: false }]);
      } else {
        const updatedClonedIndexedElementInputs = Object.assign([], clonedIndexedElementInputs, {
          [inputIndex]: { ...clonedIndexedElementInputs[inputIndex], removed: false },
        });
        updatedClonedIndexedElementInputs.push(
          updatedClonedIndexedElementInputs.splice(inputIndex, 1)[0],
        );
        setClonedIndexedElementInputs(updatedClonedIndexedElementInputs);
      }
    },
    [queue, clonedIndexedElementInputs, component.id],
  );

  const handleNameElementChange = useCallback(
    (id, name) => {
      queue.push({
        type: EditComponentOperations.EDIT_ELEMENT_LOCAL_NAME,
        componentId: component.id,
        elementId: id,
        name,
      });

      let inputIndex = findIndex((element) => element.id === id, clonedIndexedElementInputs);

      if (inputIndex === -1) {
        setClonedIndexedElementInputs([...clonedIndexedElementInputs, { id, name }]);
      } else {
        const updatedClonedIndexedElementInputs = Object.assign([], clonedIndexedElementInputs, {
          [inputIndex]: { ...clonedIndexedElementInputs[inputIndex], name },
        });
        setClonedIndexedElementInputs(updatedClonedIndexedElementInputs);
      }
    },
    [queue, clonedIndexedElementInputs, component.id],
  );

  const handleSave = useCallback(() => onSave(component.id, queue), [component, queue, onSave]);

  let updatedElements = useMemo(() => {
    const orderedElementsTemplate = getOrderedElementsTemplate(
      values(clonedIndexedElementInputs),
      component.componentInput.elementsOrder,
      indexedElementsTemplate,
      component.componentTemplate,
    );

    if (!!component.componentInput.elementsOrder) {
      return orderedElementsTemplate;
    }

    try {
      return sortBy(pipe(prop('id'), indexOf(__, pluck('id', component.componentInput.elements))))(
        orderedElementsTemplate,
      );
    } catch (e) {
      return orderedElementsTemplate;
    }
  }, [
    clonedIndexedElementInputs,
    component.componentInput.elements,
    component.componentInput.elementsOrder,
    indexedElementsTemplate,
    component.componentTemplate,
  ]);

  return (
    <div
      css={styles.modal}
      style={{ zIndex: isOpen ? 1000 : -1 }}
      className={classNames({ 'is-open': isOpen })}
    >
      <EditComponentSubModal isOpen={isAddElementSubModalOpen} position={'right'}>
        <EditComponentToAddElements
          language={language}
          componentsTemplate={componentsTemplate}
          indexedElementsTemplate={indexedElementsTemplate}
          currentElements={updatedElements}
          onAdd={handleAdd}
        />
      </EditComponentSubModal>
      <EditComponentSubModal isOpen={true} position={!isAddElementSubModalOpen ? 'center' : 'left'}>
        <EditComponentCurrentElements
          language={language}
          componentHeader={component.componentHeader}
          indexedElementInputs={clonedIndexedElementInputs}
          elements={updatedElements}
          onNameChange={handleNameChange}
          onOpenAddModal={handleOpenAddModal}
          onRemove={handleRemove}
          onNameElementChange={handleNameElementChange}
          onCancel={onCancel}
          onSave={handleSave}
        />
      </EditComponentSubModal>
    </div>
  );
};

EditComponentModal.defaultProps = {
  isOpen: false,
};

export default EditComponentModal;

export class EditComponentOperations {
  static ADD = 1;
  static REMOVE = -1;
  static EDIT_LOCAL_NAME = 0;
  static EDIT_ELEMENT_LOCAL_NAME = 2;
}
