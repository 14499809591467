// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html
import { DefaultPage } from './';

const acceptanceProtocolRoute = {
  path: '/acceptance-protocol',
  name: 'Acceptance Protocol',
  childRoutes: [{ path: ':id/:step', name: 'Default page', component: DefaultPage, isIndex: true }],
};

export default acceptanceProtocolRoute;
