/** @jsxImportSource @emotion/react */
import { useEffect, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { range, any } from 'ramda';

import { colors } from '../../../common/theme/colors';

import Modal from '../../common/Modal';
import Spinner from '../../common/Spinner';

import { getLocalizedNameForCost, getLocalizedName } from './Functions';
import { TabsCost } from './TabsCost';
import { InputCost } from './InputCost';
import { getFraction } from './Functions';
import Button from '../../common/Button';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'auto min-content',
    gridTemplateColumns: '2fr 3fr',
    gridAutoFlow: 'row',
    gridGap: '1.5em',
  },
  tiles: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
    gridAutoFlow: 'row',
    borderLeft: `solid 1px ${colors.lighterGrey}`,
    borderTop: `solid 1px ${colors.lighterGrey}`,
  },
  tile: {
    borderRight: `solid 1px ${colors.lighterGrey}`,
    borderBottom: `solid 1px ${colors.lighterGrey}`,
    backgroundColor: colors.tileUnselectedBackgroundColor,

    '&.is-selected': {
      backgroundColor: colors.reportBlue,
    },

    '& > button': {
      color: colors.black,
      backgroundColor: 'transparent',
      width: '100%',
      height: '100%',
    },
  },
  buttons: {
    display: 'grid',
    gridColumn: '1 / span 2',
    gridTemplateColumns: 'min-content auto min-content min-content min-content',
    gridGap: '0 1.5em',
  },
};

const CostModal = (props) => {
  const {
    isOpen,
    isBusy,
    protocolId,
    selected,
    templateCosts,
    language,
    onClose,
    onChangeValue,
    onDeleteValue,
  } = props;

  const { component, element, attribute } = selected;

  const [defaultButton, setDefaultButton] = useState(null);
  const [cost, setCost] = useState({ type: 'CHF', value: '' });
  const [age, setAge] = useState('');
  const [life, setLife] = useState('');

  const title = useMemo(
    () => getLocalizedName(attribute.template, language),
    [attribute.template, language],
  );

  const initCost = useCallback(() => {
    if (!!attribute.input && !!attribute.input.cost && attribute.input.cost.type === 'x/y') {
      const fraction = getFraction(attribute.input.cost.value);
      if (!!fraction) {
        const lifeValue = parseFloat(fraction.sub) || 0;
        const ageValue = lifeValue - (parseFloat(fraction.sup) || 0);
        setAge(ageValue);
        setLife(lifeValue);
      }
    }

    setCost(
      !!attribute.input && !!attribute.input.cost
        ? attribute.input.cost
        : { type: 'CHF', value: '' },
    );
  }, [attribute, setCost, setAge, setLife]);

  const handleClose = useCallback(() => onClose(), [onClose]);

  const handleChangeCost = useCallback(
    (updatedCost) => {
      setDefaultButton('save');
      setCost({ ...cost, ...updatedCost });
    },
    [cost, setCost, setDefaultButton],
  );

  const handleClearCostText = useCallback(() => {
    setCost({ type: !!cost.type ? cost.type : 'CHF', value: cost.value });
  }, [cost, setCost]);

  const handleDelete = useCallback(() => {
    // prettier-ignore
    const expression =
    `componentsInput/[${component.id}]/elements/[${element.id}]/attributesSelected/[${attribute.id}]`;

    onDeleteValue(protocolId, expression);

    handleClose();
  }, [protocolId, component.id, element.id, attribute.id, onDeleteValue, handleClose]);

  const handleSaveWithoutCost = useCallback(() => {
    // prettier-ignore
    const expression =
    `componentsInput/[${component.id}]/elements/[${element.id}]/attributesSelected/[${attribute.id}]`;

    onChangeValue(protocolId, expression, { id: attribute.id, cost: null });

    handleClose();
  }, [protocolId, component.id, element.id, attribute.id, onChangeValue, handleClose]);

  const handleSave = useCallback(() => {
    // prettier-ignore
    const expression =
    `componentsInput/[${component.id}]/elements/[${element.id}]/attributesSelected/[${attribute.id}]`;

    onChangeValue(protocolId, expression, {
      id: attribute.id,
      cost: !!cost && (!!cost.id || (!!cost.value && cost.value !== '0')) ? cost : null,
    });

    handleClose();
  }, [protocolId, component.id, element.id, attribute.id, cost, handleClose, onChangeValue]);

  const handleReturnKey = useCallback(
    (event) => {
      if (event.keyCode === 13) {
        switch (defaultButton) {
          case 'without-costs':
            handleSaveWithoutCost();
            break;
          case 'save':
            handleSave();
            break;
          case 'cancel':
            handleClose();
            break;
          default:
            break;
        }
      }
    },
    [defaultButton, handleClose, handleSave, handleSaveWithoutCost],
  );

  useEffect(() => {
    const isSelected =
      !!attribute &&
      any((a) => a.id === attribute.id)(
        !!element && !!element.input && !!element.input.attributesSelected
          ? element.input.attributesSelected
          : [],
      );
    setDefaultButton(isSelected ? 'cancel' : 'without-costs');
  }, [attribute, element]);

  useEffect(() => {
    window.addEventListener('keydown', handleReturnKey);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener('keydown', handleReturnKey);
    };
  }, [protocolId, component.id, element.id, attribute.id, cost, defaultButton, handleReturnKey]);

  return (
    <Modal title={title} isOpen={isOpen} onOpen={initCost} onClose={handleClose}>
      <Spinner show={isBusy}>
        <div css={styles.container}>
          <TabsCost
            type={cost.type}
            value={cost.value}
            age={age}
            life={life}
            setAge={setAge}
            setLife={setLife}
            onChangeCost={handleChangeCost}
          />
          <div css={styles.tiles}>
            {range(0, 6).map((index) => {
              const isSelected =
                index < templateCosts.length &&
                !!cost &&
                !!cost.id &&
                cost.id === templateCosts[index].id;

              return (
                <div
                  key={`tile-${index}`}
                  css={styles.tile}
                  className={classNames({ 'is-selected': isSelected })}
                >
                  {index < templateCosts.length && (
                    <InputCost
                      id={templateCosts[index].id}
                      text={getLocalizedNameForCost(templateCosts[index], language)}
                      isSelected={isSelected}
                      onChange={handleChangeCost}
                      onClear={handleClearCostText}
                    />
                  )}
                </div>
              );
            })}
          </div>
          <div css={styles.buttons}>
            <Button secondary onClick={handleDelete}>
              <Trans>BUTTONS.DELETE</Trans>
            </Button>
            <div />
            <Button
              default={defaultButton === 'cancel'}
              secondary={defaultButton !== 'cancel'}
              onClick={handleClose}
            >
              <Trans>BUTTONS.CANCEL</Trans>
            </Button>
            <Button
              autoFocus
              default={defaultButton === 'without-costs'}
              secondary={defaultButton !== 'without-costs'}
              onClick={handleSaveWithoutCost}
            >
              <Trans>BUTTONS.WITHOUT-COSTS</Trans>
            </Button>
            <Button
              default={defaultButton === 'save'}
              secondary={defaultButton !== 'save'}
              onClick={handleSave}
            >
              <Trans>BUTTONS.SAVE</Trans>
            </Button>
          </div>
        </div>
      </Spinner>
    </Modal>
  );
};

CostModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
};

CostModal.defaultProps = {
  isOpen: false,
};

export default CostModal;
