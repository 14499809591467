import {
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR,
  HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_SUCCESS,
} from './constants';
import produce from 'immer';
import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { homeRoutes } from '../routes';
import { commonGetProtocols } from '../../common/redux/getProtocols';
import { i18n } from '../../../common/i18n-loader';
import { post } from '../../common/services/api';

function getMessageFromErrorCode(errorCode) {
  if (!errorCode) {
    return i18n._('ERRORS.API.GENERIC');
  }

  switch (errorCode) {
    case 2:
      return i18n._('ERRORS.API.CREATE-PROTOCOL-FROM-ANOTHER-RENTAL-OBJECT.NOT-FIRST-PROTOCOL');
    case 3:
      return i18n._('ERRORS.API.CREATE-PROTOCOL-FROM-ANOTHER-RENTAL-OBJECT.FEATURE-NOT-ENABLED');
    case 4:
      return i18n._(
        'ERRORS.API.CREATE-PROTOCOL-FROM-ANOTHER-RENTAL-OBJECT.TEMPLATE-VERSION-MISMATCH',
      );
    case 6:
      return i18n._('ERRORS.API.CREATE-PROTOCOL-FROM-ANOTHER-RENTAL-OBJECT.MISSING-TEMPLATE');
    case 7:
      return i18n._('ERRORS.API.CREATE-PROTOCOL-FROM-ANOTHER-RENTAL-OBJECT.NO-PROTOCOLS-FOUND');
    case 8:
      return i18n._(
        'ERRORS.API.CREATE-PROTOCOL-FROM-ANOTHER-RENTAL-OBJECT.RENTAL-OBJECT-NOT-EXISTS',
      );
    default:
      return i18n._('ERRORS.API.GENERIC');
  }
}

export function homeCreateProtocolWithREMReferenceFromAnotherProtocol(
  targetRentalObjectId,
  sourceRentalObjectReference,
) {
  return {
    type: HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL,
    payload: {
      targetRentalObjectId,
      sourceRentalObjectReference,
    },
  };
}

function homeCreateProtocolWithREMReferenceFromAnotherProtocolError({ type: errorCode }) {
  const localizedErrorMessage = getMessageFromErrorCode(errorCode);

  return {
    type: HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR,
    payload: localizedErrorMessage,
  };
}

function homeCreateProtocolWithREMReferenceFromAnotherProtocolSuccess() {
  return {
    type: HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_SUCCESS,
  };
}

function* doGoToHomepage() {
  yield put(push(homeRoutes.home));

  yield put(commonGetProtocols());
}

function* doCreateProtocolWithREMReferenceFromAnotherProtocol({ payload: actionPayload }) {
  const { targetRentalObjectId, sourceRentalObjectReference } = actionPayload;

  const httpPayload = {
    targetRentalObjectId,
    sourceRentalObjectReference,
  };

  try {
    yield call(post, 'protocol/fromanotherprotocol', httpPayload);

    yield put(homeCreateProtocolWithREMReferenceFromAnotherProtocolSuccess());
  } catch (error) {
    yield put(homeCreateProtocolWithREMReferenceFromAnotherProtocolError(error));
  }
}

export function* switchCreateProtocolWithREMReferenceFromAnotherProtocol() {
  yield takeLatest(
    HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL,
    doCreateProtocolWithREMReferenceFromAnotherProtocol,
  );

  yield takeLatest(
    HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_SUCCESS,
    doGoToHomepage,
  );
}

export const homeCreateProtocolWithREMReferenceFromAnotherProtocolReducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL:
        draft.ui.busy.createProtocolWithREMReferenceFromAnotherProtocol = true;
        break;
      case HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_SUCCESS:
        draft.ui.busy.createProtocolWithREMReferenceFromAnotherProtocol = false;
        draft.ui.modal.protocolWithREMReference = false;
        break;
      case HOME_CREATE_PROTOCOL_WITH_REM_REFERENCE_FROM_ANOTHER_PROTOCOL_ERROR:
        draft.ui.busy.createProtocolWithREMReferenceFromAnotherProtocol = false;
        break;
      default:
        return state;
    }
  });
