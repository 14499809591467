/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import localForage from 'localforage';
import useInterval from '@use-it/interval';

const styles = {
  list: {
    justifySelf: 'start',
    color: '#fff',
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '16px',
    whiteSpace: 'nowrap',
  },
  listElement: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridColumnGap: '2px',
    alignItems: 'center',
  },
  listElementName: {
    color: 'rgba(255,255,255,0.7)',
  },
  listElementData: {
    fontWeight: 'bold',
  },
};

function humanFileSize(size) {
  if (size < 1024) return size + ' B';
  let i = Math.floor(Math.log(size) / Math.log(1024));
  let num = size / Math.pow(1024, i);
  let round = Math.round(num);
  num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
  return `${num} ${'KMGTPEZY'[i - 1]}B`;
}

export const QuotaCalculator = () => {
  const [estimateStr, setEstimateStr] = useState('');
  const [jsonStr, setJsonStr] = useState('');
  const [imagesStr, setImagesStr] = useState('');

  useInterval(async () => {
    if ('storage' in navigator && 'estimate' in navigator.storage) {
      const estimate = await navigator.storage.estimate();

      setEstimateStr(humanFileSize(estimate.usage));
    } else {
      setEstimateStr('unavailable');
    }

    const json = await localForage.getItem('persist:common');
    const jsonBytes = new TextEncoder().encode(JSON.stringify(json)).length;
    setJsonStr(humanFileSize(jsonBytes));

    const keys = await localForage.keys();

    let imageBytes = 0;
    for (const key of keys) {
      if (key !== 'persist:common') {
        const image = await localForage.getItem(key);
        if (!!image) {
          imageBytes += image.byteLength;
        }
      }
    }
    setImagesStr(humanFileSize(imageBytes));
  }, 2000);

  return (
    <div css={styles.list}>
      <div css={styles.listElement}>
        <div css={styles.listElementName}>DB:</div>
        <div css={styles.listElementData}>{estimateStr || 'Calculating...'}</div>
      </div>
      <div css={styles.listElement}>
        <div css={styles.listElementName}>Protocols:</div>
        <div css={styles.listElementData}>{jsonStr || 'Calculating...'}</div>
      </div>
      <div css={styles.listElement}>
        <div css={styles.listElementName}>Images:</div>
        <div css={styles.listElementData}>{imagesStr || 'Calculating...'}</div>
      </div>
    </div>
  );
};
