import { LOCATION_CHANGE, push } from 'connected-react-router';
import { put, select, takeLatest } from 'redux-saga/effects';
import { selectIsDevUser } from '../../common/redux/selectors';
import { selectMigrationRoute, selectHubConnection } from './selectors';
import { migrationHubConnect, migrationHubDisconnect } from './actions';

function* doIsAuthorized() {
  const route = yield select(selectMigrationRoute);

  if (!!route && route.isExact) {
    const isDev = yield select(selectIsDevUser);

    if (isDev) {
      yield put(migrationHubConnect());
    } else {
      yield put(push('/not-found'));
    }
  } else {
    const connection = yield select(selectHubConnection);

    if (!!connection) {
      yield put(migrationHubDisconnect());
    }
  }
}

export function* switchIsAuthorized() {
  yield takeLatest(LOCATION_CHANGE, doIsAuthorized);
}
