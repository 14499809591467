const getEnv = () => {
  const domain = document.location.host;
  switch (domain) {
    case 'dap-test.garaio-rem.ch':
      return 'TEST';
    case 'dap.garaio-rem.ch':
      return 'PROD';
    default:
      return '';
  }
};

const getSentryConfiguration = () => {
  switch (getEnv()) {
    case 'TEST': {
      return {
        dsn: 'https://1f07db4f0f29409bb89a930c0ea14f25@o1134581.ingest.sentry.io/6182204',
        environment: 'TEST',
      };
    }
    case 'PROD': {
      return {
        dsn: 'https://1f07db4f0f29409bb89a930c0ea14f25@o1134581.ingest.sentry.io/6182204',
        environment: 'PROD',
      };
    }
    default: {
      return {
        dsn: '',
        environment: '',
      };
    }
  }
};

export const sentryConfiguration = getSentryConfiguration();
