export const SEND_STEP_COMPLETE_PROTOCOL = '[Send Step] Complete protocol';
export const SEND_STEP_COMPLETE_PROTOCOL_SYNCHRONIZED =
  '[Send Step] Complete protocol synchronized';
export const SEND_STEP_COMPLETE_PROTOCOL_START = '[Send Step] Complete protocol start';
export const SEND_STEP_COMPLETE_PROTOCOL_END = '[Send Step] Complete protocol end';
export const SEND_STEP_COMPLETE_PROTOCOL_CLOSED = '[Send Step] Complete protocol closed';
export const SEND_STEP_COMPLETE_PROTOCOL_EMAIL_SENT = '[Send Step] Complete protocol email sent';
export const SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC =
  '[Send Step] Complete protocol OnSite Message Sync';
export const SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC_ERROR =
  '[Send Step] Complete protocol OnSite Message Sync Error';
export const SEND_STEP_COMPLETE_PROTOCOL_ONSITE_MESSAGE_SYNC__AUTH_ERROR =
  '[Send Step] Complete protocol OnSite Message Sync Auth Error';
export const SEND_STEP_COMPLETE_PROTOCOL_PDFS_GENERATED =
  '[Send Step] Complete protocol pdfs generated';
export const SEND_STEP_COMPLETE_PROTOCOL_SUCCESS = '[Send Step] Complete protocol success';
export const SEND_STEP_COMPLETE_PROTOCOL_ERROR = '[Send Step] Complete protocol error';
export const SEND_STEP_CLOSE_PROGRESS_MODAL = '[Send Step] Close progress modal';

export const SEND_STEP_GO_TO_HOME = '[Send Step] Go to home';

export const PROGRESS_STATUS_INITIAL = -1;
export const PROGRESS_STATUS_ON_PROGRESS = 0;
export const PROGRESS_STATUS_COMPLETED = 1;
export const PROGRESS_STATUS_ENDED = -2;
export const PROGRESS_STATUS_ERROR = -3;
export const PROGRESS_STATUS_AUTH_ERROR = -4;
