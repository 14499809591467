import { LOCATION_CHANGE } from 'connected-react-router';
import { equals } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import { extractNames } from '../../../../common/code/extractNames';
import { commonPatchOperation } from '../../../common/redux/actions';
import {
  selectCurrentProtocol,
  selectIndexedBasedataInput,
  selectProtocolIdRoute,
} from '../../redux/selectors';

function* doUpdatePersonList() {
  const route = yield select(selectProtocolIdRoute);

  if (!!route) {
    if (!!route.params.id && route.params.step && route.params.step === 'sign-step') {
      const protocol = yield select(selectCurrentProtocol);

      if (!protocol) {
        return;
      }

      const indexedBasedataInput = yield select(selectIndexedBasedataInput);

      const oldTenant = [];
      const newTenant = [];
      
      for (const baseData in indexedBasedataInput)
      {
        const rentalPerson = indexedBasedataInput[baseData];

        if (!!rentalPerson && !!!rentalPerson.isDeleted) {
          if (baseData.includes('oldRentalPerson')) {
            const oldRentalName = extractNames(
              rentalPerson.fields,
              'VormieterVorname',
              baseData === 'oldRentalPerson' ? 'VormieterName' : ''
            );
            oldTenant.push(oldRentalName);
          }
          else if (baseData.includes('newRentalPerson')) {
            const newRentalName = extractNames(
              rentalPerson.fields,
              'NachmieterVorname',
              baseData === 'newRentalPerson' ? 'NachmieterName' : ''
            );
            newTenant.push(newRentalName);
          }
        }
      }

      if (!equals(protocol.input.signatures.oldTenant.personList, oldTenant)) {
        yield put(
          commonPatchOperation(protocol.id, `signatures/oldTenant`, { personList: oldTenant }),
        );
      }
      if (!equals(protocol.input.signatures.newTenant.personList, newTenant)) {
        yield put(
          commonPatchOperation(protocol.id, `signatures/newTenant`, { personList: newTenant }),
        );
      }
    }
  }
}

export function* switchUpdatePersonList() {
  yield takeLatest(LOCATION_CHANGE, doUpdatePersonList);
}
