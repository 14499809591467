import React from 'react';

export const PlusIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 26"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M21,26H0V2H21ZM1,25H20V3H1Z" />
    <polygon points="15 13.5 11 13.5 11 9.5 10 9.5 10 13.5 6 13.5 6 14.5 10 14.5 10 18.5 11 18.5 11 14.5 15 14.5 15 13.5" />
  </svg>
);
