/** @jsxImportSource @emotion/react */

import { useCallback } from 'react';
import { i18n } from '../../../common/i18n-loader';
import TextArea from '../../common/TextArea';
import { useDebouncedWithStateCallback } from '../../../common/code/useDebouncedWithStateCallback';
import Button from '../../common/Button';
import { Trans } from '@lingui/macro';

const styles = {
  container: {
    height: '100%',
    display: 'grid',
    gridGap: '1em',
    gridTemplateRows: 'auto min-content',
  },
  comment: {
    borderTopRightRadius: '0',
    borderTopLeftRadius: '0',
  },
};

export const InputComment = (props) => {
  const { protocolId, selected, onChange, onDelete, onDeleteComment } = props;

  const handleCommentChange = useCallback(
    (value) => {
      const expression =
        // prettier-ignore
        `componentsInput/[${selected.component.id}]/elements/[${selected.element.id}]/comment`;

      if (!!value) {
        onChange(protocolId, expression, value);
      } else {
        onDelete(protocolId, expression);
      }
    },
    [protocolId, selected.component.id, selected.element.id, onChange, onDelete],
  );

  const [comment, handleChange] = useDebouncedWithStateCallback(
    handleCommentChange,
    selected.element.input,
    'comment',
  );

  const handleDeleteComment = useCallback(() => {
    onDeleteComment({
      protocolId: protocolId,
      selectedComponentId: selected.component.id,
      selectedElementId: selected.element.id,
    });
  }, [onDeleteComment, protocolId, selected.component.id, selected.element.id]);

  return (
    <div css={styles.container}>
      <TextArea
        css={styles.comment}
        placeholder={i18n._('MAIN-STEP.TEXTS.FLOORING-COMMENT')}
        onChange={handleChange}
        value={comment}
      />
      <div>
        <Button secondary onClick={handleDeleteComment} disabled={!comment}>
          <Trans>BUTTONS.DELETE-COMMENT</Trans>
        </Button>
      </div>
    </div>
  );
};
