import produce from 'immer';
import { reducer as closeOpenProtocolFromPreviousProtocolModal } from './homeCloseProtocolFromPreviousProtocolModal';
import { reducer as homeCloseChangeProtocolIdModal } from './homeCloseChangeProtocolIdModal';
import { reducer as homeCloseProtocolWithoutREMReferenceModal } from './homeCloseProtocolWithoutREMReferenceModal';
import { reducer as homeCloseProtocolWithREMReferenceModal } from './homeCloseProtocolWithREMReferenceModal';
import { reducer as homeCreateProcotolWithoutREMReference } from './homeCreateProcotolWithoutREMReference';
import { reducer as homeCreateProtocolWithREMReference } from './homeCreateProtocolWithREMReference';
import { reducer as homeGetTemplates } from './homeGetTemplates';
import { reducer as homeOpenChangeProtocolIdModal } from './homeOpenChangeProtocolIdModal';
import { reducer as homeOpenProtocolWithoutREMReferenceModal } from './homeOpenProtocolWithoutREMReferenceModal';
import { reducer as homeOpenProtocolWithREMReferenceModal } from './homeOpenProtocolWithREMReferenceModal';
import { reducer as homeOpenProtocolFromPreviousProtocolModal } from './homeOpenProtocolFromPreviousProtocolModal';
import { reducer as homeCreateProtocolFromPreviousProtocol } from './homeCreateProtocolFromPreviousProtocol';
import { reducer as homeSearchRentalObjects } from './homeSearchRentalObjects';
import initialState from './initialState';
import { homeCreateProtocolWithREMReferenceFromAnotherProtocolReducer } from './homeCreateProtocolWithREMReferenceFromAnotherProtocol';
import { COMMON_LOGOUT } from "../../common/redux/constants";

const reducers = [
  homeOpenProtocolWithoutREMReferenceModal,
  homeOpenProtocolWithREMReferenceModal,
  homeCloseProtocolWithoutREMReferenceModal,
  homeCloseProtocolWithREMReferenceModal,
  homeCreateProcotolWithoutREMReference,
  homeOpenProtocolFromPreviousProtocolModal,
  closeOpenProtocolFromPreviousProtocolModal,
  homeCreateProtocolWithREMReference,
  homeSearchRentalObjects,
  homeOpenChangeProtocolIdModal,
  homeCloseChangeProtocolIdModal,
  homeCreateProtocolFromPreviousProtocol,
  homeGetTemplates,
  homeCreateProtocolWithREMReferenceFromAnotherProtocolReducer,
];

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_LOGOUT:
        draft.rentalObjects = [];
        draft.search = null;
        break;
      default:
        break;
    }

    return reducers.reduce((s, r) => r(s, action), draft);
  });

export default reducer;
