import { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

export function useDebouncedWithStateCallback(onChange, dependencies, prop) {
  const [value, setValue] = useState('');

  const [handleDebouncedChange] = useDebouncedCallback((evt) => {
    onChange(evt);
  }, 500);

  const handleChange = useCallback(
    (evt) => {
      setValue(evt.currentTarget.value);
      handleDebouncedChange(evt.currentTarget.value);
    },
    [setValue, handleDebouncedChange],
  );

  useEffect(() => {
    if (!!dependencies) {
      if (!!prop && !!dependencies[prop]) {
        setValue(dependencies[prop]);
      } else if (!prop) {
        setValue(dependencies);
      } else {
        setValue('');
      }
    } else {
      setValue('');
    }
  }, [dependencies, prop]);

  return [value, handleChange];
}
