import produce from 'immer';
import { FILL_STEP_CLOSE_TAKE_PHOTO_MODAL } from './constants';

export function fillStepCloseTakePhotoModal() {
  return {
    type: FILL_STEP_CLOSE_TAKE_PHOTO_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_CLOSE_TAKE_PHOTO_MODAL:
        draft.ui.modal.takePhoto = false;
        draft.ui.busy.spinner = true;
        break;
      default:
        return state;
    }
  });
