/** @jsxImportSource @emotion/react */
import { object, string } from 'yup';
import { i18n } from '../../common/i18n-loader';
import { Formik } from 'formik';
import { dropdownFieldRow } from '../common/form';
import Dropdown from '../common/Dropdown';
import classNames from 'classnames';
import Fade from 'react-reveal/Fade';
import { Trans } from '@lingui/macro';
import Button from '../common/Button';
import { sortOptsByLanguage } from './utils';

const createProtocolFromTemplateFormInitialValues = {
  value: '',
  label: '',
};

const createProtocolFromTemplateFormValidationSchema = object().shape({
  value: string()
    .notOneOf([-1], i18n._('VALIDATIONS.REQUIRED.TEMPLATE-NAME'))
    .required(i18n._('VALIDATIONS.REQUIRED.TEMPLATE-NAME'))
    .nullable(),
});

export function NewProtocolWithREMReferenceFromTemplate(props) {
  const {
    language,
    onCancel,
    onSwitchProtocolCreationMode,
    onSubmit,
    styles,
    templates,
    hasInitialAcceptance,
  } = props;

  const sortedOptions = sortOptsByLanguage(templates, language);

  return (
    <Formik
      initialValues={createProtocolFromTemplateFormInitialValues}
      validationSchema={createProtocolFromTemplateFormValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleSubmit, handleReset, setFieldValue, setFieldTouched }) => (
        <form onSubmit={handleSubmit} onReset={handleReset} css={styles.body} autoComplete="off">
          <div css={styles.formContainer}>
            <div css={dropdownFieldRow}>
              <Dropdown
                name="value"
                options={sortedOptions}
                language={language}
                emptyValue={{
                  value: -1,
                  label: i18n._('DROPDOWN-TEMPLATE.DEFAULT-VALUE'),
                }}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                value={values.value}
                className={classNames({
                  'has-error': touched.value && errors.value,
                })}
              />
              <Fade right when={!!touched.value && !!errors.value}>
                <span>{errors.value}</span>
              </Fade>
            </div>
          </div>
          <div css={styles.modal.buttons}>
            {hasInitialAcceptance && (
              <button css={styles.modal.modeSwitchButton} onClick={onSwitchProtocolCreationMode}>
                <Trans>SELECT-TEMPLATE-MODAL.SWITCH-TO-INITIAL-ACCEPTANCE</Trans>
              </button>
            )}
            <Button secondary onClick={onCancel} type="reset" className="leftButton">
              <Trans>SELECT-TEMPLATE-MODAL.ABORT</Trans>
            </Button>
            <Button default type="submit" className="rightButton">
              <Trans>SELECT-TEMPLATE-MODAL.CONFIRM</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default NewProtocolWithREMReferenceFromTemplate;
