/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';
import { i18n } from '../../common/i18n-loader';
import { colors } from '../../common/theme/colors';
import plusButton from '../../images/plus-button.png';
import { CameraPicker } from './Camera/CameraPicker';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'grid',
  },
  filePicker: {
    display: 'none',
  },
  filePickerLabel: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    placeContent: 'center',
    placeItems: 'center',
    rowGap: '8px',

    cursor: 'pointer',
    '&[disabled]': {
      background: colors.grey80,
      cursor: 'not-allowed',
    },
  },
  text: {
    textAlign: 'center',
    '[disabled] &': {
      color: colors.darker,
      userSelect: 'none',
    },
  },
  spinnerContainer: {
    width: '100%',
    height: '100%',
    display: 'grid',
    placeContent: 'center',
    placeItems: 'center',
  },
};

export const ImagePicker = ({ disabled, ...props }) => {
  return (
    <CameraPicker
      containerStyle={styles.container}
      inputFileStyle={styles.filePicker}
      spinnerContainerStyle={styles.spinnerContainer}
      labelInput="file-sketcher"
      disabled={disabled}
      {...props}
    >
      <label htmlFor="file-sketcher" css={styles.filePickerLabel} disabled={disabled}>
        <img src={plusButton} alt="plus" />
        <span css={styles.text}>
          {disabled ? (
            i18n._('FILL-STEP.IMAGE-LIMIT-REACHED-BUTTON')
          ) : (
            <Trans>LABELS.NEW-IMAGE</Trans>
          )}
        </span>
      </label>
    </CameraPicker>
  );
};
