import produce from 'immer';
import { MIGRATION_SET_TEMPLATE_MIGRATION } from './constants';

export function migrationSetTemplateMigration(templateName) {
  return {
    type: MIGRATION_SET_TEMPLATE_MIGRATION,
    payload: templateName,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case MIGRATION_SET_TEMPLATE_MIGRATION:
        draft.selectedTemplate = action.payload;
        break;
      default:
        return state;
    }
  });
