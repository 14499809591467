/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import classNames from 'classnames';

import Input from '../../common/Input';

export const FormStringField = (props) => {
  const { name, setFieldValue, setFieldTouched, onChange, value, errors, disabled } = props;

  const handleChange = useCallback(
    (e) => {
      setFieldValue(name, e.currentTarget.value);
      onChange(e);
    },
    [name, onChange, setFieldValue],
  );
  const handleBlur = useCallback((e) => setFieldTouched(name), [name, setFieldTouched]);

  return (
    <Input
      disabled={disabled}
      type="text"
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
      autoComplete="off"
      className={classNames({
        'has-error': errors,
      })}
    />
  );
};
