import { split } from 'ramda';

export class LeavingAgreementPatcher {
  delete(leavingAgreement, expression) {
    const tokens = split('/', expression);

    let navigator = leavingAgreement;

    for (let i = 0; i < tokens.length; i++) {
      if (i < tokens.length - 1) {
        const token = this.isValue(tokens[i]) ? this.getValue(tokens[i]) : tokens[i];
        navigator = navigator[token];
        if (!navigator) {
          throw Error(`invalid token ${token} in ${expression}`);
        }
      } else {
        if (this.isValue(tokens[i])) {
          navigator.splice(this.getValue(tokens[i]), 1);
        } else {
          delete navigator[tokens[i]];
        }
      }
    }
  }

  patch(leavingAgreement, expression, value) {
    const tokens = split('/', expression);

    let navigator = leavingAgreement;

    for (let i = 0; i < tokens.length; i++) {
      const token = this.isValue(tokens[i]) ? this.getValue(tokens[i]) : tokens[i];
      if (i < tokens.length - 1) {
        navigator = navigator[token];
        if (!navigator) {
          throw Error(`invalid token ${token} in ${expression}`);
        }
      } else {
        navigator[token] = value;
      }
    }
  }

  getValue(token) {
    const [, valueAsString] = /\[([0-9]+?)\]/gi.exec(token);
    const index = !isNaN(valueAsString) ? parseInt(valueAsString, 10) : valueAsString;
    return index;
  }

  isValue(token) {
    return /\[([0-9]+?)\]/gi.test(token);
  }
}
