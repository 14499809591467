export const colors = {
  success: 'green',
  error: '#ff5555',
  partiallySuccess: 'orange',

  white: '#FFFFFF',
  black: '#000000',
  dark: '#4b4b4b', //Navbar background color
  darker: '#373739', //Input search color
  darkGrey: '#828282',
  darkBlue: '#0600bf',
  greyExternalLink: '#d9d9d9',
  grey80: '#c2c2c2',
  lighterGrey: '#aaaaaa',
  lightGrey: '#f7efef',
  whiteSmoke: '#F3F3F3', // serach result
  greyTextElementAccordion: '#adaaaf',
  darkAccordion: '#262424',
  greyColumnAcceptanceProtocol: '#666363',
  greyBorderAcceptanceProtocol: '#777777',
  tileSelectedBackgroundColor: '#31afe1',
  tileUnselectedBackgroundColor: 'white',
  brand: '#31afe1', // Brand Color
  brand_rgb: '49, 175, 225', // Brand Color RGB
  brand_dark: '#1c93c3', // Brand Dark Color

  topbarUnselected: '#474749', //Input search color

  reportBlue: '#CBE5EF',
  reportGreen: '#B9FFBD',
  defaultButton: '#28dbef',
  link: 'blue', // Link
  disabledIcon: '#999999', // Disabled Icon
  red: '#DC143C',
  green: '#008000',
  yellow: '#f4c430',
  lightCyan: 'LightCyan',
  errors: '#FC5453',

  input: { disabled: '#EDEDED' },

  leavingAgreement: {
    disabled: {
      color: 'rgba(0,0,0,0.6)',
      background: {
        container: 'rgba(0,0,0,0.1)',
        input: 'transparent',
      },
    },
  },
};
