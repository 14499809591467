import { startsWith } from 'ramda';

export const convertBase64ToFile = function (image) {
  const byteString = atob(image.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  const newBlob = new Blob([ab], {
    type: 'image/jpeg',
  });
  return newBlob;
};

export const convertArrayBufferToBlob = function (arrayBuffer, contentType) {
  const newBlob = new Blob([arrayBuffer], {
    type: contentType,
  });
  return newBlob;
};

export const convertArrayBufferToBase64 = function (arrayBuffer, contentType) {
  contentType = contentType || getImageFormat(arrayBuffer);
  const base64 = btoa(
    new Uint8Array(arrayBuffer).reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );

  return `data:${contentType};base64,${base64}`;
};

const mimeTypes = {
  '.bmp': 'image/bmp',
  '.gif': 'image/gif',
  '.jpeg': 'image/jpeg',
  '.jpg': 'image/jpeg',
  '.png': 'image/png',
  '.tiff': 'image/tiff',
  '.tif': 'image/tiff',
};

export const getContentType = function (extension) {
  extension = extension.toLowerCase();
  return !!mimeTypes[extension] ? mimeTypes[extension] : 'application/octet-stream';
};

export const getFileExtension = function (filename) {
  return filename.indexOf('.') !== -1 ? filename.substr(filename.indexOf('.')) : '';
};

const getImageFormat = (arrayBuffer) => {
  var bmp = [66, 77]; // BMP
  var gif = [71, 73, 70]; // GIF
  var png = [137, 80, 78, 71]; // PNG
  var tiff = [73, 73, 42]; // TIFF
  var tiff2 = [77, 77, 42]; // TIFF
  var jpeg = [255, 216, 255, 224]; // jpeg
  var jpeg2 = [255, 216, 255, 225]; // jpeg canon

  if (startsWith(bmp, arrayBuffer)) return 'image/bmp';
  if (startsWith(gif, arrayBuffer)) return 'image/gif';
  if (startsWith(jpeg, arrayBuffer)) return 'image/jpeg';
  if (startsWith(jpeg2, arrayBuffer)) return 'image/jpeg';
  if (startsWith(png, arrayBuffer)) return 'image/png';
  if (startsWith(tiff, arrayBuffer)) return 'image/tiff';
  if (startsWith(tiff2, arrayBuffer)) return 'image/tiff';

  return 'application/octet-stream';
};

export const blobToArrayBuffer = async (blob) => {
  return new Promise((resolve, reject) => {
    var fileReader = new FileReader();
    fileReader.onload = function (event) {
      resolve(event.target.result);
    };
    fileReader.readAsArrayBuffer(blob);
  });
};
