import React from 'react';

export const ResidenceOut = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 50"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M60,46h4V32H61V25H19v7H16V46h4v2h3V46H57v2h3ZM59,32H57v7H42V27H59ZM21,27H40V39H23V32H21Zm-3,7h3v7H59V34h3V44H18Z" />
    <path d="M10,26a1,1,0,0,1-.71-.29l-8-8a1,1,0,0,1,0-1.42l8-8a1,1,0,0,1,1.09-.21A1,1,0,0,1,11,9v4H24v2H10a1,1,0,0,1-1-1V11.41L3.41,17,9,22.59V20a1,1,0,0,1,1-1H24v2H11v4a1,1,0,0,1-.62.92A.84.84,0,0,1,10,26Z" />
  </svg>
);
