/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { Icon } from '../../../features/common/Icon';
import { colors } from '../../../common/theme/colors';
import { isMobile } from 'react-device-detect';
import { sharedContainerStyle } from './TabsCost';

const styles = {
  container: {
    gridColumn: '1 / span 3',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridAutoFlow: 'row',
    backgroundColor: colors.lighterGrey,
    gridGap: '0.5em',
    padding: '1.5em',
    input: {
      gridColumn: '1 / span 3',
      textAlign: 'right',
      borderRadius: '4px',
      padding: '12px 8px',
      backgroundColor: colors.white,
      border: `solid 2px ${colors.grey80}`,
    },
  },
  button: {
    color: colors.black,
    backgroundColor: colors.grey80,
    borderRadius: '4px',
  },
};

const keypadChars = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '.', '0', 'backspace'];

const format = (value) => {
  let newValue = value;
  if (/^\..*$/.test(newValue)) {
    newValue = '0' + newValue;
  } else if (/^0[^.].*$/.test(newValue)) {
    newValue = newValue.substring(1);
  }
  return newValue;
};

export const TabKeyPad = (props) => {
  const { value, onChangeCost } = props;

  const handleChangeValue = useCallback(
    (event) => {
      if (
        /^(|[0-9]*\.?[0-9]*)$/.test(event.currentTarget.value) &&
        event.currentTarget.value !== value
      ) {
        const newValue = format(event.currentTarget.value);
        onChangeCost({ value: newValue });
      }
    },
    [onChangeCost, value],
  );

  const handleAddChar = useCallback(
    (event) => {
      if (!value || value.indexOf('.') === -1 || event.currentTarget.innerText !== '.') {
        const newValue = format(value.concat(event.currentTarget.innerText));
        onChangeCost({ value: newValue });
      }
    },
    [value, onChangeCost],
  );

  const handleRemoveChar = useCallback(() => {
    const newValue = value.substring(0, value.length - 1);
    onChangeCost({ value: newValue });
  }, [value, onChangeCost]);

  return (
    <div css={{ ...styles.container, ...sharedContainerStyle }}>
      {isMobile ? (
        <input defaultValue={value} readOnly="readOnly" />
      ) : (
        <input value={value} onChange={handleChangeValue} />
      )}

      {keypadChars.map((char) =>
        char === 'backspace' ? (
          <button key={char} onClick={handleRemoveChar} css={styles.button}>
            <Icon icon="backspace" />
          </button>
        ) : (
          <button key={char} onClick={handleAddChar} css={styles.button}>
            {char}
          </button>
        ),
      )}
    </div>
  );
};
