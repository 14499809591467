import produce from 'immer';

import { COMMON_ERROR_SIGN } from './constants';

export function commonErrorSign(errorMessage) {
  return {
    type: COMMON_ERROR_SIGN,
    payload: errorMessage,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_ERROR_SIGN:
        break;
      default:
        return state;
    }
  });
