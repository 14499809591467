/** @jsxImportSource @emotion/react */
import { map, addIndex } from 'ramda';

import { TopBarBaseStep } from './TopBarBaseStep';
import { TopBarMainStep } from './TopBarMainStep';
import { TopBarFillStep } from './TopBarFillStep';
import { TopBarSignStep } from './TopBarSignStep';
import { TopBarLeavingAgreementStep } from './TopBarLeavingAgreementStep';
import { TopBarSendStep } from './TopBarSendStep';

const styles = {
  ul: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, 1fr)',
  },
  step: {
    textTransform: 'uppercase',
  },
};

const steps = {
  mainStep: ({ protocolId, currentStep, protocol, onNavigateTo, isLast }) => (
    <TopBarMainStep
      key="main-step"
      vormieterReferenznummer={
        !!protocol && !!protocol.rentalData && protocol.rentalData.vormieterReferenznummer
      }
      protocolId={protocolId}
      currentStep={currentStep}
      onNavigateTo={onNavigateTo}
      isLast={isLast}
    />
  ),
  fillStep: ({ protocolId, currentStep, onNavigateTo, isProtocolLocked, isLast }) => (
    <TopBarFillStep
      key="fill-step"
      protocolId={protocolId}
      currentStep={currentStep}
      onNavigateTo={onNavigateTo}
      isProtocolLocked={isProtocolLocked}
      isLast={isLast}
    />
  ),
  signStep: ({ protocolId, currentStep, onNavigateTo, isLast }) => (
    <TopBarSignStep
      key="sign-step"
      currentStep={currentStep}
      onNavigateTo={onNavigateTo}
      protocolId={protocolId}
      isLast={isLast}
    />
  ),
  leavingAgreementStep: ({ protocolId, currentStep, onNavigateTo, isLast, hasManagerSigned }) => (
    <TopBarLeavingAgreementStep
      key="leaving-agreement-step"
      currentStep={currentStep}
      onNavigateTo={onNavigateTo}
      protocolId={protocolId}
      isLast={isLast}
      hasManagerSigned={hasManagerSigned}
    />
  ),
  sendStep: ({ currentStep, onNavigateTo, protocolId, isLast }) => (
    <TopBarSendStep
      key="send-step"
      currentStep={currentStep}
      onNavigateTo={onNavigateTo}
      protocolId={protocolId}
      isLast={isLast}
    />
  ),
};

const mapProgressDetails = ({
  protocolId,
  currentStep,
  protocol,
  onNavigateTo,
  onOpenNotImplementedModal,
  isProtocolLocked,
  hasManagerSigned,
  stepKeys,
}) =>
  addIndex(map)((step, index) => {
    const isLast = index === stepKeys.length - 1;

    return !!steps[step] ? (
      steps[step]({
        protocolId,
        currentStep,
        protocol,
        onNavigateTo,
        onOpenNotImplementedModal,
        isProtocolLocked,
        isLast,
        hasManagerSigned,
      })
    ) : (
      <TopBarBaseStep step={step} onSelectStep={onOpenNotImplementedModal} isLast={isLast}>
        <div css={styles.step}>Not implemented</div>
      </TopBarBaseStep>
    );
  });

export const TopBarSteps = (props) => {
  const { stepKeys } = props;

  return (
    <ul key="steps" css={styles.ul}>
      {stepKeys.length > 0 ? (
        mapProgressDetails(props)(stepKeys)
      ) : (
        <TopBarBaseStep key="empty" step="empty" isLast={true} />
      )}
    </ul>
  );
};
