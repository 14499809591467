import initialState from './initialState';
import { reducer as migration } from './migrationGetMigration';
import { reducer as startMigration } from './migrationStartMigration';
import { reducer as stopMigration } from './migrationStopMigration';
import { reducer as retainMigration } from './migrationRetainMigration';
import { reducer as deleteMigration } from './migrationDeleteMigration';
import { reducer as setTemplateMigration } from './migrationSetTemplateMigration';
import { reducer as closeRetainModal } from './migrationCloseRetainModal';
import { reducer as closeDeleteModal } from './migrationCloseDeleteModal';
import { reducer as toogleShowSuccessLogs } from './migrationToogleShowSuccessLogs';
import { reducer as hubConnect } from './migrationHubConnect';
import { reducer as hubDisconnect } from './migrationHubDisconnect';
import { reducer as hubListener } from './migrationHubListener';

const reducers = [
  migration,
  startMigration,
  stopMigration,
  retainMigration,
  deleteMigration,
  setTemplateMigration,
  closeRetainModal,
  closeDeleteModal,
  toogleShowSuccessLogs,
  hubConnect,
  hubDisconnect,
  hubListener,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  /* istanbul ignore next */
  return reducers.reduce((s, r) => r(s, action), newState);
}
