import { LOCATION_CHANGE } from 'connected-react-router';
import produce from 'immer';
import { head } from 'ramda';
import { put, select, takeLatest } from 'redux-saga/effects';

import { ACCEPTANCE_PROTOCOL_SELECTED_PROTOCOL } from '../../redux/constants';
import { selectProtocolIdRoute, selectBasedataInput, selectCurrentId } from '../../redux/selectors';
import { FILL_STEP_CLEAR_SELECTED_ELEMENT, FILL_STEP_SELECT_ELEMENT } from './constants';
import { selectSelected } from './selectors';
import { COMMON_PATCH_OPERATION } from '../../../common/redux/constants';

export function fillStepSelectElement(selected) {
  return {
    type: FILL_STEP_SELECT_ELEMENT,
    payload: selected,
  };
}

export function fillStepClearSelectedElement() {
  return {
    type: FILL_STEP_CLEAR_SELECTED_ELEMENT,
  };
}

function* doSetCopiedToFalseWhenSelectingElement() {
  const protocolId = yield select(selectCurrentId);
  const selectedElement = yield select(selectSelected);

  if (
    protocolId !== null &&
    selectedElement !== null &&
    selectedElement.type === 'component' &&
    !!selectedElement.component.id &&
    !!selectedElement.element.id &&
    !!selectedElement.element.input &&
    !!selectedElement.element.input.copied
  ) {
    const expression =
      // prettier-ignore
      `componentsInput/[${selectedElement.component.id}]/elements/[${selectedElement.element.id}]/copied`;

    yield put({
      type: COMMON_PATCH_OPERATION,
      payload: {
        protocolId,
        expression,
        value: false,
      },
    });
  }
}

function* doSelectFirstElementIfNone() {
  const selectedElement = yield select(selectSelected);

  if (selectedElement === null) {
    const baseData = yield select(selectBasedataInput);

    if (!!baseData && baseData.length > 0) {
      const firstBasedata = head(baseData);

      yield put(
        fillStepSelectElement({
          type: 'basedata',
          componentId: 'basedata',
          elementId: firstBasedata.id,
        }),
      );
    }
  }
}

function* doEmptySelectedElementWhenWeNavigateAway() {
  const route = yield select(selectProtocolIdRoute);

  if (!route) {
    yield put(fillStepClearSelectedElement());
  }
}

export function* switchSelectElement() {
  yield takeLatest(FILL_STEP_SELECT_ELEMENT, doSetCopiedToFalseWhenSelectingElement);
  yield takeLatest(ACCEPTANCE_PROTOCOL_SELECTED_PROTOCOL, doSelectFirstElementIfNone);
  yield takeLatest(LOCATION_CHANGE, doEmptySelectedElementWhenWeNavigateAway);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILL_STEP_SELECT_ELEMENT:
        const { type, componentId, elementId, attributeId } = action.payload;
        draft.selectedIds.type = type;
        draft.selectedIds.componentId = componentId;
        draft.selectedIds.elementId = elementId;
        draft.selectedIds.attributeId = attributeId;
        break;
      case FILL_STEP_CLEAR_SELECTED_ELEMENT:
        draft.selectedIds.type = null;
        draft.selectedIds.componentId = null;
        draft.selectedIds.elementId = null;
        draft.selectedIds.attributeId = null;
        break;
      default:
        return state;
    }
  });
