/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';

import { colors } from '../../common/theme/colors';

const styles = {
  container: {
    display: 'grid',
    gridGap: '0.5em',
    gridAutoFlow: 'row',
    marginTop: '1em',
    gridTemplateRows: 'min-content max-content',
    textAlign: 'left',
  },

  protocolsContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
    overflowY: 'scroll',
    maxHeight: '200px',
  },

  row: {
    display: 'grid',
    gridAutoFlow: 'row',
    borderBottom: `solid 1px ${colors.dark}`,
    '&:first-of-type': {
      borderTop: `solid 1px ${colors.dark}`,
    },
  },

  protocolRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridAutoFlow: 'row',
    padding: '1em',
  },

  protocolRowInfoMessage: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 15fr',
    textAlign: 'justify',
    padding: '1em',
  },

  bold: { fontWeight: 'bold' },

  rowEven: { backgroundColor: colors.lightGrey },
  rowOdd: { backgroundColor: colors.white },
};

export const Results = (props) => {
  const { results } = props;

  function Message({ item }) {
    if (item && !!!item.success) {
      return (
        <div css={styles.protocolRowInfoMessage}>
          <span css={styles.bold}>
            <Trans>LABELS.MIGRATIONS.MESSAGE</Trans>:
          </span>
          <span>{item.errorMessage}</span>
        </div>
      );
    }
    return null;
  }

  return !!results && !!results.length ? (
    <div css={styles.container}>
      <div css={styles.bold}>
        <Trans>LABELS.MIGRATIONS.COMPLETE-WITH-ERROR</Trans>
      </div>
      <div css={styles.protocolsContainer}>
        {results.map((item, index) => (
          <div
            key={index}
            css={{
              ...styles.row,
              ...(index % 2 ? styles.rowEven : styles.rowOdd),
            }}
          >
            <div css={styles.protocolRow}>
              <div>
                <span css={styles.bold}>
                  <Trans>LABELS.MIGRATIONS.TEMPLATENAME</Trans>:
                </span>
                <span> {item.templateName}</span>
              </div>

              <div>
                <span css={styles.bold}>
                  <Trans>LABELS.MIGRATIONS.PROTOCOLNUMBER</Trans>:
                </span>
                <span> {item.protocolName}</span>
              </div>

              <div>
                <span css={styles.bold}>
                  <Trans>LABELS.MIGRATIONS.FILENAME</Trans>:
                </span>
                <span> {item.fileName}</span>
              </div>
            </div>
            <Message item={item} />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div css={styles.bold}>
      <Trans>LABELS.MIGRATIONS.COMPLETE</Trans>
    </div>
  );
};
