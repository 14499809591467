import React from 'react';

export const SignIcon = () => (
  <svg
    id="Ebene_1"
    data-name="Ebene 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    height="100%"
    widht="100%"
    fill="currentColor"
  >
    <path d="M12.6,4.88,14,3.47,10.53,0,9.12,1.4l-7,4.18L0,13.33H0L4.28,9.06a1.47,1.47,0,0,1,.28-1.69,1.49,1.49,0,1,1,.37,2.37L.67,14h0l7.75-2.14Z" />
  </svg>
);
