import produce from 'immer';

import { COMMON_OPEN_NOT_IMPLEMENTED_MODAL } from './constants';

export function commonOpenNotImplementedModal() {
  return {
    type: COMMON_OPEN_NOT_IMPLEMENTED_MODAL,
  };
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMON_OPEN_NOT_IMPLEMENTED_MODAL:
        draft.ui.modal.notImplemented = true;
        break;
      default:
        return state;
    }
  });
