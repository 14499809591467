/** @jsxImportSource @emotion/react */
import { Trans } from '@lingui/macro';

import { colors } from '../../common/theme/colors';

import { Icon } from '../common/Icon';

const styles = {
  container: {
    display: 'grid',
    gridGap: '0.5em',
    gridAutoFlow: 'row',
    marginTop: '1em',
    gridTemplateRows: 'min-content max-content',
  },

  protocolsContainer: {
    display: 'grid',
    gridAutoFlow: 'row',
  },

  row: {
    display: 'grid',
    gridAutoFlow: 'row',
    borderBottom: `solid 1px ${colors.dark}`,
    '&:first-of-type': {
      borderTop: `solid 1px ${colors.dark}`,
    },
  },

  protocolRow: {
    paddingLeft: '1em',
    paddingTop: '1em',
    paddingRigth: '1em',
    paddingBottom: '0em',
  },

  protocolRowInfoContainer: {
    display: 'grid',
    gridGap: '0.5em',
    gridTemplateRows: '1fr',
    gridAutoFlow: 'row',
    paddingLeft: '1em',
    paddingTop: '0.5em',
    paddingBottom: '1em',
  },

  protocolRowInfoArea: {
    display: 'grid',
    gridTemplateColumns: '1fr 15fr',
    gridAutoFlow: 'row',
    paddingRight: '1em',
  },

  protocolRowInfoAreaBorder: {
    borderTop: `solid 1px ${colors.lighterGrey}`,
  },

  protocolRowInfoText: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: '1em',
    paddingTop: '1em',
  },

  protocolRowInfoIcon: {
    display: 'grid',
    gridAutoFlow: 'row',
  },

  protocolRowInfoIconPadding: {
    paddingTop: '0.5em',
  },

  protocolRowInfoFileName: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 1fr',
  },

  protocolRowInfoMessage: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateColumns: '1fr 15fr',
    textAlign: 'justify',
    paddingRight: '1em',
  },

  bold: { fontWeight: 'bold' },

  rowEven: { backgroundColor: colors.lightGrey },
  rowOdd: { backgroundColor: colors.white },
};

export const MigrationResults = (props) => {
  const { results, selectedTemplate } = props;

  function Message({ item }) {
    if (item && !!!item.success) {
      return (
        <div css={styles.protocolRowInfoMessage}>
          <span css={styles.bold}>
            <Trans>LABELS.MIGRATIONS.MESSAGE</Trans>:
          </span>
          <span>{item.errorMessage}</span>
        </div>
      );
    }
    return null;
  }

  function FileIcon({ item }) {
    if (!!item) {
      const colorIcon = item.success ? colors.green : item.skipped ? colors.yellow : colors.red;
      if (!!item.fileName) {
        const ext = item.extension;
        if (ext) {
          switch (ext) {
            case '.pdf':
              return <Icon icon={'file-pdf'} color={colorIcon} size="2x" />;
            case '.json':
              return <Icon icon={'file-code'} color={colorIcon} size="2x" />;
            case '.css':
              return <Icon icon={'file'} color={colorIcon} size="2x" />;
            case '.png':
              return <Icon icon={'file-image'} color={colorIcon} size="2x" />;
            case '.pug':
              return <Icon icon={'cog'} color={colorIcon} size="2x" />;
            case '.html':
            case '.cshtml':
              return <Icon icon={'file-alt'} color={colorIcon} size="2x" />;
            default:
              return <Icon icon={'bug'} color={colorIcon} size="2x" />;
          }
        }
      }
      return <Icon icon={'bug'} color={colorIcon} size="2x" title="bug" />;
    }
    return <Icon icon={'comment-dots'} color={colors.green} size={'3x'} />;
  }

  return !!results && results.length ? (
    <div css={styles.container}>
      {selectedTemplate && (
        <div>
          <span css={styles.bold}>{selectedTemplate}</span>
        </div>
      )}
      <div css={styles.protocolsContainer}>
        {results.map((item, index) => (
          <div
            key={index}
            css={{
              ...styles.row,
              ...(index % 2 ? styles.rowEven : styles.rowOdd),
            }}
          >
            <div css={styles.protocolRow}>
              <div css={styles.bold}>{item.protocolName}</div>

              <div css={styles.protocolRowInfoContainer}>
                {item.results.map((item2, index2) => (
                  <div
                    key={index2}
                    css={{
                      ...styles.protocolRowInfoArea,
                      ...styles.protocolRowInfoAreaBorder,
                    }}
                  >
                    <div
                      css={{
                        ...styles.protocolRowInfoIcon,
                        ...styles.protocolRowInfoIconPadding,
                      }}
                    >
                      <FileIcon item={item2} />
                    </div>
                    <div css={styles.protocolRowInfoText}>
                      <div css={styles.protocolRowInfoFileName}>
                        {!!item2.fileName ? (
                          <div>
                            <span css={styles.bold}>
                              <Trans>LABELS.MIGRATIONS.FILENAME</Trans>:
                            </span>
                            <span> {item2.fileName}</span>
                          </div>
                        ) : (
                          <div />
                        )}
                        {!!item2.shortDate ? (
                          <div>
                            <span css={styles.bold}>
                              <Trans>LABELS.MIGRATIONS.IMPORTDATE</Trans>:
                            </span>
                            <span> {item2.shortDate}</span>
                          </div>
                        ) : (
                          <div />
                        )}
                      </div>
                      <Message item={item2} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div css={styles.container}>
      <div css={styles.protocolsContainer}>
        <div
          css={{
            ...styles.row,
            ...styles.rowOdd,
          }}
        >
          <div css={styles.protocolRow}>
            <div css={styles.protocolRowInfoContainer}>
              <div css={styles.protocolRowInfoArea}>
                <div css={styles.protocolRowInfoIcon}>
                  <FileIcon item={null} />
                </div>
                <div css={styles.protocolRowInfoText}>
                  <Trans>LABELS.MIGRATIONS.NOTHINGTOSHOW</Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
