import produce from 'immer';

import { HOME_OPEN_PROTOCOL_WITH_REM_REFERENCE_MODAL } from './constants';
import { put, takeLatest } from 'redux-saga/effects';
import { homeGetTemplates } from './homeGetTemplates';

export function homeOpenProtocolWithREMReferenceModal() {
  return { type: HOME_OPEN_PROTOCOL_WITH_REM_REFERENCE_MODAL };
}

function* doGetTemplates() {
  yield put(homeGetTemplates());
}

export function* switchOpenProtocolWithREMReferenceModal() {
  yield takeLatest(HOME_OPEN_PROTOCOL_WITH_REM_REFERENCE_MODAL, doGetTemplates);
}

export const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case HOME_OPEN_PROTOCOL_WITH_REM_REFERENCE_MODAL:
        draft.ui.modal.protocolWithREMReference = true;
        break;
      default:
        return state;
    }
  });
