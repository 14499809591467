import { takeLatest } from 'redux-saga/effects';
import { FILL_STEP_SAVE_IMAGE_ERROR, FILL_STEP_NOTIFY_ERROR } from './constants';
import { doToast } from "../../../../common/rootToastr";

export function notifyError(message) {
  return {
    type: FILL_STEP_NOTIFY_ERROR,
    payload: message,
  };
}

function* doNotifyError({ payload }) {
  yield* doToast({
    payload: {
      type: 'error',
      attention: true,
      message: payload,
    }
  });
}

export function* switchNotifyError() {
  yield takeLatest([FILL_STEP_SAVE_IMAGE_ERROR, FILL_STEP_NOTIFY_ERROR], doNotifyError);
}
